export const DATE_FORMATS = {
  approval_date: 'yyyy-MM-dd',
  version_date: 'yyyy-MM-dd',
  submitted_date: 'yyyy-MM-dd',
  decision_date: 'yyyy-MM-dd',
  revision_date: 'yyyy-MM-dd',
  notification_issue_date: 'MMM dd, yyyy',
  commission_decision_date: 'MMM dd, yyyy'
};

export default DATE_FORMATS;
