import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { customTransform } from '../utils';
import styles from './styles';
import datagridStyles from '../Datagrid.styles';

interface GridCellExpandProps {
  value: string;
  width: number;
  field: string;
}

const isOverflown = (element: Element): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { width, field } = props;
  let { value } = props;
  value = customTransform(value, field);
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={styles.wrapper}>
      <Box ref={cellDiv} sx={{ ...styles.cellDiv, width }} />
      <Box>
        {value?.split('\n')?.map((line: string, index: number) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            ref={cellValue}
            sx={{
              ...styles.clamppedText,
              ...datagridStyles.datagridCellText
            }}
            className='cellValue'>
            {line}
          </Box>
        ))}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          placement='bottom'
          style={{ width, marginLeft: -17, zIndex: 1300 }}>
          <Paper
            elevation={1}
            sx={{ ...styles.popperPaper, minHeight: wrapper.current!.offsetHeight - 3 }}>
            {value?.split('\n')?.map((line: string, index: number) => (
              <Typography
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                variant='subtitle2'
                style={{ padding: 2, lineHeight: '21px' }}>
                {line}
              </Typography>
            ))}
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const renderCellExpand = (params: GridRenderCellParams) => {
  const { field } = params as any;

  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} field={field} />
  );
};

export default renderCellExpand;
