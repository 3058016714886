export const applNumberMixin = {
  color: 'white.main',
  borderRadius: '8px',
  padding: '3px 8px',
  fontSize: '12px',
  fontWeight: 400,
  width: 'max-content'
};

const nameMixin = {
  fontWeight: 700,
  fontSize: '14px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap'
};

const styles = {
  dropDownBox: {
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  appNumRed: {
    ...applNumberMixin,
    backgroundColor: 'red.light'
  },
  appNumBlue: {
    ...applNumberMixin,
    backgroundColor: 'blue.berry'
  },
  nameRed: {
    ...nameMixin,
    color: 'red.light'
  },
  nameBlue: {
    ...nameMixin,
    color: 'blue.berry'
  },
  chipMetadata: {
    ml: 1,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    }
  },
  tradeName: {
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  identifier: {
    color: 'white.main',
    borderRadius: '8px',
    padding: '3px 8px',
    fontSize: '12px',
    fontWeight: 400,
    width: 'max-content'
  },
  identifierNumber: {
    color: 'gray.700',
    fontSize: '12px',
    fontWeight: 400
  },
  title: {
    fontWeight: 700,
    fontSize: '16px',
    textTransform: 'uppercase',
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  searchTerm: {
    color: 'white.main',
    fontWeight: '700',
    fontSize: '14px'
  },
  timeLineCards: {
    pt: '2.5rem',
    position: 'sticky !important',
    left: 0,
    zIndex: 3,
    backgroundColor: 'white.main',
    display: 'flex',
    height: 'fit-content'
  },
  yearBox: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: 'white.main'
  },
  headerText: {
    fontWeight: 700,
    textTransform: 'capitalize',
    color: 'primary.600',
    fontSize: '14px'
  },
  reportIcon: {
    height: '16px',
    width: '16px',
    color: 'secondary.500'
  },
  visualizeButton: {
    minWidth: 0,
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    color: 'secondary.500'
  },
  dialogBox: {
    '& >.MuiDrawer-paper': {
      height: '98%',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      position: 'absolute',
      backgroundColor: 'white.smokeDark'
    }
  },
  gridContainer: {
    overflow: 'auto',
    maxHeight: '75vh',
    padding: '24px 24px 0px 24px',
    background: 'white',
    border: '1px solid',
    borderColor: 'grey.300',
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.300',
      borderRadius: '5px'
    }
  },
  badgeContainer: {
    zIndex: 1,
    position: 'relative',
    top: '28%',
    textAlign: 'center',
    pl: '16px',
    pr: '16px'
  },
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'blue.backgroundLight',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    fontSize: '12px',
    fontWeigth: '400'
  },
  documentIcon: {
    height: '18px',
    width: '18px',
    color: 'blue.background',
    cursor: 'pointer',
    ml: '5px'
  },
  pdfContainer: {
    width: 700,
    borderRadius: 5,
    mb: 10,
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    maxHeight: '99vh',
    '& > div#pdfBox': { height: '86%', width: '96%' }
  },
  snackBarContainer: {
    right: `655px !important`,
    top: `50px !important`
  },
  titleText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 700,
    textOverflow: 'ellipsis'
  },
  chatRiaIcon: {
    width: '16px',
    height: '16px',
    p: '2.667px 2.666px 2.667px 2.667px'
  },
  closeIcon: { width: '18px', height: '18px', cursor: 'pointer', color: 'grey.600' },
  message: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  add: {
    height: 35,
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      color: 'primary.lightBluish',
      borderStyle: 'solid'
    }
  },
  addText: {
    color: 'primary.lightBluish',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  reportGridContainer: {
    display: 'inline-block',
    height: '100%',
    width: '100%',
    'page-break-before': 'avoid',
    'page-break-after': 'avoid'
  },
  plusIcon: { height: 13, width: 13, color: 'primary.700' },
  yearText: { fontWeight: 600, color: 'gray.600', fontSize: 14 },
  patentIcon: {
    height: '12px',
    width: '12px',
    backgroundColor: 'primary.500',
    mx: 1
  },
  exclusivityIcon: {
    height: '12px',
    width: '12px',
    backgroundColor: 'secondary.500',
    mx: 1
  }
};

export const CardContainer: any = {
  p: 0.5,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  mt: 0,
  border: '1px solid',
  borderColor: 'gray.300',
  borderRadius: '8px',
  position: 'relative',
  display: 'flex',
  width: '170px',
  padding: '8px 12px',
  alignItems: 'stretch',
  gap: '2px',
  backgroundColor: 'white.main',
  justifyContent: 'space-between',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'white.main',
    boxShadow: 0
  },
  mb: '17px'
};

export const snackBarContentStyle: any = {
  backdropFilter: 'blur(10px)',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: 650,
  height: `calc(100vh - 0px)`,
  maxHeight: `calc(100vh - 0px)`,
  top: '0px',
  right: '0 !important',
  backgroundColor: 'gray.50'
};

export const horizontalTimelineStyle: any = {
  cursor: 'grab',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  backgroundColor: 'white.main'
};
export const plusStyle: any = {
  position: 'relative',
  height: '76px',
  borderColor: 'grey.400',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    background: '#0000'
  },
  '&::before': {
    left: '50%',
    top: '0%',
    bottom: '4px',
    transform: 'translateX(-50%)',
    border: '1px dashed #adadad80'
  },
  '&::after': {
    left: '0%',
    top: '50%',
    right: '4px',
    height: '1px',
    transform: 'translateY(-50%)',
    border: '1px dashed #adadad80'
  }
};
export const badgeStyle: any = {
  p: '4px 8px',
  border: '1px solid',
  borderRadius: '33px',
  fontSize: '13px',
  fontWeight: 700,
  lineHeight: '16px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecorationSkipInk: 'none',
  cursor: 'pointer',
  zIndex: 1,
  '&#patent': {
    backgroundColor: 'primary.background',
    borderColor: 'primary.darkVariant2',
    color: 'primary.darkVariant2',
    minWidth: '110px',
    mx: '10px'
  },
  '&#patent:hover': {
    backgroundColor: 'primary.main',
    color: 'white.main'
  },
  '&#exclusivity': {
    backgroundColor: 'secondary.0',
    borderColor: 'secondary.main',
    color: 'secondary.main',
    minWidth: '110px',
    mx: '10px'
  },
  '&#exclusivity:hover': {
    backgroundColor: 'secondary.main',
    color: 'white.main'
  },
  '&#more': {
    borderRadius: '50%',
    m: 'auto',
    color: 'gray.750',
    backgroundColor: 'gray.background',
    borderColor: 'gray.750'
  }
};

export const snackBarHeader: any = {
  backgroundColor: 'primary.600',
  color: 'gray.0',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '30px',
  textAlign: 'left',
  textDecorationSkipInk: 'none',
  p: '12px 16px'
};

export const snackBarReg360Icon: any = {
  color: 'primary.700',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  textTransform: 'none',
  '& svg': {
    height: '16px !important',
    width: '16px !important'
  }
};
export const infoDiv: any = {
  '&>div': {
    display: 'flex',
    minHeight: '56px',
    padding: '8px 16px',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    whiteSpace: 'normal'
  },
  color: 'gray.900',
  backgroundColor: 'white !important',
  '&#heading': {
    color: 'gray.700'
  }
};
export const infoDivContainer: any = {
  '& > div': {
    backgroundColor: 'white !important',
    p: '12px',
    boxShadow: '0px 0px 4px 0px #E0E0E0'
  },
  '& > div:nth-child(odd)': {
    borderRadius: '4px 0px 0px 4px'
  },
  '& > div:nth-child(even)': {
    borderRadius: '0px 4px 4px 0px'
  }
};

export default styles;
