import makeRequest, { makePDFRequest } from '../client';

// eslint-disable-next-line import/prefer-default-export
export const getGraphData = (templateId: string, payload: any) => {
  const xClientId = (window as any).xClientId || null;
  return makeRequest(`/clignosis/graph/${templateId}`, 'POST', payload, {
    ...(xClientId ? { 'x-client-id': xClientId } : {})
  });
};

export const getGraphSuggestions = (source: string, graphType: string, module: string) => {
  // eslint-disable-next-line prefer-destructuring
  const xClientId = (window as any).xClientId;
  return makeRequest(
    `/clignosis/graph/suggestion/${source}/${module}/${graphType}`,
    'GET',
    {},
    {
      ...(xClientId ? { 'x-client-id': xClientId } : {})
    }
  );
};

export const getGraphDataByConfig = (graphType: string, payload: any) => {
  // eslint-disable-next-line prefer-destructuring
  const xClientId = (window as any).xClientId;
  return makeRequest(`/clignosis/graph/data/${graphType}`, 'POST', payload, {
    ...(xClientId ? { 'x-client-id': xClientId } : {})
  });
};

export const getLogoS3Lnk = (category: string, logoName: string) => {
  const xClientId = (window as any).xClientId || null;
  return makeRequest(
    `/report/assets/${category}/${logoName}/s3_url`,
    'GET',
    {},
    {
      ...(xClientId ? { 'x-client-id': xClientId } : {})
    }
  );
};

export const uploadReporFile = (payload: FormData) => {
  const xClientId = (window as any).xClientId || null;
  return makeRequest(`/report/assets/upload`, 'POST', payload, {
    ...(xClientId ? { 'x-client-id': xClientId } : {})
  });
};

export const getReportPDF = (payload: any) => {
  return makePDFRequest(`/report/layout/download`, 'POST', payload);
};
