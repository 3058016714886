/**
 * this object will contain all the hex values of colors being used in the application.
 * for each color we can have standardized variables to differentiate the shades of each color
 * variables - main, dark, light, darkVariant, lightVariant, background, border, contrastText
 */
const palette = {
  primary: {
    main: '#33B187',
    dark: '#12815E',
    medium: '#2ecc71',
    darkVariant: '#16A085',
    darkVariant1: '#424242',
    darkVariant2: '#0A806D',
    darkBluish: '#028b8b',
    lightBluish: '#3AB09E',
    light: '#48E0A6',
    lightVariant: '#30B5A8',
    lightVariant1: '#E1F3F1',
    background: '#F2F7F7',
    backgroundDark: '#DCEDE7',
    darkCyan: '#008b8b',
    backgroundPrimary: '#46B163',
    backgroundLight: '#D0EFE5',
    backgroundLightVariant: '#E6F1F1',
    avatar: '#289D78',
    green: '#06C66C',
    textVariant: '#0D917D',
    0: '#F2F7F7',
    50: '#E1F3F1FF',
    100: '#B4E2DBFF',
    150: '#B4E2DB',
    200: '#83D0C4FF',
    300: '#52BCAD',
    400: '#2DAD9BFF',
    450: '#2DAD9B',
    500: '#109E8AFF',
    550: '#109E8A',
    600: '#0D917DFF',
    650: '#0D917D',
    700: '#0A806DFF',
    800: '#06705FFF',
    850: '#06705F',
    900: '#015442FF',
    950: '#015442'
  },
  secondary: {
    main: '#0076D6FF',
    0: '#F1F7FB',
    50: '#E3F2FDFF',
    75: '#E3F2FD',
    100: '#BADEFCFF',
    200: '#8DCAFBFF',
    250: '#8DCAFB',
    300: '#5FB5F9FF',
    350: '#5FB5F9',
    400: '#38A5F9FF',
    450: '#38A5F9',
    500: '#0096F7FF',
    600: '#0088E9FF',
    650: '#0088E9',
    700: '#0076D6FF',
    800: '#0065C4FF',
    900: '#0047A5FF',
    950: '#0047A5'
  },
  black: {
    main: '#000000',
    dark: '#090909',
    darkVariant: '#1D262D',
    darkVariant1: '#454545',
    darkVariant3: '#050505',
    light: '#202020',
    lightVariant: '#323232',
    lightVariant2: '#5C5C5C',
    lightVariant4: '#4F4F4F',
    contrastText: '#99a3ad',
    shadow: '#00000008',
    avatar: '#333333',
    darkVariant2: '#525252',
    gravel: '#4F4F4F',
    disable: '#00000042'
  },
  gray: {
    main: '#707070',
    sonicSilver: '#777',
    dark: '#505050',
    darkVariant: '#5F5F5F',
    darkStorm: '#6D7D8A',
    light: '#B2B2B2',
    lightVariant: '#6E6E6E',
    lightVariant2: '#7E7E7E',
    lightVariant3: '#BDBDBD',
    lightVariant4: '#828282',
    lightVariant5: '#EDEDED80',
    lightVariant6: '#7D7D7D',
    lightVariant7: '#696974',
    lightVariant8: '#E0E0E0',
    dove: '#6A6A6A',
    background: '#EEEEEE',
    backgroundDark: '#F0F2FD',
    backgroundVariant: '#EDEDED',
    contrastText: '#8B8B8B',
    backgroundLight: '#C9D2F6',
    backgroundLightVariant: '#f1f2f8',
    backgroundLightVariant2: '#FBFBFB',
    backgroundLightVariant3: '#F8F8F8',
    scrollBarBg: '#f1f1f1',
    scrollBarThumb: '#888',
    border: '#CDC9C9',
    lightText: '#B1BBE4',
    lightBorder: '#00000029',
    lightBorderVariant: '#D3D3D3',
    darkBluish: '#464255',
    darkText: '#646464',
    lightBackground: '#F4F7F7',
    collaborateGray: '#f4f4f4',
    mediumDark: '#434343',
    0: '#FFFFFFFF',
    50: '#FBFBFB',
    100: '#F5F5F5FF',
    200: '#EEEEEEFF',
    300: '#E0E0E0FF',
    400: '#BDBDBDFF',
    500: '#9E9E9EFF',
    550: '#9E9E9E',
    600: '#757575FF',
    650: '#757575',
    700: '#616161FF',
    750: '#616161',
    800: '#424242FF',
    900: '#212121FF',
    950: '#212121',
    green: '#9DB7B3'
  },
  blue: {
    main: '#6868f9',
    periwinkle: '#6868FA',
    backgroundLight: '#4172D9',
    light: '#6C9BFD',
    royal: '#4A4EC6',
    lightRoyal: '#2C87F1',
    lightNavy: '#7879f1',
    berry: '#6868FA',
    alice: '#e3f1ff',
    lightVariant: '#defaff',
    cyan: '#29ABE2',
    pastelPurpleBlue: '#7775DE',
    purple: '#6F3DDB',
    background: '#0076D6',
    gray700: '#385C75',
    gray400: '#A8BFCE'
  },
  white: {
    main: '#FFFFFF',
    smoke: '#F7F7F7',
    smokeDark: '#F5F5F5',
    background: '#FAFAFA',
    light: '#f1fbf7',
    aliceBlue: '#F1F5F8',
    aliceBlueDark: '#eaf0f4'
  },
  red: {
    main: '#e74c3c',
    light: '#ea5252',
    brown: '#A33232',
    background: '#CA4E50',
    lightVariant: '#845100',
    100: '#B22234',
    200: '#E74C3C',
    900: '#B00020',
    600: '#DC5A59'
  },
  orange: {
    main: '#f39c12',
    rose: '#FF4C4C',
    dark: '#FF883E',
    light: '#FFF5EA',
    darkVariant1: '#BD4B00',
    background: '#F39C12'
  },
  yellow: {
    brown: '#CAA563',
    400: '#F89C11',
    700: '#D3760C',
    900: '#845100'
  },
  green: {
    accept: '#3AB09E',
    jungle: '#289D78',
    emerald: '#119E8A',
    sea: '#219653',
    light: '#009163',
    lightVariant1: '#D5EEE6'
  },
  gradient: {
    blueToGreen: 'linear-gradient(90deg, #29ABE2 0%, #39B54A 100%)',
    emraldToGreen: 'linear-gradient(to right, #3C999A, #47A836)'
  },

  gold: {
    main: '#ffd700'
  },
  pink: {
    orchid: '#DA70D6'
  },
  purple: {
    main: '#5D5FEF'
  },
  error: {
    main: '#B00020',
    900: '#B00020'
  }
};

export default palette;
