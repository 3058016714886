import React, { useCallback, useMemo } from 'react';

import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
// components
import { isEmpty } from 'lodash';
import CustomBarGraph from '../../../../components/CustomComponents/Graphs/CustomBarGraph';
import { InsightsLoader } from './InsightsLoader';

// utils
import truncateCountAboveMillions from '../../../../utils/truncateCountAboveMillions';
import formatCount from '../../../../utils/formatCount';

// styles
import styles from '../../styles/HomePage.styles';
import { BAR_CHART_COLOR } from '../../../../components/CustomComponents/Graphs/constant';
import convertDateRange from '../../../PDCO/utils/convertDateRange';
import ReportButton from '../../../../components/Report/ReportButton';
import VISUALIZE_CHART_MAPPING from '../../../../components/Insights/const';
import { sourceMap } from '../../../ResultsPage/components/SearchSuggestions';
import useReportingHandler from '../../../../components/Report/hooks/useReportingHandler';

const HomePage = ({
  stats,
  loading,
  source = '',
  searchId = '',
  filters = {}
}: {
  stats: any;
  loading: boolean;
  // eslint-disable-next-line react/require-default-props
  source?: string;
  // eslint-disable-next-line react/require-default-props
  searchId?: string;
  // eslint-disable-next-line react/require-default-props
  filters?: any;
}) => {
  const { updateReportData } = useReportingHandler();
  const customGraphData = useMemo(() => {
    return stats?.unique_data_by_year?.filter((item: any) => item.year !== 0) || [];
  }, [stats]);

  const getData = useMemo(() => {
    if (source && source in VISUALIZE_CHART_MAPPING) {
      const dataSet = VISUALIZE_CHART_MAPPING[source].layer1[0];
      if (stats) {
        dataSet.data = customGraphData;
        return dataSet;
      }
      return dataSet;
    }
    return {};
  }, []);

  const addToReport = useCallback(
    async (data: any, reportData: any) => {
      let referenceText = '';
      if (source in sourceMap) {
        referenceText += sourceMap[source];
      }
      const sectionLayout = {
        templateType: 'ria_graph',
        templateId: data?.id,
        chartType: data?.graphType,
        sectionType: 'CHART',
        id: reportData?.reportId,
        style: {
          placement: {
            h: 40,
            w: 12,
            i: reportData?.reportId,
            minW: 8,
            moved: false,
            static: false,
            x: 0,
            y: 0
          },
          references: {
            show: true,
            href: window.location.href,
            text: `${referenceText}`
          },
          graphStyle: data?.graphStyle,
          title: data?.title
        },
        data: {
          filters,
          search_id: searchId || ''
        }
      };
      updateReportData(reportData?.id, sectionLayout);
    },
    [getData]
  );

  const renderStatTile = (title: string, value: number) => (
    <Stack sx={styles.statsTile} justifyContent='center'>
      <Box>
        <Tooltip title={value >= 10000 ? formatCount(value) : ''} placement='top'>
          <Typography textAlign='left' fontSize={20} fontWeight={700} letterSpacing='0.4px'>
            {truncateCountAboveMillions(value)}
          </Typography>
        </Tooltip>
        <Typography fontSize={14} fontWeight={400} lineHeight='150%' color='gray.700'>
          {title}
        </Typography>
      </Box>
    </Stack>
  );

  if (loading) {
    return <InsightsLoader />;
  }

  return (
    <Grid height='100%' m={0} container spacing={2}>
      {/* grid for stats counts */}
      <Grid item xs={2} p='0px !important'>
        <Stack spacing={2.5} direction='column'>
          {Object.keys(stats?.statsTiles)?.length > 0 &&
            Array.from({ length: 3 }, (_, index) =>
              renderStatTile(stats.statsTiles[index].title, stats.statsTiles[index].value)
            )}
        </Stack>
      </Grid>
      <Grid item xs={10} pt='0px !important'>
        {/* basic info */}
        <Stack spacing={2} direction='row'>
          <Stack sx={styles.basicInfoContainer}>
            {stats?.basic_info && Object.keys(stats?.basic_info)?.length > 0 ? (
              <>
                <Typography fontSize={16} fontWeight={700}>
                  {stats?.basic_info?.title}
                </Typography>
                <Stack sx={{ ...styles.scrollContainer, pr: 1.5 }} spacing={2}>
                  <Typography fontSize={14} color='gray.700' lineHeight='150%'>
                    {stats?.basic_info?.defination}
                  </Typography>
                  <Stack>
                    <Typography fontSize={14} fontWeight={700} color='gray.700' lineHeight='150%'>
                      Role:
                    </Typography>
                    <Typography fontSize={14} color='gray.700'>
                      {stats?.basic_info?.role}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            ) : (
              <Typography fontSize={14} fontWeight={700}>
                No information available
              </Typography>
            )}
          </Stack>

          {/* Upcoming Meetings */}
          <Stack
            justifyContent='flex-start'
            alignItems='flex-start'
            spacing={1.5}
            sx={styles.basicInfoContainer}>
            {stats?.upcoming_events && Object.keys(stats?.upcoming_events).length !== 0 ? (
              <>
                <Typography sx={styles.graphHeading}>Upcoming Meetings</Typography>
                <Stack gap={1} width='100%' sx={{ ...styles.scrollContainer, pr: 1 }}>
                  {Object.entries(stats?.upcoming_events).map(([key, value]: any) => {
                    const { startDate, endDate } = convertDateRange(value.date);

                    return (
                      <Box key={key} sx={styles.upcomingMeetingsContainer}>
                        <Box
                          gap={1}
                          display='flex'
                          justifyContent='space-between'
                          flexDirection='column'>
                          <Tooltip title={value?.title}>
                            <Typography noWrap sx={styles.dialogText}>
                              {value?.title}
                            </Typography>
                          </Tooltip>
                          <Box>
                            <Typography fontSize={14} color='gray.700' lineHeight='150%'>
                              Start: {value.start_date || startDate}
                            </Typography>
                            <Typography fontSize={14} color='gray.700' lineHeight='150%'>
                              End: {value.end_date || endDate}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </>
            ) : (
              <Typography fontSize={14} fontWeight={700}>
                No upcoming meetings found
              </Typography>
            )}
          </Stack>
        </Stack>

        {/* bar graph */}
        <Stack sx={styles.pieCharContainer}>
          {!isEmpty(customGraphData) ? (
            <>
              <Box height='10%' display='flex' justifyContent='space-between'>
                <Typography sx={styles.graphHeading}>{stats?.graph_title}</Typography>
                <ReportButton data={getData} addToReport={addToReport} />
              </Box>

              <CustomBarGraph
                keys={['year']}
                index='key'
                colors={BAR_CHART_COLOR}
                data={customGraphData}
                paddings={0.7}
                tickRotationValue={90}
                borderWidth='0px'
                enableLabel
                borderRadius={4}
                axisText='#9E9E9E'
                labelTextColor='#ffffff'
                margins={{ top: 30, right: 0, bottom: 30, left: 30 }}
              />
            </>
          ) : (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              <Typography fontSize={14} fontWeight={700}>
                No data available
              </Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default React.memo(HomePage);
