const widgetStyles = {
  gridContainer: {
    display: 'flex',
    height: 400,
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  dialogBox: {
    '.MuiDialog-paper': {
      maxWidth: 1200,
      borderRadius: 1.25
    }
  },
  selectGrid: { borderLeft: '1px solid', borderColor: 'gray.500' },
  headingContainer: {
    display: 'flex',
    height: '48px',
    padding: '16px 32px',
    backgroundColor: 'primary.400',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headingText: {
    color: 'gray.0',
    fontSize: 20,
    fontWeight: 700
  },
  closeButton: {
    border: '2px solid',
    width: 24,
    height: 24,
    color: 'white.main',
    borderColor: 'white.main',
    padding: '3px !important',
    ml: 1,
    '& > svg': {
      fontSize: 20
    },
    '&:hover': {
      color: 'primary.main',
      borderColor: 'primary.main',
      backgroundColor: 'white.main'
    }
  },
  propertyContainer: {
    pt: 2,
    display: 'flex',
    padding: '16px 32px 0px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  tabHeader: {
    fontSize: 16,
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
  divider: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
    borderRadius: 2,
    height: 24
  },
  checkBox: {
    '& .MuiSvgIcon-root': {
      fontSize: 16
    },
    color: 'grey.800',
    ml: 0,
    mr: 0
  },
  propertiesHeading: {
    color: 'gray.600',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'capitalize'
  },
  colorHeading: {
    color: 'gray.600',
    fontSize: 12,
    fontWeight: 400
  },
  riaGraphBoxContainer: {
    display: 'flex',
    width: 270,
    justifyContent: 'flex-end',
    alignItems: 'center',
    pl: 2
  },
  dividerContainer: {
    height: '1px',
    backgroundColor: 'gray.600',
    mx: 1,
    my: 2.5
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      width: 256,
      height: 40,
      display: 'flex'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0 !important',
      borderWidth: '1px !important',
      borderRadius: '8px !important'
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important',
      borderRadius: '8px !important'
    }
  },
  customStyled: {
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      height: 40,
      alignItems: 'end'
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      fontWeight: 500
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0 !important',
      borderWidth: '1px !important',
      borderRadius: '8px !important'
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important',
      borderRadius: '8px !important'
    },
    '& .MuiSvgIcon-root': {
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  reportButton: {
    display: 'flex',
    height: 32,
    minWidth: 100,
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.75,
    borderRadius: 6,
    backgroundColor: 'gray.0',
    color: 'primary.700',
    fontSize: 14,
    fontWeight: 600,
    border: '1px solid',
    borderColor: 'primary.700',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'primary.0'
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.50'
    },
    '&:active': {
      backgroundColor: 'primary.600'
    }
  },
  reportCloseButton: {
    display: 'flex',
    height: 32,
    minWidth: 100,
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.75,
    borderRadius: 6,
    backgroundColor: 'gray.0',
    color: 'primary.700',
    fontSize: 14,
    fontWeight: 600,
    border: '1px solid',
    borderColor: 'primary.700',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'primary.0'
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.50'
    },
    '&:active': {
      backgroundColor: 'primary.600'
    }
  },
  formStyle: {
    mr: 1,
    mb: 1,
    width: '95%'
  },
  scopeRadio: {
    '& .MuiTypography-root': {
      color: 'gray.600',
      fontSize: 14,
      fontWeight: 400
    }
  },
  scopeRadioButton: {
    p: '9px',
    '& .MuiSvgIcon-root': {
      fontSize: 16
    },
    '&.Mui-checked': {
      color: 'primary.600'
    }
  },
  tableDropDown: {
    width: '100%!important',
    marginLeft: '2px',
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      height: 'fit-content',
      alignItems: 'end'
    }
  },
  chartPopover: {
    display: 'inline-flex',
    minWidth: 200,
    padding: '12px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 1,
    backgroundColor: 'gray.0'
  },
  chartIcon: {
    display: 'flex',
    padding: '6px 32px 6px 24px',
    alignItems: 'center',
    gap: 1.5,
    fontSize: 14,
    fontWeight: 400,
    color: 'gray.800',
    cursor: 'pointer'
  },
  reportText: {
    color: 'gray.0',
    fontWeight: 600,
    fontSize: 14,
    alignItems: 'center',
    display: 'inline-flex',
    textTransform: 'capitalize',
    gap: 1,
    '&:hover': {
      background: 'none'
    }
  },
  buttonContainer: {
    mt: 2,
    mb: 4,
    ml: 2,
    mr: 4.5
  },
  submitButton: {
    display: 'flex',
    height: 32,
    minWidth: 100,
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.75,
    borderRadius: 6,
    color: 'gray.0',
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: 'primary.600',
    '&:hover': {
      backgroundColor: 'primary.700'
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.800'
    },
    '&:active': {
      backgroundColor: 'primary.900'
    },
    textTransform: 'capitalize'
  },
  reportSubmitButton: {
    height: 32,
    borderRadius: 1.5,
    backgroundColor: 'primary.600',
    cursor: 'pointer'
  },
  tableColumn: {
    display: 'flex',
    alignItems: 'center',
    flex: 3,
    mt: 1
  }
};

export default widgetStyles;
