import React, { useContext, useMemo, useState } from 'react';
import { startCase } from 'lodash';

// mui components
import { Divider, Stack, Box } from '@mui/material';

// components
import DropDownButton from '../DropDownButton';
import SourceDropDown from '../../../Home/components/SourceDropDown';
import FavoriteAndProjectActions from '../../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import ShareButton from '../../Buttons/ShareButton';

// styles
import styles from '../../styles/ApplicationCard.styles';

// constants
import { SOURCE_DROPDOWN_TYPES } from '../../../Home/const';

// hooks
import useEntities from '../../hooks/useEntities';

// context
import ResultsStore from '../../../../store/SearchResults';
import ResultActions from '../../../../store/SearchResults/actions';

// utils
import { getFilteredSourceMenuItemsForModuleTabs } from '../../utils/moduleTabUtils';

const ModuleHeader = ({ viewType, searchId }: { viewType: string; searchId: string }) => {
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const {
    searchType,
    selectedSources,
    handleSourceChange,
    entityCategory,
    entityCategoryOptions,
    applyCategoryEntity,
    entityValue,
    entityTextOptions,
    applyEntityText,
    makeSearch
  } = useEntities();

  const moduleSourceDropdownOptions = useMemo(
    () => getFilteredSourceMenuItemsForModuleTabs(viewType),
    []
  );

  // Secondary Navbar for Module View
  return (
    <Stack pt={0.75} pb={1} mx={2}>
      <Stack direction='row' borderBottom='1px solid rgba(0, 0, 0, 0.05)'>
        {searchType !== 'advanced' && (
          <Box justifySelf='start' display='flex' alignItems='center'>
            <Box fontSize={14} fontWeight={700}>
              Showing results for
            </Box>
            {/* Source selection dropdown on module tab resutls */}
            <Stack direction='row' alignItems='center'>
              <SourceDropDown
                selectedSources={selectedSources}
                handleChange={handleSourceChange}
                options={moduleSourceDropdownOptions}
                anchorEl={sourceDropDownAnchorEl}
                setAnchorEl={setSourceDropDownAnchorEl}
                type={SOURCE_DROPDOWN_TYPES.MODULE_VIEW}
                miniminumOneSelected
                showApplyButton={moduleSourceDropdownOptions.length > 1}
                makeSearch={makeSearch}
              />
            </Stack>

            <Divider orientation='vertical' sx={styles.divider} />

            {/* Entity Category dropdown for module tabs */}
            <DropDownButton
              label={entityCategory || `${resultsState?.entities?.category_label}` || 'Category'}
              options={entityCategoryOptions}
              onOptionSelect={applyCategoryEntity}
              tooltip='Switch category'
            />

            <Divider orientation='vertical' sx={styles.divider} />
            {/* Entity Search text dropdown for module tabs */}
            <DropDownButton
              label={
                startCase(entityValue)?.replace(/_/g, '') ||
                startCase(resultsState?.entities?.search_term)?.replace(/_/g, '') ||
                'Entity Text'
              }
              options={entityTextOptions}
              onOptionSelect={applyEntityText}
              tooltip='Switch query'
            />
          </Box>
        )}

        {/* User profile actions */}
        <Box display='flex' ml='auto' alignItems='center'>
          <Divider orientation='vertical' sx={styles.verticalDivider} />
          <FavoriteAndProjectActions
            invokedBy='chmp'
            source=''
            id={searchId}
            isButton
            isFavorite={resultsState?.isFavorite ?? false}
            inProjects={resultsState?.inProjects ?? []}
            setFavoriteInHook={({ isFavorite }: { isFavorite: boolean }) => {
              resultsDispatch({ type: ResultActions.SET_IS_FAVORITE, value: isFavorite });
            }}
            setInProjectInHook={({ project }: { project: any }) => {
              let projects: any = [];
              if (project?.inProject) {
                projects = resultsState?.inProjects.filter(
                  (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
                );
              } else {
                projects = [
                  ...(resultsState?.inProjects ?? []),
                  {
                    id: project?.project_id ?? project?.id,
                    name: project?.name
                  }
                ];
              }
              resultsDispatch({ type: ResultActions.SET_IN_PROJECTS, value: projects });
            }}
          />
          <ShareButton
            searchType={resultsState.decryptedPayload?.searchType ?? ''}
            search={resultsState.decryptedPayload?.query}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default React.memo(ModuleHeader);
