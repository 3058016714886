import { createSvgIcon } from '@mui/material';

const BinIcon = createSvgIcon(
  <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.8334 2.33336H8.91675V1.1667C8.91675 0.857278 8.79383 0.560532 8.57504 0.341739C8.35625 0.122947 8.0595 3.05176e-05 7.75008 3.05176e-05L4.25008 3.05176e-05C3.94066 3.05176e-05 3.64392 0.122947 3.42512 0.341739C3.20633 0.560532 3.08341 0.857278 3.08341 1.1667V2.33336H0.166748V3.50003H1.33341V12.25C1.33341 12.7142 1.51779 13.1593 1.84598 13.4875C2.17417 13.8157 2.61929 14 3.08341 14H8.91675C9.38088 14 9.826 13.8157 10.1542 13.4875C10.4824 13.1593 10.6667 12.7142 10.6667 12.25V3.50003H11.8334V2.33336ZM4.25008 1.1667H7.75008V2.33336H4.25008V1.1667ZM9.50008 12.25C9.50008 12.4047 9.43862 12.5531 9.32923 12.6625C9.21983 12.7719 9.07146 12.8334 8.91675 12.8334H3.08341C2.92871 12.8334 2.78033 12.7719 2.67094 12.6625C2.56154 12.5531 2.50008 12.4047 2.50008 12.25V3.50003H9.50008V12.25Z'
      fill='currentColor'
    />
    <path d='M5.41666 5.83301H4.25V10.4997H5.41666V5.83301Z' fill='currentColor' />
    <path d='M7.75017 5.83301H6.5835V10.4997H7.75017V5.83301Z' fill='currentColor' />
  </svg>,
  'BinIcon'
);

export default BinIcon;
