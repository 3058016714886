import { useContext } from 'react';
import { isEmpty } from 'lodash';

import UserProfileStore from '../../../store/UserProfile';
import UserProfileActions from '../../../store/UserProfile/actions';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { listFavorite } from '../../../api/pages/UserProfile';
import { normalizeFavoritesAndProjectsKey } from '../utils';
import { identifierKey } from '../../SearchResults/constants';
import { MODULE_TABS_SOURCE_LIST } from '../../SearchResults/components/constants';

const useSavedData = () => {
  const { profileState, profileDispatch } = useContext(UserProfileStore);
  const { dispatch } = useContext(Store) as any;

  // Helper function to update projects in the saved data
  const updateProjects = (projects: any[], project: any): any[] => {
    return project.inProject
      ? projects.filter(p => (p.project_id ?? p.id) !== (project.project_id ?? project.id))
      : [...projects, { id: project.project_id ?? project.id, name: project.name }];
  };

  const fetchSavedData = async () => {
    try {
      profileDispatch({
        type: UserProfileActions.SET_LOADING,
        value: true
      });
      const res = await listFavorite();
      if (!res || res?.status !== 200 || !res?.data) {
        throw new Error('Error in fetching data');
      }
      const { body, status, error, message } = res.data;
      if (status !== 200 || error) {
        throw new Error(message);
      }
      const normalizedSavedData = Object.keys(body).reduce((acc: any, key: string) => {
        acc[key] = normalizeFavoritesAndProjectsKey(body[key]);
        return acc;
      }, {});

      const documents = normalizedSavedData?.aria_searches ?? [];
      const documentsWithFavorites = documents.filter((doc: any) => doc.isFavorite);
      let quickAdvancedSearches = normalizedSavedData?.quick_advanced_searches ?? [];
      // filter data which does not have search term and filters applied
      // this scenario is possible when user opened module page which uses search APIs
      // Eg: CHMP
      // When filters are applied in such module we need to show the filtered search in activity
      quickAdvancedSearches = quickAdvancedSearches.filter((item: any) => {
        return !(isEmpty(item.filters) && item.term === '');
      });

      // for those activities which does not have search term and have valid filters
      // update the term field with the `${sourceValue} Module with Filters`
      // Eg: CHMP Module with Filters
      quickAdvancedSearches = quickAdvancedSearches.map((item: any) => {
        // Create a shallow copy of the item to avoid direct mutation
        const updatedItem = { ...item };

        // Get the keys of the source object
        const sourceKeys = Object.keys(updatedItem.source);

        // Check if term is an empty string, filters object is not empty, and source matches the conditions
        if (
          updatedItem.term === '' &&
          !isEmpty(updatedItem.filters) &&
          sourceKeys.length === 1 && // Ensure only one key exists in source
          MODULE_TABS_SOURCE_LIST.includes(updatedItem.source[sourceKeys[0]]?.[0]?.toLowerCase()) // Match against MODULE_TABS_SOURCE_LIST
        ) {
          // Dynamically update the term field based on the source list value
          const sourceValue = updatedItem.source[sourceKeys[0]][0]; // Convert source value to uppercase
          updatedItem.term = `${sourceValue?.toUpperCase()} Module with Filters`; // Update the term field
          // also add a key in the item that will determine that activity row needs to be redirected to module
          // page instead of search results page
          updatedItem.isModule = true;
          const moduleRedirectUrl = `/${sourceValue}?searchId=${updatedItem.id}`;
          updatedItem.moduleRedirectUrl = moduleRedirectUrl;
        }

        return updatedItem; // Return the updated object
      });

      const applications = normalizedSavedData?.applications ?? [];

      // Searches is a combination of documnetsWithFavorites and quickAdvancedSearches
      const searches = [...documentsWithFavorites, ...quickAdvancedSearches].sort(
        (searchA: any, searchB: any) =>
          new Date(searchA.date).getTime() - new Date(searchB.date).getTime()
      );

      profileDispatch({
        type: UserProfileActions.SET_SAVED_DATA,
        value: {
          searches,
          documents,
          applications
        }
      });
    } catch (error) {
      const errorMessage = (error as Error)?.message ?? 'Something went wrong';
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    } finally {
      profileDispatch({
        type: UserProfileActions.SET_LOADING,
        value: false
      });
    }
  };

  const handleSavedSearchTabFavorite = ({ identifier }: { identifier: string }) => {
    const { searches } = profileState.savedData;

    const searchExists = searches.some(search => search.id === identifier);

    if (!searchExists) {
      throw new Error('Search not found');
    }
    try {
      const newSavedData = {
        ...profileState.savedData,
        searches: searchExists ? searches.filter(item => item.id !== identifier) : searches
      };

      profileDispatch({
        type: UserProfileActions.SET_SAVED_DATA,
        value: newSavedData
      });
    } catch (e) {
      const errorMessage = (e as Error)?.message ?? 'Something went wrong';
      dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const handleSavedSearchTabProject = ({
    identifier,
    project
  }: {
    identifier: string;
    project: any;
  }) => {
    const { searches } = profileState.savedData;

    const searchIndex = searches.findIndex(search => search.id === identifier);

    if (searchIndex === -1) {
      throw new Error('Search not found');
    }

    try {
      const newSearches = [...searches];

      const currentSearch = { ...newSearches[searchIndex] };
      currentSearch.inProjects = updateProjects(currentSearch.inProjects, project);
      newSearches[searchIndex] = currentSearch;
      const newSavedData = {
        ...profileState.savedData,
        searches: newSearches
      };
      profileDispatch({
        type: UserProfileActions.SET_SAVED_DATA,
        value: newSavedData
      });
    } catch (e) {
      const errorMessage = (e as Error)?.message ?? 'Something went wrong';
      dispatch({
        type: Actions.SET_ALERT,
        value: { message: errorMessage, status: true }
      });
    }
  };

  const handleSavedDocumentTabFavorite = ({
    documentSearchId,
    identifier
  }: {
    documentSearchId: string;
    // result ID of the result
    identifier: string;
  }) => {
    const newDocuments = [...profileState.savedData.documents];
    const listItemIndex = newDocuments.findIndex(
      (item: { id: string }) => item.id === documentSearchId
    );
    if (listItemIndex === -1) return;

    newDocuments[listItemIndex].aria_results = newDocuments[listItemIndex]?.aria_results?.filter(
      (item: { result_id: string }) => item?.result_id !== identifier
    );
    if (
      newDocuments[listItemIndex]?.aria_results?.length === 0 &&
      !newDocuments[listItemIndex].isFavorite
    ) {
      newDocuments.splice(listItemIndex, 1);
    }
    const newSavedData = {
      ...profileState.savedData,
      documents: newDocuments
    };

    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleSavedDocumentTabProject = ({
    documentSearchId,
    identifier,
    project
  }: {
    documentSearchId: string;
    // result ID of the result
    identifier: string;
    project: any;
  }) => {
    const newDocuments = [...profileState.savedData.documents];
    const listItemIndex = newDocuments.findIndex(
      (item: { id: string }) => item.id === documentSearchId
    );
    if (listItemIndex === -1) return;

    const ariaResultIndex = newDocuments[listItemIndex]?.aria_results?.findIndex(
      (item: { result_id: string }) => item?.result_id === identifier
    );
    if (ariaResultIndex === -1) return;

    const newAriaResults = [...newDocuments[listItemIndex].aria_results];

    const currentAriaResult = { ...newAriaResults[ariaResultIndex] };
    currentAriaResult.inProjects = updateProjects(currentAriaResult.inProjects, project);
    newAriaResults[ariaResultIndex] = currentAriaResult;
    newDocuments[listItemIndex].aria_results = newAriaResults;

    const newSavedData = {
      ...profileState.savedData,
      documents: newDocuments
    };

    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleSavedApplicationTabFavorite = ({
    source,
    identifier
  }: {
    source: string;
    identifier: string;
  }) => {
    const newApplications = [...profileState.savedData.applications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;

    newApplications.splice(selectedApplicationIndex, 1);

    const newSavedData = {
      ...profileState.savedData,
      applications: newApplications
    };
    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleSavedApplicationTabProject = ({
    source,
    identifier,
    project
  }: {
    source: string;
    identifier: string;
    project: any;
  }) => {
    const newApplications = [...profileState.savedData.applications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;
    newApplications[selectedApplicationIndex].inProjects = updateProjects(
      newApplications[selectedApplicationIndex].inProjects,
      project
    );

    const newSavedData = {
      ...profileState.savedData,
      applications: newApplications
    };
    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  const handleSavedApplicationTabSubscription = ({
    source,
    identifier,
    isSubscribed
  }: {
    source: string;
    identifier: string;
    isSubscribed: boolean;
  }) => {
    const newApplications = [...profileState.savedData.applications];
    const selectedApplicationIndex = newApplications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;

    newApplications[selectedApplicationIndex].isSubscribed = isSubscribed;
    const newSavedData = {
      ...profileState.savedData,
      applications: newApplications
    };
    profileDispatch({
      type: UserProfileActions.SET_SAVED_DATA,
      value: newSavedData
    });
  };

  return {
    isLoading: profileState.loading,
    savedData: profileState.savedData,
    fetchSavedData,
    handleSavedSearchTabFavorite,
    handleSavedSearchTabProject,
    handleSavedDocumentTabFavorite,
    handleSavedDocumentTabProject,
    handleSavedApplicationTabFavorite,
    handleSavedApplicationTabProject,
    handleSavedApplicationTabSubscription
  };
};

export default useSavedData;
