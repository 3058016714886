import React, { useState } from 'react';
import { Box, Popover, Stack, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { badgeStyle } from './styles';

const TimelineBadge = ({
  patentExclusivity,
  application,
  activeBadge,
  isExpandedCardOpen,
  setIsExpandedCardOpen,
  setActiveBadge,
  setExpandedPayload
}: {
  patentExclusivity: any;
  application: any;
  activeBadge: string;
  isExpandedCardOpen: boolean;
  setIsExpandedCardOpen: any;
  setActiveBadge: any;
  setExpandedPayload: any;
}) => {
  const [showAll, setShowAll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };
  const openDialog = (item: any) => {
    if (activeBadge === item.identifier && isExpandedCardOpen) {
      setActiveBadge('');
      setIsExpandedCardOpen(false);
      return;
    }
    setActiveBadge(item.identifier);
    setExpandedPayload({ ...item, application });
    setIsExpandedCardOpen(true);
  };
  const remainingCount = patentExclusivity?.length - 1;

  return (
    <Stack
      gap={'4px'}
      mx={1}
      onMouseDown={(e: any) => {
        setAnchorEl(e.currentTarget);
      }}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        mt: remainingCount > 0 ? '12px' : '22px'
      }}>
      {patentExclusivity?.map((item: any, index: number) => {
        if (!item.expire_date) return null;
        let formatedTime = item.expire_date;
        try {
          formatedTime = format(new Date(item.expire_date), 'dd MMM');
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        if (index < 1 || showAll) {
          return (
            <Tooltip placement='right' arrow title={item.definition} key={item.identifier}>
              <Box sx={badgeStyle} id={item.type} onClick={() => openDialog(item)}>
                {`${formatedTime}`}{' '}
                {item.type !== 'patent' && item?.identifier?.split('-')?.[0]
                  ? `(${item.identifier.split('-')?.[0]})`
                  : ''}
              </Box>
            </Tooltip>
          );
        }
        return null;
      })}
      {!showAll && remainingCount > 0 && (
        <Tooltip title='More items' key='more-items'>
          <Box sx={badgeStyle} onClick={handleShowAll} id={'more'}>
            {`+${remainingCount}`}
          </Box>
        </Tooltip>
      )}

      <Popover
        open={showAll}
        anchorEl={anchorEl}
        onClose={handleShowAll}
        disablePortal={isExpandedCardOpen}
        transitionDuration={1}
        elevation={2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Stack
          gap={'4px'}
          sx={{
            backgroundColor: 'rgba(255, 255, 255,0.7) !important',
            borderRadius: '10px',
            p: '10px'
          }}>
          {patentExclusivity?.map((item: any) => {
            if (!item.expire_date) return null;
            let formatedTime = item.expire_date;
            try {
              formatedTime = format(new Date(item.expire_date), 'dd MMM');
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e);
            }
            return (
              <Tooltip placement='right' arrow title={item.definition} key={item.identifier}>
                <Box sx={badgeStyle} id={item.type} onClick={() => openDialog(item)}>
                  {`${formatedTime}`}{' '}
                  {item.type !== 'patent' && item?.identifier?.split('-')?.[0]
                    ? `(${item.identifier.split('-')?.[0]})`
                    : ''}
                </Box>
              </Tooltip>
            );
          })}
          <Tooltip title='Collapse items' key='collapse-items'>
            <Box sx={badgeStyle} onClick={handleShowAll} id={'more'}>
              X
            </Box>
          </Tooltip>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default React.memo(TimelineBadge);
