import { createSvgIcon } from '@mui/material';

const FeedbackIcon = createSvgIcon(
  <svg width='43' height='42' viewBox='0 0 43 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8700_76037)'>
      <path
        d='M28.1243 21.9854H14.3827C14.0183 21.9854 13.6687 22.1301 13.411 22.3878C13.1533 22.6455 13.0085 22.9951 13.0085 23.3595V32.9786H29.4985V23.3595C29.4985 22.9951 29.3537 22.6455 29.096 22.3878C28.8383 22.1301 28.4888 21.9854 28.1243 21.9854Z'
        fill='#F39C12'
      />
      <path
        d='M21.2536 13.7401C23.5304 13.7401 25.3761 11.8944 25.3761 9.6176C25.3761 7.34082 23.5304 5.49512 21.2536 5.49512C18.9768 5.49512 17.1311 7.34082 17.1311 9.6176C17.1311 11.8944 18.9768 13.7401 21.2536 13.7401Z'
        fill='#F39C12'
      />
      <path
        d='M33.6211 0H8.88616C7.7928 0 6.74423 0.434332 5.97112 1.20745C5.198 1.98056 4.76367 3.02913 4.76367 4.12248L4.76367 32.9799H10.2603V23.3607C10.2603 22.2674 10.6946 21.2188 11.4678 20.4457C12.2409 19.6726 13.2894 19.2383 14.3828 19.2383H28.1244C29.2178 19.2383 30.2663 19.6726 31.0394 20.4457C31.8126 21.2188 32.2469 22.2674 32.2469 23.3607V32.9799H37.7435V4.12248C37.7435 3.02913 37.3092 1.98056 36.5361 1.20745C35.763 0.434332 34.7144 0 33.6211 0V0ZM21.2536 16.4899C19.8947 16.4899 18.5663 16.087 17.4364 15.332C16.3065 14.577 15.4258 13.5039 14.9058 12.2485C14.3858 10.993 14.2497 9.61151 14.5148 8.2787C14.7799 6.94589 15.4343 5.72163 16.3952 4.76073C17.3561 3.79984 18.5804 3.14545 19.9132 2.88034C21.246 2.61523 22.6275 2.7513 23.8829 3.27133C25.1384 3.79137 26.2115 4.67201 26.9665 5.80191C27.7214 6.93181 28.1244 8.26021 28.1244 9.61913C28.1222 11.4407 27.3976 13.1871 26.1096 14.4751C24.8215 15.7632 23.0752 16.4878 21.2536 16.4899Z'
        fill='#616161'
      />
    </g>
    <g clipPath='url(#clip1_8700_76037)'>
      <path
        d='M14.7395 41.7907L12.4593 40.1149L10.1791 41.7907L11.0546 39.0843L8.77563 37.4191H11.5893L12.4593 34.708L13.3293 37.4191H16.1427L13.864 39.0843L14.7395 41.7907Z'
        fill='#F39C12'
      />
    </g>
    <g clipPath='url(#clip2_8700_76037)'>
      <path
        d='M5.94481 41.7907L3.66462 40.1149L1.38443 41.7907L2.25993 39.0843L-0.019043 37.4191H2.79463L3.66462 34.708L4.53462 37.4191H7.34799L5.06932 39.0843L5.94481 41.7907Z'
        fill='#F39C12'
      />
    </g>
    <g clipPath='url(#clip3_8700_76037)'>
      <path
        d='M23.5342 41.7907L21.254 40.1149L18.9738 41.7907L19.8493 39.0843L17.5703 37.4191H20.384L21.254 34.708L22.124 37.4191H24.9373L22.6587 39.0843L23.5342 41.7907Z'
        fill='#F39C12'
      />
    </g>
    <g clipPath='url(#clip4_8700_76037)'>
      <path
        d='M32.3286 41.7907L30.0484 40.1149L27.7682 41.7907L28.6437 39.0843L26.3647 37.4191H29.1784L30.0484 34.708L30.9184 37.4191H33.7318L31.4531 39.0843L32.3286 41.7907Z'
        fill='#F39C12'
      />
    </g>
    <g clipPath='url(#clip5_8700_76037)'>
      <path
        d='M41.1233 41.7907L38.8431 40.1149L36.5629 41.7907L37.4384 39.0843L35.1594 37.4191H37.9731L38.8431 34.708L39.7131 37.4191H42.5265L40.2478 39.0843L41.1233 41.7907Z'
        fill='#F39C12'
      />
    </g>
    <defs>
      <clipPath id='clip0_8700_76037'>
        <rect width='32.9799' height='32.9799' fill='white' transform='translate(4.76367)' />
      </clipPath>
      <clipPath id='clip1_8700_76037'>
        <rect
          width='7.32886'
          height='7.32886'
          fill='white'
          transform='translate(8.79468 34.6709)'
        />
      </clipPath>
      <clipPath id='clip2_8700_76037'>
        <rect width='7.32886' height='7.32886' fill='white' transform='translate(0 34.6709)' />
      </clipPath>
      <clipPath id='clip3_8700_76037'>
        <rect
          width='7.32886'
          height='7.32886'
          fill='white'
          transform='translate(17.5894 34.6709)'
        />
      </clipPath>
      <clipPath id='clip4_8700_76037'>
        <rect
          width='7.32886'
          height='7.32886'
          fill='white'
          transform='translate(26.3838 34.6709)'
        />
      </clipPath>
      <clipPath id='clip5_8700_76037'>
        <rect
          width='7.32886'
          height='7.32886'
          fill='white'
          transform='translate(35.1785 34.6709)'
        />
      </clipPath>
    </defs>
  </svg>,
  'Feedback'
);

export default FeedbackIcon;
