import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Popover, Skeleton } from '@mui/material';

import ReportButton from '../../../../components/Report/ReportButton';

import DocumentCitationData from '../DocumentCitationData';
import useDocumentsViewFunctions from '../../hooks/useDocumentsViewFunctions';
import ResultsStore from '../../../../store/SearchResults';
import styles from './styles';

const TypingAnimationHTML = ({
  sequence,
  speed,
  repeat,
  handleHtmlClick,
  handleMouseMove,
  setCompleted,
  completed,
  doneLoading
}: any) => {
  const [currentHTML, setCurrentHTML] = useState(''); // Tracks the current HTML being typed
  const [charIndex, setCharIndex] = useState(0); // Tracks the character position being typed
  useEffect(() => {
    const handleTyping = (textToUse: any) => {
      setCurrentHTML(textToUse.substring(0, charIndex + 1));
      setCharIndex(prev => prev + 1);
    };
    const currentText = sequence[0];

    const timer = setTimeout(() => handleTyping(currentText), speed);
    return () => clearTimeout(timer);
  }, [charIndex, sequence, currentHTML, speed, repeat]);
  useEffect(() => {
    if (
      currentHTML === sequence[0] &&
      currentHTML.length === sequence[0].length &&
      currentHTML !== '' &&
      doneLoading
    ) {
      setCompleted(true);
    }
  }, [currentHTML, sequence, setCompleted, doneLoading]);
  return (
    <Typography
      py={1}
      fontSize={15}
      color='gray.800'
      minHeight={440}
      sx={styles.insightContent}
      dangerouslySetInnerHTML={{ __html: completed ? sequence[0] : currentHTML }}
      component={Box}
      onClick={handleHtmlClick}
      onMouseMove={handleMouseMove}
    />
  );
};
const ResultContent = ({
  content,
  citations,
  loading,
  addReport,
  doneLoading,
  firstLoad,
  setFirstLoad
}: any) => {
  const { resultsState } = useContext(ResultsStore);
  const [hoverContentTargetElement, setHoverContentTargetElement] = useState<any>();
  const [hoverResultContent, setHoverResultContent] = useState<any>();

  const { findDocumentByResultId, handleInsightCitationClick } = useDocumentsViewFunctions();

  const handleHtmlClick = ({ target }: any) => {
    if (!doneLoading) {
      return;
    }
    if (target.id?.includes('citation__')) {
      const citationId = parseInt(target.id.replace('citation__', ''), 10);
      const { resultId } = citations[citationId]; // remove an replace with below code after API integration
      const ariResult = findDocumentByResultId(resultId);
      handleInsightCitationClick(resultId, ariResult);
      setHoverResultContent(null);
      setHoverContentTargetElement(null);
    }
  };

  const handleMouseMove = (event: any) => {
    event.stopPropagation();
    if (!doneLoading) {
      return;
    }
    const { target } = event;
    if (target.id?.includes('citation__')) {
      const citationId = parseInt(target.id.replace('citation__', ''), 10);
      const { resultId } = citations[citationId];
      const matchedAriaDocumentResult = findDocumentByResultId(resultId);
      setHoverResultContent(matchedAriaDocumentResult);
      setHoverContentTargetElement(target);
    } else if (hoverResultContent || hoverContentTargetElement) {
      setHoverResultContent(null);
      setHoverContentTargetElement(null);
    }
  };

  const handleMouseLeave = (event: any) => {
    event.stopPropagation();
    setHoverResultContent(null);
    setHoverContentTargetElement(null);
  };

  const handleAddToReport = useCallback(
    (_: any, reportData: any) => {
      const id = resultsState.documentResults.results[0].result_id;
      addReport(id, content, reportData);
    },
    [resultsState?.documentResults?.results, addReport]
  );

  return (
    <>
      <Box sx={styles.insightsContainer}>
        <Stack
          direction='column'
          divider={<Divider orientation='horizontal' sx={styles.divider} />}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' pb={1}>
            <Typography fontSize={13} color='gray.700'>
              Summary of top{' '}
              <Typography component='span' sx={styles.numberText}>
                {resultsState?.documentResults?.results?.length > 10
                  ? 10
                  : resultsState?.documentResults?.results?.length}{' '}
                results
              </Typography>
            </Typography>
          </Stack>

          {loading || !content ? (
            <Stack>
              {Array.from({ length: 5 }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Stack key={index} mt={2}>
                  <Skeleton animation='wave' sx={styles.loadingSkeleton} />
                  <Skeleton animation='wave' width='60%' sx={styles.loadingSkeleton} />
                  <Skeleton animation='wave' width='30%' sx={styles.loadingSkeleton} />
                </Stack>
              ))}
            </Stack>
          ) : (
            <TypingAnimationHTML
              sequence={[content]}
              speed={2}
              handleHtmlClick={handleHtmlClick}
              handleMouseMove={handleMouseMove}
              setCompleted={setFirstLoad}
              completed={firstLoad}
              doneLoading={doneLoading}
            />
          )}

          <Stack direction='row' alignItems='center' justifyContent='space-between' pt={1}>
            <ReportButton data={{}} addToReport={handleAddToReport} />

            {/* <Box>Feedback</Box> */}
          </Stack>
        </Stack>
      </Box>
      <Popover
        disableScrollLock
        onClose={handleMouseLeave}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'unset',
              color: 'unset',
              boxShadow: 'unset',
              padding: 0,
              margin: 0,
              border: 0
            },
            onMouseLeave: handleMouseLeave
          },
          root: {
            sx: {
              pointerEvents: 'none'
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorEl={hoverContentTargetElement}
        open={hoverContentTargetElement && hoverResultContent}>
        <DocumentCitationData resultDetails={hoverResultContent} />
      </Popover>
    </>
  );
};

export default React.memo(ResultContent);
