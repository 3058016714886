import { memo } from 'react';

import { Stack, Tooltip, Typography, Chip } from '@mui/material';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent
} from '@mui/lab';

import { Circle } from '@mui/icons-material';

import { TimeIcon } from '../../../assets/svgs/Icons';

import subApprovalTimelineStyles from './SubApprovalTimeline.styles';
import { LABEL_COLOR, LABEL_TOOLTIP_TITLE } from '../../../pages/SearchResults/constants';

interface EventEntryProps {
  date: string;
  type: string;
  labelTooltipTitle: string;
  labelText: string;
  labelColor: string;
}

const EventEntry = ({ date, type, labelTooltipTitle, labelText, labelColor }: EventEntryProps) => {
  return (
    <Stack sx={subApprovalTimelineStyles.timelineEventWrapper}>
      <Typography sx={subApprovalTimelineStyles.timelineEventDate}>{date}</Typography>
      <Typography sx={subApprovalTimelineStyles.timelineEventDateType}>{type}</Typography>
      <Tooltip
        title={labelTooltipTitle}
        disableInteractive
        disableFocusListener
        disableTouchListener>
        <Chip
          label={labelText.toUpperCase()}
          sx={{ bgcolor: labelColor, ...subApprovalTimelineStyles.agencyTag }}
        />
      </Tooltip>
    </Stack>
  );
};

interface TimelineItemData {
  id: string;
  date: string;
  dateType: string;
  agency?: string;
  closeDate?: string;
  closeDateType?: string;
  source?: string;
  duration?: number;
}

interface SubApprovalTimelineProps {
  timeline: TimelineItemData[];
}

const SubApprovalTimeline = ({ timeline }: SubApprovalTimelineProps) => {
  return (
    <Timeline sx={subApprovalTimelineStyles.timelineContainer}>
      {timeline.map((item, index) => {
        const isLastItem = index === timeline.length - 1;
        const labelKey = item?.agency?.toLowerCase() ?? '';
        const labelColor = LABEL_COLOR[labelKey];
        const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
        const isCloseDatePresent = item?.closeDate && item?.closeDateType && item?.source;
        const closeDateSourceKey = item?.source?.toLowerCase() ?? '';
        const closeDateSourceColor = LABEL_COLOR[closeDateSourceKey];
        const closeDateSourceTooltipTitle = LABEL_TOOLTIP_TITLE[closeDateSourceKey];
        return (
          <TimelineItem key={item.id} sx={{ minHeight: 'auto' }}>
            <TimelineSeparator
              sx={{
                ...subApprovalTimelineStyles.timelineSeparator,
                ...(isLastItem && { height: 0 })
              }}>
              <Circle sx={subApprovalTimelineStyles.circle} />
              <TimelineConnector sx={subApprovalTimelineStyles.timelineConnector} />
            </TimelineSeparator>
            <Stack flex={1} direction='column'>
              <Stack sx={subApprovalTimelineStyles.timelineEventContainer}>
                <EventEntry
                  date={item.date}
                  type={item.dateType}
                  labelText={labelKey}
                  labelTooltipTitle={labelTooltipTitle}
                  labelColor={labelColor}
                />
                {isCloseDatePresent && (
                  <EventEntry
                    date={item?.closeDate ?? ''}
                    type={item?.closeDateType ?? ''}
                    labelText={item?.source ?? ''}
                    labelTooltipTitle={closeDateSourceTooltipTitle}
                    labelColor={closeDateSourceColor}
                  />
                )}
              </Stack>
              {!isLastItem && (
                <TimelineContent p='0px !important'>
                  <Stack direction='row' gap={0.5} alignItems='center' my={2}>
                    <TimeIcon sx={subApprovalTimelineStyles.timeIcon} />
                    <Typography sx={subApprovalTimelineStyles.timeDuration}>
                      {item?.duration} days
                    </Typography>
                  </Stack>
                </TimelineContent>
              )}
            </Stack>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default memo(SubApprovalTimeline);
