const timelineDateMixin = {
  color: 'white.main',
  fontSize: '16px',
  fontWeight: '600',
  height: '100%',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 10px',
  borderRadius: `8px 0px 0px 8px`,
  width: '100%',
  marginRight: '16px',
  minHeight: '80px'
};
const styles: { [key: string]: any } = {
  TimelineDateDarkGreen: {
    bgcolor: 'primary.700',
    ...timelineDateMixin
  },
  TimelineDateSuccessGreen: {
    bgcolor: 'primary.400',
    ...timelineDateMixin
  },
  '.MuiTimelineContent-root': {
    padding: '0px 0px'
  },
  reportIcon: {
    height: 14,
    width: 14,
    cursor: 'pointer',
    color: '#00FFDB'
  },
  dateColumnWithIconContainer: {
    minWidth: { xs: 'auto', sm: 'auto', md: 'auto', lg: 140 },
    maxWidth: { xs: 'auto', sm: 'auto', md: 'auto', lg: 140 },
    justifyContent: { xs: 'center', sm: 'center', md: 'center', lg: 'flex-start' },
    alignItems: 'center',
    fontSize: 13
  }
};

export default styles;
