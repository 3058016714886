/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';
import PatentExclusivityTag from '../../../components/Datagrid/DatagridNumberTag/PatentExclusivityTag';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import PatentExclusivityProductTag from '../../../components/Datagrid/DatagridNumberTag/PatentExclusivityProductTag';

export const PatentExclusivity: GridColDef[] = [
  {
    field: 'expire_date',
    headerName: 'Expire Date',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row'
  },
  {
    field: 'type',
    headerName: 'Type',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: PatentExclusivityTag,
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'patent_use_code',
    headerName: 'Use Code',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'identifier',
    headerName: 'Number',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'definition',
    headerName: 'Definition',
    headerClassName: 'table-header',
    width: 450,
    cellClassName: 'table-row',
    renderCell: renderCellExpand,
    valueGetter: (params: any) => params.value || '-'
  },
  {
    field: 'product_data',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 200,
    cellClassName: 'table-row',
    renderCell: PatentExclusivityProductTag,
    valueGetter: (params: any) => params?.value || '-'
  }
];
