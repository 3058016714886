import React, { memo, useState, useEffect, useContext, useMemo } from 'react';
import {
  Box,
  Dialog,
  IconButton,
  Divider,
  Typography,
  Button,
  Radio,
  TextField,
  Rating,
  Stack,
  Tooltip,
  FormControlLabel
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

import { SURVEYS, SURVEY_QUESTION_TYPE } from '../const';
import { CrossIcon, CustomCheckIcon, InfoIconSummary } from '../../../assets/svgs/Icons';
import neutralFaceEmoji from '../../../assets/images/Emojis/neutral-face.png';
import grinningFaceEmoji from '../../../assets/images/Emojis/grinning-face.png';
import pensiveFaceEmoji from '../../../assets/images/Emojis/pensive-face.png';
import smilingFaceEmoji from '../../../assets/images/Emojis/smiling-face.png';
import unamusedFaceEmoji from '../../../assets/images/Emojis/unamused-face.png';
import styles from '../styles/QuickSurvey.styles';
import { getUserSurvey, submitUserSurvey } from '../../../api/pages/HomePage';
import GlobalStore from '../../../store';
import Actions from '../../../store/actions';

import themev5 from '../../../themev5';

import CustomCheckbox from '../../../components/CustomComponents/Checkbox';

const QuickSurvey = () => {
  const EMOJIS: any = {
    neutralFace: neutralFaceEmoji,
    grinningFace: grinningFaceEmoji,
    pensiveFace: pensiveFaceEmoji,
    smilingFace: smilingFaceEmoji,
    unamusedFace: unamusedFaceEmoji
  };

  const location = useLocation();
  const [showIntroDialog, setShowIntroDialog] = useState(false);
  const [showQuickSurveyDialog, setShowQuickSurveyDialog] = useState(false);
  const [showSurveyCompleteDialog, setShowSurveyCompleteDialog] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<any>({});
  const { dispatch } = useContext<any>(GlobalStore);
  const ALERT_MESSAGE = 'You can still submit the feedback from the Home page.';

  const survey = SURVEYS.riaCustomerExperienceFeedback1stAnniversary;
  const questions = Object.values(survey.questions);

  const handleSubmit = async () => {
    const res = await submitUserSurvey({ answers, surveyTitle: survey.title });

    if (res?.status === 200 && res?.data?.body?.success) {
      setShowSurveyCompleteDialog(true);
    }
  };

  const handleNext = () => {
    setCurrentQuestionIndex(prevIndex => Math.min(prevIndex + 1, questions.length - 1));

    if (currentQuestionIndex === questions.length - 1) {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleChange = (questionId: any, value: any) => {
    setAnswers((prevAnswers: any) => ({
      ...prevAnswers,
      [questionId]: value
    }));
  };

  const handleCloseSurvey = () => {
    localStorage.setItem(survey.title, 'false');
    setShowIntroDialog(false);
    setShowQuickSurveyDialog(false);
    setShowSurveyCompleteDialog(false);
  };

  useEffect(() => {
    const checkSurveyAlreadySubmitted = async () => {
      const res = await getUserSurvey({ surveyTitle: survey.title });

      if (res?.status === 200 && res?.data?.body?.is_submitted) {
        setShowSurveyCompleteDialog(true);
        return;
      }

      setShowIntroDialog(true);
    };

    if (location.hash === '#quick-survey' || !localStorage.getItem(survey.title)) {
      checkSurveyAlreadySubmitted();
      setCurrentQuestionIndex(0);
      setAnswers({});
    } else {
      setShowIntroDialog(false);
      setShowQuickSurveyDialog(false);
      setShowSurveyCompleteDialog(false);
    }
  }, [location]);

  const currentQuestion: any = useMemo(
    () => questions[currentQuestionIndex],
    [questions, currentQuestionIndex]
  );
  const currentAnswer: any = useMemo(
    () => answers[currentQuestion.question],
    [answers, currentQuestion]
  );

  const handleCloseQuestionsDialog = () => {
    setShowQuickSurveyDialog(false);
    setShowIntroDialog(false);
    dispatch({
      type: Actions.SET_ALERT,
      value: {
        status: true,
        message: ALERT_MESSAGE,
        duration: 3000,
        color: 'warning'
      }
    });
  };

  const handleStartSurvey = () => {
    setShowIntroDialog(false);
    setShowQuickSurveyDialog(true);
  };

  const handleCloseCompleteDialog = () => {
    setShowSurveyCompleteDialog(false);
  };

  const handleCloseSurveyBeforeCompletion = () => {
    handleCloseSurvey();
    dispatch({
      type: Actions.SET_ALERT,
      value: {
        status: true,
        message: ALERT_MESSAGE,
        duration: 3000,
        color: 'warning'
      }
    });
  };

  const isButtonDisabled = () => {
    return (currentQuestion?.isMandatory && !currentAnswer) || currentAnswer?.length === 0;
  };

  return (
    <ThemeProvider theme={themev5}>
      <Dialog open={showIntroDialog} onClose={handleCloseQuestionsDialog} sx={styles.dialog}>
        <Box sx={styles.dialogContent}>
          <Box sx={styles.introDialog.headerWrapper}>
            <IconButton onClick={handleCloseSurveyBeforeCompletion} sx={styles.closeButton}>
              <CrossIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography sx={styles.introDialog.heading}>Customer Experience Feedback</Typography>
            <Typography sx={styles.introDialog.subtitle}>
              Marking the 1st Anniversary of RIA 2.0.
            </Typography>
            <Typography sx={styles.introDialog.title}>
              Let us know how we&apos;re doing and how we can serve you better!
            </Typography>
            <Box sx={styles.introDialog.infoButtonWrapper}>
              <Button sx={styles.introDialog.infoButton}>5 Questions</Button>
              <Button sx={styles.introDialog.infoButton}>30 seconds total</Button>
            </Box>
            <Box sx={styles.introDialog.surveyStartButtonWrapper}>
              <Button sx={styles.introDialog.surveyStartButton} onClick={handleStartSurvey}>
                Let&apos;s start !
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={showQuickSurveyDialog} onClose={handleCloseQuestionsDialog} sx={styles.dialog}>
        <Box sx={styles.dialogContent}>
          <Box sx={styles.headerWrapper}>
            <Typography sx={styles.headerTitle}>User Feedback</Typography>
            <IconButton onClick={handleCloseSurveyBeforeCompletion} sx={styles.closeButton}>
              <CrossIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={styles.questionWrapper}>
            <Typography sx={styles.questionTitle}>{currentQuestion.title}</Typography>
            <Typography sx={styles.questionText}>{currentQuestion.question}</Typography>
          </Box>
          <Box sx={styles.dialogContentWrapper}>
            <Box>
              {currentQuestion.type === SURVEY_QUESTION_TYPE.BUTTON && (
                <Box sx={styles.buttonTypeQuestion.wrapper}>
                  {Object.entries(currentQuestion.options).map(([optionId, option]: any) => (
                    <Button
                      sx={{
                        ...styles.buttonTypeQuestion.button,
                        ...(option === currentAnswer && styles.selectedButton)
                      }}
                      variant='contained'
                      key={optionId}
                      onClick={() => handleChange(currentQuestion.question, option)}>
                      {option}
                    </Button>
                  ))}
                </Box>
              )}
              {currentQuestion.type === SURVEY_QUESTION_TYPE.EMOJI_BUTTON && (
                <Box sx={styles.emojiButtonTypeQuestion.wrapper}>
                  {Object.entries(currentQuestion.options).map(([optionId, option]: any) => (
                    <Box
                      sx={{
                        ...styles.emojiButtonTypeQuestion.button,
                        ...(option === currentAnswer && styles.selectedButton)
                      }}
                      key={optionId}
                      onClick={() => handleChange(currentQuestion.question, option)}>
                      <img
                        src={EMOJIS[currentQuestion.emojis[optionId]]}
                        alt='emoji'
                        height='34'
                        width='34'
                      />
                      <Typography sx={styles.emojiButtonTypeQuestion.optionText}>
                        {currentQuestion.options[optionId]}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              {currentQuestion.type === SURVEY_QUESTION_TYPE.RADIO && (
                <Box>
                  <Box sx={styles.radioTypeQuestion.radioWrapper}>
                    {Object.entries(currentQuestion.options).map(([optionId, option]: any) => (
                      <Radio
                        key={optionId}
                        checked={option === currentAnswer}
                        onChange={e => handleChange(currentQuestion.question, e.target.value)}
                        value={option}
                        name='radio-buttons'
                        color='success'
                        inputProps={{ 'aria-label': answers[currentQuestionIndex] }}
                        sx={styles.radioTypeQuestion.radio}
                      />
                    ))}
                  </Box>

                  <Divider sx={styles.radioTypeQuestion.divider} />

                  <Box sx={styles.radioTypeQuestion.optionsWrapper}>
                    {Object.entries(currentQuestion.options).map(([optionId, option]: any) => (
                      <Typography sx={styles.radioTypeQuestion.optionText} key={optionId}>
                        {option}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )}
              {currentQuestion.type === SURVEY_QUESTION_TYPE.INPUT && (
                <TextField
                  id='standard-basic'
                  placeholder='Type here...'
                  onChange={e => handleChange(currentQuestion.question, e.target.value)}
                  fullWidth
                  sx={styles.inputTypeQuestion.textField}
                  inputProps={{ maxLength: 300 }}
                  value={currentAnswer ?? ''}
                />
              )}
              {currentQuestion.type === SURVEY_QUESTION_TYPE.STAR_RATING && (
                <Box>
                  <Rating
                    sx={styles.starRatingTypeQuestion.rating}
                    name={currentQuestion.title}
                    defaultValue={currentAnswer ?? 0}
                    max={currentQuestion.totalStars}
                    onChange={(_, newValue) => handleChange(currentQuestion.question, newValue)}
                    size='large'
                  />
                </Box>
              )}
              {currentQuestion.type === SURVEY_QUESTION_TYPE.CHECK_BOX && (
                <Box sx={styles.checkBoxContainer}>
                  {Object.entries(currentQuestion.options).map(([, option]: any) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <CustomCheckbox
                          checked={currentAnswer?.includes(option)}
                          name='radio-buttons'
                          sx={styles.checkBoxTypeQuestion.checkbox}
                          onClick={(e: React.MouseEvent) => e.stopPropagation()}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.stopPropagation();
                            const newValue = e.target.checked
                              ? [...(currentAnswer ?? []), option]
                              : (currentAnswer ?? []).filter((o: any) => o !== option);
                            handleChange(currentQuestion.question, newValue);
                          }}
                        />
                      }
                      sx={styles.checkBoxTypeQuestion.formControlLabel}
                      label={option}
                    />
                  ))}
                </Box>
              )}
              {currentQuestion.type === SURVEY_QUESTION_TYPE.INPUT_WITH_TOOLTIP && (
                <Stack alignItems='center' gap={3}>
                  <TextField
                    id='standard-basic'
                    placeholder='Type here...'
                    onChange={e => handleChange(currentQuestion.question, e.target.value)}
                    fullWidth
                    sx={styles.inputTypeQuestion.textField}
                    inputProps={{ maxLength: 300 }}
                    value={currentAnswer ?? ''}
                  />
                  <Stack direction='row' alignItems='center' gap={0.5}>
                    <Typography sx={styles.inputTooltipTypeQuestion.title}>
                      List of features
                    </Typography>
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          {currentQuestion.tooltipTitle}
                        </span>
                      }>
                      <InfoIconSummary sx={styles.inputTooltipTypeQuestion.tooltipInfoIcon} />
                    </Tooltip>
                  </Stack>
                </Stack>
              )}
            </Box>
            <Box sx={styles.actionsWrapper}>
              {currentQuestionIndex > 0 ? (
                <Button sx={styles.backButton} type='button' onClick={handlePrevious}>
                  Back
                </Button>
              ) : (
                <Box sx={{ width: '86px' }} />
              )}
              <Typography sx={styles.questionStats}>{`${currentQuestionIndex + 1} / ${
                questions.length
              }`}</Typography>

              <Button
                sx={styles.nextAndSubmitButton}
                type='button'
                onClick={handleNext}
                disabled={isButtonDisabled()}>
                {currentQuestionIndex === questions.length - 1 ? 'Submit' : 'Next'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={showSurveyCompleteDialog}
        onClose={handleCloseCompleteDialog}
        sx={styles.dialog}>
        <Box sx={styles.dialogContent}>
          <Box sx={styles.surveyCompleteDialog.headerWrapper}>
            <IconButton onClick={handleCloseSurvey} sx={styles.closeButton}>
              <CrossIcon />
            </IconButton>
          </Box>
          <Box textAlign='center'>
            <IconButton sx={styles.surveyCompleteDialog.successCheckIconButton}>
              <CustomCheckIcon />
            </IconButton>
            <Typography sx={styles.surveyCompleteDialog.heading}>Done, thank you!</Typography>
            <Typography sx={styles.surveyCompleteDialog.title}>
              Your feedback is extremely valuable to us.
            </Typography>
            <Box sx={styles.introDialog.surveyStartButtonWrapper}>
              <Button
                sx={styles.surveyCompleteDialog.surveyCloseButton}
                onClick={handleCloseSurvey}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default memo(QuickSurvey);
