/**
 * To be used for results page where the color scheme is determined by one of the attributes in the response
 */
import * as React from 'react';

import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Tooltip } from '@mui/material';
import styles from '../Datagrid.styles';

interface GridCellExpandProps {
  value: string;
  row: any;
}

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { value, row } = props;
  const { drug_product_flag: drugProductFlag, drug_substance_flag: drugSubstanceFlag } = row;
  return (
    <>
      <Typography
        align='center'
        variant='subtitle2'
        fontWeight='bold'
        id={value}
        sx={{ ...styles.nameTag }}>
        {value}
      </Typography>
      {drugProductFlag ? (
        <Tooltip title='Drug Product'>
          <Typography
            align='center'
            variant='subtitle2'
            fontWeight='bold'
            id='type'
            sx={{ ...styles.nameTag }}>
            DP
          </Typography>
        </Tooltip>
      ) : null}
      {drugSubstanceFlag ? (
        <Tooltip title='Drug Substance'>
          <Typography
            align='center'
            variant='subtitle2'
            fontWeight='bold'
            id='type'
            sx={{ ...styles.nameTag }}>
            DS
          </Typography>
        </Tooltip>
      ) : null}
    </>
  );
});

const PatentExclusivityTag = (params: GridRenderCellParams) => {
  const { value, row } = params;
  return <GridCellExpand value={value || ''} row={row} />;
};

export default PatentExclusivityTag;
