import React, { useContext } from 'react';
import { isEmpty } from 'lodash';

// context
import ResultsStore from '../../../../store/SearchResults';

// constants
import { EMPTY_RESULTS_MESSAGES } from '../../constants';

// components
import Loading from '../Loading';
import EmptyResult from '../EmptyResult';
import Chmp from '../../../NewChmp';
import ModuleTabHeader from './ModuleHeader';

const ModuleWrapper = ({ viewType, searchId }: { viewType: string; searchId: string }) => {
  const { resultsState } = useContext(ResultsStore);

  if (resultsState.isContentLoading) {
    return <Loading />;
  }

  // Check if resultsState.moduleTabResults is empty or not
  if (isEmpty(resultsState?.moduleTabResults)) {
    return (
      <EmptyResult
        firstMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.firstMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.firstMessage
        }
        secondMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.secondMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.secondMessage
        }
      />
    );
  }

  const moduleProps = resultsState.moduleTabResults;
  // Check if moduleProps has the key corresponding to viewType
  const viewTypeData = moduleProps[viewType];

  // Render different modules based on the viewType
  switch (viewType) {
    case 'chmp':
      return (
        <>
          <ModuleTabHeader viewType={viewType} searchId={searchId} />
          <Chmp
            moduleTabResults={viewTypeData}
            searchId={searchId}
            isEntitiesAvailable={resultsState?.entities?.entities?.length > 0}
          />
        </>
      );
    default:
      return null;
  }
};

export default React.memo(ModuleWrapper);
