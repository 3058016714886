import React, { memo, useContext, useMemo, useState } from 'react';

import { IconButton, Stack, Tooltip, Typography, Popover, Box, Button } from '@mui/material';

import SearchResultsStore from '../../../store/SearchResults';

import styles from '../styles/ChatRiaAnswer.styles';

import ResultsActions from '../../../store/SearchResults/actions';
import useDocumentsView from '../hooks/useDocumentsViewFunctions';

import ReportButton from '../../../components/Report/ReportButton';
import trimText from '../../../utils/trimText';

const AriaResponseText = ({ addToReport }: any) => {
  const {
    resultsState: {
      showTop10Summary,
      top10Summary,
      ariaResponseAnswerText,
      selectedDocument,
      documentResults
    },
    resultsDispatch
  } = useContext(SearchResultsStore);
  const { findDocumentByResultId, handleResultClick } = useDocumentsView();
  const [showMore, setShowMore] = useState(false);
  // state for popover showed on hover of info icon on the RIA response title
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [documentCardDetails, setDocumentCardDetails] = useState<any>({});

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, resultId: string) => {
    const selectedCard = findDocumentByResultId(resultId);
    setDocumentCardDetails(selectedCard);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const maxCharacters = useMemo(() => (selectedDocument ? 500 : 350), [selectedDocument]);

  const toggleShowMore = () => setShowMore(prev => !prev);

  const renderText = useMemo(
    () => (text: string) => {
      if (!text) {
        return 'Sorry, we were unable to generate a response for your query based on the selected document. You can explore the next best relevant documents for a generative AI response or consider trying one of the suggested questions below.';
      }
      return showMore || text.length <= maxCharacters ? text : `${trimText(text, maxCharacters)}`;
    },
    [showMore, maxCharacters]
  );

  const scrollToAnswer = (resultId: string) => {
    setAnchorEl(null);
    const selectedCard = findDocumentByResultId(resultId);
    resultsDispatch({
      type: ResultsActions.SET_SELECTED_ARIA_RESPONSE,
      value: selectedCard
    });
    handleResultClick({
      resultId: selectedCard.resultId,
      resultDetails: selectedCard,
      pdfUrl: selectedCard.pdfUrl,
      pdfUrlOriginal: selectedCard.pdfUrlOriginal
    });
  };

  const handleAddToReport = (data: any, reportDetails: any) => {
    addToReport(data?.result_id[0], data?.content, reportDetails);
  };

  const renderMetadataItems = useMemo(
    () =>
      documentCardDetails?.metadataList?.map((tags: any, index: number) => (
        <>
          <Tooltip key={tags.id} title={`${tags.label}: ${tags.value}`} placement='bottom-end'>
            <Typography noWrap variant='body1' sx={styles.tagText}>
              {tags.value}
            </Typography>
          </Tooltip>
          {index !== (documentCardDetails?.metadataList?.length ?? -1) - 1 && (
            <Typography color='gray.500'>&#x2022;</Typography>
          )}
        </>
      )),
    [documentCardDetails?.metadataList]
  );

  const renderToggleButton = (text: string) => {
    return (
      text.length > maxCharacters && (
        <Box sx={styles.hiddenTextContainer}>
          <Tooltip title={showMore ? 'Show less' : 'Show more'}>
            <Button sx={styles.hiddenTextBtn} variant={'text'} onClick={toggleShowMore}>
              {showMore ? 'Show less' : 'Show more'}
            </Button>
          </Tooltip>
        </Box>
      )
    );
  };

  if (showTop10Summary) {
    return (
      <Stack spacing={1}>
        {top10Summary?.map((summary: any) => (
          <Typography
            key={summary.result_id[0]}
            variant='subtitle2'
            sx={{ ...styles.answerParagraph, maxHeight: '100%' }}>
            {summary.content}

            {summary?.result_id?.map((resultId: any) => {
              const resultIndex = documentResults?.results?.findIndex(
                (result: any) => result.result_id === resultId
              );
              return (
                <>
                  <Tooltip key={resultId} title='Go to result' placement='top'>
                    <IconButton
                      size='small'
                      aria-owns={open ? `mouse-over-popover-${resultId}` : undefined}
                      aria-haspopup='true'
                      onMouseEnter={event => handlePopoverOpen(event, resultId)}
                      onMouseLeave={handlePopoverClose}
                      sx={styles.referenceNumber}
                      onClick={() => scrollToAnswer(resultId)}>
                      {resultIndex + 1}
                    </IconButton>
                  </Tooltip>
                  {/* popover to be showed on hover of info icon */}
                  <Popover
                    id={`mouse-over-popover-${resultId}`}
                    sx={styles.popoverStyle}
                    elevation={0}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus>
                    <Stack p={1} spacing={0} sx={styles.popoverContainer}>
                      <Typography noWrap variant='body1' sx={styles.verifyPopoverTitle}>
                        {documentCardDetails.documentTitle}{' '}
                        {documentCardDetails.subtitle ? `(${documentCardDetails.subtitle})` : ''}
                      </Typography>
                      <Stack
                        direction='row'
                        spacing={0.5}
                        alignItems='center'
                        useFlexGap
                        flexWrap='wrap'
                        sx={{ overflowX: 'hidden' }}>
                        {renderMetadataItems}
                      </Stack>
                    </Stack>
                  </Popover>
                </>
              );
            })}

            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
              width='95%'
              gap='14px'
              mt={1}>
              <Tooltip title='Add to Report'>
                <ReportButton data={summary} addToReport={handleAddToReport} prefixId='chatria__' />
              </Tooltip>
            </Stack>
          </Typography>
        ))}
      </Stack>
    );
  }
  return (
    <Typography variant='subtitle2' sx={styles.answerParagraph}>
      {renderText(ariaResponseAnswerText)}
      {renderToggleButton(ariaResponseAnswerText)}
    </Typography>
  );
};

export default memo(AriaResponseText);
