const styles = {
  locationIcon: {
    color: 'gray.700'
  },
  sourceButton: { color: 'inherit', textAlign: 'start', paddingX: '12px', paddingY: 0.375 },
  navSourceButton: {
    borderRadius: 2,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'gray.300',
    height: 33,
    mr: 1
  },
  keydownIconNav: {
    stroke: 'gray.400',
    transition: 'transform 0.3s ease-in-out',
    fontSize: `12px !important`
  },
  keydownIcon: {
    fontSize: 16,
    color: 'gray.600'
  },
  keydownIconColor: {
    fontSize: 12,
    color: 'primary.700'
  },
  scopeText: {
    color: 'primary.700',
    fontWeight: 400,
    fontSize: 9,
    textTransform: 'None'
  },
  menuText: {
    color: 'gray.800',
    fontWeight: '400',
    fontSize: 20
  },
  helperText: {
    color: 'green.jungle'
  },
  option: {
    fontSize: 13,
    fontWeight: 700,
    color: 'gray.800'
  },
  optionModule: {
    fontSize: 13,
    fontWeight: 400,
    color: 'gray.800'
  },
  checkbox: {
    '&.Mui-checked': {
      color: 'primary.600'
    }
  },
  moduleListButton: { paddingLeft: 2.5, paddingRight: 1, paddingY: 0 },
  applyButton: {
    bgcolor: 'primary.650',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    border: 1,
    marginY: 1,
    marginX: 2.5,
    flex: 1,
    height: '30px',
    borderColor: 'primary.650',
    borderRadius: '16px',
    borderStyle: 'solid',
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: '12px',
    color: 'white.main',
    '&:hover': {
      bgcolor: 'primary.700'
    }
  },
  navbarDropdown: {
    width: '152px !important',
    background: 'white !important',
    zIndex: 2,
    border: 'none',
    height: 40,
    ml: '0',
    p: '8px 16px !important',
    fontSize: '13px !important',
    gap: '6px',
    '& > div': {
      width: '100%'
    }
  },
  divider: {
    mr: '10px',
    my: 'auto',
    height: 16,
    borderWidth: 1,
    borderColor: 'gray.300'
  },
  setDefaultSourcesOptionDivider: { mt: 1, mb: 0.5 },
  setDefaultSourcesOption: {
    minWidth: 180,
    fontFamily: 'Mulish',
    fontSize: 13,
    fontWeight: 400,
    color: 'primary.800',
    py: 0.75,
    px: 3,
    height: 30,
    cursor: 'pointer',
    mb: 0.5
  },
  defaultSourceLabel: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Mulish',
    color: 'gray.800',
    maxWidth: 94,
    textTransform: 'none'
  },
  setDefaultSourcesOptionWrapper: {
    color: 'gray.800',
    fontWeight: '400',
    fontSize: 20,
    minWidth: 180,
    textAlign: 'center'
  },
  icon: {
    color: 'gray.lightVariant4',
    cursor: 'pointer'
  },
  closeIconButton: { p: 0, minWidth: 0, mr: 0.5 },
  subSourceSection: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 'fit-content'
  },
  selectAllButton: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'none',
    textDecorationLine: 'underline',
    color: 'primary.600',
    ml: 1
  },
  keyDownIconVarient: {
    fontSize: 14,
    transform: 'rotate(270deg)',
    marginRight: 3,
    alignContent: 'center',
    verticalAlign: 'center',
    alignSelf: 'center'
  }
};

export default styles;
