import React, { useState } from 'react';
import { Box, Button, Divider, Drawer, Stack, Tooltip, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { isEqual } from 'lodash';
import { filterSidebarStyles, styles } from './styles';
import FilterSidebarOptions from './FilterSidebarOptions';
import RadioGroups from './RadioGroups';
import { Category, FilterSidebarProps } from './types';
import { FILTER_TYPE } from './constants';
import DateYearRange from './DateYearRange';
import NumberRange from './NumberRange';
import { ArrowLeftIcon, InfoIconSummary } from '../../assets/svgs/Icons';
import ApplyFilterAlert from './ApplyFilterAlert';
import palette from '../../themev5/palette';
import SearchKeyword from './SearchKeyword';

const FilterSidebar = ({
  showFilterSidebar,
  setShowFilterSidebar,
  data,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  applyFilters,
  isLoading,
  previousSelectedCategoryOptions,
  setPreviousSelectedCategoryOptions
}: FilterSidebarProps) => {
  const [showFilterSidebarOptions, setShowFilterSidebarOptions] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [isNumberRangeInputError, setIsNumberRangeInputError] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const isSelectedFiltersEqual = isEqual(selectedCategoryOptions, previousSelectedCategoryOptions);

  const handleShowResults = async () => {
    // setPreviousSelectedCategoryOptions need to be set before applyFilters as with date filter category i.e. approval date we display in year range
    // but payload is sent in full date range which in turn returns the applied filter in full date format rather than year format
    // this results in selectedCategoryOptions and previousSelectedCategoryOptions does not match which trigger the alert pop up
    setPreviousSelectedCategoryOptions(selectedCategoryOptions);
    await applyFilters(selectedCategoryOptions);

    if (!isLoading) {
      setShowFilterSidebar();
    }
  };

  const handleReset = async () => {
    setSelectedCategoryOptions({});
    await applyFilters({});
    if (!isLoading) {
      setShowFilterSidebar();
    }
  };

  const handleClose = () => {
    if (isEqual(selectedCategoryOptions, previousSelectedCategoryOptions)) {
      setShowFilterSidebar(false);
    } else {
      setShowAlert(true);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    setShowFilterSidebar(false);
    setShowFilterSidebarOptions(false);
  };

  return (
    <>
      <Drawer
        anchor='right'
        open={showFilterSidebar}
        onClose={handleClose}
        sx={filterSidebarStyles.drawer}>
        <Box minHeight={60} sx={styles.sidebarHeaderFooter}>
          <Typography fontSize={20} color='gray.800' fontWeight={700} ml={4}>
            Filters
          </Typography>
          <Button onClick={handleClose} sx={{ ...styles.closeIconButton, mr: 4 }}>
            <CloseIcon sx={styles.icon} />
          </Button>
        </Box>
        <Divider sx={{ mb: 3.5 }} />
        <Box
          sx={{
            ...styles.scrollContainer,
            ...filterSidebarStyles.contentContainer
          }}>
          {data.map((item: any) => {
            if (item.filterType === FILTER_TYPE.SEARCH) {
              return (
                <>
                  {item.groupName && (
                    <Box key={item.groupName} ml={4} mt={3}>
                      <Typography sx={filterSidebarStyles.separator1}>{item.groupName}</Typography>
                    </Box>
                  )}
                  <Box ml={4} key={item.value} sx={filterSidebarStyles.searchContent}>
                    <SearchKeyword
                      selectedCategoryOptions={selectedCategoryOptions}
                      setSelectedCategoryOptions={setSelectedCategoryOptions}
                    />
                  </Box>
                </>
              );
            }

            return null;
          })}
          {data.map((item: any, index: number) => {
            const groupElement = item.groupName &&
              (index === 0 || (index > 1 && data[index - 1].groupName !== item.groupName)) && (
                <Box key={item.groupName} ml={4} mt={3}>
                  <Typography sx={filterSidebarStyles.separator1}>{item.groupName}</Typography>
                </Box>
              );
            if (item.filterType === FILTER_TYPE.RADIO) {
              return (
                <>
                  {groupElement}
                  <Box key={item.value} ml={4} sx={filterSidebarStyles.radioContent}>
                    <Stack direction='row' alignItems='center'>
                      <Typography fontSize={14} color='black.darkVariant1'>
                        {item.label}
                      </Typography>
                      {item.tooltip && (
                        <Tooltip placement='top' title={item.tooltip}>
                          <InfoIconSummary sx={filterSidebarStyles.infoIcon} />
                        </Tooltip>
                      )}
                    </Stack>
                    <RadioGroups
                      setSelectedCategoryOptions={setSelectedCategoryOptions}
                      selectedCategoryOptions={selectedCategoryOptions}
                      selectedCategory={item}
                      categoryOption={item?.optionsList ?? []}
                    />
                  </Box>
                </>
              );
            }
            if (
              item.filterType === FILTER_TYPE.DATE_RANGE ||
              item.filterType === FILTER_TYPE.YEAR_RANGE
            ) {
              return (
                <>
                  {groupElement}
                  <Box key={item.value} ml={4} sx={filterSidebarStyles.dateYearRangeContent}>
                    <Stack direction='row' alignItems='center'>
                      <Typography fontSize={14} color='black.darkVariant1' sx={{ mb: 1.5 }}>
                        {item.label}
                      </Typography>
                      {item.tooltip && (
                        <Tooltip placement='top' title={item.tooltip}>
                          <InfoIconSummary sx={{ ...filterSidebarStyles.infoIcon, mb: 1.5 }} />
                        </Tooltip>
                      )}
                    </Stack>

                    <DateYearRange
                      rangeType={item.filterType}
                      selectedCategory={item}
                      selectedCategoryOptions={selectedCategoryOptions}
                      setSelectedCategoryOptions={setSelectedCategoryOptions}
                      previousSelectedCategoryOptions={previousSelectedCategoryOptions}
                      setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
                    />
                  </Box>
                </>
              );
            }
            if (item.filterType === FILTER_TYPE.NUMBER_RANGE) {
              return (
                <>
                  {groupElement}
                  <Box key={item.value} ml={4} sx={filterSidebarStyles.numberRangeContent}>
                    <Stack direction='row' alignItems='center'>
                      <Typography fontSize={14} color='black.darkVariant1' sx={{ mb: 1.5 }}>
                        {item.label}
                      </Typography>
                      {item.tooltip && (
                        <Tooltip placement='top' title={item.tooltip}>
                          <InfoIconSummary sx={{ ...filterSidebarStyles.infoIcon, mb: 1.5 }} />
                        </Tooltip>
                      )}
                    </Stack>

                    <NumberRange
                      selectedCategory={item}
                      selectedCategoryOptions={selectedCategoryOptions}
                      setSelectedCategoryOptions={setSelectedCategoryOptions}
                      isInputError={isNumberRangeInputError}
                      setIsInputError={setIsNumberRangeInputError}
                      minValue={item.min}
                      maxValue={item.max}
                    />
                  </Box>
                </>
              );
            }
            if (
              item.filterType === FILTER_TYPE.SELECT_OPTIONS ||
              item.filterType === FILTER_TYPE.MULTI_SEARCH
            ) {
              const valueKey = item?.elasticSearchType || item?.value;
              let selectedOptionCount = 0;
              if (item.filterType === FILTER_TYPE.MULTI_SEARCH) {
                item?.options?.forEach((each: any) => {
                  selectedOptionCount += selectedCategoryOptions[each.value]?.length || 0;
                });
              } else if (item.filterType === FILTER_TYPE.SELECT_OPTIONS) {
                selectedOptionCount = selectedCategoryOptions[valueKey]?.length;
              }
              const isCategoryOptionSelected = selectedOptionCount > 0;
              return (
                <>
                  {groupElement}
                  <Box
                    display='flex'
                    key={item.value}
                    alignItems='center'
                    ml={selectedCategory && selectedCategory.value === item.value ? 1.5 : 0}>
                    {selectedCategory && selectedCategory.value === item.value && (
                      <ArrowLeftIcon sx={{ fontSize: 20, color: 'primary.650' }} />
                    )}
                    <Box
                      ml={selectedCategory && selectedCategory.value === item.value ? 0 : 4}
                      key={item.value}
                      sx={{
                        ...filterSidebarStyles.selectOptionsContent,
                        backgroundColor: isCategoryOptionSelected
                          ? 'primary.600'
                          : 'gray.lightBackground',
                        border:
                          selectedCategory?.value === item.value
                            ? `1px solid ${palette.primary[600]}`
                            : 'none'
                      }}
                      onClick={() => {
                        setShowFilterSidebarOptions(
                          showFilterSidebarOptions === true || showFilterSidebarOptions === false
                        );
                        setSelectedCategory(item);
                      }}>
                      <Button
                        sx={{
                          ...filterSidebarStyles.selectOptionsText,
                          color: isCategoryOptionSelected ? 'white.main' : 'gray.800'
                        }}
                        style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                        {isCategoryOptionSelected
                          ? `${item.label} (${selectedOptionCount})`
                          : item.label}
                        {item.tooltip && (
                          <Tooltip placement='top' title={item.tooltip}>
                            <InfoIconSummary sx={filterSidebarStyles.infoIcon} />
                          </Tooltip>
                        )}
                      </Button>
                      <AddIcon
                        sx={{
                          ...filterSidebarStyles.addIcon,
                          color: isCategoryOptionSelected ? 'white.main' : 'black.darkVariant1'
                        }}
                      />
                    </Box>
                  </Box>
                </>
              );
            }
            return null;
          })}
        </Box>
        <Divider sx={{ mt: 1 }} />
        <Box mx={3} minHeight={60} sx={{ ...styles.sidebarHeaderFooter }}>
          <Button
            disabled={Object.keys(selectedCategoryOptions).length === 0 || isNumberRangeInputError}
            size='small'
            sx={styles.clearAll}
            onClick={handleReset}>
            Reset
          </Button>
          <LoadingButton
            disabled={isSelectedFiltersEqual || isNumberRangeInputError}
            size='small'
            loading={!!isLoading}
            sx={{
              ...styles.applyButton,
              opacity: isSelectedFiltersEqual || isNumberRangeInputError ? 0.38 : 1,
              '&.Mui-disabled': {
                color: isLoading ? 'transparent' : 'white.main'
              }
            }}
            style={{ backgroundColor: '#0D917D' }}
            onClick={handleShowResults}>
            Apply
          </LoadingButton>
        </Box>

        {showFilterSidebarOptions && (
          <FilterSidebarOptions
            showFilterSidebarOptions={showFilterSidebarOptions}
            setShowFilterSidebarOptions={() => setShowFilterSidebarOptions(false)}
            selectedCategory={selectedCategory}
            setSelectedCategory={() => setSelectedCategory(null)}
            selectedCategoryOptions={selectedCategoryOptions}
            setSelectedCategoryOptions={setSelectedCategoryOptions}
          />
        )}
      </Drawer>
      {showAlert && (
        <ApplyFilterAlert
          showAlert={showAlert}
          selectedCategoryOptions={selectedCategoryOptions}
          applyFilters={applyFilters}
          previousSelectedCategoryOptions={previousSelectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default React.memo(FilterSidebar);
