import { isEqual } from 'lodash';
import {
  getSubscriptionSourcePreference,
  getSubscriptionStatus
} from '../../api/modules/userNotification';
import {
  cnfToAdvancedSearch,
  generateCNFQuery,
  handle505b2AdvancedSearch,
  handleAdvancedSearch,
  urlDateToLocalDate
} from '../../components/Header/utils/advanceSearchHelpers';
import {
  CountInfo,
  DocumentSearchSubscription,
  ParentNotification,
  SearchData
} from './components/NotificationInterface';
import { CHANNELS } from './constants';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../../components/Header/utils/advanceSearchConstants';
import RESULT_VIEW_TYPES from '../SearchResults/components/constants';
import { encodeObjectToBase64 } from '../../utils/encodeDecodeObject';

const combineDocumentSearchNotifications = (notifications: ParentNotification[]) => {
  /*
   * Combine document search notifications with the same subscription id and date
   * into a single notification and adds new attributes to the notification
   */

  const combinedDocSearchNotifications = new Map<string, ParentNotification>();
  const finalNotifications = [] as ParentNotification[];

  const docSearchNotifications = notifications.filter(
    notif => notif.channel.name === 'document_search'
  );

  docSearchNotifications.forEach(notif => {
    const { date, entity_id: docSearchSubscriptionId } = notif;
    const key = `${date}_${docSearchSubscriptionId}`;
    const existingNotification = combinedDocSearchNotifications.get(key);
    if (existingNotification) {
      // add source to existing notification if not already present
      if (!existingNotification?.sources?.includes(notif.source))
        existingNotification.sources?.push(notif.source);

      // update count
      if (existingNotification?.count_info?.Results) {
        existingNotification.count_info.Results.add += notif?.count_info?.Results.add ?? 0;
        existingNotification.count_info.Results.delete += notif?.count_info?.Results.delete ?? 0;
        existingNotification.count_info.Results.update += notif?.count_info?.Results.update ?? 0;
      }

      // update seen and clicked status
      if (!notif.clicked) existingNotification.clicked = false;
      if (!notif.seen) {
        existingNotification.seen = false;
        existingNotification.count_info['New Results'].add += notif?.count_info?.Results?.add ?? 0;
        existingNotification.count_info['New Results'].delete +=
          notif?.count_info?.Results?.delete ?? 0;
        existingNotification.count_info['New Results'].update +=
          notif?.count_info?.Results?.update ?? 0;
      }
      // add new results to existing notification
      if (existingNotification?.notifications?.Results)
        existingNotification.notifications.Results =
          existingNotification.notifications.Results.concat(notif?.notifications?.Results ?? []);
    } else {
      const updatedDocSearcnNotif = { ...notif, sources: [notif?.source] };
      // update seen and clicked status
      updatedDocSearcnNotif.count_info['New Results'] = !notif.seen
        ? notif?.count_info?.Results ?? { add: 0, delete: 0, update: 0 }
        : { add: 0, delete: 0, update: 0 };
      combinedDocSearchNotifications.set(key, updatedDocSearcnNotif);
    }
  });

  notifications.forEach(notif => {
    if (notif.channel.name !== 'document_search') finalNotifications.push(notif);
    else {
      const { date, entity_id: docSearchSubscriptionId } = notif;
      const key = `${date}_${docSearchSubscriptionId}`;
      const combinedNotif = combinedDocSearchNotifications.get(key);
      if (combinedNotif) {
        // Sort Combined Notification Results by date
        combinedNotif.notifications?.Results.sort(
          (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        finalNotifications.push(combinedNotif);
        combinedDocSearchNotifications.delete(key);
      }
    }
  });
  return finalNotifications;
};

const getSourceSubscriptions = async () => {
  try {
    const response = await getSubscriptionSourcePreference();
    if (response?.status !== 200) return {};
    const preferencesList = response?.data?.body?.source_preferences ?? [];
    const subscriptions = preferencesList.reduce(
      (acc: Record<string, boolean>, preference: any) => {
        if (preference?.source) {
          acc[preference.source] = !!preference.subscribed;
        }
        return acc;
      },
      {}
    ) as Record<string, boolean>;
    return subscriptions;
  } catch (e) {
    return {};
  }
};

const getEntitySubscriptionStatus = async ({
  source,
  entityType,
  entityId
}: {
  source: string;
  entityType: (typeof CHANNELS)[number];
  entityId: string;
}): Promise<{
  isSubscribed: boolean;
  subscribedDocumentSearchDetails: DocumentSearchSubscription | null;
}> => {
  try {
    const payload: any = {
      source,
      entityId,
      entityType
    };
    const subscriptionStatus = await getSubscriptionStatus(payload);
    const isSubscribed = subscriptionStatus?.data?.body?.is_subscribed ?? false;
    const subscribedDocumentSearchDetails =
      subscriptionStatus?.data?.body?.subscribed_search_details;
    return { isSubscribed, subscribedDocumentSearchDetails };
  } catch (e) {
    return { isSubscribed: false, subscribedDocumentSearchDetails: null };
  }
};

const getQuickSearchTitle = (searchInfo: SearchData | undefined) => {
  if (searchInfo?.category === 'first_approval_date') {
    return searchInfo?.searchData?.first_approval_date?.join(' - ') ?? '';
  }
  if (
    searchInfo?.category === 'approval_date' &&
    Array.isArray(searchInfo?.searchData?.approval_date)
  )
    return searchInfo?.searchData?.approval_date?.join(' - ') ?? '';
  return searchInfo?.term ?? '';
};

const getQuickSearchSubtitle = (searchInfo: SearchData | undefined) => {
  let localPath = '';
  if (searchInfo?.category_label) {
    localPath += `${searchInfo?.category_label ?? ''}`;
  }
  if (searchInfo?.filters && Object.keys(searchInfo?.filters)?.length !== 0) {
    if (localPath) localPath += ` | `;
    localPath += 'With filters';
  }
  return localPath;
};

const get505b2Data = (searchInfo: SearchData | undefined) => {
  const { searchData, source: searchSource } = searchInfo ?? {};

  const is505b2Data = {} as any;
  if (isEqual(searchSource, { us: ['505b2'] })) {
    Object.entries(searchData).forEach(([key, items]) => {
      if (items) {
        const from = (items as any)?.from;
        const to = (items as any)?.to;
        if (from && to) {
          is505b2Data[key] = `${from} -> ${to}`;
        }
      }
    });
  }
  return is505b2Data;
};

const getAdvancedSearchTitle = (searchInfo: SearchData | undefined) => {
  let localSearch: string | string[] = '';
  let payload = [];
  const { cnf_query: cnfQuery, source: searchSource } = searchInfo ?? {};
  const currentSource = Object.keys(searchSource ?? {})?.[0] ?? '';
  if (!currentSource)
    return { localSearch: 'Advanced Search | No source found', advanceSearchPayload: [] }; // This should never happen

  const is505b2Data = get505b2Data(searchInfo);
  if (Object.keys(is505b2Data).length > 0) {
    Object.entries(is505b2Data).forEach(([key, value]) => {
      if (localSearch) localSearch += ` | `;
      localSearch += `${key}: ${value}`;
    });
  } else {
    payload = cnfToAdvancedSearch(cnfQuery ?? '');
    // @ts-ignore
    localSearch = generateCNFQuery(
      payload,
      // @ts-ignore
      ELASTIC_SEARCH_ATTRIBUTES_LABELS[currentSource.toLowerCase()]
    );
  }
  return { localSearch, advanceSearchPayload: payload };
};

const getAdvancedSearchSubtitle = (searchInfo: SearchData | undefined) => {
  let localPath = 'Advanced Search';
  const { filters, dateFilter, source: searchSource } = searchInfo ?? {};
  const currentSource = Object.keys(searchSource ?? {})?.[0];
  if (!currentSource) return 'Advanced Search | No source found';
  const is505b2Data = get505b2Data(searchInfo);
  if (Object.keys(is505b2Data).length > 0) localPath = '505(b)(2)';
  if (dateFilter && Object.keys(dateFilter).length !== 0) {
    const localDateFilter = {
      startDate: '',
      endDate: ''
    };
    if (dateFilter.start_date) {
      localDateFilter.startDate = urlDateToLocalDate(dateFilter.start_date)
        .toISOString()
        ?.split('T')[0];
    }
    if (dateFilter.end_date) {
      localDateFilter.endDate = urlDateToLocalDate(dateFilter.end_date)
        .toISOString()
        ?.split('T')[0];
    }
    localPath += ` | ${localDateFilter.startDate} - ${localDateFilter.endDate}`;
  }
  if (filters && Object.keys(filters)?.length !== 0) {
    if (localPath) localPath += ` | `;
    localPath += 'With filters';
  }

  return localPath;
};

const handleDocumentSearchClick = (
  documentSearchSubscription: DocumentSearchSubscription,
  documentSearchRiaAddedDate = ''
) => {
  const { subscription_search_data: ariaSearchData } = documentSearchSubscription ?? {};
  const payload: any = {
    search_term: ariaSearchData?.term ?? '',
    source: ariaSearchData?.source ?? {},
    filters: ariaSearchData?.filters ?? {},
    view_type: 'document'
  };
  if (documentSearchRiaAddedDate) {
    payload.filters.document_created_date = [
      documentSearchRiaAddedDate,
      documentSearchRiaAddedDate
    ];
  }
  const encodedPayload = encodeObjectToBase64(payload) ?? '';
  window.open(`/search/${encodedPayload}`, '_blank');
};

const handleSearchClick = ({ searchInfo }: { searchInfo: SearchData | undefined }) => {
  const { term, source: searchSource, id: searchId } = searchInfo ?? {};
  const payload = {
    search_term: term,
    source: searchSource,
    application_search_id: searchId,
    view_type: RESULT_VIEW_TYPES.APPLICATION
  };
  const encodedPayload = encodeObjectToBase64(payload) ?? '';
  const url = `/search/${encodedPayload}`;
  window.open(url, '_blank');
};

const handleAdvancedSearchClick = async ({
  searchInfo,
  advanceSearchPayload
}: {
  searchInfo: SearchData | undefined;
  advanceSearchPayload: any[];
}) => {
  const {
    searchData,
    dateFilter = {},
    id: searchId,
    use_synonyms: useSynonyms = true,
    source: searchSource
  } = searchInfo ?? {};
  let url = '';
  if ('formulations' in searchData || 'route' in searchData) {
    url =
      handle505b2AdvancedSearch(
        (searchData as { [key: string]: { from: string; to: string } })[Object.keys(searchData)[0]],
        'formulations' in searchData ? 'formulation' : 'route',
        {
          startDate: dateFilter.start_date ? new Date(dateFilter.start_date) : null,
          endDate: dateFilter.end_date ? new Date(dateFilter.end_date) : null
        },
        searchId ?? ''
      ) ?? '';
  } else {
    const otherData: {
      startDate: string | Date;
      endDate: string | Date;
      useSynonyms: boolean;
    } = {
      startDate: '',
      endDate: '',
      useSynonyms
    };
    if ('start_date' in dateFilter) {
      otherData.startDate = urlDateToLocalDate(dateFilter.start_date);
    }
    if ('end_date' in dateFilter) {
      otherData.endDate = urlDateToLocalDate(dateFilter.end_date);
    }
    url = handleAdvancedSearch(advanceSearchPayload, otherData, searchSource, searchId ?? '');
  }
  window.open(url, '_blank');
};

const getPageNumberFromURL = (url: string): number => {
  try {
    const parsedUrl = new URL(url);
    const { hash } = parsedUrl; // Get the fragment (e.g., #page=23)

    if (hash.startsWith('#page=')) {
      return parseInt(hash.split('=')[1], 10); // Extract and return the page number
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

const extractTextWithCharacterLimit = (subscriptionQuery: string, text: string): string => {
  // Split the subscription query into trimmed keywords
  const keywords = subscriptionQuery.split('AND').map(keyword => keyword.trim());

  // Build a regex to find bold tags containing any of the keywords
  const keywordRegex = new RegExp(`<b>(.*?)(${keywords.join('|')})(.*?)</b>`, 'i');
  const match = keywordRegex.exec(text);

  if (match) {
    // Calculate start index for extraction, ensuring a context buffer
    let boldStartIndex = match.index;

    // Check if words exist before the bold tag
    const wordsBeforeBold = text.slice(0, boldStartIndex).split(/\s+/);
    const wordCount = wordsBeforeBold.length;

    // Get 2 or 3 words before the bold tag based on word length
    let contextWords = [];
    if (wordCount >= 3) {
      const lastWordLength = wordsBeforeBold[wordCount - 1]?.length || 0;
      const numWords = lastWordLength < 4 ? 3 : 2;
      contextWords = wordsBeforeBold.slice(-numWords);
    } else {
      contextWords = wordsBeforeBold; // Use all available words if less than 3
    }

    // Adjust the start index to include the selected context words
    boldStartIndex = Math.max(0, boldStartIndex - contextWords.join(' ').length - 1);

    // Extract and balance text
    const extractedText = text.substring(boldStartIndex);

    // Remove any partially cut-off HTML tags and balance the text
    const balancedText = extractedText.replace(/<[^<>]*$/, '').trim();

    // Return the balanced text with ellipses
    return `...${balancedText}...`;
  }

  // Return the plain text
  return text;
};

const getSumOfCountInfo = (countInfo: CountInfo) => {
  return Object.values((countInfo as any) || {}).reduce(
    (acc: number, val: any) => acc + (val ?? 0),
    0
  );
};

export {
  getAdvancedSearchSubtitle,
  getAdvancedSearchTitle,
  getEntitySubscriptionStatus,
  getSourceSubscriptions,
  getQuickSearchSubtitle,
  getQuickSearchTitle,
  handleAdvancedSearchClick,
  handleSearchClick,
  handleDocumentSearchClick,
  combineDocumentSearchNotifications,
  getPageNumberFromURL,
  extractTextWithCharacterLimit,
  getSumOfCountInfo
};
