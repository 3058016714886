import { PIECHARTCOLOR } from '../CustomComponents/Graphs/constant';

export const FONTSIZES = [
  { value: 16, label: '16' },
  { value: 18, label: '18' },
  { value: 20, label: '20' },
  { value: 22, label: '22' }
];
export const LIMITVALS = [
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' }
];

export const RIA_GRAPHS: any = {
  us: [
    {
      id: 'us_result_approval_by_year',
      title: 'Approval(s) by Year',
      widget: 'bar-widget',
      query: {
        'x-axis': ['approval_year'],
        agg_function: 'count'
      }
    },
    {
      id: 'us_result_approval_time_by_year',
      title: 'Median Approval Time, Days from Filing',
      widget: 'bar-widget',
      query: {
        'x-axis': ['approval_year'],
        'y-axis': ['approval_time'],
        agg_function: 'median'
      }
      // overlay: {
      //   type: 'TEXT',
      //   value_format: 'Mean: {{}}',
      //   query: {
      //     'x-axis': ['approval_year'],
      //     'y-axis': ['approval_time'],
      //     agg_function: 'mean'
      //   }
      // }
    },
    {
      id: 'accelerated_approval_percentage',
      title: 'Accelerated Approvals',
      widget: 'pie-widget',
      query: {
        'x-axis': ['accelerated_approval'],
        agg_function: 'percentage'
      }
    },
    {
      id: 'breakthrough_designation_percentage',
      title: 'Breakthrough Designation',
      widget: 'pie-widget',
      query: {
        'x-axis': ['breakthrough_status'],
        agg_function: 'percentage'
      }
    },
    {
      id: 'fast_track_percentage',
      title: 'Fast Track',
      widget: 'pie-widget',
      query: {
        'x-axis': ['fast_track'],
        agg_function: 'percentage'
      }
    },
    {
      id: 'orphan_designation_percentage',
      title: 'Orphan Designation',
      widget: 'pie-widget',
      query: {
        'x-axis': ['orphan_status'],
        agg_function: 'percentage'
      }
    }
  ],
  ct: [
    {
      id: 'number_of_trials_by_completion_year',
      title: 'Number of Trials by Completion year',
      widget: 'bar-widget',
      query: { 'x-axis': ['study_completion_year'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 30
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3'
      }
    },
    {
      id: 'number_of_trials_by_phase',
      title: 'Number of Trials by Phase',
      widget: 'bar-widget',
      query: { 'x-axis': ['phase'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 100
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        layout: 'horizontal'
      }
    },
    {
      id: 'number_of_trials_by_recruitment_status',
      title: 'Number of Trials by Recruitment Status',
      widget: 'bar-widget',
      query: { 'x-axis': ['overall_status'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 120
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        layout: 'horizontal'
      }
    },
    {
      id: 'number_of_patients_by_trials',
      title: 'Number of Patients by Trial',
      widget: 'bar-widget',
      query: { 'x-axis': ['nct_id'], 'y-axis': ['enrollment'], agg_function: 'median' }
    }
  ],
  'fda-guidance': [
    {
      id: 'guidance_documents_by_year',
      title: 'Guidance Documents by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['issue_datetime'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 30
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'document_type',
      title: 'Document Type',
      widget: 'pie-widget',
      query: {
        'x-axis': ['communication_type'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'fda_Organization',
      title: 'FDA Organization',
      widget: 'pie-widget',
      query: {
        'x-axis': ['center'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'products',
      title: 'Products',
      widget: 'pie-widget',
      query: {
        'x-axis': ['regulated_product_field'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  'ema-guidance': [
    {
      id: 'guidance_documents_by_year',
      title: 'Guidance Documents by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['first_published_date'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 30
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'keyword_pie_chart',
      title: 'Keywords',
      widget: 'pie-widget',
      query: {
        'x-axis': ['keywords'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'status_pie_chart',
      title: 'Status',
      widget: 'pie-widget',
      query: {
        'x-axis': ['status'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  'us-adcomm': [
    {
      id: 'meeting_by_year',
      title: 'Total Number of Meeting by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['start_date'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 30
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'appl_type_pie_chart',
      title: 'NDAs vs BLAs vs Other',
      widget: 'pie-widget',
      query: {
        'x-axis': ['appl_type'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'appl_type_post_acm',
      title: 'Post Meeting Approvals',
      widget: 'pie-widget',
      query: {
        'x-axis': ['approval_decision'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],

  'fda-written-requests': [
    {
      id: 'drugs_by_determination_year',
      title: 'Drugs by Determination Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['determination_date'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        tickRotationValue: 0,
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'formulation',
      title: 'Formulations',
      widget: 'pie-widget',
      query: {
        'x-axis': ['formulation'],
        agg_function: 'count',
        limit: 4
      },
      graphStyle: {
        innerRadius: 0.7,
        colors: PIECHARTCOLOR,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'route',
      title: 'Routes',
      widget: 'pie-widget',
      query: {
        'x-axis': ['route'],
        agg_function: 'count',
        limit: 4
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  'ema-pips': [
    {
      id: 'drugs_by_determination_year',
      title: 'Drugs by Determination Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['decision_date'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'formulation',
      title: 'Formulations',
      widget: 'pie-widget',
      query: {
        'x-axis': ['formulations'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'route',
      title: 'Routes',
      widget: 'pie-widget',
      query: {
        'x-axis': ['routes'],
        agg_function: 'count',
        limit: 4
      },
      graphStyle: {
        innerRadius: 0.7,
        colors: PIECHARTCOLOR,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  chmp: [
    {
      id: 'meetings_by_year',
      title: 'Total Number of Unique Products by Years',
      widget: 'bar-widget',
      query: { 'x-axis': ['year'], agg_function: 'count', sort_on_axis: 'x' },
      graphStyle: {
        colors: ['#6868FA'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 30
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'meeting_insights',
      title: 'Meeting Insights',
      widget: 'pie-widget',
      query: {
        'x-axis': ['highlights'],
        agg_function: 'count'
      }
    }
  ],
  impact: [
    {
      id: 'pmrs_issued_by_year',
      title: 'PMRs Issued by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['issued_year'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 30
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'pmrs_fulfilled_by_year',
      title: 'PMRs Fulfilled by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['fulfilled_year'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.7,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 30
        },
        tickRotationValue: 90,
        borderRadius: 4,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    }
  ],
  surveillance: [
    {
      id: 'pmrs_by_category',
      title: 'PMRs/PMCs by Categories',
      widget: 'bar-widget',
      query: { 'x-axis': ['category'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        padding: 0.8,
        axisBottom: {
          tickRotation: 25
        },
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'status',
      title: 'PMR / PMC Status',
      widget: 'pie-widget',
      query: { 'x-axis': ['status'], agg_function: 'count' },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'subpart_desc',
      title: 'PMRs/PMCs by Subpart Description',
      widget: 'pie-widget',
      query: { 'x-axis': ['subpart_desc'], agg_function: 'count' },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  hta: [
    {
      id: 'hta_documents_by_year',
      title: 'HTA Documents by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['published_date'], agg_function: 'count', sort_on_axis: 'x' },
      graphStyle: {
        colors: ['#B1BBE4'],
        axisLeft: {
          tickRotation: 0
        },
        axisBottom: {
          tickRotation: 80
        }
      }
    },
    {
      id: 'nice_doc_type',
      title: 'Document Type',
      widget: 'pie-widget',
      query: {
        'x-axis': ['nice_doc_type'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'nice_advice_type',
      title: 'Advice Type',
      widget: 'pie-widget',
      query: {
        'x-axis': ['nice_advice_type'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'std_status',
      title: 'Status',
      widget: 'pie-widget',
      query: {
        'x-axis': ['std_status'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  'canada-guidance': [
    {
      id: 'guidance_documents_by_year',
      title: 'Guidance Documents by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['issued_date'], agg_function: 'count' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.8,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'guidance-by-adopted-date',
      title: 'Canada Guideline Documents by Adopted Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['date_adopted'], agg_function: 'count', sort_on_axis: 'x' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.8,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'keyword_pie_chart',
      title: 'Keywords',
      widget: 'pie-widget',
      query: {
        'x-axis': ['keywords'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  'fda-letters': [
    {
      id: 'letters_by_year',
      title: 'Letters by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['published_date'], agg_function: 'count', sort_on_axis: 'x' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.8,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'letter_document_type',
      title: 'Document Type',
      widget: 'pie-widget',
      query: {
        'x-axis': ['letter_document_type'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'fda_issuing_office',
      title: {
        title: 'FDA Issuing Office',
        show: true
      },
      widget: 'pie-widget',
      query: {
        'x-axis': ['fda_issuing_office'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  'dd-tools': [
    {
      id: 'ddt_projects_by_year',
      title: 'Drug Development Tool Projects by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['latest_stage_date'], agg_function: 'count', sort_on_axis: 'x' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.8,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'program_type_desc',
      title: 'Project Type',
      widget: 'pie-widget',
      query: {
        'x-axis': ['program_type_desc'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'submission_status',
      title: {
        title: 'Submission Status',
        show: true
      },
      widget: 'pie-widget',
      query: {
        'x-axis': ['submission_status'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ],
  ema_orphan: [
    {
      id: 'ema_orphan_designations_by_year',
      title: 'Opinions on Orphan Medicinal Product Designation by Year',
      widget: 'bar-widget',
      query: { 'x-axis': ['decision_date'], agg_function: 'count', sort_on_axis: 'x' },
      graphStyle: {
        colors: ['#B1BBE4'],
        paddings: 0.8,
        labelTextColor: '#ffffff',
        axisText: '#A3A3A3',
        axisLeft: {
          tickRotation: 0
        }
      }
    },
    {
      id: 'decision_outcome',
      title: 'Decision Outcome',
      widget: 'pie-widget',
      query: {
        'x-axis': ['decision_outcome'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'designation_status',
      title: 'Decision Status',
      widget: 'pie-widget',
      query: {
        'x-axis': ['designation_status'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    },
    {
      id: 'age_range',
      title: 'Age Ranges',
      widget: 'pie-widget',
      query: {
        'x-axis': ['age_range'],
        agg_function: 'count'
      },
      graphStyle: {
        colors: PIECHARTCOLOR,
        innerRadius: 0.7,
        pieLabel: true,
        pieLegend: false,
        enableLabels: 'both',
        sliceLabelsTextColor: { from: 'color' },
        fillText: '#707070',
        radialLabelsTextColor: '#707070'
      }
    }
  ]
};

export const dateFields = [
  'approval_year',
  'first_submitted_year',
  'last_updated_year',
  'published_date',
  'date_of_fullfillment',
  'fulfilled_year',
  'issued_year',
  'determination_date',
  'first_published',
  'issue_datetime',
  'first_published_date',
  'legal_effective_date',
  'consultation_date_to',
  'consultation_date_from',
  'last_updated_date',
  'study_completion_year',
  'start_date',
  'decision_date',
  'year',
  'issued_date',
  'adopted_date',
  'sales_year',
  'date'
];

export const CHART_TYPE_TITLE_SUFFIX: any = {
  'pie-widget': 'Distribution',
  'bar-widget': 'Breakdown',
  'table-widget': 'Summary Table'
};

export const REPORT_DOWNLOAD_FORMAT = {
  PDF: 'pdf',
  DOCX: 'docx'
};

export const MAX_TABLE_ROWS = 50;

export const GRAPH_TYPES_TO_SKIP: string[] = [
  'ct_timeline',
  'indication_sequencing',
  'patent_exclusivity_timeline'
];

export const LIST_OF_CUSTOM_GRAPHS: string[] = [
  'CDP_TIMELINE',
  'CT_TIMELINE_GANTT',
  'EXPEDITED_TIMELINE',
  'CT_TIMELINE_CHANGE',
  'INDICATION_SEQUENCING',
  'PATENT_EXCLUSIVITY_TIMELINE'
];

export const EXCLUDED_CHART_TYPES = [
  'CDP_TIMELINE',
  'CT_TIMELINE_GANTT',
  'EXPEDITED_TIMELINE',
  'CT_TIMELINE_CHANGE',
  'TABLE',
  'INDICATION_SEQUENCING',
  'PATENT_EXCLUSIVITY_TIMELINE'
];
