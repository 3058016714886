import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';

import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Drawer,
  Tooltip,
  CircularProgress
} from '@mui/material';
import {
  CloseCrossIcon,
  AngleDownIcon,
  SearchIcon,
  InfoIconSummary,
  ArrowLeftIcon,
  CustomCheckIcon
} from '../../../assets/svgs/Icons';

import TitleInfoPopover from './components/TitleInfoPopover';
import Metadata from './components/EntityMetadata';

import styles from './Drawer.styles';
import DocumentAccordion from './components/DocumentAccordion';
import SecondaryDrawer from './components/SecondaryDrawer';
import DocumentViewer from './components/DocumentViewer';
import { DocumentItem, DocumentListDialogProps, DocumentsGroup, SubEntity } from './types';
import { filterByCategory, filterBySearchText } from './filterUtils';
import Button from '../../ui/Button';
import CompareDialog from './components/CompareDialog';

const DEFAULT_DOCUMENT_CATEGORY = [
  {
    name: 'All Files',
    count: 0,
    id: 'all'
  }
];

const DocumentListDialog = ({
  defaultDocuments,
  title,
  infoComponent,
  onClose,
  chatRIAMetadata,
  source,
  defaultSelectedEntityId,
  subEntities = [],
  disableChatRia = false,
  allowComparison = false,
  secondaryDrawerTitle = 'Submission',
  metadata = [],
  showLoading = false
}: DocumentListDialogProps) => {
  const [infoElement, setInfoElement] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState('all');

  const [openSecondaryDrawer, setOpenSecondaryDrawer] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentItem | null>(null);
  const [chatRIADocumentMetadata, setChatRIADocumentMetadata] = useState<any>(null);

  const [selectedEntityId, setSelectedEntityId] = useState<string>(defaultSelectedEntityId);
  const [documents, setDocuments] = useState<DocumentsGroup[]>(defaultDocuments);

  const [documentsToCompare, setDocumentsToCompare] = useState<Array<DocumentItem>>([]);
  const [compareEnabled, setCompareEnabled] = useState<boolean>(false);
  const [openCompareDrawer, setOpenCompareDrawer] = useState<boolean>(false);
  const [pdfURL, setPdfURL] = useState<string>('');

  const [searchText, setSearchText] = useState<string>('');

  const entitySelectionContainerRef = useRef<HTMLElement>();
  const documentCategorySelectionContainerRef = useRef<HTMLElement>();
  const comparisonButtonContainerRef = useRef<HTMLElement>();

  const [headerHeight, setHeaderHeight] = useState(allowComparison ? 248 : 200);

  const handleDocumentSelection = async (document: DocumentItem) => {
    let url = document.title_link as string;
    if (document.getPdfUrl) {
      url = await document.getPdfUrl(document?.title_link as string);
    }
    setPdfURL(url);

    setSelectedDocument(document);
    setOpenSecondaryDrawer(false);
    setChatRIADocumentMetadata({
      ...chatRIAMetadata,
      category: document.category,
      ...(document.chatRiaMetadata || {})
    });
  };

  const handleEntitySelection = (entity: SubEntity) => {
    setSelectedDocument(null);
    setSelectedCategoryId('all');
    setDocuments(entity.getDocuments?.() || []);
    setSelectedEntityId(entity.id);
  };

  const handleCompareClick = useCallback(() => {
    if (compareEnabled && documentsToCompare.length === 2) {
      setSelectedDocument(null);
      setOpenCompareDrawer(true);
    } else {
      setCompareEnabled(true);
      setOpenCompareDrawer(false);
    }
  }, [compareEnabled, documentsToCompare]);

  const handleComparisonCheckbox = useCallback(
    (doc: DocumentItem) => {
      if (
        documentsToCompare.find((docComp: DocumentItem) => docComp.title_link === doc.title_link)
      ) {
        const removeDocument = documentsToCompare.filter(
          (docComp: DocumentItem) => doc.title_link !== docComp.title_link
        );
        setDocumentsToCompare(removeDocument);
      } else if (documentsToCompare.length === 2) {
        const newDocs = [...documentsToCompare];
        newDocs.pop();
        setDocumentsToCompare([...newDocs, doc]);
      } else {
        // Check whether doc is present in array of document_history
        const addDocument = [...documentsToCompare, doc];
        setDocumentsToCompare(addDocument);
      }
    },
    [documentsToCompare, documents]
  );

  const exitCompare = useCallback(() => {
    setCompareEnabled(false);
    setDocumentsToCompare([]);
    setOpenCompareDrawer(false);
  }, []);

  const clearComparison = useCallback(() => {
    setDocumentsToCompare([]);
    setOpenCompareDrawer(false);
  }, []);

  const selectedEntity = useMemo(() => {
    return subEntities?.find((entity: SubEntity) => entity.id === selectedEntityId);
  }, [selectedEntityId]);

  const documentCategories = useMemo(() => {
    let totalCount = 0;
    const docs = documents.length > 0 ? documents : defaultDocuments;
    const categories = docs?.map((doc: DocumentsGroup) => {
      totalCount += doc.documents?.length || 0;
      return {
        id: doc.id,
        name: doc.categoryTitle,
        count: doc.documents?.length || 0
      };
    });
    categories.unshift({
      ...DEFAULT_DOCUMENT_CATEGORY[0],
      count: totalCount
    });
    return categories;
  }, [documents, defaultDocuments]);

  const handleSearchTextChange = debounce((event: any) => setSearchText(event.target.value), 200);

  const documentsToDisplay = useMemo(() => {
    let docs = documents.length > 0 ? documents : defaultDocuments;
    docs = filterByCategory(docs, selectedCategoryId);
    docs = filterBySearchText(docs, searchText);
    return docs;
  }, [documents, defaultDocuments, selectedCategoryId, searchText]);

  const accordionContainerStyle = useMemo(() => {
    return {
      ...styles.accordionContainer,
      height: `calc(100% - ${headerHeight}px)`
    };
  }, [allowComparison, headerHeight]);

  useEffect(() => {
    setTimeout(() => {
      const height =
        (entitySelectionContainerRef.current?.offsetHeight ?? 0) +
        (documentCategorySelectionContainerRef.current?.offsetHeight ?? 0) +
        (comparisonButtonContainerRef.current?.offsetHeight ?? 0) +
        80;
      setHeaderHeight(height);
    }, 200);
  }, []);

  return (
    <Drawer
      open
      anchor='right'
      transitionDuration={500}
      hideBackdrop={false}
      sx={{
        ...styles.mainDrawer,
        ...(openSecondaryDrawer || !!selectedDocument
          ? {
              '& .MuiDrawer-paper': {
                ...styles.mainDrawer['& .MuiDrawer-paper'],
                width: {
                  md: 564,
                  'lg-md': 632
                },
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }
            }
          : {})
      }}
      onClose={onClose}>
      <Box bgcolor='primary.600' py={1.5} px={2.5}>
        <Stack direction='row' alignItems='center'>
          <Tooltip title={title}>
            <Typography sx={{ ...styles.drawerTitle, maxWidth: !infoElement ? '90%' : '45%' }}>
              {title}
            </Typography>
          </Tooltip>

          {infoComponent && (
            <IconButton
              sx={{ p: 0.75, ml: 1 }}
              onClick={(event: any) => setInfoElement(event.currentTarget)}>
              <InfoIconSummary sx={styles.drawerHeaderIcon} />
            </IconButton>
          )}
          <IconButton sx={styles.closeIconButton} onClick={onClose}>
            <CloseCrossIcon sx={styles.drawerHeaderIcon} />
          </IconButton>
        </Stack>
        <TitleInfoPopover
          open={!!infoElement}
          anchorEl={infoElement}
          infoComponent={infoComponent}
          onClose={() => setInfoElement(null)}
        />
      </Box>
      {!showLoading && (
        <Box height='100%'>
          <Box px={2.5} py={1.5} ref={entitySelectionContainerRef}>
            {selectedEntity && subEntities.length > 0 && (
              <Stack direction='row' alignItems='center'>
                <Box
                  display='flex'
                  alignItems='center'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenSecondaryDrawer(true);
                    setSelectedDocument(null);
                  }}
                  maxWidth='30%'>
                  <ArrowLeftIcon sx={styles.arrowLeftIcon} />
                  <Tooltip title={selectedEntity?.name}>
                    <Typography sx={styles.selectedEntity}>{selectedEntity?.name}</Typography>
                  </Tooltip>
                </Box>
                {selectedEntity?.metadata && selectedEntity?.metadata?.length > 0 && (
                  <>
                    <Divider orientation='vertical' sx={styles.entityDivider} />
                    <Metadata data={selectedEntity?.metadata} />
                  </>
                )}
              </Stack>
            )}
            {metadata.length > 0 && !selectedEntity?.metadata?.length && (
              <Metadata data={metadata} />
            )}
          </Box>
          <Divider sx={{ borderColor: 'gray.200' }} />
          <Box px={2.5} py={2} ref={documentCategorySelectionContainerRef}>
            <Stack direction='row' alignItems='center'>
              <Select
                value={selectedCategoryId}
                sx={styles.categorySelection}
                onChange={(event: any) => setSelectedCategoryId(event.target.value)}
                MenuProps={{ PaperProps: { sx: styles.categorySelectionPopover } }}
                IconComponent={AngleDownIcon}>
                {documentCategories.map((doc: any) => (
                  <MenuItem
                    value={doc.id}
                    key={doc.id}
                    sx={{
                      px: 3,
                      '& .Mui-selected': {
                        bgcolor: 'primary.0'
                      }
                    }}>
                    <ListItemText
                      sx={{
                        m: 0,
                        span:
                          selectedCategoryId === doc.id
                            ? {
                                fontSize: 13,
                                fontWeight: 700,
                                color: 'gray.800',
                                letterSpacing: 0.2
                              }
                            : {
                                fontSize: 13,
                                fontWeight: 400,
                                color: 'gray.800',
                                letterSpacing: 0.2
                              }
                      }}>
                      {doc.name} ({doc.count})
                    </ListItemText>
                    <ListItemIcon>
                      {selectedCategoryId === doc.id && (
                        <CustomCheckIcon sx={styles.secondaryDrawerListItemSelectedCheckbox} />
                      )}
                    </ListItemIcon>
                  </MenuItem>
                ))}
              </Select>
              <TextField
                variant='outlined'
                placeholder='Search...'
                sx={styles.searchInputBox}
                onChange={handleSearchTextChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
          </Box>
          <Divider sx={{ mx: 2.5, borderColor: 'gray.200' }} />
          {allowComparison && (
            <Box px={2.5} py={2} ref={comparisonButtonContainerRef}>
              <Stack direction='row' alignItems='center' spacing={1.5}>
                {compareEnabled && (
                  <Stack direction='row' alignItems='center' spacing={1.5}>
                    <Typography sx={styles.activeSelectionText}>
                      {`${documentsToCompare.length}/2`} Selected
                    </Typography>
                    <Divider orientation='vertical' sx={{ height: 16, borderColor: 'gray.300' }} />
                    <Typography
                      onClick={clearComparison}
                      sx={
                        documentsToCompare.length > 0
                          ? styles.compareResetActive
                          : styles.compareResetInactive
                      }>
                      Reset
                    </Typography>
                  </Stack>
                )}
                <Stack direction='row' alignItems='center' spacing={1.5} ml='auto !important'>
                  <Button
                    title='Compare'
                    selected={documentsToCompare.length === 2}
                    disabled={compareEnabled && documentsToCompare.length !== 2}
                    onClick={handleCompareClick}
                    size='medium'
                  />
                  {compareEnabled && (
                    <Button
                      title='Exit Compare'
                      selected={false}
                      onClick={exitCompare}
                      size='medium'
                    />
                  )}
                </Stack>
              </Stack>
            </Box>
          )}

          <Box px={2.5} py={allowComparison ? 0 : 2} sx={accordionContainerStyle}>
            {documentsToDisplay?.map((docGroup: DocumentsGroup) => (
              <DocumentAccordion
                key={docGroup.id}
                title={docGroup.categoryTitle}
                items={docGroup.documents}
                selectedDocument={selectedDocument as DocumentItem}
                onDocumentSelect={handleDocumentSelection}
                compareEnabled={compareEnabled}
                selectedDocumentForComparison={documentsToCompare.map(
                  (item: DocumentItem) => item.title_link as string
                )}
                onComparisonChange={handleComparisonCheckbox}
              />
            ))}
            {!documentsToDisplay ||
              (documentsToDisplay.length === 0 && (
                <Box sx={styles.noDocMessage}>
                  <Typography fontSize={14} fontWeight='700'>
                    No documents available.
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      )}
      {showLoading && (
        <Box sx={styles.loadingIcon}>
          <CircularProgress />
        </Box>
      )}
      {openSecondaryDrawer && (
        <SecondaryDrawer
          title={secondaryDrawerTitle}
          entities={subEntities}
          selectedEntity={selectedEntityId}
          onClose={() => setOpenSecondaryDrawer(false)}
          onEntitySelect={handleEntitySelection}
        />
      )}
      {selectedDocument && chatRIADocumentMetadata && (
        <DocumentViewer
          title={selectedDocument.title}
          link={pdfURL}
          chatRIAMetadata={chatRIADocumentMetadata}
          source={source}
          entityTitle={title}
          otherLanguages={selectedDocument.otherLanguages}
          disableChatRia={disableChatRia}
          onClose={() => {
            setSelectedDocument(null);
            setChatRIADocumentMetadata(null);
          }}
        />
      )}

      {openCompareDrawer && (
        <CompareDialog
          open={openCompareDrawer}
          onClose={exitCompare}
          compareItems={documentsToCompare}
          onNewCompare={clearComparison}
        />
      )}
    </Drawer>
  );
};

export default React.memo(DocumentListDialog);
