const styles = {
  container: {
    display: 'flex',
    width: 'inherit',
    maxHeight: 'inherit',
    height: 'inherit',
    overflow: 'auto',
    py: 2.5,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 3,
    backgroundColor: 'gray.0'
  },
  headingContainer: {
    pb: 1.5,
    px: 2.5,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  heading: {
    fontSize: 16,
    fontWeight: 700,
    color: 'gray.900'
  },
  subHeading: {
    fontSize: 14,
    fontWeight: 500,
    color: 'gray.800'
  },
  infoContainer: {
    display: 'flex',
    px: 2.5,
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%'
  },
  accordion: {
    mt: '8px !important',
    width: '100%'
  },
  accordionTitle: {
    color: 'gray.900',
    fontFamily: 'Mulish',
    fontSize: 16,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  iconButton: { width: 16, height: 16 },
  caretDownIcon: { fontSize: 16, color: 'gray.800' },
  divider: {
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'gray.200'
  },
  noContentContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    pr: 5,
    pl: 2.5,
    flexDirection: 'column',
    width: '100%'
  },
  noContentheading: {
    fontSize: 20,
    fontWeight: 500,
    color: 'gray.800'
  },
  accordionStyle: {
    width: '100%',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  documentsContainer: {
    alignItems: 'center',
    gap: 0.75,
    cursor: 'pointer'
  },
  documentsIcon: { height: 14, width: 14 },
  documentsText: {
    color: 'blue.background',
    fontFamily: 'Mulish',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  }
};

export default styles;
