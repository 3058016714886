import React, { useState } from 'react';
import { Stack, Typography, Box, Tooltip, Chip, IconButton } from '@mui/material';
import styles, { CardContainer } from './styles';
import { LABEL_COLOR, LABEL_TOOLTIP_TITLE } from '../../constants';
import { InfoIconSummary } from '../../../../assets/svgs/Icons';
import TitleInfoPopover from '../../../../components/Dialogs/DocumentListing/components/TitleInfoPopover';
import Card from '../Card';

const Cards = ({
  tradeName,
  applicationType,
  cardsRef,
  applicationId,
  applicationData = {}
}: {
  tradeName: string;
  applicationType: string;
  cardsRef: React.RefObject<HTMLDivElement>;
  applicationId: string;
  applicationData: any;
}) => {
  const [infoElement, setInfoElement] = useState(null);

  const labelKey = applicationType?.toLowerCase();
  const labelColor = LABEL_COLOR[labelKey];
  const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
  return (
    <Stack sx={CardContainer} direction='row' ref={cardsRef} id={`application-${applicationId}`}>
      <Stack width='70%'>
        <Box>
          <Tooltip title={tradeName}>
            <Typography
              sx={{
                color: labelColor,
                ...styles.tradeName
              }}>
              {tradeName}
            </Typography>
          </Tooltip>
        </Box>
        {applicationData && (
          <Stack direction='row'>
            <Typography
              sx={{
                ...styles.identifierNumber
              }}>
              {applicationData?.identifier}
            </Typography>
            <IconButton
              sx={{ p: 0, ml: 0.5 }}
              onClick={(event: any) => setInfoElement(event.currentTarget)}>
              <InfoIconSummary sx={{ fontSize: 12 }} />
            </IconButton>
            <TitleInfoPopover
              open={!!infoElement}
              anchorEl={infoElement}
              infoComponent={
                <Box width={360}>
                  <Card
                    data={applicationData}
                    hideApplication={() => {}}
                    triggerChatRIA={() => {}}
                    setFavoriteInHook={() => {}}
                    setInProjectInHook={() => {}}
                    setSubscriptionInHook={() => {}}
                    hideCardOptions
                  />
                </Box>
              }
              onClose={() => setInfoElement(null)}
            />
          </Stack>
        )}
      </Stack>
      <Stack justifyContent='center'>
        <Tooltip title={labelTooltipTitle}>
          <Chip
            label={applicationType}
            key={applicationType}
            sx={{
              ...styles.chipMetadata,
              bgcolor: labelColor
            }}
          />
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default React.memo(Cards);
