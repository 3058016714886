import actions from './actions';

interface ActionProps {
  type: string;
  value: any;
}

export interface RegulatoryInformationContextInterface {
  loading: boolean;
  productDetails: any;
  generics: any;
  patents: any;
  exclusivity: any;
  prescribingInfo: any[];
  searchDetails: any;
  db: string;
  applicationDetails: any;
  approvalHistory: any[];
  adverseEvents: any[];
  rems: any[];
  rld_info: any[];
  commissionProcedures: any[];
  filters: any;
  postmarketingData: any[];
  proceduralSteps: any;
  isApprovalHistoryFilterApplied: boolean;
  isPMRCFilterApplied: boolean;
  triggerAddtoReport: boolean;
  triggerCsvDownload: boolean;
  triggerOpenFilters: boolean;
  loadingGenerics: boolean;
  loadingPatents: boolean;
  loadingExclusivity: boolean;
  loadingProductDetails: boolean;
  loadingproceduralSteps: boolean;
  is_favorite: boolean;
  is_subscribed: boolean;
  inProjects: any;
  sortingApprovalHistory: boolean;
  [x: string]: any;
  summaryDetails: any;
  loadingResourcesDetails: boolean;
  resourcesDetails: any;
  companionDiagnosticDevices: any[];
  orphanDesignations: any[];
  submissionDocuments: any[];
  commissionProceduresSubmissionDocuments: any[];
  showAllProcedures: boolean;
  patentExclusivity: any[];
}

const reducers = (state: RegulatoryInformationContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_LOADING: {
      return {
        ...state,
        loading: action.value
      };
    }
    case actions.SET_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: action.value
      };
    }
    case actions.SET_STATE_DETAILS: {
      return {
        ...state,
        stateDetails: action.value
      };
    }
    case actions.SET_GENERICS: {
      return {
        ...state,
        generics: action.value
      };
    }
    case actions.SET_PATENTS: {
      return {
        ...state,
        patents: action.value
      };
    }
    case actions.SET_PATENT_EXCLUSIVITY: {
      return {
        ...state,
        patentExclusivity: action.value
      };
    }
    case actions.SET_PRESCRIBING_INFO: {
      return {
        ...state,
        prescribingInfo: action.value
      };
    }
    case actions.SET_SEARCH_DETAILS: {
      return {
        ...state,
        searchDetails: action.value
      };
    }
    case actions.SET_DB: {
      return {
        ...state,
        db: action.value
      };
    }
    case actions.SET_APPLICATION_DETAILS: {
      return {
        ...state,
        applicationDetails: action.value
      };
    }
    case actions.SET_APPROVAL_HISTORY: {
      return {
        ...state,
        approvalHistory: action.value
      };
    }
    case actions.SET_ADVERSE_EVENTS: {
      return {
        ...state,
        adverseEvents: action.value
      };
    }
    case actions.SET_REMS: {
      return {
        ...state,
        rems: action.value
      };
    }
    case actions.SET_RLD_INFO: {
      return {
        ...state,
        rld_info: action.value
      };
    }
    case actions.SET_COMMISSION_PROCEDURES: {
      return {
        ...state,
        commissionProcedures: action.value
      };
    }
    case actions.SET_APPROVAL_HISTORY_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          approvalHistory: action.value
        }
      };
    }
    case actions.SET_APPROVAL_HISTORY_APPLIED_FILTERS: {
      return {
        ...state,
        appliedFilters: action.value
      };
    }
    case actions.SET_PROCEDURAL_STEPS: {
      return {
        ...state,
        proceduralSteps: action.value
      };
    }
    case actions.SET_PMRC_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          pmrc: action.value
        }
      };
    }
    case actions.SET_PostMarketingData: {
      return {
        ...state,
        postmarketingData: action.value
      };
    }
    case actions.SET_APPROVAL_HISTORY_FILTERS_APPLIED_FLAG: {
      return {
        ...state,
        isApprovalHistoryFilterApplied: action.value
      };
    }
    case actions.SET_PMRC_FILTERS_APPLIED_FLAG: {
      return {
        ...state,
        isPMRCFilterApplied: action.value
      };
    }
    case actions.SET_TRIGGER_ADDTOREPORT: {
      return {
        ...state,
        triggerAddtoReport: action.value
      };
    }
    case actions.SET_TRIGGER_CSVDOWNLOAD: {
      return {
        ...state,
        triggerCsvDownload: action.value
      };
    }
    case actions.SET_TRIGGER_OPENFILTERS: {
      return {
        ...state,
        triggerOpenFilters: action.value
      };
    }
    case actions.SET_LOADING_PATENTS: {
      return {
        ...state,
        loadingPatents: action.value
      };
    }
    case actions.SET_LOADING_GENERICS: {
      return {
        ...state,
        loadingGenerics: action.value
      };
    }
    case actions.SET_LOADING_PATENT_EXCLUSIVITY: {
      return {
        ...state,
        loadingPatentExclusivity: action.value
      };
    }
    case actions.SET_LOADING_PRODUCT_DETAILS: {
      return {
        ...state,
        loadingProductDetails: action.value
      };
    }
    case actions.SET_LOADING_STATE_DETAILS: {
      return {
        ...state,
        loadingStateDetails: action.value
      };
    }
    case actions.SET_LOADING_PROCEDURAL_STEPS: {
      return {
        ...state,
        loadingproceduralSteps: action.value
      };
    }
    case actions.SET_IS_FAVORITE: {
      return {
        ...state,
        is_favorite: action.value
      };
    }
    case actions.SET_IS_SUBSCRIBED: {
      return {
        ...state,
        is_subscribed: action.value
      };
    }
    case actions.SET_IN_PROJECTS: {
      return {
        ...state,
        inProjects: action.value
      };
    }
    case actions.SET_SORTING_APPROVAL_HISTORY: {
      return {
        ...state,
        sortingApprovalHistory: action.value
      };
    }
    case actions.SET_SUMMARY_DETAILS: {
      return {
        ...state,
        summaryDetails: action.value
      };
    }
    case actions.SET_RESOURCES_DETAILS: {
      return {
        ...state,
        resourcesDetails: action.value
      };
    }
    case actions.SET_RESOURCES_DETAILS_LOADING: {
      return {
        ...state,
        loadingResourcesDetails: action.value
      };
    }
    case actions.SET_COMPANION_DIAGNOSTIC_DEVICES: {
      return {
        ...state,
        companionDiagnosticDevices: action.value
      };
    }
    case actions.SET_ORPHAN_DESIGNATIONS: {
      return {
        ...state,
        orphanDesignations: action.value
      };
    }
    case actions.SET_SUBMISSION_DOCUMENTS: {
      return {
        ...state,
        submissionDocuments: action.value
      };
    }
    case actions.SET_COMMISSION_PROCEDURE_SUBMISSION_DOCUMENTS: {
      return {
        ...state,
        commissionProceduresSubmissionDocuments: action.value
      };
    }
    case actions.SET_REMS_DOCUMENTS: {
      return {
        ...state,
        remsDocuments: action.value
      };
    }
    case actions.SET_RESOURCES_DETAILS_DOCUMENTS: {
      return {
        ...state,
        resourceDocuments: action.value
      };
    }
    case actions.SET_SIDE_EFFECT_DOCUMENTS: {
      return {
        ...state,
        sideEffectDocuments: action.value
      };
    }
    case actions.SET_OVERVIEW_DETAILS: {
      return {
        ...state,
        overviewDetails: action.value
      };
    }
    case actions.SET_SHOW_ALL_PROCEDURES: {
      return {
        ...state,
        showAllProcedures: action.value
       };
     }
    case actions.SET_SHOW_TIMELINE: {
      return {
        ...state,
        showTimeline: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
