import RESULT_VIEW_TYPES from '../../pages/SearchResults/components/constants';

const FILTER_TYPE = {
  DATE_RANGE: 'dateRange',
  SELECT_OPTIONS: 'selectOptions',
  RADIO: 'radio',
  SEARCH: 'search',
  YEAR_RANGE: 'yearRange',
  NUMBER_RANGE: 'numberRange',
  MULTI_SEARCH: 'multiSearch',
  SEPARATOR_1: 'separator1',
  SEPARATOR_2: 'separator2'
};

const QUICK_MULTISOURCE_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'First Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const SINGLE_SOURCE_US_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Application Type',
    value: 'application_type',
    elasticSearchType: 'appl_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Type of application submitted to FDA for approval.'
  },
  {
    id: 2,
    label: 'Submission Pathway',
    value: 'approval_pathway',
    elasticSearchType: 'approval_pathway',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Legal framework under which the FDA reviews the application.'
  },
  {
    id: 3,
    label: 'Submission Class',
    value: 'submission_class',
    elasticSearchType: 'submission_class_code_description',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Classification assigned by FDA, indicating the type of submission.'
  },
  {
    id: 4,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Year the drug was officially approved by FDA.'
  },
  {
    id: 5,
    label: 'Approval Time (days)',
    value: 'approval_time',
    elasticSearchType: 'approval_time',
    filterType: FILTER_TYPE.NUMBER_RANGE,
    max: 100000,
    min: 0,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Total time taken to get an approval.'
  },
  {
    id: 6,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Brand name of the product.'
  },
  {
    id: 7,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Chemical name of the active ingredient. '
  },
  {
    id: 8,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: "Company responsible for the drug's application."
  },
  {
    id: 9,
    label: 'Priority Review',
    value: 'review_status',
    elasticSearchType: 'review_priority',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Indicates if the drug received Priority Review.'
  },
  {
    id: 10,
    label: 'Application Number',
    value: 'application_number',
    elasticSearchType: 'appl_num',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Unique identifier assigned by the FDA.'
  },
  {
    id: 11,
    label: 'Marketing Status',
    value: 'marketing_status_description',
    elasticSearchType: 'marketing_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Application Details',
    tooltip: 'Specifies if the product is currently on the market.'
  },
  {
    id: 12,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details',
    tooltip: 'Physical form of the drug.'
  },
  {
    id: 13,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details',
    tooltip: 'How the drug is administered.'
  },
  {
    id: 14,
    label: 'Monoclonal Antibody',
    value: 'monoclonol_antibody',
    elasticSearchType: 'monoclonol_antibody',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Product Details',
    tooltip: 'Indicates if the product is a monoclonal antibody.'
  },
  {
    id: 15,
    label: 'Combination Product',
    value: 'combination_product_flag',
    elasticSearchType: 'combination_product_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Product Details',
    tooltip: 'Product with two or more active ingredients.'
  },
  {
    id: 16,
    label: 'Companion Diagnostic Devices',
    value: 'companion_diagnostic_devices_status',
    elasticSearchType: 'companion_diagnostic_devices_status',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Product Details',
    tooltip: 'Device approved with the drug for specific use.'
  },
  {
    id: 17,
    label: 'Biosimilar',
    value: 'biosimilar',
    elasticSearchType: 'biosimilar',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Product Details',
    tooltip: 'Biologic product highly similar to an approved reference product.'
  },
  {
    id: 18,
    label: 'Advisory Committee',
    value: 'advisory_committee',
    elasticSearchType: 'advisory_committee.adcomm_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Product Details',
    tooltip: 'Indicates if the drug was reviewed by an advisory committee.'
  },
  {
    id: 19,
    label: 'REMS',
    value: 'rems_active_flag',
    elasticSearchType: 'rems.rems_active_flag',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip:
      'Risk Evaluation and Mitigation Strategies. A Program to manage risks associated with the drug.'
  },
  {
    id: 20,
    label: 'Peds Use',
    value: 'pediatric_use',
    elasticSearchType: 'pediatric_use.pediatric_terms_matching',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip: 'Approved for use in children.'
  },
  {
    id: 21,
    label: 'Boxed Warning',
    value: 'application_boxed_warning_text',
    elasticSearchType: 'application_boxed_warning.application_boxed_warning_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip: 'Strongest FDA warning for serious risks.'
  },
  {
    id: 22,
    label: 'Accelerated Approval',
    value: 'accelerated_approval',
    elasticSearchType: 'accelerated_approval_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip: 'Approval based on surrogate endpoints.'
  },
  {
    id: 23,
    label: 'Breakthrough',
    value: 'breakthrough_status',
    elasticSearchType: 'bt_status',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip: 'Expedited review for significant benefits over existing therapies.'
  },
  {
    id: 24,
    label: 'Fast Track',
    value: 'fast_track',
    elasticSearchType: 'fast_track_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip: 'Accelerated development and review process.'
  },
  {
    id: 25,
    label: 'Orphan Status',
    value: 'orphan_status',
    elasticSearchType: 'orphan_status',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip: 'Designation for treating rare diseases.'
  },
  {
    id: 26,
    label: 'RTOR',
    value: 'rtor',
    elasticSearchType: 'rtor_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Specialities',
    tooltip: 'Real-Time Oncology Review for faster approvals.'
  },
  {
    id: 27,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    showFilter: (data: any) =>
      data?.entityCategory === 'label_section_search' ||
      data?.searchTerm?.includes('label_section_search') ||
      data?.entityCategory === 'indications_and_usage' ||
      data?.searchTerm?.includes('indications_and_usage'),
    groupName: 'Specialities',
    tooltip: "Sections within a drug's label."
  },
  {
    id: 28,
    label: 'Center',
    value: 'center',
    elasticSearchType: 'center',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Organization',
    tooltip: "FDA division overseeing the drug's review."
  },
  {
    id: 29,
    label: 'Office',
    value: 'office',
    elasticSearchType: 'office',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Organization',
    tooltip: 'Specific office within the FDA reviewing the drug.'
  },

  {
    id: 30,
    label: 'Division',
    value: 'division',
    elasticSearchType: 'division',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Organization',
    tooltip: "FDA division managing the drug's approval process."
  },
  {
    id: 31,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }
];

export const SINGLE_SOURCE_EU_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    showFilter: (data: any) =>
      data?.entityCategory === 'label_section_search' ||
      data?.searchTerm?.includes('label_section_search'),
    groupName: 'Product Details'
  },
  {
    id: 2,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 5,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 6,
    label: 'Product Number',
    value: 'product_number',
    elasticSearchType: 'product_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 7,
    label: 'Peds Use',
    value: 'pediatric_use',
    elasticSearchType: 'pediatric_use.pediatric_terms_matching',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 8,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 9,
    label: 'Marketing Status',
    value: 'marketing_text',
    elasticSearchType: 'marketing_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 19,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true,
    groupName: 'Product Details'
  },
  {
    id: 10,
    label: 'Center',
    value: 'center',
    elasticSearchType: 'center',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 21,
    label: 'Submission Pathway',
    value: 'submission_pathway',
    elasticSearchType: 'submission_pathway',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 11,
    label: 'Accelerated Assessment',
    value: 'accelerated_assessment',
    elasticSearchType: 'accelerated_assessment',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 12,
    label: 'Conditional Approval',
    value: 'conditional_approval',
    elasticSearchType: 'conditional_approval',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 13,
    label: 'Exceptional Circumstances',
    value: 'exceptional_circumstances',
    elasticSearchType: 'exceptional_circumstances',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 14,
    label: 'Orphan Medicine',
    value: 'orphan_medicine',
    elasticSearchType: 'orphan_medicine',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 15,
    label: 'Monoclonal Antibody',
    value: 'monoclonal_antibody',
    elasticSearchType: 'monoclonal_antibody',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 16,
    label: 'Advanced Therapy',
    value: 'advanced_therapy',
    elasticSearchType: 'advanced_therapy',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 17,
    label: 'Biosimilar',
    value: 'biosimilar',
    elasticSearchType: 'biosimilar',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 18,
    label: 'Combination Product',
    value: 'combination_product_flag',
    elasticSearchType: 'combination_product_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true,
    groupName: 'Administrative (Initial Authorisation)'
  },
  {
    id: 20,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }

  // disabled for now
  // {
  //   id: 22,
  //   label: 'Scientific Advice',
  //   value: 'scientific_advice',
  //   elasticSearchType: 'scientific_advice',
  //   filterType: FILTER_TYPE.RADIO,
  //   showInBottomFilter: true
  // },
  // {
  //   id: 23,
  //   label: 'New Active Substance',
  //   value: 'new_active_substance',
  //   elasticSearchType: 'new_active_substance',
  //   filterType: FILTER_TYPE.RADIO,
  //   showInBottomFilter: true
  // }
];

export const SINGLE_SOURCE_CA_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    showFilter: (data: any) =>
      data?.entityCategory === 'label_section_search' ||
      data?.searchTerm?.includes('label_section_search')
  },
  {
    id: 2,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Application Number',
    value: 'vin',
    elasticSearchType: 'vin',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'NOC with Condition',
    value: 'noc_w_condition_flag',
    elasticSearchType: 'noc_w_condition_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'Priority Review',
    value: 'priority_review_flag',
    elasticSearchType: 'priority_review_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true
  },
  {
    id: 11,
    label: 'Clinical Information Docs',
    value: 'clinical_information_flag',
    elasticSearchType: 'clinical_information_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true
  },
  {
    id: 12,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }
];

export const SINGLE_SOURCE_AU_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    showFilter: (data: any) =>
      data?.entityCategory === 'label_section_search' ||
      data?.searchTerm?.includes('label_section_search')
  },
  {
    id: 2,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Application Number',
    value: 'vin',
    elasticSearchType: 'vin',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  },
  {
    id: 12,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }
];

export const SINGLE_SOURCE_IE_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    showFilter: (data: any) =>
      data?.entityCategory === 'label_section_search' ||
      data?.searchTerm?.includes('label_section_search')
  },
  {
    id: 2,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Center',
    value: 'source_index',
    elasticSearchType: 'source_index',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Product Number',
    value: 'product_number',
    elasticSearchType: 'product_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Marketing Status',
    value: 'marketing_text',
    elasticSearchType: 'marketing_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  },
  {
    id: 11,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }
];

export const SINGLE_SOURCE_JP_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    showFilter: (data: any) =>
      data?.entityCategory === 'label_section_search' ||
      data?.searchTerm?.includes('label_section_search')
  },
  {
    id: 2,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Application Number',
    value: 'yj_code',
    elasticSearchType: 'yj_code',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Latest Submission Year',
    value: 'approval_year',
    elasticSearchType: 'approval_year',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }
];

const SINGLE_SOURCE_HMA_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Product Number',
    value: 'product_number',
    elasticSearchType: 'product_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Reference State',
    value: 'rm_state',
    elasticSearchType: 'rm_state',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Concerned State',
    value: 'cm_states',
    elasticSearchType: 'state_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Marketing Status',
    value: 'marketing_text',
    elasticSearchType: 'marketing_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  }
];

const SINGLE_SOURCE_US_DEVICES_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'generic_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Product Code',
    value: 'product_code',
    elasticSearchType: 'product_code',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Device Class',
    value: 'device_class',
    elasticSearchType: 'device_class',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'applicant',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Application Number',
    value: 'application_number',
    elasticSearchType: 'application_number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Approval Pathway',
    value: 'approval_pathway',
    elasticSearchType: 'approval_pathway',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Expedited Review Flag',
    value: 'expedited_review_flag',
    elasticSearchType: 'expedited_review_flag',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Combination Product',
    value: 'combination_product',
    elasticSearchType: 'combination_product',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'Companion Diagnostic Devices',
    value: 'companion_diagnostic_devices_status',
    elasticSearchType: 'companion_diagnostic_devices_status',
    filterType: FILTER_TYPE.RADIO,
    showInBottomFilter: true
  },
  {
    id: 11,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  },
  {
    id: 12,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }
];

const CT_RESULTS_FILTERS: any = {
  usnlm: [
    {
      label: 'NCT ID',
      value: 'nct_id',
      elasticSearchType: 'nct_id',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Endpoints',
      options: [
        { label: 'Primary Endpoint:', value: 'primary_outcome' },
        { label: 'Secondary Endpoint:', value: 'secondary_outcome' }
      ],
      value: 'endpoints',
      elasticSearchType: 'endpoints',
      filterType: FILTER_TYPE.MULTI_SEARCH,
      showInBottomFilter: true
    },
    {
      label: 'Has Results?',
      value: 'has_results',
      elasticSearchType: 'has_results',
      filterType: FILTER_TYPE.RADIO,
      showInBottomFilter: true
    },
    {
      label: 'Status',
      value: 'overall_status',
      elasticSearchType: 'overall_status',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Study Type',
      value: 'study_type',
      elasticSearchType: 'study_type',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Phase',
      value: 'phase',
      elasticSearchType: 'phase',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Gender',
      value: 'gender',
      elasticSearchType: 'gender',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Number of Arms',
      value: 'number_of_arms',
      elasticSearchType: 'number_of_arms',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Allocation',
      value: 'allocation',
      elasticSearchType: 'allocation',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Masking',
      value: 'masking',
      elasticSearchType: 'masking',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Expanded Access',
      value: 'has_expanded_access',
      elasticSearchType: 'has_expanded_access',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Healthy Volunteers',
      value: 'healthy_volunteers',
      elasticSearchType: 'healthy_volunteers',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Age Category',
      value: 'age_category',
      elasticSearchType: 'age_category',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Country',
      value: 'countries',
      elasticSearchType: 'country',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Sponsors',
      value: 'sponsors',
      elasticSearchType: 'name',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Agency Class',
      value: 'agency_class',
      elasticSearchType: 'agency_class',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Interventions',
      value: 'interventions',
      elasticSearchType: 'interventions',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Primary Completion',
      value: 'primary_completion',
      elasticSearchType: 'primary_completion_date',
      filterType: FILTER_TYPE.DATE_RANGE,
      showInBottomFilter: true
    },
    {
      label: 'Study Completion',
      value: 'study_completion',
      elasticSearchType: 'completion_date',
      filterType: FILTER_TYPE.DATE_RANGE,
      showInBottomFilter: true
    },
    {
      label: 'Study First Submitted',
      value: 'first_submitted_year',
      elasticSearchType: 'study_first_submitted_date',
      filterType: FILTER_TYPE.DATE_RANGE,
      showInBottomFilter: true
    },
    {
      label: 'Study Last Updated',
      value: 'last_updated_year',
      elasticSearchType: 'last_update_submitted_date',
      filterType: FILTER_TYPE.DATE_RANGE,
      showInBottomFilter: true
    }
  ],
  euctr: [
    {
      label: 'EUCT ID',
      value: 'euct_id',
      elasticSearchType: 'euct_id',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: false
    },
    {
      label: 'Endpoints',
      options: [
        { label: 'Primary Endpoint:', value: 'primary_outcome' },
        { label: 'Secondary Endpoint:', value: 'secondary_outcome' }
      ],
      value: 'endpoints',
      elasticSearchType: 'endpoints',
      filterType: FILTER_TYPE.MULTI_SEARCH,
      showInBottomFilter: true
    },
    {
      label: 'Has Results?',
      value: 'has_results',
      elasticSearchType: 'has_results',
      filterType: FILTER_TYPE.RADIO,
      showInBottomFilter: true
    },
    {
      label: 'Status',
      value: 'overall_status',
      elasticSearchType: 'overall_status',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Phase',
      value: 'phase',
      elasticSearchType: 'phase',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Gender',
      value: 'gender',
      elasticSearchType: 'gender',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true
    },
    {
      label: 'Number of Arms',
      value: 'number_of_arms',
      elasticSearchType: 'number_of_arms',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Allocation',
      value: 'allocation',
      elasticSearchType: 'allocation',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Masking',
      value: 'masking',
      elasticSearchType: 'masking',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Healthy Volunteers',
      value: 'healthy_volunteers',
      elasticSearchType: 'healthy_volunteers',
      filterType: FILTER_TYPE.RADIO,
      showInBottomFilter: true
    },
    {
      label: 'Age Category',
      value: 'age_category',
      elasticSearchType: 'age_category',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Sponsors',
      value: 'sponsors',
      elasticSearchType: 'name',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Agency Class',
      value: 'agency_class',
      elasticSearchType: 'agency_class',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Interventions',
      value: 'interventions',
      elasticSearchType: 'interventions',
      filterType: FILTER_TYPE.SELECT_OPTIONS,
      showInBottomFilter: true,
      isNested: true
    },
    {
      label: 'Primary Completion',
      value: 'primary_completion',
      elasticSearchType: 'primary_completion_date',
      filterType: FILTER_TYPE.DATE_RANGE,
      showInBottomFilter: true
    },
    {
      label: 'Study Completion',
      value: 'study_completion',
      elasticSearchType: 'completion_date',
      filterType: FILTER_TYPE.DATE_RANGE,
      showInBottomFilter: true
    },
    {
      label: 'Study First Submitted',
      value: 'first_submitted_year',
      elasticSearchType: 'study_first_submitted_date',
      filterType: FILTER_TYPE.DATE_RANGE,
      showInBottomFilter: true
    }
  ]
};

export const SINGLE_SOURCE_MHRA_APPLICATION_SEARCH_FILTERS = [
  {
    id: 1,
    label: 'Label Sections',
    value: 'filter_label_sections',
    elasticSearchType: 'filter_label_sections',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true,
    showFilter: (data: any) =>
      data?.entityCategory === 'label_section_search' ||
      data?.searchTerm?.includes('label_section_search')
  },
  {
    id: 2,
    label: 'Generic Name',
    value: 'generic_name',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Formulation',
    value: 'formulation',
    elasticSearchType: 'formulation',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Route',
    value: 'route',
    elasticSearchType: 'route',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Application Number',
    value: 'vin',
    elasticSearchType: 'vin',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Product Name',
    value: 'product_name',
    elasticSearchType: 'product_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  // Comment out for now
  // {
  //   id: 8,
  //   label: 'Marketing Status',
  //   value: 'marketing_text',
  //   elasticSearchType: 'marketing_status',
  //   filterType: FILTER_TYPE.SELECT_OPTIONS,
  //   showInBottomFilter: true
  // },
  {
    id: 9,
    label: 'Legal Status',
    value: 'legal_status',
    elasticSearchType: 'legal_status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 10,
    label: 'Territory',
    value: 'territory',
    elasticSearchType: 'territory',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 11,
    label: 'Authorization Type',
    value: 'authorization_type',
    elasticSearchType: 'authorization_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 12,
    label: 'Drug Type',
    value: 'drug_type',
    elasticSearchType: 'drug_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 13,
    label: 'First Approval Year',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  },
  {
    id: 14,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  }
];

const TABWISE_RESULT_FILTERS: any = {
  [RESULT_VIEW_TYPES.APPLICATION]: QUICK_MULTISOURCE_APPLICATION_SEARCH_FILTERS
};

const MAP_SINGLE_SOURCE_APPLICATION_FILTERS: any = {
  us: SINGLE_SOURCE_US_APPLICATION_SEARCH_FILTERS,
  eu: SINGLE_SOURCE_EU_APPLICATION_SEARCH_FILTERS,
  ca: SINGLE_SOURCE_CA_APPLICATION_SEARCH_FILTERS,
  hpra: SINGLE_SOURCE_IE_APPLICATION_SEARCH_FILTERS,
  jp: SINGLE_SOURCE_JP_APPLICATION_SEARCH_FILTERS,
  hma: SINGLE_SOURCE_HMA_APPLICATION_SEARCH_FILTERS,
  'us-devices': SINGLE_SOURCE_US_DEVICES_APPLICATION_SEARCH_FILTERS,
  au: SINGLE_SOURCE_AU_APPLICATION_SEARCH_FILTERS,
  mhra: SINGLE_SOURCE_MHRA_APPLICATION_SEARCH_FILTERS
};

const ALL_MAPPINGS = [
  ...QUICK_MULTISOURCE_APPLICATION_SEARCH_FILTERS,
  ...SINGLE_SOURCE_US_APPLICATION_SEARCH_FILTERS,
  ...SINGLE_SOURCE_EU_APPLICATION_SEARCH_FILTERS,
  ...SINGLE_SOURCE_CA_APPLICATION_SEARCH_FILTERS,
  ...SINGLE_SOURCE_IE_APPLICATION_SEARCH_FILTERS,
  ...SINGLE_SOURCE_AU_APPLICATION_SEARCH_FILTERS,
  ...SINGLE_SOURCE_MHRA_APPLICATION_SEARCH_FILTERS
];

const ALL_SOURCES_FILTER_VALUE_LABEL_MAPPING: { [key: string]: string } = {};
ALL_MAPPINGS.forEach(item => {
  ALL_SOURCES_FILTER_VALUE_LABEL_MAPPING[item.elasticSearchType] = item.label;
});

const BOTTOM_FILTERS_ORDER: any = {
  'application-us-sba': ['Generic Name', 'Product Name', 'Sponsor Name'],
  'application-eu-epar': ['Generic Name', 'Product Name', 'Sponsor Name'],
  'application-ie-hpra': ['Generic Name', 'Product Name', 'Sponsor Name'],
  'application-eu-hma': ['Generic Name', 'Product Name', 'Sponsor Name'],
  'application-ca-hpfb': ['Generic Name', 'Product Name', 'Sponsor Name'],
  'application-jp-pmda': ['Generic Name', 'Product Name', 'Sponsor Name'],
  'document-us-sba': ['Category', 'Generic Name', 'Product Name'],
  'document-eu-epar': ['Category', 'Generic Name', 'Product Name'],
  'document-ie-hpra': ['Generic Name', 'Product Name'],
  'document-eu-hma': ['Generic Name', 'Product Name'],
  'document-ca-hpfb': ['Category', 'Generic Name', 'Brand Name'],
  'document-jp-pmda': ['Category', 'Generic Name', 'Product Name'],
  'document-us-adcomm': ['Document Type', 'Committee Id', 'Designation Type'],
  'document-us-guidance': ['Category', 'Status', 'Topics'],
  'document-eu-guidance': ['Document Type', 'Status', 'Category'],
  'document-ca-guidance': ['Keywords', 'Type'],
  'document-us-wr': ['Category', 'Drug Name', 'Sponsor'],
  'document-eu-pip': ['Category', 'Generic Name', 'Product Name'],
  'ct-ct-usnlm': ['Status', 'Phase', 'Agency Class'],
  'application-uk-mhra': ['Generic Name', 'Product Name', 'Sponsor Name'],
  'document-uk-mhra': ['Generic Name', 'Product Name']
};

const COMMON_SECTIONS_EU_HPRA = [
  'Name of the Medicinal Product',
  'Qualitative and Quantitative Composition',
  'Pharmaceutical Form',
  'Therapeutic Indications',
  'Posology and Method of Administration',
  'Contraindications',
  'Special Warnings and Precautions for Use',
  'Interactions with Other Medicinal Products and Other Forms of Interaction',
  'Fertility, Pregnancy and Lactation',
  'Effects on Ability to Drive and Use Machines',
  'Undesirable Effects',
  'Overdose',
  'Pharmacodynamic Properties',
  'Pharmacokinetic Properties',
  'Preclinical Safety Data',
  'Pharmaceutical Particulars',
  'Marketing Authorisation Holder',
  'Marketing Authorisation Number(s)',
  'Date of First Authorisation/Renewal of the Authorisation',
  'Date of Revision of the Text'
];

const ALL_SECTIONS_FILTER_OPTIONS: { [key: string]: Array<any> } = {
  us: [
    'Indications and Usage (Primary)',
    'Limitations of Use',
    'Dosage and Administration',
    'Dosage Forms and Strengths',
    'Contraindications',
    'Warnings and Precautions',
    'Adverse Reactions',
    'Drug Interactions',
    'Use in Specific Populations',
    'Drug Abuse and Dependence',
    'Overdose',
    'Description',
    'Clinical Pharmacology',
    'Non-clinical Toxicology',
    'Clinical Studies',
    'How Supplied Storage and Handling',
    'Patient Counseling Information',
    'References'
  ],
  ca: [
    'Indications and Usage',
    'Contraindications',
    'Dosage and Administration',
    'Warnings and Precautions',
    'Adverse Reactions',
    'Drug Interactions',
    'Clinical Pharmacology',
    'Serious Warnings and Precautions Box',
    'Overdosage',
    'Dosage Forms Strengths Somposition and Packaging',
    'Storage Stability and Disposal',
    'Special Handling Instructions',
    'Pharmaceutical Information',
    'Clinical Trials',
    'Microbiology',
    'Nonclinical Toxicology',
    'Supporting Product Monographs',
    'Detailed Pharmacology',
    'Patient Medication Information'
  ],
  eu: [...COMMON_SECTIONS_EU_HPRA],
  hpra: [...COMMON_SECTIONS_EU_HPRA],
  jp: [
    'Warnings',
    'Contraindications',
    'Composition and Product Description',
    'Indications',
    'Precautions Concerning Indications',
    'Dosage and Administration',
    'Precautions Concerning Dosage and Administration',
    'Important Precautions',
    'Precautions Concerning Patients With Specific Backgrounds',
    'Interactions',
    'Adverse Reactions',
    'Influence on Laboratory Tests',
    'Overdosage',
    'Precautions Concerning Use',
    'Other Precautions',
    'Pharmacokinetic Properties',
    'Clinical Studies',
    'Pharmacology',
    'Physicomechanical Properties',
    'Precautions for Handling',
    'Approval Conditions',
    'Packaging',
    'References',
    'Reference Request and Contact Information',
    'Precaution Concerning Health Insurance Benefits',
    'Marketing Authorization Holder'
  ],
  hma: [...COMMON_SECTIONS_EU_HPRA],
  au: [
    'Name of the Medicine',
    'Qualitative and Quantitative Composition',
    'Pharmaceutical Form',
    'Therapeutic Indications',
    'Dosage and Method of Administration',
    'Contraindications',
    'Special Warnings and Precautions for Use',
    'Interactions with Other Medicines',
    'Fertility, Pregnancy, and Lactation',
    'Effects on Ability to Drive and Use Machines',
    'Adverse Effects',
    'Overdose',
    'Pharmacodynamic Properties',
    'Pharmacokinetic Properties',
    'Preclinical Safety Data',
    'Pharmaceutical Particulars',
    'Medicine Schedule',
    'Sponsor',
    'Date of First Approval',
    'Date of Revision'
  ],
  mhra: [
    ...COMMON_SECTIONS_EU_HPRA,
    'Dosimetry',
    'Instructions for Preparation of Radiopharmaceuticals'
  ]
};

// this is used when search(quick or advance) is done using us source with category indication and usage to get application page
const FILTER_LABEL_SECTION_US_MAPPING = ['Indications and Usage (Primary)', 'Limitations of Use'];

export {
  FILTER_TYPE,
  QUICK_MULTISOURCE_APPLICATION_SEARCH_FILTERS,
  MAP_SINGLE_SOURCE_APPLICATION_FILTERS,
  ALL_SOURCES_FILTER_VALUE_LABEL_MAPPING,
  TABWISE_RESULT_FILTERS,
  BOTTOM_FILTERS_ORDER,
  ALL_SECTIONS_FILTER_OPTIONS,
  CT_RESULTS_FILTERS,
  FILTER_LABEL_SECTION_US_MAPPING
};

export const DEFAULT_RADIO_OPTION_LIST = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  },
  {
    label: 'All',
    value: 'all'
  }
];

export const BOTTOM_FILTER_SEARCH_KEYWORD_PLACEHOLDER_TEXT: { [key: string]: string } = {
  pdco: 'Search PDCO',
  'ich-guideline': 'Search ICH Guidelines',
  'mhra-guidance': 'Search MHRA Guidelines',
  default: 'Search Keyword'
};
