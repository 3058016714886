import { useState } from 'react';

import ICH_GUIDELINES_FILTERS from '../contants';
import fetchICHGuidelines from '../../../api/pages/ICHGuidelines';
import getFilterOptions from '../../Guidelines/utils/common/getFilterOptions';

const useICHGuidelinesData = () => {
  const [guidelinesData, setGuidelinesData] = useState<any>({});
  const [ICHGuidelinesFilters, setICHGuidelinesFilters] = useState<any>([]);
  const [guidelinesLoading, setGuidelinesLoading] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied]: [boolean, Function] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState<any>({});

  // Fetch the Guidelines details
  const fetchGuidelinesDetails = async (
    appliedFilters: any = {},
    saveFilters: boolean = true,
    cancelToken = null
  ) => {
    try {
      setGuidelinesLoading(true);
      if (saveFilters) {
        setIsFilterApplied(Object.keys(appliedFilters).length > 0);
        setFiltersApplied(appliedFilters);
      }

      if (appliedFilters && 'party' in appliedFilters && appliedFilters?.party?.length !== 0) {
        // eslint-disable-next-line no-param-reassign
        appliedFilters.condition = ['Implemented'];
      } else if ('condition' in appliedFilters) {
        // eslint-disable-next-line no-param-reassign
        delete appliedFilters.condition;
      }
      const res = await fetchICHGuidelines(appliedFilters, cancelToken);
      if (res?.status === 200) {
        const guidelinesDetails = res.data.Success;
        const guidelines = guidelinesDetails?.guidelines || {};
        const guidelinesStats = guidelinesDetails?.stats || {};
        const filtersData = guidelinesDetails?.filters;

        const filterOptions = getFilterOptions(ICH_GUIDELINES_FILTERS, filtersData);
        setICHGuidelinesFilters(filterOptions);
        setGuidelinesData({
          guidelines,
          stats: guidelinesStats
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setGuidelinesLoading(false);
    }
  };

  return {
    fetchGuidelinesDetails,
    guidelinesData,
    filtersApplied,
    ICHGuidelinesFilters,
    isFilterApplied,
    guidelinesLoading
  };
};

export default useICHGuidelinesData;
