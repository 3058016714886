const styles = {
  container: {
    width: '100%',
    bgcolor: 'white.main',
    borderRadius: '12px',
    border: '1px solid #DBDBDB'
  },
  title: {
    color: 'primary.900',
    fontWeight: 700,
    lineHeight: '21px'
  },
  followUpQuestionsStyle: {
    bgcolor: '#314f00',
    p: 1,
    borderRadius: '16px 4px 16px 16px',
    color: '#e9eddc',
    fontSize: 12,
    fontWeight: 'bold'
  },
  followUpLoader: {
    bgcolor: 'primary.backgroundLight',
    p: 1,
    borderRadius: '16px 4px 16px 16px',
    color: '#002108',
    fontSize: 12
  },
  expandIcon: {
    color: 'primary.600',
    bgcolor: 'primary.backgroundLightVariant',
    borderRadius: '50%'
  },
  loadingSkeleton: {
    bgcolor: 'primary.backgroundLight',
    height: 30
  },
  answerParagraph: {
    color: 'gray.800',
    maxHeight: 265,
    lineHeight: '22px ',
    fontWeight: '400',
    overflowY: 'auto',
    position: 'relative'
  },
  verifyText: {
    color: 'primary.700',
    cursor: 'pointer',
    fontWeight: 600,
    lineHeight: '14px'
  },
  secondaryText: {
    color: 'gray.dark'
  },
  followUpText: {
    color: 'gray.700',
    lineHeight: '28px'
  },
  questionText: {
    cursor: 'pointer',
    bgcolor: 'primary.50',
    p: 1,
    borderRadius: '16px 4px 16px 16px',
    color: 'primary.700',
    fontSize: 12,
    width: '40%'
  },
  followUpBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: 12,
    color: 'primary.800',
    bgcolor: 'primary.0',
    borderRadius: '16px 4px 16px 16px',
    px: '12px',
    py: '6px',
    flex: '1 0 0',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: '21.98px'
  },
  hiddenTextContainer: {
    bgcolor: 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(1px)',
    position: 'sticky',
    bottom: -1,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hiddenTextBtn: {
    zIndex: 1,
    color: 'primary.600',
    lineHeight: '19.2px',
    cursor: 'pointer',
    display: 'block',
    textTransform: 'none',
    fontSize: 12
  },
  infoText: { color: 'black.main', fontSize: 6, fontWeight: 500 },
  tagText: {
    maxWidth: '30%',
    color: 'gray.700',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21.98px'
  },
  popoverContainer: {
    maxWidth: 260,
    borderRadius: '8px',
    bgcolor: 'primary.50',
    border: '1px solid',
    borderColor: 'primary.100'
  },
  verifyPopoverTitle: {
    color: 'black.main',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'capitalize',
    maxWidth: '80%'
  },
  iconBtn: {
    height: 14,
    width: 14,
    color: 'primary.700'
  },
  referenceNumber: {
    ml: 0.5,
    bgcolor: 'primary.50',
    height: 17,
    fontSize: 12,
    display: 'inline-flex',
    color: 'primary.800',
    '&:hover': {
      color: 'black !important'
    }
  },
  popoverStyle: {
    pointerEvents: 'none',
    '& .MuiPopover-paper': {
      borderRadius: '8px',
      bgcolor: 'none'
    }
  }
};

export default styles;
