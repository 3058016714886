import { Box, Typography } from '@mui/material';

const USProducts = ({ data }: any) => {
  return (
    <Box>
      <Typography variant='body2'>
        <b>Product number:</b> {data.product_num}
      </Typography>
      <Typography variant='body2'>
        <b>Strength:</b> {data?.active_ingredients?.[0]?.strength || 'N/A'}
      </Typography>
      <Typography variant='body2'>
        <b>Reference Listed Drug:</b> {data?.reference_drug}
      </Typography>
      <Typography variant='body2'>
        <b>Therapeautic Equivalence:</b> {data?.te_code}
      </Typography>
      <Typography variant='body2'>
        <b>Marketing Status:</b> {data?.marketing_status_description}
      </Typography>
    </Box>
  );
};
export default USProducts;
