import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { useCallback, useContext, useState } from 'react';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import axios from 'axios';
import { makeStreamRequestWithCancel } from '../../../api/client';
import ResultsStore from '../../../store/SearchResults';
import { METADATA_KEYS } from '../constants';
import { ResultData, ResultItem } from '../types/DocumentResult';
import useReportingHandler from '../../../components/Report/hooks/useReportingHandler';
import { sourceMap } from '../../ResultsPage/components/SearchSuggestions';
import { prepareDocumentCard } from '../utils/documentResultsUtils';

interface InsightsProps {
  html: string;
  citations: any;
  canceled: boolean;
  errored: boolean;
  completed: boolean;
}

const useRIAInsights = () => {
  const [insights, setInsights] = useState<InsightsProps>();
  const [loading, setLoading] = useState<Boolean>();
  const [firstLoad, setFirstLoad] = useState<Boolean>(false);

  const { resultsState } = useContext(ResultsStore);
  const { updateReportData } = useReportingHandler();

  const addFeedback = (feedback: string) => {
    console.log(feedback);
  };

  const addReport = async (id: any = null, content: any = null, reportData: any = {}) => {
    const resultId = id;
    const resultContent = content.replace(
      /<span [^>]*id="citation__[^"]*"[^>]*>[^<]*<\/span>/g,
      ''
    ); // remove all citations

    const generatedForSource =
      Object.keys(resultsState.decryptedPayload.source).length > 0
        ? Object.keys(resultsState.decryptedPayload.source)[0]
        : 'Multiple Sources';
    const prettySource = sourceMap[generatedForSource] || generatedForSource;
    const sectionLayout = {
      dataSource: 'custom',
      sectionType: 'TEXT',
      textType: 'HTML_TEXT',
      id: reportData?.reportId,
      _chatId: resultId,
      style: {
        placement: {
          h: 10,
          w: 12,
          i: reportData?.reportId,
          minW: 8,
          moved: false,
          static: false,
          x: 0,
          y: 0
        },
        references: {
          show: true,
          href: `${window.location.href}`,
          text: `${prettySource}`
        }
      },
      content: `${resultContent}`
    };
    updateReportData(reportData?.id, sectionLayout);
  };

  const replaceKeysWithButtons = (text: any, citationsData: any) => {
    Object.keys(citationsData).forEach((key: string) => {
      const button = `<span id="citation__${key}" style="border-radius: 50%;font-size: 13px;color: #06705F;cursor: pointer;background-color: #E1F3F1;padding: 2.5px 6px;margin-left: 4px;">${key}</span>`;

      if (text.includes(key)) {
        // eslint-disable-next-line no-param-reassign
        text = text.replaceAll(`[${key}]`, button);
      }
    });

    return text;
  };

  const formatMessage = (message: string[]) => {
    const newMessage = message.join('').trimStart();
    if (newMessage.startsWith('.')) {
      return newMessage.substring(1);
    }
    return newMessage;
  };

  const onDownloadProgress = useCallback(async (progressEvent: any) => {
    const { currentTarget } = progressEvent.event;
    const data = currentTarget.response;
    const lines = data
      .trim()
      .split('\n')
      .filter((txt: string) => !!txt);
    if (!lines.length) {
      return;
    }
    const newMessages = lines.map((line: string) => {
      return JSON.parse(line.replace('data: ', '')).message;
    });

    let references: any = {};
    if (newMessages?.length > 0 && typeof newMessages[0] === 'object') {
      [references] = newMessages;
    }

    const responseMessage = newMessages.filter((message: string) => typeof message === 'string');
    const content = formatMessage(responseMessage);

    const convertedHtml = marked(content);
    // Use the DOMPurify library to sanitize the HTML and prevent XSS attacks
    const sanitizedHtml = DOMPurify.sanitize(replaceKeysWithButtons(convertedHtml, references), {
      USE_PROFILES: { html: true }
    });

    setInsights((prev: any) => ({
      ...prev,
      html: sanitizedHtml,
      citations: references
    }));
    setLoading(false);
    currentTarget.onabort = async () => {
      setInsights((prev: any) => ({ ...prev, errored: true, canceled: true }));
    };
    currentTarget.onerror = (error: any) => {
      console.error(error);
      setInsights((prev: any) => ({ ...prev, errored: true }));
    };
  }, []);

  const initiateConversationAPI = useCallback(
    (payloadResults: any) => {
      const cancel = axios.CancelToken.source() as any;
      setLoading(true);
      return makeStreamRequestWithCancel(
        `/chat_ria/conversations`,
        'POST',
        {
          question: resultsState?.decryptedPayload?.query,
          question_source: 'ria_insights',
          ria_mode: true,
          help_question: false,
          results: payloadResults
        },
        {},
        cancel.token,
        onDownloadProgress
      ).then(() => setInsights((prev: any) => ({ ...prev, completed: true })));
    },
    [resultsState?.decryptedPayload?.query]
  );

  const getPayload = () => {
    const allResults = resultsState.documentResults.results?.slice(0, 10) || [];

    const payloadResults: ResultData[] = [];

    allResults.forEach((resultItem: ResultItem) => {
      const pageNumber = parseInt(resultItem.document_url?.split('#page=')[1], 10) || 1;
      // Ensure metadataMapping is typed or at least assumed to have a consistent structure.
      const metadataMapping = deepClone(
        METADATA_KEYS[resultItem.data_source || resultItem['data-source']]
      );

      if (resultItem.documentTitle) {
        metadataMapping.push({
          id: 'title',
          label: 'Title'
        });
      }

      // Add the source info
      const itemSrc = prepareDocumentCard(resultItem)?.sourceName;
      const contentSource = sourceMap[itemSrc];
      if (contentSource) {
        metadataMapping.push({
          id: 'content_source',
          label: 'Content Source'
        });
      }

      if (!metadataMapping) return; // Early exit if metadataMapping is undefined.
      // Explicitly assert the type of metadataKeys as an array of strings.
      const metadataKeys: string[] = metadataMapping.map((item: any) => item.id);

      const resultData: ResultData = metadataKeys.reduce(
        (acc: any, key: any) => {
          acc[key] = resultItem[key] || acc[key];
          return acc;
        },
        {
          resultId: resultItem.result_id,
          s3_document_url: resultItem.document_url,
          page: pageNumber,
          metadataMapping,
          summary: '',
          title: resultItem.documentTitle,
          content_source: contentSource
        }
      );
      payloadResults.push(resultData);
    });

    return payloadResults;
  };

  const getInsights = async () => {
    if (insights?.html) {
      return;
    }
    const payload = getPayload();
    initiateConversationAPI(payload);
  };

  return {
    insights,
    addFeedback,
    addReport,
    getInsights,
    insightsLoading: loading,
    setFirstLoad,
    firstLoad
  };
};

export default useRIAInsights;
