/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash/isEmpty';
import makeRequest from '../client';

interface SearchStubProps {
  source: string;
  category: string;
  module?: string;
  searchText: string;
  role?: string;
  dateRange?: any;
}

/**
 * Function to do a quick search for core and biologics module
 * @param {object} payload
 */

// eslint-disable-next-line no-unused-vars
export const getQuickSearchResults = (searchStub: SearchStubProps) => null;
// makeRequest(
//   `/quick_search/${searchStub.source}/${searchStub.category}/${searchStub.searchText}${
//     searchStub.module ? `?module_name=${searchStub.module}` : ''
//   }`,
//   'GET',
//   {},
//   {
//     role: searchStub.role // Temp header for saving the user search histories
//   }
// );

/**
 * Function to do a quick search for core and biologics module
 * @param {object} payload
 */
export const getApprovalDateSearch = (searchStub: SearchStubProps) =>
  makeRequest(
    `/quick_search/${searchStub.source}/${searchStub.category}${
      searchStub.module ? `?module_name=${searchStub.module}` : '?module_name=core'
    }${
      !isEmpty(searchStub.dateRange) && searchStub.dateRange
        ? `&startDate=${searchStub.dateRange.startDate}&endDate=${searchStub.dateRange.endDate}`
        : ''
    }`,
    'GET',
    {},
    {
      role: searchStub.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to get filtered response for quick search results
 * @param {object} payload
 */
export const applySearchFilters = (searchStub: SearchStubProps, payload: any) =>
  makeRequest(
    `/quick_search/${searchStub.source}/${searchStub.category}/${searchStub.searchText}${
      searchStub.module ? `?module_name=${searchStub.module}` : ''
    }`,
    'POST',
    payload,
    {
      role: searchStub.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to get filtered response for approval date quick search results
 * @param {object} payload
 */
export const applyApprovalDateSearchFilters = (searchStub: SearchStubProps, payload: any) =>
  makeRequest(
    `/quick_search/${searchStub.source}/${searchStub.category}${
      searchStub.module ? `?module_name=${searchStub.module}` : ''
    }${
      !isEmpty(searchStub.dateRange) && searchStub.dateRange
        ? `&startDate=${searchStub.dateRange.startDate}&endDate=${searchStub.dateRange.endDate}`
        : ''
    }`,
    'POST',
    payload,
    {
      role: searchStub.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to get filtered response for advanced search results
 * @param {object} payload
 */
export const applyAdvancedSearchFilters = (searchStub: SearchStubProps, payload: any) =>
  makeRequest(
    `/quick_search/${searchStub.source}/advanced-filters${
      searchStub.module ? `?module_name=${searchStub.module}` : ''
    }`,
    'POST',
    payload,
    {
      role: searchStub.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to get clinical trials count
 * @param {object} payload
 */
export const getClinicalTrialsCount = (searchStub: SearchStubProps) =>
  makeRequest(
    `/quick_search/clinical_trials/${searchStub.source}/${searchStub.category}/${searchStub.searchText}`,
    'GET',
    {},
    {
      role: searchStub.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to get clinical trials count advanced search
 * @param {object} payload
 */
export const getClinicalTrialsCountAdvancedSearch = (source: string, payload: any) =>
  makeRequest(`/advanced-search/clinical_trials/${source?.toLowerCase()}`, 'POST', payload, {
    role: '' // Temp header for saving the user search histories
  });

export const getSearchSuggestions = (searchStub: SearchStubProps) =>
  makeRequest(
    `/quick_search/${searchStub.source}/${searchStub.category}/${
      searchStub.searchText
    }?suggested_indexed_matches=true${
      searchStub.module ? `&module_name=${searchStub.module}` : ''
    }`,
    'GET',
    {},
    {
      role: searchStub.role // Temp header for saving the user search histories
    }
  );

/**
 * Function to get signed url of any s3_bucket
 * @param {object} payload
 */
export const getPdfResource = (payload: any) =>
  makeRequest(`/pdf/resource`, 'POST', payload, {
    role: '' // Temp header for saving the user search histories
  });

export const getCTBusinessIntelligence = (searchStub: SearchStubProps, payload: any) => {
  const url = `/quick_search/clinical_trials/business_intelligence/${searchStub.category}/${
    !isEmpty(searchStub.dateRange) && searchStub.dateRange
      ? `${searchStub.module ? `?module_name=${searchStub.module}` : ''}${
          !isEmpty(searchStub.dateRange) && searchStub.dateRange
            ? `&startDate=${searchStub.dateRange.startDate}&endDate=${searchStub.dateRange.endDate}`
            : ''
        }`
      : `${searchStub.searchText}${searchStub.module ? `?module_name=${searchStub.module}` : ''}`
  }`;
  return makeRequest(url, 'POST', payload, {
    role: searchStub.role // Temp header for saving the user search histories
  });
};

export const getCTAdverseEvents = (searchStub: SearchStubProps, payload: any) => {
  const url = `/quick_search/clinical_trials/adverse_events/${searchStub.category}/${
    !isEmpty(searchStub.dateRange) && searchStub.dateRange
      ? `${searchStub.module ? `?module_name=${searchStub.module}` : ''}${
          !isEmpty(searchStub.dateRange) && searchStub.dateRange
            ? `&startDate=${searchStub.dateRange.startDate}&endDate=${searchStub.dateRange.endDate}`
            : ''
        }`
      : `${searchStub.searchText}${searchStub.module ? `?module_name=${searchStub.module}` : ''}`
  }`;
  return makeRequest(url, 'POST', payload, {
    role: searchStub.role // Temp header for saving the user search histories
  });
};

export const getCTBusinessIntelligenceAdvancedSearch = (
  searchStub: SearchStubProps,
  payload: any
) => {
  const url = `/quick_search/clinical_trials/business_intelligence/advanced-filters${
    searchStub.module ? `?module_name=${searchStub.module}` : ''
  }`;
  return makeRequest(url, 'POST', payload, {
    role: searchStub.role // Temp header for saving the user search histories
  });
};

export const getCTAdverseEventsAdvancedSearch = (searchStub: SearchStubProps, payload: any) => {
  const url = `/quick_search/clinical_trials/adverse_events/advanced-filters${
    searchStub.module ? `?module_name=${searchStub.module}` : ''
  }`;
  return makeRequest(url, 'POST', payload, {
    role: searchStub.role // Temp header for saving the user search histories
  });
};

export const getSearchApplicationResults = (payload: any) => {
  const path = `/search`;
  return makeRequest(path, 'POST', payload);
};

export const getGenericSearch = (payload: any, pageNumber?: number) => {
  let path = `/search`;
  if (pageNumber) {
    path += `?page=${pageNumber}`;
  }
  return makeRequest(path, 'POST', payload);
};

export const getAllowedApplicationFilters = (payload: any) => {
  const path = `/advanced-search/category`;
  return makeRequest(path, 'POST', payload);
};

export const getAdvancedSearch = (payload: any) => {
  const path = `/advanced-search/search`;
  return makeRequest(path, 'POST', payload);
};

export const getGenericSynonyms = (category: string, searchTerm: string) => {
  const path = `/quick_search/synonyms/${category}/${searchTerm}`;
  return makeRequest(path, 'GET');
};

export const getAdvancedSearchSynonyms = (payload: any) =>
  makeRequest(`/advanced-search/synonyms`, 'POST', payload);

export const getGenericSearchWithSearchId = (payload: any) => {
  let path = `/user-profile/search/${payload.searchId}?view_type=${payload.view_type}`;

  if (payload?.from || payload?.limit) {
    path += `&from=${payload.from}&limit=${payload.limit}`;
  }

  return makeRequest(path, 'GET');
};

export const getAriaSearchWithSearchId = (payload: any) => {
  const path = `/user-profile/aria_search/${payload.searchId}?view_type=document`;
  return makeRequest(path, 'GET');
};

export const getCategoryCountValue = (payload: any) => {
  const path = `/quick_search/count`;
  return makeRequest(path, 'POST', payload);
};

export const getAdvancedSearchCt = (from: number, limit: number, payload: any) => {
  const path = `/advanced-search/search?from=${from}&limit=${limit}`;
  return makeRequest(path, 'POST', payload);
};

export const getAdvancedSearchCtSaveDisabled = (from: number, limit: number, payload: any) => {
  const path = `/advanced-search/search?from=${from}&limit=${limit}&disable_save=true`;
  return makeRequest(path, 'POST', payload);
};

export const getAdvancedSearchCtFilterOptions = (payload: any) => {
  const path = `/advanced-search/filters`;
  return makeRequest(path, 'POST', payload);
};

export const getAdvancedSearchForDataGrdFilters = (limit: number, offset: number, payload: any) => {
  const path = `/advanced-search/search?from=${limit}&limit=${offset}&disable_save=true`;
  return makeRequest(path, 'POST', payload);
};

export const getGenericSearchCt = (from: number, limit: number, payload: any) => {
  const path = `/search?from=${from}&limit=${limit}`;
  return makeRequest(path, 'POST', payload);
};

export const getCtFilterOptions = (payload: any) => {
  const path = `/search/filters`;
  return makeRequest(path, 'POST', payload);
};

export const getCtFilterOptionsWithSearchId = (searchId: any) => {
  const path = `/user-profile/filters/${searchId}`;
  return makeRequest(path, 'GET');
};

export const getMatchSubmissionsData = (searchId: any) => {
  const path = `/user-profile/search-submissions/${searchId}`;
  return makeRequest(path, 'GET');
};

export const getMatchSynonymsData = (searchId: any) => {
  const path = `/user-profile/search/matched-synonyms/${searchId}`;
  return makeRequest(path, 'GET');
};

export const getRelevantSearchTerms = (relevantTermsPayload: { term: string }) => {
  const path = `/suggestions/mesh`;
  return makeRequest(path, 'POST', relevantTermsPayload);
};

export const getPatentExclusivityData = (searchId: any) => {
  const path = `/user-profile/patent-exclusivity/${searchId}`;
  return makeRequest(path, 'GET');
};
