import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';

// componentns

import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import Main from './Main';
import Header from '../../Guidelines/components/common/Header';
import NewFilters from '../../../components/NewFilters';

// Store
import GlobalStore from '../../../store';

// hooks

import { Stat } from '../../SearchResults/types';
import Datagrid from './DataGrid';

import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import useICHGuidelinesData from '../hooks/useICHGuidelinesData';
import HeaderTitle from '../../Guidelines/components/common/HeaderTitle';
import ComparisonIcon from '../../../assets/svgs/Icons/ComparisonIcon';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import ComparsionDrawer from '../../../components/Comparison/ComparsionDrawer';
import { sortCompareGuidelineData } from '../utils';

const Home = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [stats, setStats] = useState<Stat[]>([]);
  const [compareEnable, setCompareEnable] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [ariaModalOpen, setAriaModalOpen] = React.useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [documentCount, setDocumentCount] = useState({
    totalCount: 0,
    pageCount: 0
  });
  const {
    fetchGuidelinesDetails,
    guidelinesData,
    ICHGuidelinesFilters,
    isFilterApplied,
    guidelinesLoading
  } = useICHGuidelinesData();

  useEffect(() => {
    if (!compareEnable) {
      fetchGuidelinesDetails(appliedFilters);
    }
  }, [compareEnable]);

  useEffect(() => {
    if (guidelinesData?.stats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total Guidelines',
        value: guidelinesData?.stats?.total_guidelines || 0
      });
      newStats.push({
        title: 'Documents',
        value: guidelinesData?.stats?.documents || 0
      });
      newStats.push({
        title: 'Pages',
        value: guidelinesData?.stats?.pages || 0
      });
      setStats(newStats);
      setDocumentCount({
        totalCount: guidelinesData?.stats?.documents,
        pageCount: guidelinesData?.stats?.pages
      });
    }
  }, [ICHGuidelinesFilters, guidelinesData?.stats]);

  const handleFilterApply = useCallback((currentfilters: any) => {
    fetchGuidelinesDetails(currentfilters);
    setAppliedFilters(currentfilters);
  }, []);

  useAriaShareHandler(false, ariaModalOpen, setAriaModalOpen);

  const getAriaDocuments = () => {
    const ariaDocuments: Array<string> = [];

    Object.keys(guidelinesData?.guidelines || {}).forEach(key => {
      const items = guidelinesData?.guidelines[key];
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (item.code) {
            ariaDocuments.push(item.code);
          }
        });
      }
    });

    return ariaDocuments;
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const handleSearchKeywordFilter = useCallback((currentfilters: any, cancelToken: any) => {
    fetchGuidelinesDetails(currentfilters, false, cancelToken);
  }, []);

  return (
    <>
      <Header
        title={
          <HeaderTitle
            meetingName='International Council for Harmonisation (ICH) Guidelines'
            tooltipContent='The International Council for Harmonisation of Technical Requirements for Pharmaceuticals for Human Use (ICH) provides globally accepted guidelines to ensure the development, registration, and lifecycle management of pharmaceuticals meet the highest standards of quality, safety, and efficacy.'
          />
        }
        stats={stats}
        handleVisualizeOpen={handleVisualizeOpen}
        showVisualize={false}>
        <Button
          startIcon={
            <ComparisonIcon
              sx={{
                height: 16,
                width: 16,
                color: guidelinesData?.stats?.documents > 0 ? 'primary.700' : 'gray.600'
              }}
            />
          }
          onClick={() => {
            setCompareEnable(!compareEnable);
          }}
          disabled={guidelinesData?.stats?.documents === 0}
          sx={{
            fontSize: 14,
            textTransform: 'none',
            fontWeight: '400',
            ml: 0.5,
            color: guidelinesData?.stats?.documents > 0 ? 'primary.700' : 'gray.600'
          }}>
          Compare Guidelines
        </Button>
      </Header>
      <Main selectedGuidelines={guidelinesData} guidelinesListLoading={guidelinesLoading} />
      <ComparsionDrawer
        open={compareEnable}
        title='Compare Guidelines'
        onClose={() => setCompareEnable(!compareEnable)}>
        <Datagrid
          data={guidelinesData?.guidelines && sortCompareGuidelineData(guidelinesData.guidelines)}
          isLoading={guidelinesLoading}
          fetchGuidelineDetails={fetchGuidelinesDetails}
          appliedFilters={appliedFilters}
        />
      </ComparsionDrawer>
      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        hideReportIcon
        hideDivider>
        {/* TODO: Visualize code  */}
      </BottomDrawer>
      <Box px={2.8}>
        <NewFilters
          data={ICHGuidelinesFilters}
          applyFilters={handleFilterApply}
          isLoading={guidelinesLoading}
          showSearchKeywordInBottomFilters
          source='ich-guideline'
          handleSearchKeyword={handleSearchKeywordFilter}
        />
      </Box>
      {ariaModalOpen && (
        <AriaDrawerCore
          applications={isFilterApplied ? getAriaDocuments() : []}
          sources={{ ich: ['ich-guideline'] }}
          groupId='entity_id'
          docCount={documentCount?.totalCount}
          docPageCount={documentCount?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}
      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={guidelinesLoading}
        />
      </Box>
    </>
  );
};

export default React.memo(Home);
