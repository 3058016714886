/**
 * To be used for results page where the color scheme is determined by one of the attributes in the response
 */
import * as React from 'react';

import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Box, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PatentProducts from '../../../pages/RegulatoryInformation/PatentExclusivity/Procducts/PatentProducts';

interface GridCellExpandProps {
  value: string;
  source: string;
}

const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { value, source } = props;

  return (
    <>
      {
        // @ts-ignore
        value?.map((data: any) => (
          <Box
            key={data.din}
            display='flex'
            flexDirection='row'
            alignItems='center'
            mr={1}
            justifyContent='flex-start'>
            <Typography>{data.identifier}</Typography>
            <Tooltip title={<PatentProducts data={data} source={source} />}>
              <IconButton>
                <InfoOutlinedIcon sx={{ color: 'gray.700', height: '12px', width: '12px' }} />
              </IconButton>
            </Tooltip>
          </Box>
        ))
      }
    </>
  );
});

const PatentExclusivityProductTag = (params: GridRenderCellParams) => {
  const { value, row } = params;
  return <GridCellExpand value={value || ''} source={row?.source} />;
};

export default PatentExclusivityProductTag;
