import { Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import SendIcon from '@mui/icons-material/Send';
import styles from '../styles/DocumentPDFMetadata.styles';

interface DocumentsPDFMetadataProps {
  title: string;
  metadataList: Array<any>;
  isPdf?: boolean;
  showChatRia?: boolean;
  handleChatRIAClick?: any;
  flag?: any;
}

const DocumentsPDFMetadata = ({
  title,
  metadataList,
  isPdf = false,
  showChatRia = true,
  handleChatRIAClick,
  flag = null
}: DocumentsPDFMetadataProps) => {
  const renderMetadataItems = useMemo(
    () =>
      metadataList?.map((tags: any, index: number) => (
        <>
          <Tooltip key={tags.id} title={`${tags.label}: ${tags.value}`} placement='bottom-end'>
            <Typography noWrap variant='body1' sx={styles.tagText}>
              {tags.value}
            </Typography>
          </Tooltip>
          {index < metadataList.length - 1 && <Typography color='gray.500'>&#x2022;</Typography>}
        </>
      )),
    [metadataList]
  );
  return (
    <Stack direction='column' alignItems='flex-start' pt={1}>
      <Stack direction='row' justifyContent='flex-start' alignItems='center' gap={1}>
        <Tooltip title={title} placement='bottom-start'>
          <Typography sx={styles.title}>{title}</Typography>
        </Tooltip>
        {showChatRia && (
          <>
            <Divider orientation='vertical' sx={{ height: 16 }} />
            <Button
              disabled={!isPdf}
              variant='text'
              onClick={handleChatRIAClick}
              sx={styles.chatRiaBtn}
              endIcon={
                <Stack>
                  <SendIcon fontSize='small' sx={styles.sendIcon} />
                </Stack>
              }>
              Chat RIA
            </Button>
          </>
        )}
      </Stack>
      <Stack direction='row' display='flex' justifyContent='space-between'>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          useFlexGap
          flexWrap='wrap'
          sx={{ overflowX: 'hidden' }}>
          {renderMetadataItems}
          {flag && <Typography sx={styles.flag}>&#x2022;&nbsp;&nbsp; {flag}</Typography>}
        </Stack>
      </Stack>
    </Stack>
  );
};

DocumentsPDFMetadata.defaultProps = {
  isPdf: false,
  showChatRia: false,
  handleChatRIAClick: () => {},
  flag: null
};

export default React.memo(DocumentsPDFMetadata);
