import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// api
import { fetchCHMPMeetingDetails } from '../../../api/pages/NewCHMP';
import useQuery from '../../../helpers/customHooks/queryParameter';
import getFilterOptions from '../../EMAMeetings/utils/common/getFilterOptions';
import { CHMP_FILTERS } from '../constants';
import { getGenericSearch, getGenericSearchWithSearchId } from '../../../api/pages/ResultsPage';

import AuthContext from '../../../store/Auth/AuthProvider';

import getUserDetails from '../../SearchResults/utils/getUserDetails';

const useCHMPData = () => {
  const query: any = useQuery();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [allMeetingsList, setAllMeetingsList] = useState<any>({});
  const [meetingsList, setMeetingsList] = useState<any>({});
  const [meetingsStats, setMeetingsStats] = useState<any>({});
  const [meetingsListLoading, setMeetingsListLoading] = useState<boolean>(false);
  const [selectedMeeting, setSelectedMeeting] = useState<any>({});
  const [chmpFilters, setPracFilters] = useState<any>([]);
  const [selectedResources, setSelectedResources] = useState<any>({});
  const [meetingDetailsLoading, setMeetingDetailsLoading] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied]: [boolean, Function] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState<any>({});
  const [meetingType, setMeetingType] = useState<'past' | 'upcoming'>('past');
  const [inFavourites, setInFavourites] = useState<boolean>(false);
  const [inProjects, setInProjects] = useState<any[]>([false]);
  const [internalSearchId, setInternalSearchId] = useState<string>('');

  // Utility to group resources by their type (category bucket)
  const groupResourcesByType = (resources: any) => {
    return resources.reduce((acc: any, item: any) => {
      const categoryBucket = item.category_bucket;
      const originalFileName = item?.s3_url.split('/').pop()?.split('?')[0];
      const modifiedItem = { ...item, originalFileName };
      acc[categoryBucket] = acc[categoryBucket]
        ? [...acc[categoryBucket], modifiedItem]
        : [modifiedItem];
      return acc;
    }, {});
  };

  // Select the default meeting based on the filters or the latest one
  const selectDefaultMeeting = (meetings: any) => {
    const sortedKeys = Object.keys(meetings)
      .map(key => parseInt(key, 10))
      .sort((a, b) => b - a);
    const selectedYear = parseInt(query.get('meetingId')?.split(' ')[1], 10);
    if (query.get('meetingId') && meetings[selectedYear]) {
      return meetings[selectedYear]?.find(
        (meeting: any) => meeting.meeting_id === query.get('meetingId')
      );
    }
    return meetings[sortedKeys[0]]?.find((meeting: any) => meeting?.total_document_count > 0);
  };

  // Reset meetings and meeting data
  const resetMeetings = () => {
    setMeetingsList({});
    setSelectedMeeting({});
    // Check if the current pathname is exactly '/chmp'
    if (window.location.pathname === '/chmp') {
      history.push('/chmp');
    }
  };

  // Update the browser URL with the selected meeting ID
  const updateURL = (meetingId: string) => {
    const queryParams = new URLSearchParams();
    queryParams.set('meetingId', meetingId);
    history.push(`?${queryParams.toString()}`);
  };

  const addSearchIdToUrl = (searchId: string | null) => {
    // Parse existing query parameters from the current URL
    const currentParams = new URLSearchParams(window.location.search);

    if (searchId) {
      // If searchId is provided, set or update the 'searchId' parameter
      currentParams.set('searchId', searchId);
      currentParams.set('filtersApplied', 'true');
    } else {
      // If searchId is null, remove the 'searchId' parameter
      currentParams.delete('searchId');
      currentParams.delete('filtersApplied');
    }

    // Push the updated query string to the history
    history.push(`?${currentParams.toString()}`);
  };

  // Fetch the details for a selected meeting
  const fetchMeetingDetails = async (
    meetingId: string,
    appliedFilters: any = {},
    searchId: string = ''
  ) => {
    try {
      const payload = searchId
        ? { filters: appliedFilters, search_id: searchId }
        : { filters: appliedFilters };
      const res = await fetchCHMPMeetingDetails(meetingId, payload);
      if (res?.status === 200) {
        const meetingDetails = res.data.Success;
        const sections = meetingDetails?.sections || {};
        const meetingStats = meetingDetails?.meeting_stats || {};
        const meetingHighlightData = meetingDetails?.highlight_data || {};
        const documentResources =
          groupResourcesByType(meetingDetails?.meeting_stats?.documents) || {};

        setSelectedMeeting({
          sections,
          meeting_stats: meetingStats,
          highlightedData: meetingHighlightData
        });
        setSelectedResources(documentResources);

        return true;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    setSelectedMeeting({});
    return false;
  };

  // Filter and sort meetings based on the meeting type
  const filterMeetingsByType = (meetings: any, type: 'past' | 'upcoming') => {
    const filteredMeetings: { [year: string]: any[] } = {};
    Object.keys(meetings).forEach(year => {
      const completedMeetings = meetings[year].filter(
        (meeting: any) => meeting.meeting_type === type
      );
      if (completedMeetings.length > 0) {
        filteredMeetings[year] = completedMeetings;
      }
    });
    return filteredMeetings;
  };

  // Handle CHMP meetings response, filtering and selecting default meeting
  const handleCHMPMeetingsResponse = async (res: any, appliedFilters: any, searchId: string) => {
    const meetingsData = res?.meetings?.meetings || {};
    const statsData = res?.stats || {};
    const filtersData = res?.meetings?.filters || [];

    setAllMeetingsList(meetingsData);
    setMeetingsStats(statsData);

    const filteredMeetings = filterMeetingsByType(meetingsData, meetingType);
    setMeetingsList(filteredMeetings);

    const filterOptions = getFilterOptions(CHMP_FILTERS, filtersData);
    setPracFilters(filterOptions);

    const defaultMeeting = selectDefaultMeeting(filteredMeetings);
    if (defaultMeeting) {
      const success = await fetchMeetingDetails(
        defaultMeeting?.meeting_id,
        appliedFilters,
        searchId
      );
      if (success) {
        updateURL(defaultMeeting?.meeting_id);
      }
    } else {
      resetMeetings();
    }
  };

  // Fetch CHMP meetings
  const getCHMPMeetings = async (
    appliedFilters: any = {},
    resetMeetingsList: boolean = false,
    fetchedData: any = null,
    searchId: string = ''
  ) => {
    try {
      setMeetingsListLoading(true);
      setMeetingDetailsLoading(true);
      const areFiltersApplied = Object.keys(appliedFilters).length > 0;
      setIsFilterApplied(areFiltersApplied);
      setFiltersApplied(appliedFilters);

      let updateUrl = false;
      // prepare search api payload
      const userDetails = getUserDetails(currentUser);
      const payload = {
        query: '',
        source: {
          eu: ['chmp']
        },
        userDetails,
        view_type: 'chmp',
        ...(areFiltersApplied && { filters: appliedFilters })
      };

      let res = null;
      let searchResponse: any = {}; // Declare search_response outside the block

      if (fetchedData) {
        res = fetchedData;
      } else if (query.get('searchId') && !resetMeetingsList) {
        res = await getGenericSearchWithSearchId({
          searchId: query.get('searchId'),
          view_type: 'chmp'
        });
        searchResponse = res?.data?.body?.result?.chmp || {};
        // eslint-disable-next-line no-param-reassign
        appliedFilters = res?.data?.body?.filters || {};
        setIsFilterApplied(Object.keys(appliedFilters).length > 0);
        setFiltersApplied(appliedFilters);
        updateUrl = true;
      } else {
        res = await getGenericSearch(payload);
        searchResponse = res?.data?.body?.result?.chmp || {};
        if (areFiltersApplied) {
          updateUrl = true;
        }
      }

      // Now search_response is accessible here
      if (fetchedData || res?.status === 200) {
        const projectsList =
          res?.data?.body?.view_type_metadata?.inProjects ?? res?.data?.body?.inProjects ?? [];
        const favouritesList =
          res?.data?.body?.view_type_metadata?.isFavorite ?? res?.data?.body?.isFavorite ?? false;
        const filterSearchId =
          res?.data?.body?.view_type_metadata?.search_id ?? res?.data?.body?.search_id ?? '';
        setInProjects(projectsList);
        setInFavourites(favouritesList);
        setInternalSearchId(filterSearchId);
        await handleCHMPMeetingsResponse(
          fetchedData ? res : searchResponse,
          appliedFilters,
          searchId
        );
        if (updateUrl) {
          addSearchIdToUrl(filterSearchId);
        }
      } else {
        resetMeetings();
      }
    } catch (err) {
      resetMeetings();
    } finally {
      setMeetingsListLoading(false);
      setMeetingDetailsLoading(false);
    }
  };

  // Handle selecting a meeting by ID
  const handleSelectMeeting = async (meetingId: string, searchId: string) => {
    setMeetingDetailsLoading(true);
    const success = await fetchMeetingDetails(meetingId, filtersApplied, searchId);
    if (success) {
      updateURL(meetingId);
    } else {
      resetMeetings();
    }
    setMeetingDetailsLoading(false);
  };

  // Handle selecting meeting type (past or upcoming)
  const handleSelectMeetingType = async (selectedType: 'past' | 'upcoming') => {
    try {
      setMeetingsListLoading(true);
      setMeetingDetailsLoading(true);
      setMeetingType(selectedType);

      if (Object.keys(allMeetingsList).length > 0) {
        const filteredMeetings = filterMeetingsByType(allMeetingsList, selectedType);
        setMeetingsList(filteredMeetings);
        const sortedKeys = Object.keys(filteredMeetings)
          .map(key => parseInt(key, 10))
          .sort((a, b) => b - a);
        const defaultMeeting = filteredMeetings[sortedKeys[0]][0];

        if (defaultMeeting) {
          const success = await fetchMeetingDetails(defaultMeeting?.meeting_id, {});
          if (success) {
            updateURL(defaultMeeting?.meeting_id);
          }
        } else {
          resetMeetings();
        }
      } else {
        resetMeetings();
      }
    } catch (err) {
      setMeetingsList({});
      setSelectedMeeting({});
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setMeetingsListLoading(false);
      setMeetingDetailsLoading(false);
    }
  };

  return {
    getCHMPMeetings,
    meetingsList,
    meetingsStats,
    selectedMeeting,
    chmpFilters,
    selectedResources,
    setSelectedMeeting,
    handleSelectMeeting,
    isFilterApplied,
    meetingsListLoading,
    meetingDetailsLoading,
    meetingType,
    handleSelectMeetingType,
    inFavourites,
    inProjects,
    setInFavourites,
    setInProjects,
    internalSearchId,
    setInternalSearchId,
    filtersApplied,
    addSearchIdToUrl,
    query
  };
};

export default useCHMPData;
