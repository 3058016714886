import CAProducts from './CAProducts';
import USProducts from './USProducts';

const PatentProducts = ({ data, source }: any) => {
  switch (source) {
    case 'ca':
      return <CAProducts data={data} />;
    case 'us':
      return <USProducts data={data} />;
    default:
      return null;
  }
};

export default PatentProducts;
