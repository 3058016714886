const styles = {
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'blue.backgroundLight',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    fontSize: '12px',
    fontWeigth: '400'
  },
  pdfContainer: {
    borderRadius: 5,
    mb: 10,
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    maxHeight: '99vh',
    '& > div#pdfBox': { height: '86%', width: '96%' }
  },
  snackBarContainer: {
    top: `50px !important`,
    left: '0px !important'
  },
  titleText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 700,
    textOverflow: 'ellipsis'
  },
  chatRiaIcon: {
    width: '16px',
    height: '16px',
    p: '2.667px 2.666px 2.667px 2.667px'
  },
  closeIcon: { width: '18px', height: '18px', cursor: 'pointer', color: 'grey.600' },
  drawer: {
    '& .MuiDrawer-paperAnchorRight': {
      top: 0
    },
    '& >.MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.25)'
    }
  },
  stackWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    py: 1.5,
    backgroundColor: 'primary.600',
    height: '5.5%'
  },
  iconButton: {
    border: '2px solid',
    width: '24px',
    height: '24px',
    color: 'white.main',
    borderColor: 'white.main',
    padding: '3px !important',
    ml: 1,
    '& > svg': {
      fontSize: 20
    },
    '&:hover': {
      color: 'primary.main',
      borderColor: 'primary.main',
      backgroundColor: 'white.main'
    }
  },
  timelineWrapper: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch',
    backgroundColor: 'white.smokeDark'
  },
  sponsorHeading: {
    overflow: 'hidden',
    color: 'gray.650',
    textOverflow: 'ellipsis',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    textTransform: 'capitalize',
    marginBottom: '4px'
  },
  sponsorHeadingText: {
    overflow: 'hidden',
    color: 'gray.800',
    textOverflow: 'ellipsis',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    textTransform: 'capitalize',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 8, // Limit text to 8 lines
    maxHeight: '9.6em' // Ensures that no more than 8 lines are shown based on line height
  },
  pipNumberText: {
    color: 'gray.800',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '120%',
    mr: 1.5
  },
  latestPIPText: {
    color: 'gray.800',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '120%',
    marginLeft: '4px'
  },
  latestPIPBox: {
    borderRight: '1px solid',
    borderRightColor: 'gray.300',
    mr: 1.5
  },
  otherPIPText: {
    color: 'primary.700',
    fontSize: 13,
    fontWeight: 600,
    cursor: 'pointer',
    lineHeight: '120%'
  },
  timelineTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: 'gray.0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  dropDownBox: {
    display: 'flex',
    borderRadius: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  drawerTitle: {
    maxWidth: '45%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white.main',
    fontSize: 20,
    fontWeight: 700
  },
  closeIconButton: { ml: 'auto', p: 0.75, mr: -0.75 },
  drawerHeaderIcon: { fontSize: 16, color: 'white.main' },
  secondaryDrawer: {
    position: 'fixed',
    top: 0,
    transform: 'translate(-100%, 0)',
    height: '100%',
    width: 300,
    bgcolor: 'white.main',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderRight: '1px solid gray',
    borderColor: 'primary.100',
    overflow: 'hidden'
  },
  secondaryDrawerListItemText: {
    color: 'gray.800',
    fontSize: 14,
    letterSpacing: 0.2,
    width: 'calc(100% - 28px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap'
  },
  secondaryDrawerListItemSelectedText: {
    color: 'gray.900',
    fontSize: 14,
    letterSpacing: 0.2,
    width: 'calc(100% - 28px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontWeight: 700
  },
  secondaryDrawerListItemSelectedCheckbox: { ml: 'auto', fontSize: 16, color: 'gray.800' },
  secondaryDrawerListItem: { px: 3, py: 0.75, cursor: 'pointer' },
  secondaryDrawerListItemSelected: { px: 3, py: 0.75, bgcolor: 'primary.0' },
  searchInputBox: {
    ml: 'auto',
    '.MuiInputBase-root': {
      pr: 1.25
    },
    '& input': {
      fontSize: 13,
      px: 1.25,
      pr: 0,
      py: 1,
      height: 16,
      width: 138,
      '&::placeholder': {
        color: 'gray.500',
        opacity: 1
      }
    },
    '& fieldset': {
      borderColor: 'gray.300'
    },
    '& svg': {
      fontSize: 14,
      color: 'gray.500',
      rotate: '280deg'
    }
  },
  categorySelectionPopover: {
    borderRadius: 2
  },
  categorySelection: {
    width: 182,
    borderRadius: 1.5,
    borderColor: 'gray.300',
    fontSize: 14,
    fontWeight: 500,
    p: 0,
    '& .MuiSelect-select': {
      py: 0.75,
      px: 1.25,
      height: 18,
      '& > .MuiListItemIcon-root': {
        display: 'none'
      },
      '& > .MuiListItemText-root span': {
        fontWeight: 500,
        fontSize: 13,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    '& fieldset': {
      borderColor: 'gray.300'
    },
    '& svg': {
      color: 'gray.800',
      fontSize: 14
    }
  },
  secondaryDrawerTitleText: { fontSize: 20, fontWeight: 500, color: 'white.main' },
  secondaryDrawerScrollContainer: {
    height: 'calc(100% - 54px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.400',
      borderRadius: 1,
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  }
};

export default styles;
