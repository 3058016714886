/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
import React, { memo, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Stack } from '@mui/material';

import Box from '@mui/material/Box';

import ResultsStore from '../../../store/SearchResults';

import AriaResponse from './AriaResponse';
import ExplorePageFilters from '../../Aria/components/ExplorePageFilters';
import PdfDetailsView from './PdfDetailsView';
import Loading from './Loading';
import styles from '../styles/DocumentView.styles';

const useWindowSize = () => {
  const [size, setSize] = useState<{
    widthOfRightContainer: number;
  } | null>({ widthOfRightContainer: 670 });
  const updateSize = () => {
    const containerDiv = document.getElementById('pdf-viewer-container');
    if (containerDiv) {
      // get  pdf-viewer-div offsetHeight
      // set width of pdf-viewer-div to that of pdf-viewer-container
      setSize({
        widthOfRightContainer: containerDiv.getBoundingClientRect().width
      });
    }
    setSize(null);
  };
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { ...size, updateSize };
};
const DocumentRightGrid = ({
  exploreSelectedFilters,
  setExploreSelectedFilters,
  paginatedData,
  renderedInPopup
}: any) => {
  const { resultsState } = useContext(ResultsStore);
  const showAriaResponse = !resultsState?.selectedDocument && !resultsState.documentLoader;
  const { widthOfRightContainer, updateSize } = useWindowSize();
  const rightGridRef = useRef<HTMLInputElement>(null);
  const [offsetHeight, setOffsetHeight] = useState(0);

  useEffect(() => {
    if (rightGridRef?.current && widthOfRightContainer) {
      rightGridRef.current.style.width = `${widthOfRightContainer}px`;
      rightGridRef.current.style.minWidth = `${widthOfRightContainer}px`;
      rightGridRef.current.style.maxWidth = `${widthOfRightContainer}px`;
    }
  }, [widthOfRightContainer]);
  useEffect(() => {
    updateSize();
  }, [resultsState?.selectedDocument]);
  useEffect(() => {
    setOffsetHeight(renderedInPopup ? 15 : 0);
  }, [renderedInPopup]);
  return (
    <Stack
      sx={styles.rightGrid}
      top={resultsState?.selectedDocument ? `${185 + offsetHeight}px` : `${173 + offsetHeight}px`}
      pb={resultsState?.selectedDocument ? 0 : 5}
      height={resultsState?.selectedDocument ? '80vh' : '70vh'}
      ref={rightGridRef}>
      {showAriaResponse && (
        <ExplorePageFilters
          selectedFiltersInfo={exploreSelectedFilters}
          setSelectedFiltersInfo={setExploreSelectedFilters}
          paginatedData={paginatedData}
        />
      )}
      {/* AriaResponse is always mounted, but its visibility is controlled */}
      <Box
        sx={{
          visibility: showAriaResponse ? 'visible' : 'hidden',
          position: showAriaResponse ? 'static' : 'absolute',
          pb: 5,
          mt: showAriaResponse ? 2 : 0
        }}>
        <AriaResponse />
      </Box>

      {resultsState.documentLoader ? (
        <Stack justifyContent='center' alignItems='center'>
          <Loading showTips={false} />
        </Stack>
      ) : (
        <Stack>
          <PdfDetailsView />
        </Stack>
      )}
    </Stack>
  );
};

export default memo(DocumentRightGrid);
