import { memo, useContext, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { SearchListItemProps } from '../../interface';

import styles from '../../styles/UserProfileListItem.styles';
import {
  checkIs505b2,
  getAdvanceSearchText,
  getCnfQueryfor505b2,
  getFilterText,
  getOpenLinkTextAndIcon,
  getSearchTypeText,
  getSourceInfo,
  getViewType
} from '../../utils';
import { FilterEmptyIcon, ThreeDotIcon } from '../../../../assets/svgs/Icons';
import { encodeObjectToBase64 } from '../../../../utils/encodeDecodeObject';
import ResultsStore from '../../../../store/SearchResults';
import ResultActions from '../../../../store/SearchResults/actions';
import FavoriteAndProjectActions, {
  invokedByType
} from '../../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import { RoundedMenu } from '../../../../components/ui/Menu';
import cardsActionsMenuStyles from '../../../SearchResults/styles/CardActionsMenu.styles';
import { MODULE_TABS_SOURCE_LIST } from '../../../SearchResults/components/constants';

const SearchListItem = ({
  index,
  title,
  searchType,
  source,
  data,
  handleFavoriteHook,
  handleProjectHook,
  isModule,
  moduleRedirectUrl
}: SearchListItemProps) => {
  const { resultsDispatch } = useContext(ResultsStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const searchTypeText = useMemo(() => getSearchTypeText(searchType, source), [searchType, source]);
  const finalSearchTypeText = useMemo(() => {
    if (searchTypeText === 'ct') return 'Clinical Trials';
    return searchTypeText[0].toUpperCase() + searchTypeText.slice(1);
  }, [searchTypeText]);
  const sourceInfo = useMemo(() => getSourceInfo(source), [source]);

  const is505b2 = checkIs505b2(source);

  const handleClick = (e: any) => {
    e.stopPropagation();
    // redirect to module page if it is a module filtered search
    if (isModule) {
      window.open(moduleRedirectUrl, '_blank');
      return;
    }
    let payload: any = {};
    if (data?.type === 'advancedSearch') {
      payload = {
        search_type: 'advanced',
        search_term: is505b2 ? getCnfQueryfor505b2(data?.searchData) : data?.cnf_query,
        source: is505b2
          ? {
              us: ['505b2', 'sbas']
            }
          : data?.source,
        approval_date: data?.dateFilter ?? {},
        use_synonyms: data?.use_synonyms,
        approvalPathway: is505b2 ? data?.searchData : {}
      };
    } else {
      payload = {
        search_term: data?.term,
        source: data?.source,
        filterSource: 'default',
        filters: data?.filters
      };
    }

    if (
      Object.keys(source).length === 1 &&
      (data?.source?.ct || data?.source?.eu?.includes('euctr'))
    ) {
      payload.view_type = getViewType('ct');
      payload.ct_search_id = data?.id;
    } else {
      const viewType = getViewType(searchTypeText);
      payload.view_type = viewType;
      if (viewType === 'document') {
        payload.document_search_id = data?.id;
      } else if (MODULE_TABS_SOURCE_LIST?.includes(viewType)) {
        payload[`${viewType}_search_id`] = data?.id;
      } else {
        payload.application_search_id = data?.id;
      }
    }
    const encodedPayload = encodeObjectToBase64(payload) ?? '';
    resultsDispatch({
      type: ResultActions.SET_VIEW_TYPE,
      value: ''
    });
    window.open(`/search/${encodedPayload}`, '_blank');
  };

  const hasFilter = useMemo(
    () =>
      (data?.filters && Object.keys(data?.filters).length !== 0) ||
      (searchType !== 'advancedSearch' &&
        data?.dateFilter &&
        Object.keys(data?.dateFilter).length !== 0),
    [data.filters, data.dateFilter]
  );

  const isFavoriteIconVisible = useMemo(() => {
    const projectsList = data?.inProjects ?? [];
    return (data?.isFavorite ?? false) || projectsList.length > 0;
  }, [data?.isFavorite, data?.inProjects]);

  const openLinkIconAndText = useMemo(() => getOpenLinkTextAndIcon(searchType), [searchType]);

  return (
    <Box>
      <ListItemButton
        disableRipple
        sx={styles.listItem}
        onClick={e => {
          handleClick(e);
        }}>
        <Box sx={styles.listItemFlexBox}>
          <Box sx={styles.indexBox}>
            <Typography sx={styles.indexText}>{index}.</Typography>
          </Box>
          {searchType === 'advancedSearch' ? (
            <Typography sx={{ ...styles.titleText, ml: '4px' }}>
              {data.cnf_query || is505b2
                ? getAdvanceSearchText(data, source, is505b2)
                : `End date:${data?.dateFilter?.end_date}, Start date:${data?.dateFilter?.start_date}`}
            </Typography>
          ) : (
            <Typography
              fontStyle={isModule ? 'italic' : 'normal'}
              sx={{
                ...styles.titleText,
                ml: '4px'
              }}>
              {title}
            </Typography>
          )}
          <Box sx={styles.backgroundText}>
            <Tooltip title={`Page: ${finalSearchTypeText}`}>
              <Typography sx={styles.textWithBackground}>{finalSearchTypeText}</Typography>
            </Tooltip>
          </Box>
          <Box sx={styles.sourceBackgroundText}>
            <Tooltip title={`Region: ${sourceInfo.sourceTooltip}`}>
              <Typography sx={styles.sourceTextWithBackground}>{sourceInfo.source}</Typography>
            </Tooltip>
          </Box>
          <Box sx={styles.backgroundText}>
            <Tooltip title={`Source: ${sourceInfo.moduleTooltip}`}>
              <Typography sx={styles.moduleTextWithBackground}>{sourceInfo.module}</Typography>
            </Tooltip>
          </Box>
          <Tooltip
            title={
              hasFilter ? (
                <>
                  Filters Applied:
                  {getFilterText(data?.filters, data?.source)
                    .split('\n')
                    .map(line => (
                      <div key={line}>{line}</div>
                    ))}
                </>
              ) : (
                ''
              )
            }>
            <Box sx={{ ...styles.iconBox, visibility: hasFilter ? 'visible' : 'hidden' }}>
              <FilterEmptyIcon sx={styles.filterIcon} />
            </Box>
          </Tooltip>
          <Box
            sx={{
              ...styles.favoriteIconBox,
              visibility: isFavoriteIconVisible ? 'visible' : 'hidden'
            }}>
            <FavoriteAndProjectActions
              invokedBy={searchType as invokedByType}
              isButton
              isFavorite={data?.isFavorite ?? false}
              inProjects={data?.inProjects ?? []}
              setFavoriteInHook={handleFavoriteHook}
              setInProjectInHook={handleProjectHook}
              source=''
              sourceIndex={data?.source_index}
              id={data?.id}
            />
          </Box>
          <IconButton
            size='small'
            onClick={e => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}>
            <ThreeDotIcon fontSize='small' sx={styles.threeDotIcon} />
          </IconButton>
        </Box>
      </ListItemButton>
      <Divider variant='middle' sx={styles.divider} />
      <RoundedMenu
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem
          onClick={e => {
            handleClick(e);
          }}
          sx={cardsActionsMenuStyles.menuItem}>
          <ListItemIcon sx={cardsActionsMenuStyles.listItemIcon}>
            {openLinkIconAndText?.icon && (
              <openLinkIconAndText.icon sx={cardsActionsMenuStyles.svgIcon} />
            )}
          </ListItemIcon>
          <ListItemText sx={cardsActionsMenuStyles.listItemText}>
            {openLinkIconAndText?.title ?? 'Go to Search'}
          </ListItemText>
        </MenuItem>
        <FavoriteAndProjectActions
          invokedBy={searchType as invokedByType}
          isButton={false}
          isFavorite={data?.isFavorite ?? false}
          inProjects={data?.inProjects ?? []}
          setFavoriteInHook={handleFavoriteHook}
          setInProjectInHook={handleProjectHook}
          source=''
          sourceIndex={data?.source_index}
          id={data?.id}
        />
      </RoundedMenu>
    </Box>
  );
};
export default memo(SearchListItem);
