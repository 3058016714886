// react
import { memo, MouseEvent, useContext, useEffect, useMemo, useState } from 'react';

// mui
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopoverOrigin,
  Tooltip,
  Typography
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { toast } from 'react-toastify';

// api
import {
  addApplicationToFavorite,
  addAriaResultToProject,
  addEntityToProject,
  addRemoveAriaResultToFavorite,
  addSearchToFavorite,
  getAllProjects,
  removeApplicationFromFavorite,
  removeAriaResultFromProject,
  removeEntityFromProject,
  removeSearchFromFavorite
} from '../../api/pages/UserProfile';
import TitleDescriptionDialog from '../../pages/UserProfile/components/TitleDescriptionDialog';
// store
import GlobalStore from '../../store';
import Actions from '../../store/actions';
import { RoundedMenu } from '../ui/Menu';
import CustomCheckbox from '../CustomComponents/Checkbox';
import { SaveIcon, SaveIconFilled } from '../../assets/svgs/Icons/SaveIcon';
import MenuItemWithSubMenu from '../ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';

import styles from './styles/FavoriteAndProjectActions.styles';
import trimText from '../../utils/trimText';
import useProjectData from '../../pages/UserProfile/hooks/useProjectData';
import {
  PROJECT_DESCRIPTION_MAX_LENGTH,
  PROJECT_TITLE_MAX_LENGTH
} from '../../pages/UserProfile/constants';

export type invokedByType =
  | 'search'
  | 'ariaSearch'
  | 'ariaResult'
  | 'applications'
  | 'application'
  | 'quickSearch'
  | 'advancedSearch'
  | 'applicationSearch'
  | 'documentSearch'
  | 'chmp';

interface FavoriteAndProjectActionsProps {
  // This is used to decide the source of the favorite and project actions
  // Search is used for aria search and quick search (we still send ariaSearch in API for these cases)
  invokedBy: invokedByType;
  // Source
  source: string;
  // This is only used for applications case to decide if the application is from hpra or eu
  sourceIndex?: string;
  // This can either be the application id or the aria result id depending on the invokedBy prop
  id: string;
  documentSearchIDInResult?: string;
  // This is used to decide if the component is a button or a menu item
  isButton: boolean;
  isFavorite: boolean;
  inProjects: any[];
  // This function is used to update the state of isFavorite in the parent component or hook
  setFavoriteInHook: Function;
  // This function is used to update the state of inProject in the parent component or hook
  setInProjectInHook: Function;
  // This is used for sending ARIA result information (not used for other cases)
  ariaResultData?: any;
}

const NestedMenuChildren = ({
  favoriteLoading,
  handleFavorite,
  isFavorite,
  projectListLoading,
  projects,
  projectLoadingId,
  handleProjects,
  handleCreateProjectDialogOpen,
  id
}: any) => {
  return (
    <>
      <MenuItem
        disabled={favoriteLoading}
        onClick={e => {
          e.stopPropagation();
          handleFavorite();
        }}>
        <ListItemIcon>
          {favoriteLoading ? (
            <CircularProgress size={20} sx={styles.menuItemCheckbox} />
          ) : (
            <CustomCheckbox value={id} sx={styles.menuItemCheckbox} checked={isFavorite} />
          )}
        </ListItemIcon>
        <ListItemText>
          <Typography sx={styles.menuItemText}>Save for Later</Typography>
        </ListItemText>
      </MenuItem>
      <Divider sx={styles.divider} />
      <ListItem dense sx={styles.listItemTitle}>
        <ListItemText>
          <Typography sx={styles.menuItemTitle}>Save to projects:</Typography>
        </ListItemText>
      </ListItem>
      {projectListLoading ? (
        <MenuItem>
          <ListItemIcon>
            <CircularProgress size={14} sx={styles.loadingIcon} />
          </ListItemIcon>
          <ListItemText sx={styles.menuItemText}>Loading...</ListItemText>
        </MenuItem>
      ) : (
        <List sx={styles.projectMenuList}>
          {projects.map((project: any) => (
            <MenuItem
              key={project?.project_id ?? project?.id}
              disabled={projectLoadingId === (project?.project_id ?? project?.id)}
              onClick={e => {
                e.stopPropagation();
                handleProjects(project);
              }}>
              <ListItemIcon>
                {projectLoadingId === (project?.project_id ?? project?.id) ? (
                  <CircularProgress size={20} sx={styles.menuItemCheckbox} />
                ) : (
                  <CustomCheckbox
                    value={project?.project_id ?? project?.id}
                    sx={styles.menuItemCheckbox}
                    checked={project?.inProject}
                  />
                )}
              </ListItemIcon>
              <Tooltip title={project?.name?.length > 20 ? project?.name : ''} placement='left'>
                <ListItemText>
                  <Typography sx={styles.menuItemText}>{trimText(project?.name, 20)}</Typography>
                </ListItemText>
              </Tooltip>
            </MenuItem>
          ))}
        </List>
      )}
      <MenuItem onClick={handleCreateProjectDialogOpen}>
        <ListItemIcon>
          <Box sx={styles.createProjecIconBG}>
            <Add sx={styles.menuItemAddIcon} />
          </Box>
        </ListItemIcon>
        <ListItemText sx={styles.menuItemText}>Create New Project</ListItemText>
      </MenuItem>
    </>
  );
};

export const invokedByTitle = {
  applications: 'Application',
  application: 'Application',
  search: 'Search',
  documentSearch: 'Document Search',
  ariaSearch: 'Document Search',
  ariaResult: 'Aria Result',
  advancedSearch: 'Advanced Search',
  applicationSearch: 'Application Search',
  quickSearch: 'Application Search',
  chmp: 'CHMP Search'
};

const FavoriteAndProjectActions = ({
  invokedBy,
  source,
  sourceIndex,
  id,
  isButton,
  isFavorite,
  inProjects,
  setFavoriteInHook,
  setInProjectInHook,
  ariaResultData,
  documentSearchIDInResult = ''
}: FavoriteAndProjectActionsProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(null);
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [projectLoadingId, setProjectLoadingId] = useState<string | null>(null);
  const [projectListLoading, setProjectListLoading] = useState(false);
  const [isCreateProjectDialogOpen, setIsCreateProjectDialogOpen] = useState(false);
  const [projects, setProjects] = useState<any[]>([]);
  const isMenuOpen = Boolean(anchorEl);
  const isNestedMenuOpen = Boolean(nestedAnchorEl);
  const { creatNewProject } = useProjectData();

  // Function to decide the position of the nested menu
  const getSubMenuPosition = useMemo(() => {
    if (!nestedAnchorEl) return undefined;
    // You can customize this logic based on your layout and styles
    const windowWidth = window.innerWidth;
    const nestedRect = nestedAnchorEl?.getBoundingClientRect();
    const spaceRight = windowWidth - (nestedRect?.right ?? 0);
    const nestedWidth = nestedRect?.width ?? 999;

    // Open to the right if there is enough space, otherwise open to the left
    return spaceRight > nestedWidth
      ? {
          anchorOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin
        }
      : {
          anchorOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin
        };
  }, [nestedAnchorEl]);

  // Function to get all the projects
  const fetchData = async () => {
    try {
      setProjectListLoading(true);
      const { status: axiosStatus, data: axiosData } = await getAllProjects();
      if (axiosStatus === 200) {
        const { body, error, message, status } = axiosData;
        if (status === 200 || !error) {
          const localProjects: any[] = [];
          body.forEach((project: any) => {
            const projectId = project?.project_id ?? project?.id;
            if (inProjects) {
              const indexOfProject = inProjects?.findIndex(item => {
                const addedProjectId = 'project_id' in item ? item.project_id : item.id;

                return addedProjectId === projectId;
              });
              if (indexOfProject !== -1) {
                localProjects.push({ ...project, inProject: true });
              } else {
                localProjects.push({ ...project, inProject: false });
              }
            } else {
              localProjects.push({ ...project, inProject: false });
            }
          });
          setProjects(localProjects);
        } else {
          throw new Error(message);
        }
      }
      setProjectListLoading(false);
    } catch (error) {
      toast.error((error as any)?.message ?? 'Something went wrong');
      setProjectListLoading(false);
    }
  };

  // Only fetch projects when the menu is open
  useEffect(() => {
    if (isNestedMenuOpen || isMenuOpen) fetchData();
  }, [isNestedMenuOpen, isMenuOpen]);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseNestedMenu = () => {
    setNestedAnchorEl?.(null);
  };

  const handleExpandMenu = (event: MouseEvent<HTMLElement>) => {
    setNestedAnchorEl?.(event?.currentTarget);
  };

  const handleCreateProjectDialogOpen = () => {
    setIsCreateProjectDialogOpen(true);
  };

  const isFilled = isFavorite || inProjects?.length > 0;

  // Function to add or remove favorite
  // This component does not save the state of the favorite, it just calls the api and updates the state in the parent component
  // Eg: For applications, the state is saved in the useApplications hook and the functions for manupulating the state are passed as props (setFavoriteInHook)
  const handleFavorite = async () => {
    const sourceToUse = source?.toLowerCase() === 'hpra' && sourceIndex !== 'hpra' ? 'eu' : source;
    try {
      let res = null;
      setFavoriteLoading(true);
      // used to show loading icon on individual aria result in aria search
      switch (invokedBy) {
        case 'application':
        case 'applications':
          if (isFavorite) {
            res = await removeApplicationFromFavorite({
              id,
              module: '',
              source: sourceToUse
            });
          } else {
            res = await addApplicationToFavorite({
              id,
              module: '',
              source: sourceToUse
            });
          }
          break;
        case 'applicationSearch':
        case 'advancedSearch':
        case 'quickSearch':
        case 'chmp':
          if (isFavorite) {
            res = await removeSearchFromFavorite({ id, type: 'quickSearch' });
          } else {
            res = await addSearchToFavorite({ id, type: 'quickSearch' });
          }
          break;
        case 'search':
        case 'documentSearch':
        case 'ariaSearch':
          if (isFavorite) {
            res = await removeSearchFromFavorite({ id, type: 'ariaSearch' });
          } else {
            res = await addSearchToFavorite({ id, type: 'ariaSearch' });
          }
          break;
        case 'ariaResult':
          res = await addRemoveAriaResultToFavorite({
            aria_search_id: documentSearchIDInResult,
            is_favorite: !isFavorite,
            source: sourceToUse,
            details: ariaResultData
          });
          break;
        default:
          break;
      }
      setFavoriteLoading(false);
      if (res && res.status === 200) {
        setFavoriteInHook({ source, identifier: id, isFavorite: !isFavorite });
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: !isFavorite ? 'Added to favorite' : 'Removed from favorite',
            status: true,
            color: 'success'
          }
        });
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: 'Something went wrong', status: true }
      });
    }
  };

  const projectPayloadGeneration = () => {
    let entityType = '';

    switch (invokedBy) {
      case 'search':
      case 'documentSearch':
      case 'ariaSearch':
        entityType = 'aria_search';
        break;
      case 'quickSearch':
      case 'applicationSearch':
      case 'advancedSearch':
      case 'chmp':
        entityType = 'quick_advanced_search';
        break;
      case 'ariaResult':
        entityType = 'aria_result';
        break;
      default:
        entityType = invokedBy;
    }
    const payload = {
      entity_type: entityType
    };
    if (invokedBy === 'applications' || invokedBy === 'application') {
      // @ts-ignore
      payload.additional_fields = {
        source,
        entity_id: id
      };
    } else if (invokedBy === 'ariaResult') {
      // @ts-ignore
      payload.additional_fields = {
        aria_search_id: documentSearchIDInResult,
        source,
        ...ariaResultData
      };
    }
    return payload;
  };

  // Function to add or remove project
  // This component saves the state of inProject and updates the state in the parent component as well with the help of setInProjectInHook
  // Eg: For applications, the state is saved in the useApplications hook and the functions for manupulating the state are passed as props (setInProjectInHook)
  const handleProjects = async (project: any) => {
    try {
      const projectId = project?.project_id ?? project?.id;
      setProjectLoadingId(projectId);
      const payload = projectPayloadGeneration();
      let res = null;

      if (invokedBy === 'ariaResult') {
        res = project?.inProject
          ? await removeAriaResultFromProject({
              projectId,
              payload
            })
          : await addAriaResultToProject({
              projectId,
              payload
            });
      } else {
        res = project?.inProject
          ? await removeEntityFromProject({
              projectId,
              id,
              payload
            })
          : await addEntityToProject({
              projectId,
              id,
              payload
            });
      }
      setProjectLoadingId(null);
      if (res?.data?.body && !res.data.body?.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: project?.inProject ? 'Removed from project' : 'Added to project',
            status: true,
            color: 'success'
          }
        });
        setInProjectInHook({ source, identifier: id, project });
        setProjects(prevVal => {
          const updatedProjects = prevVal.map(p =>
            (p?.project_id ?? p.id) === projectId ? { ...p, inProject: !p.inProject } : p
          );
          return updatedProjects;
        });
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: 'Something went wrong', status: true }
      });
      setProjectLoadingId(null);
    }
  };

  const handleSubmit = async (title: string, description: string) => {
    if (title) {
      const payload: any = {
        name: title.trim(),
        description: description.trim()
      };
      const { success, project } = await creatNewProject(payload);
      if (success && project) {
        setProjects((prevVal: any) => [...prevVal, project]);
      }
      handleProjects(project);
      setIsCreateProjectDialogOpen(false);
    }
  };

  if (
    (invokedBy === 'applications' || invokedBy === 'application' || invokedBy === 'ariaResult') &&
    !window.location.pathname.startsWith('/regulatory360') &&
    isButton &&
    !isFavorite &&
    inProjects?.length === 0
  )
    return null;
  return (
    <>
      {isButton ? (
        <Tooltip
          title={
            !isFilled
              ? `Save this ${invokedByTitle[invokedBy] ?? 'Search'}`
              : `Update save preferences for this ${invokedByTitle[invokedBy] ?? 'Search'}`
          }>
          <IconButton
            id='favorite-project-action-button'
            aria-label='Favorites and Projects'
            onClick={handleOpenMenu}>
            {isFilled ? (
              <SaveIconFilled sx={styles.iconPrimary} />
            ) : (
              <SaveIcon sx={styles.iconGray} />
            )}
          </IconButton>
        </Tooltip>
      ) : (
        <MenuItemWithSubMenu
          onMouseOver={handleExpandMenu}
          onClick={handleExpandMenu}
          MenuItemIcon={
            isFilled ? (
              <SaveIconFilled sx={styles.iconPrimary} />
            ) : (
              <SaveIcon sx={styles.iconGray} />
            )
          }
          MenuItemText='Save'>
          <NestedMenuChildren
            favoriteLoading={favoriteLoading}
            handleFavorite={handleFavorite}
            isFavorite={isFavorite}
            projectListLoading={projectListLoading}
            projects={projects}
            projectLoadingId={projectLoadingId}
            handleProjects={handleProjects}
            handleCreateProjectDialogOpen={handleCreateProjectDialogOpen}
            id={id}
          />
        </MenuItemWithSubMenu>
      )}
      {isButton && (
        <RoundedMenu
          id='card-actions-favorite-project-menu'
          disablePortal
          anchorEl={isButton ? anchorEl : nestedAnchorEl}
          anchorOrigin={
            isButton ? { vertical: 'bottom', horizontal: 'left' } : getSubMenuPosition?.anchorOrigin
          }
          transformOrigin={isButton ? undefined : getSubMenuPosition?.transformOrigin}
          open={isButton ? isMenuOpen : isNestedMenuOpen}
          MenuListProps={{ onMouseLeave: handleCloseNestedMenu }}
          onClose={isButton ? handleCloseMenu : handleCloseNestedMenu}
          onClick={e => {
            // this stops the event from propagating to the parent and causing the card onClick to fire
            e.stopPropagation();
          }}
          sx={styles.roundedeMenu}>
          <NestedMenuChildren
            favoriteLoading={favoriteLoading}
            handleFavorite={handleFavorite}
            isFavorite={isFavorite}
            projectListLoading={projectListLoading}
            projects={projects}
            projectLoadingId={projectLoadingId}
            handleProjects={handleProjects}
            handleCreateProjectDialogOpen={handleCreateProjectDialogOpen}
            id={id}
          />
        </RoundedMenu>
      )}
      {isCreateProjectDialogOpen && (
        <TitleDescriptionDialog
          dialogType='project'
          heading='Add Project Details'
          title=''
          description=''
          handleSubmit={handleSubmit}
          handleClose={() => {
            setIsCreateProjectDialogOpen(false);
          }}
          action='edit'
          titleMaxlength={PROJECT_TITLE_MAX_LENGTH}
          descriptionMaxLength={PROJECT_DESCRIPTION_MAX_LENGTH}
        />
      )}
    </>
  );
};

FavoriteAndProjectActions.defaultProps = {
  sourceIndex: '',
  ariaResultData: null,
  documentSearchIDInResult: ''
};

export default memo(FavoriteAndProjectActions);
