/* eslint-disable import/prefer-default-export */
export const HIDDEN_COLUMNS: { [key: string]: any } = {
  accelerated_assessment: false,
  conditional_approval: false,
  orphan_medicine: false,
  exceptional_circumstances: false,
  advanced_therapy: false,
  combination_product: false,
  expedited_review_flag: false,
  orphan_status: false,
  fast_track: false,
  breakthrough_status: false,
  accelerated_approval: false,
  advisory_committee: false,
  biosimilar: false,
  application_boxed_warning_text: false,
  rems_text: false,
  review_priority_text: false,
  review_standard_text: false,
  review_other_text: false,
  matched_submissions_csv: false,
  review_status: false,
  combination_product_flag: false,
  // Clinical Trials
  has_expanded_access: false,
  healthy_volunteers: false,
  outcomes_assessor_masked: false,
  caregiver_masked: false,
  investigator_masked: false,
  subject_masked: false,
  allocation: false,
  study_completion: false,
  sponsors: false,
  primary_completion: false,
  overall_status: false,
  last_updated_year: false,
  gender: false,
  first_submitted_year: false,
  primary_outcome_csv: false,
  secondary_outcome_csv: false,
  location: false,
  location_csv: false,
  age_range_csv: false,
  // Clinical Trials End
  atc_classification_export: false,
  ct_gov_link: false,
  ct_link: false,
  // PMR Surveillance
  study_type: false,
  study_start_date: false,
  final_report_due_date: false,
  protocol_submission_date: false,
  // PMR Impact
  aa_status: false,
  bt_status: false,
  ft_status: false,
  or_status: false,
  // canada
  noc_w_condition_flag: false,
  priority_review_flag: false,
  // faq
  link: false,
  source_csv: false,
  // hpra
  marketing_text: false,
  supply_status: false,
  advertising_status: false,
  // hma products reg360
  cm_states_csv: false,
  // ddtools
  requestor_csv: false,
  project_details_csv: false,
  scientific_advice: false,
  new_active_substance: false
};

export const DISABLED_COLUMN_ENABLING = [
  'atc_classification_export',
  'secondary_outcome_csv',
  'primary_outcome_csv',
  'review_priority_text',
  'review_standard_text',
  'review_other_text',
  'matched_submissions_csv',
  'review_status',
  'review_other_text',
  'ct_gov_link',
  'nct_id_icon',
  'next',
  'ct_link',
  'combination_product_flag',
  'new_active_substance',
  'scientific_advice',
  'age_range_csv'
];

// Data grid filter option list
export const DATAGRID_OPTION_LIST = ['contains', 'equals', 'startsWith'];
