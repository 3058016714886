export const ALLOWED_REPORT = ['approvalHistory'];

export const ALLOWED_COMPARE_LABEL = ['approvalHistory', 'commissionProcedures'];

export const ALLOWED_CSV_DOWNLOAD = ['approvalHistory'];

export const ALLOWED_FILTERS = ['approvalHistory', 'pmrc'];

export const ALLOWED_SORT_BY_DATE = ['approvalHistory'];

export const ALLOWED_SHOW_ALL_PROCEDURES = ['approvalHistory'];

export const ALLOWED_TIMELINE_VIEW = ['patentExclusivity'];

export const SORTING_OPTIONS: any = {
  us: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'approval_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'approval_date',
      sortType: 'asc'
    }
  ],
  eu: [
    {
      id: 'default',
      label: 'Opinion/Notification Issued On: New ',
      fieldName: 'notification_issue_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Opinion/Notification Issued On: Oldest ',
      fieldName: 'notification_issue_date',
      sortType: 'asc'
    }
  ],
  ca: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'approval_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'approval_date',
      sortType: 'asc'
    }
  ],
  ct: [
    {
      id: 'default',
      label: 'Submitted Date: New ',
      fieldName: 'submitted_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Submitted Date: Oldest ',
      fieldName: 'submitted_date',
      sortType: 'asc'
    }
  ],
  'us-devices': [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'decision_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'decision_date',
      sortType: 'asc'
    }
  ],
  eua: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'approval_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'approval_date',
      sortType: 'asc'
    }
  ],
  hpra: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'approval_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'approval_date',
      sortType: 'asc'
    }
  ],
  hma: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'approval_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'approval_date',
      sortType: 'asc'
    }
  ],
  jp: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'approval_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'approval_date',
      sortType: 'asc'
    }
  ],
  au: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'revision_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'revision_date',
      sortType: 'asc'
    }
  ],
  mhra: [
    {
      id: 'default',
      label: 'Approval Date: New ',
      fieldName: 'approval_date',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Date: Oldest ',
      fieldName: 'approval_date',
      sortType: 'asc'
    }
  ]
};

export const FITERS_APPLIED: { [key: string]: string } = {
  pmrc: 'isPMRCFilterApplied',
  approvalHistory: 'isApprovalHistoryFilterApplied'
};

export default ALLOWED_REPORT;
