import { memo } from 'react';

import { Stack, Tooltip, Typography } from '@mui/material';

import SubApprovalTimeline from './SubApprovalTimeline';

import subApprovalTimelineStyles from './SubApprovalTimeline.styles';

interface SubApprovalTimelineDetailsProps {
  submissionTimeline: {
    number: string;
    metadata: {
      label: string;
      value: string;
    }[];
    timeline: any;
  };
}

const SubApprovalTimelineDetails = ({ submissionTimeline }: SubApprovalTimelineDetailsProps) => {
  return (
    <Stack gap={1.5}>
      <Stack sx={subApprovalTimelineStyles.metaDataContainer}>
        {submissionTimeline.metadata.map(item => (
          <Stack key={item.label} direction='row' gap={1}>
            <Typography sx={subApprovalTimelineStyles.metaDataLabel}>{item.label}</Typography>
            <Tooltip
              title={
                <Typography sx={subApprovalTimelineStyles.metaDataValueTooltip}>
                  {item.value}
                </Typography>
              }>
              <Typography sx={subApprovalTimelineStyles.metaDataValue}>{item.value}</Typography>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
      <Stack px={2} gap={2}>
        <Typography sx={subApprovalTimelineStyles.timelineText}>
          Timeline of {submissionTimeline.number}
        </Typography>
        <SubApprovalTimeline timeline={submissionTimeline.timeline} />
      </Stack>
    </Stack>
  );
};

export default memo(SubApprovalTimelineDetails);
