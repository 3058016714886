import React, { useContext } from 'react';
import { Grid, Stack } from '@mui/material';
import ResultContent from './ResultContent';
import ResultsStore from '../../../../store/SearchResults';
import PdfDetailsView from '../PdfDetailsView';
import Loading from '../Loading';
import styles from './styles';

const RiaInsights = ({
  renderedInPopup,
  insights,
  addReport,
  setFirstLoad,
  firstLoad,
  loading
}: any) => {
  const { resultsState } = useContext(ResultsStore);

  return (
    <Grid
      maxWidth='lg-md'
      m='auto'
      id={renderedInPopup ? 'scroll-container-drawer' : 'scroll-container'}
      container
      mt={7}
      sx={
        renderedInPopup
          ? {
              height: '80vh',
              overflowY: 'auto'
            }
          : {
              mb: 10
            }
      }>
      <Grid
        item
        xs={resultsState?.selectedDocument ? 6 : 12}
        sx={{
          bgcolor: 'white.main',
          maxWidth: '904px !important',
          mb: resultsState?.selectedDocument ? -5 : 15,
          mx: 'auto'
        }}>
        <ResultContent
          content={insights?.html}
          citations={insights?.citations}
          loading={loading}
          doneLoading={insights?.completed}
          addReport={addReport}
          firstLoad={firstLoad}
          setFirstLoad={setFirstLoad}
        />
      </Grid>

      {resultsState?.selectedDocument && (
        <Grid item id='pdf-viewer-container' xs={6}>
          <Stack
            sx={styles.pdfViewerContainer}
            top={renderedInPopup ? '195px' : '185px'}
            height='80vh'>
            {resultsState.documentLoader ? (
              <Stack justifyContent='center' alignItems='center'>
                <Loading showTips={false} />
              </Stack>
            ) : (
              <Stack>
                <PdfDetailsView />
              </Stack>
            )}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RiaInsights);
