import React, { memo, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Stack,
  Divider
} from '@mui/material';

import { HideIcon, ViewIcon } from '../../../assets/svgs/Icons';
import DocumentIcon from '../../../assets/svgs/PDCO/DocumentIcon';
import shortenMonthYear from '../utils/shortenMonthYear';
import styles from '../styles/TimelineCard.styles';

const TimelineCard = ({
  section,
  pdcoNumber,
  documentUrl,
  summaryText,
  setPdfPath,
  setDocTitle,
  setOpenDocument,
  meetingId,
  setMeetingId,
  fileName,
  selectedMeetingId,
  opinionDay
}: any) => {
  const [expanded, setExpanded] = useState(false);

  const handleViewSummary = () => {
    setExpanded(prev => !prev);
  };

  const handlePDFViwer = () => {
    setPdfPath(documentUrl);
    setDocTitle(fileName);
    setMeetingId(meetingId);
    setOpenDocument(true);
  };

  const getSummaryTitle = () => {
    if (summaryText && summaryText === 'NA') {
      return 'No summary available from the source';
    }
    if (expanded) {
      return 'Hide Summary';
    }
    return 'View Summary';
  };

  return (
    <Box
      sx={{
        ...styles.wrapper,
        borderColor: selectedMeetingId === meetingId ? 'primary.200' : 'gray.200'
      }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Box flexShrink={0} width={72}>
          <Typography sx={styles.yearText}>{shortenMonthYear(meetingId)}</Typography>
        </Box>
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-start'
          ml={2}>
          <Tooltip title={section} placement='top'>
            <Typography sx={{ ...styles.sectionText, maxWidth: { md: 200, lg: 285, xl: '100%' } }}>
              {section}
            </Typography>
          </Tooltip>
          <Box sx={styles.daysContainer}>
            <Tooltip title={opinionDay} placement='top'>
              <Typography noWrap fontWeight={500} color='gray.900'>
                {opinionDay}
              </Typography>
            </Tooltip>
          </Box>
          <DocumentIcon
            onClick={handlePDFViwer}
            sx={{
              ...styles.documentIcon,
              cursor: documentUrl ? 'pointer' : null,
              color: 'secondary.700'
            }}
          />
        </Box>
        <Box flexShrink={0}>
          <Tooltip title={pdcoNumber} placement='top'>
            <Typography sx={{ ...styles.pdcoNumberText, maxWidth: { md: 100, lg: '100%' } }}>
              {pdcoNumber}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
      <Divider sx={{ my: 1, borderColor: 'gray.200' }} />
      <Accordion
        expanded={expanded}
        onChange={() => {
          if (summaryText && summaryText !== 'NA') {
            handleViewSummary();
          }
        }}
        defaultExpanded
        sx={styles.accordion}>
        <AccordionSummary
          expandIcon={
            expanded ? (
              <HideIcon
                sx={{
                  ...styles.hideIcon,
                  color: summaryText && summaryText !== 'NA' ? 'primary.700' : 'gray.500'
                }}
              />
            ) : (
              <ViewIcon
                sx={{
                  ...styles.hideIcon,
                  color: summaryText && summaryText !== 'NA' ? 'primary.700' : 'gray.500'
                }}
              />
            )
          }
          aria-controls={`${meetingId}-${pdcoNumber}`}
          id={`${meetingId}-${pdcoNumber}`}
          sx={styles.accordionSummary}>
          <Typography
            fontWeight={500}
            sx={{
              ...styles.hideText,
              color: summaryText && summaryText !== 'NA' ? 'primary.700' : 'gray.500'
            }}>
            {getSummaryTitle()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionDetails}>
          <Box component='ul' sx={styles.ulStyle}>
            <Box component='li' sx={styles.liStyle}>
              <Typography sx={styles.accordionSummaryText}>{summaryText}</Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default memo(TimelineCard);
