import React, { useEffect, useContext, useState, memo } from 'react';
import { isEmpty, capitalize } from 'lodash';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Drawer,
  Portal,
  Snackbar,
  Slide,
  Divider,
  Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import SendIcon from '@mui/icons-material/Send';
// API
import { fetchPDCOPIPDetails } from '../../../api/pages/PDCO';
// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// assets
import CloseIcon from '../../../assets/svgs/Icons/CloseIcon';
import DocumentLinkIcon from '../../../assets/svgs/PDCO/DocumentLinkIcon';
// component
import PDFViewer from '../../../components/PDFViewer';
import BasicTimeline from './TimelineDetails';
import LatestPIP from './LatestPIP';
import OtherRelatedPIP from './OtherReletedPIP';
import TimelineLoader from './TimelineLoader';

// utils
import trimText from '../../../utils/trimText';
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
// styles
import styles from '../styles/Timeline.styles';

const Transition = (props: TransitionProps) => {
  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction='left' />;
};
const Timeline = ({ timelineDetails, clearTimeline, selectedMeetingId }: any) => {
  const [viewLatestPIP, setViewLatestPIP] = useState(false);
  const [otherPIPOpen, setOtherPIPOpen] = useState(false);
  const [pipDetails, setPipDetails] = useState<any>(timelineDetails);
  const [openDocument, setOpenDocument] = useState(false);
  const [meetingId, setMeetingId] = useState('');
  const [docTitle, setDocTitle] = useState('');
  const [pdfPath, setPdfPath] = useState('');
  const [timelineLoading, setTimelineLoading] = useState(false);
  const { state, dispatch } = useContext(GlobalStore) as any;

  useEffect(() => {
    const getPIPTimelineDetails = async (pipNumber: string, pdcoNumberModified: string) => {
      try {
        setTimelineLoading(true);
        const res = await fetchPDCOPIPDetails({
          pip_number: [pipNumber],
          pdco_number_modified: [pdcoNumberModified]
        });
        if (res?.status === 200) {
          setPipDetails({ ...res.data.body, active_ingredient: pipDetails?.active_ingredient });
        }
        setTimelineLoading(false);
      } catch (err) {
        setTimelineLoading(false);
        console.error(err);
      }
    };

    if (
      !(
        Object.keys(pipDetails).includes('latest_pip') &&
        Object.keys(pipDetails).includes('timeline')
      )
    ) {
      getPIPTimelineDetails(pipDetails?.pip_number, pipDetails?.pdco_number_modified);
    }
  }, [pipDetails?.pip_number]);

  useEffect(() => {
    if (openDocument && otherPIPOpen) {
      setOtherPIPOpen(false);
    }
  }, [openDocument]);

  const handleRadioChange = (pip?: any) => {
    setPipDetails(pip);
  };

  const latestPIPAvailable = !isEmpty(pipDetails?.latest_pip);

  const handleLatestPIPClick = () => {
    if (latestPIPAvailable) {
      setViewLatestPIP(true);
      setOpenDocument(false);
      setOtherPIPOpen(false);
    }
  };

  const handleChatRia = ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    let mappedSourceName: string | null = '';
    let docDetails: any = {};
    if (viewLatestPIP && !isEmpty(pipDetails?.latestPIP)) {
      mappedSourceName = getDocumentSourceName('PIP');
      docDetails = {
        active_ingredient: pipDetails?.latestPIP?.file_name,
        decision_date: pipDetails?.latestPIP?.decision_date
          ? pipDetails?.latestPIP?.decision_date.split(' ')[0]
          : '',
        'decision-number': pipDetails?.latestPIP?.decision_number?.split(',')[0],
        formulation: pipDetails?.latestPIP?.formulations?.split(',')[0],
        route: pipDetails?.latestPIP?.routes
      };
    } else {
      docDetails = {
        'original-file-name': docTitle,
        'meeting-year': meetingId,
        'category-bucket': capitalize(pdfPath.split('/')[3])
      };
      mappedSourceName = getDocumentSourceName('PDCO');
    }

    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          ...docDetails
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setOpenDocument(false);
  };

  const getDrawerWidth = () => {
    if (openDocument) {
      return '46%';
    }
    if (state?.chatRiaOpen) {
      return '0';
    }
    return '50%';
  };

  return (
    <Drawer
      anchor='right'
      open
      transitionDuration={500}
      hideBackdrop={false}
      onClose={async (event, reason) => {
        if (reason !== 'backdropClick') {
          clearTimeline(false);
        }
      }}
      sx={{
        ...styles.drawer,
        '& >.MuiDrawer-paper': {
          width: {
            md: getDrawerWidth()
          },
          overflow: 'hidden',
          boxShadow: '-0.1em 0.1em 5em #33b1874a',
          borderTopLeftRadius: otherPIPOpen ? 0 : 12,
          borderBottomLeftRadius: otherPIPOpen ? 0 : 12
        }
      }}>
      {viewLatestPIP && !state.chatRiaOpen && (
        <LatestPIP
          latestPIP={pipDetails?.latest_pip ?? {}}
          setViewLatestPIP={setViewLatestPIP}
          clearTimeline={clearTimeline}
          setPdfPath={setPdfPath}
          setDocTitle={setDocTitle}
          setOpenDocument={setOpenDocument}
        />
      )}
      {/* Timeline eHeader  */}
      {!viewLatestPIP && !state?.chatRiaOpen && (
        <>
          <Stack sx={styles.stackWrapper}>
            <Stack width='80%' direction='row'>
              <Tooltip title={pipDetails?.active_ingredient}>
                <Typography sx={styles.timelineTitle}>
                  {pipDetails?.active_ingredient} q1
                </Typography>
              </Tooltip>
            </Stack>
            <Box>
              <Tooltip title='Close Chat'>
                <IconButton size='small' onClick={() => clearTimeline(false)}>
                  <Close sx={{ fontSize: 27.45, color: 'gray.0' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          {!timelineLoading ? (
            <>
              <Box sx={styles.timelineWrapper}>
                <Grid
                  container
                  gap={3}
                  sx={{
                    borderBottom: '1px solid #E0E0E0',
                    pb: 1.5,
                    flexWrap: 'nowrap'
                  }}>
                  <Grid item xs={2.6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                      <Typography sx={styles.sponsorHeading}>Sponsor Name</Typography>
                      <Tooltip title={pipDetails?.sponsor_name} placement='top'>
                        <Typography sx={styles.sponsorHeadingText}>
                          {pipDetails?.sponsor_name || 'NA'}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={2.6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                      <Typography sx={styles.sponsorHeading}>Indication</Typography>
                      <Tooltip title={pipDetails?.indication} placement='top'>
                        <Typography sx={styles.sponsorHeadingText}>
                          {pipDetails?.indication || 'NA'}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={2.6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                      <Typography sx={styles.sponsorHeading}>Therapeutic Area</Typography>
                      <Tooltip title={pipDetails?.therapeutic_area} placement='top'>
                        <Typography sx={styles.sponsorHeadingText}>
                          {pipDetails?.therapeutic_area || 'NA'}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={2.6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}>
                      <Typography sx={styles.sponsorHeading}>Trade Name</Typography>
                      <Tooltip title={pipDetails?.latest_pip?.brand_name} placement='top'>
                        <Typography
                          sx={{
                            ...styles.sponsorHeadingText,
                            fontWeight: pipDetails?.latest_pip?.brand_name ? 700 : 500
                          }}>
                          {pipDetails?.latest_pip?.brand_name ?? 'NA'}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sx={styles.latestPIPBox}>
                    <Typography sx={styles.pipNumberText}>{pipDetails?.pip_number}</Typography>
                  </Grid>
                  {latestPIPAvailable && (
                    <Grid item sx={styles.latestPIPBox}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 0.2,
                          cursor: 'pointer',
                          mr: 1.5
                        }}
                        onClick={handleLatestPIPClick}>
                        <DocumentLinkIcon sx={{ fontSize: 14 }} />
                        <Typography sx={styles.latestPIPText}>
                          {`Latest PIP - ${pipDetails.latest_pip?.decision_number ?? 'NA'}`}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography
                      onClick={() => {
                        setOtherPIPOpen(true);
                        setOpenDocument(false);
                      }}
                      sx={{
                        ...styles.otherPIPText,
                        textDecoration: otherPIPOpen ? 'underline' : ''
                      }}>
                      Other related PIPs
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box my={2}>
                <Typography
                  color='gray.900'
                  fontSize={14}
                  fontWeight={700}
                  lineHeight='140%'
                  sx={{ pl: 2.2 }}>
                  {`Timeline of “${pipDetails?.pdco_number_modified}” with PDCO`}
                </Typography>
              </Box>
              <BasicTimeline
                timelineTrack={pipDetails?.timeline ?? []}
                setPdfPath={setPdfPath}
                setDocTitle={setDocTitle}
                setOpenDocument={setOpenDocument}
                setMeetingId={setMeetingId}
                selectedMeetingId={selectedMeetingId}
              />
            </>
          ) : (
            <TimelineLoader />
          )}
          {otherPIPOpen && (
            <OtherRelatedPIP
              otherPIPs={timelineDetails?.other_related_pip}
              setOtherPIPOpen={setOtherPIPOpen}
              selectedPipNumber={pipDetails?.pip_number}
              handleRadioChange={handleRadioChange}
            />
          )}
        </>
      )}

      {openDocument && (
        <Portal>
          <Snackbar
            TransitionComponent={Transition}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              ...styles.snackBarContainer,
              right: {
                md: '47% !important'
              }
            }}
            open={openDocument}>
            <Stack flexDirection='column' sx={{ ...styles.pdfContainer, width: { md: '98%' } }}>
              <Box
                display='flex'
                alignItems='center'
                width='100%'
                justifyContent='space-between'
                mb={2.5}>
                <Stack flex='display' alignItems='center' sx={{ pl: 1.9, flexDirection: 'row' }}>
                  {docTitle && (
                    <Tooltip title={docTitle}>
                      <Typography sx={styles.titleText}>
                        {docTitle ? trimText(docTitle, 40) : ''}
                      </Typography>
                    </Tooltip>
                  )}
                  <Divider
                    orientation='vertical'
                    variant='middle'
                    flexItem
                    sx={{ p: 0.7, height: 16 }}
                  />
                  <Tooltip title='Use Chat RIA to interact with the document'>
                    <Button
                      variant='text'
                      disabled={!pdfPath}
                      onClick={() =>
                        handleChatRia({
                          pdfUrl: pdfPath
                        })
                      }
                      sx={styles.chatRiaButton}>
                      Chat RIA
                      <SendIcon sx={styles.chatRiaIcon} />
                    </Button>
                  </Tooltip>
                </Stack>

                <Box mr={2.5}>
                  <CloseIcon
                    onClick={() => {
                      setDocTitle('');
                      setOpenDocument(false);
                    }}
                    sx={styles.closeIcon}
                  />
                </Box>
              </Box>
              <Box id='pdfBox'>
                <PDFViewer fileUrl={pdfPath} defaultScale={1} />
              </Box>
            </Stack>
          </Snackbar>
        </Portal>
      )}
    </Drawer>
  );
};

export default memo(Timeline);
