import { createSvgIcon } from '@mui/material';

const FolderIcon = createSvgIcon(
  <svg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_236_1246)'>
      <path
        d='M11.375 2.54525H6.62783L4.46117 1.46191H1.625C1.19402 1.46191 0.780698 1.63312 0.475951 1.93787C0.171205 2.24261 0 2.65594 0 3.08691L0 13.3786H13V4.17025C13 3.73927 12.8288 3.32595 12.524 3.0212C12.2193 2.71645 11.806 2.54525 11.375 2.54525ZM1.625 2.54525H4.2055L6.37217 3.62858H11.375C11.5187 3.62858 11.6564 3.68565 11.758 3.78723C11.8596 3.88881 11.9167 4.02659 11.9167 4.17025V4.64746L1.08333 4.70866V3.08691C1.08333 2.94326 1.1404 2.80548 1.24198 2.7039C1.34357 2.60232 1.48134 2.54525 1.625 2.54525ZM1.08333 12.2952V5.792L11.9167 5.73079V12.2952H1.08333Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_236_1246'>
        <rect width='13' height='13' fill='white' transform='translate(0 0.919922)' />
      </clipPath>
    </defs>
  </svg>,
  'FolderIcon'
);

export default FolderIcon;
