import { DATAGRID_UNIQUE_FIELD } from '../../ResultsPage/utils/constants';
import transformData from './dataTransform';

const mergeMatchPatentsExclusivityData = (applData: any, subData: any) => {
  Object.keys(applData).forEach((region: string) => {
    const applDataResults = applData[region].results;
    const subDataResults = region in subData ? subData[region].results : [];

    // Create a map of application_number to match_submission
    const value = DATAGRID_UNIQUE_FIELD[region];
    const subDataMap = new Map(
      subDataResults.map((record: any) => [record[value], record.patent_exclusivity])
    );
    // Merge match_submission
    applDataResults.forEach((record: any) => {
      // eslint-disable-next-line no-param-reassign
      record.patentExclusivity = subDataMap.get(record[value]) || [];
    });
  });

  return applData;
};

// check if matched_submission present or not
export const checkForMatchPatentsExclusivity = (data: { [x: string]: { results: any[] } }) => {
  const applData = transformData(data);
  return applData.some((item: any) => 'patentExclusivity' in item);
};

export default mergeMatchPatentsExclusivityData;
