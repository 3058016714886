import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';
import EU_OVERVIEW_INFO from './constants';
import ProductInformation from './ProductInformation';
import FaqDetails from './FaqDetails';
import styles from './styles/overviewTab.styles';

const OverviewTab = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [productInfo, setProductInfo] = useState({});
  const [noContent, setNoContent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (Object.keys(regulatoryState?.overviewDetails).length === 0) {
      const getData = async () => {
        setLoading(true);
        const data = await regulatoryInformationAPI(
          regulatoryState?.db,
          regulatoryState?.applicationDetails?.application_number,
          EU_OVERVIEW_INFO
        );
        if (!data?.data?.body) {
          setNoContent(true);
        } else {
          setProductInfo(data.data.body);
          regulatoryDispatch({ type: Actions.SET_OVERVIEW_DETAILS, value: data.data.body });
        }
        setLoading(false);
      };
      getData();
    }
    setProductInfo(regulatoryState?.overviewDetails);
    // set the state with the context
  }, [regulatoryState.db, regulatoryState?.applicationDetails]);

  if (noContent) {
    return <EmptyPage message='No content for Overview' />;
  }
  if (isLoading) {
    return <EmptyPage message='Loading Product Information' />;
  }

  return (
    <Box>
      <Box display='flex' flexDirection='row'>
        <Grid container sx={styles.gridContainer} display='flex' flexDirection='row'>
          <Grid item xs={4} sx={styles.productGrid}>
            <ProductInformation dataItem={productInfo} />
          </Grid>

          <Grid item xs={7} sx={styles.detailsGrid}>
            <FaqDetails dataItem={productInfo} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default React.memo(OverviewTab);
