/* eslint-disable import/prefer-default-export */
import { SOURCE_MENU_ITEMS } from '../../Home/const';

export const getFilteredSourceMenuItemsForModuleTabs = (viewType: string) => {
  // Find the source that contains the module with the matching viewType
  const source = SOURCE_MENU_ITEMS.find((src: any) =>
    src.module.some((module: any) => module.value === viewType)
  );

  // If a matching source is found, filter its modules to only include the one with the matching viewType
  if (source) {
    return [
      {
        ...source,
        module: source.module.filter(module => module.value === viewType) // Include only the matching module
      }
    ];
  }

  // Return default constant if no matching module is found
  return SOURCE_MENU_ITEMS;
};
