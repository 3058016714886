import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import Stats from '../../../SearchResults/components/Stats';
import styles from '../../styles/Header.styles';
import { Stat } from '../../../SearchResults/types';
import { ReportIcon, SubHeaderPipeGreyIcon } from '../../../../assets/svgs/Icons';

type SubHeaderProps = {
  children: any;
  title: any;
  stats: Stat[];
  handleVisualizeOpen: () => void;
  // eslint-disable-next-line react/require-default-props
  showVisualize?: boolean;
};

const Header = ({
  children,
  title,
  stats,
  handleVisualizeOpen,
  showVisualize = true
}: SubHeaderProps) => {
  return (
    <Box display='flex' bgcolor='white.main' px={5}>
      <p>
        <SubHeaderPipeGreyIcon sx={styles.subHeaderPipeGreyIcon} />
      </p>
      <Box sx={styles.statsContainer}>
        <Typography sx={styles.title}>{title}</Typography>
        <Box sx={styles.contentContainer}>
          <Stats stats={stats} />
          <Box sx={{ display: 'flex', height: '38px', alignItems: 'center', gap: '4px' }}>
            {children}
            {showVisualize && (
              <Button
                onClick={handleVisualizeOpen}
                id='visualize-button'
                startIcon={<ReportIcon sx={styles.primaryButtonIcon} />}
                sx={styles.primaryButton}>
                Visualize & Report
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Header);
