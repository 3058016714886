import { memo, useContext, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { MoreVert } from '@mui/icons-material';
import { ActivityListItemProps } from '../../interface';

import styles from '../../styles/UserProfileListItem.styles';
import {
  checkIs505b2,
  getAdvanceSearchText,
  getCnfQueryfor505b2,
  getFilterText,
  getOpenLinkTextAndIcon,
  getSearchTypeText,
  getSourceInfo,
  getViewType
} from '../../utils';
import { FilterEmptyIcon, TrashIcon } from '../../../../assets/svgs/Icons';
import { encodeObjectToBase64 } from '../../../../utils/encodeDecodeObject';
import ResultsStore from '../../../../store/SearchResults';
import ResultActions from '../../../../store/SearchResults/actions';
import FavoriteAndProjectActions, {
  invokedByType
} from '../../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import { RoundedMenu } from '../../../../components/ui/Menu';
import useActivityData from '../../hooks/useActivityData';
import cardsActionsMenuStyles from '../../../SearchResults/styles/CardActionsMenu.styles';
import TitleDescriptionDialog from '../TitleDescriptionDialog';
import { MODULE_TABS_SOURCE_LIST } from '../../../SearchResults/components/constants';

const ActivityListItem = ({
  date,
  quantity,
  title,
  searchType,
  source,
  data,
  isModule = false,
  moduleRedirectUrl = '',
  showDivider = false
}: ActivityListItemProps) => {
  const { resultsDispatch } = useContext(ResultsStore);
  const { setFavorite, setInProject, deleteActivity } = useActivityData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const open = Boolean(anchorEl);

  const time = useMemo(() => format(date, 'h:mm a'), [date]);

  const searchTypeText = useMemo(() => getSearchTypeText(searchType, source), [searchType, source]);
  const finalSearchTypeText = useMemo(() => {
    if (searchTypeText === 'ct') return 'Clinical Trials';
    return searchTypeText[0].toUpperCase() + searchTypeText.slice(1);
  }, [searchTypeText]);

  const sourceInfo = useMemo(() => getSourceInfo(source), [source]);

  const handleDeleteActivity = async () => {
    setIsDeleteLoading(true);
    await deleteActivity({
      type: data?.type,
      activityIds: data?.activityIds ?? [data?.activity_id]
    });
    setIsDeleteLoading(false);
  };

  const is505b2 = checkIs505b2(source);

  const handleClick = (e: any) => {
    e.stopPropagation();

    // redirect to module page if it is a module filtered search
    if (isModule) {
      window.open(moduleRedirectUrl, '_blank');
      return;
    }
    let payload: any = {};
    if (data?.type === 'advancedSearch') {
      payload = {
        search_type: 'advanced',
        search_term: is505b2 ? getCnfQueryfor505b2(data?.searchData) : data?.cnf_query,
        source: is505b2
          ? {
              us: ['505b2', 'sbas']
            }
          : data?.source,
        approval_date: data?.dateFilter ?? {},
        use_synonyms: data?.use_synonyms,
        approvalPathway: is505b2 ? data?.searchData : {}
      };
    } else {
      payload = {
        search_term: data?.term,
        source: data?.source,
        filterSource: 'default',
        filters: data?.filters
      };
    }

    if (
      Object.keys(source).length === 1 &&
      (data?.source?.ct || data?.source?.eu?.includes('euctr'))
    ) {
      payload.view_type = getViewType('ct');
      payload.ct_search_id = data?.id;
    } else {
      const viewType = getViewType(searchTypeText);
      payload.view_type = viewType;
      if (viewType === 'document') {
        payload.document_search_id = data?.id;
      } else if (MODULE_TABS_SOURCE_LIST?.includes(viewType)) {
        payload[`${viewType}_search_id`] = data?.id;
      } else {
        payload.application_search_id = data?.id;
      }
    }
    const encodedPayload = encodeObjectToBase64(payload) ?? '';
    resultsDispatch({
      type: ResultActions.SET_VIEW_TYPE,
      value: ''
    });
    window.open(`/search/${encodedPayload}`, '_blank');
  };

  const hasFilter = useMemo(
    () =>
      (data?.filters && Object.keys(data?.filters).length !== 0) ||
      (searchType !== 'advancedSearch' &&
        data?.dateFilter &&
        Object.keys(data?.dateFilter).length !== 0),
    [data.filters, data.dateFilter]
  );

  const isFavoriteIconVisible = useMemo(() => {
    const projectsList = data?.inProjects ?? [];
    return (data?.isFavorite ?? false) || projectsList.length > 0;
  }, [data?.isFavorite, data?.inProjects]);

  const openLinkIconAndText = useMemo(() => getOpenLinkTextAndIcon(searchType), [searchType]);

  return (
    <Box>
      <ListItemButton
        disableRipple
        sx={styles.listItem}
        onClick={e => {
          handleClick(e);
        }}>
        <Box sx={styles.listItemFlexBox}>
          <Typography sx={styles.timeText}>{time}</Typography>
          <Box sx={styles.quantityText}>
            {quantity > 1 && (
              <Tooltip
                title={
                  <Stack>
                    <Typography>Also searched at:</Typography>
                    <Stack sx={styles.timeContent}>
                      {data?.activityTime.map((timeValue: Date) => (
                        <Typography key={timeValue.toString()}>
                          {format(timeValue, 'h:mm a')}
                        </Typography>
                      ))}
                    </Stack>
                  </Stack>
                }>
                <Typography>{`x${quantity}`}</Typography>
              </Tooltip>
            )}
          </Box>
          {searchType === 'advancedSearch' ? (
            <Typography sx={styles.titleText}>
              {data.cnf_query || is505b2
                ? getAdvanceSearchText(data, source, is505b2)
                : `End date:${data?.dateFilter?.end_date}, Start date:${data?.dateFilter?.start_date}`}
            </Typography>
          ) : (
            <Typography fontStyle={isModule ? 'italic' : 'normal'} sx={styles.titleText}>
              {title}
            </Typography>
          )}
          <Box sx={styles.backgroundText}>
            <Tooltip title={`Page: ${finalSearchTypeText.toUpperCase()}`}>
              <Typography sx={styles.textWithBackground}>{finalSearchTypeText}</Typography>
            </Tooltip>
          </Box>
          <Box sx={styles.sourceBackgroundText}>
            <Tooltip title={`Region: ${sourceInfo.sourceTooltip}`}>
              <Typography sx={styles.sourceTextWithBackground}>{sourceInfo.source}</Typography>
            </Tooltip>
          </Box>
          <Box sx={styles.backgroundText}>
            <Tooltip title={`Source: ${sourceInfo.moduleTooltip}`}>
              <Typography sx={styles.moduleTextWithBackground}>{sourceInfo.module}</Typography>
            </Tooltip>
          </Box>
          <Tooltip
            title={
              hasFilter ? (
                <>
                  Filters Applied:
                  {getFilterText(data?.filters, data?.source)
                    .split('\n')
                    .map(line => (
                      <div key={line}>{line}</div>
                    ))}
                </>
              ) : (
                ''
              )
            }>
            <Box sx={{ ...styles.iconBox, visibility: hasFilter ? 'visible' : 'hidden' }}>
              <FilterEmptyIcon sx={styles.filterIcon} />
            </Box>
          </Tooltip>
          <Box
            sx={{
              ...styles.favoriteIconBox,
              visibility: isFavoriteIconVisible ? 'visible' : 'hidden'
            }}>
            <FavoriteAndProjectActions
              invokedBy={searchType as invokedByType}
              isButton
              isFavorite={data?.isFavorite ?? false}
              inProjects={data?.inProjects ?? []}
              setFavoriteInHook={setFavorite}
              setInProjectInHook={setInProject}
              source=''
              sourceIndex={data?.source_index}
              id={data?.id}
            />
          </Box>
          <IconButton
            size='small'
            onClick={e => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}>
            <MoreVert fontSize='small' sx={styles.threeDotIcon} />
          </IconButton>
        </Box>
      </ListItemButton>
      {showDivider && <Divider variant='middle' sx={styles.divider} />}
      <RoundedMenu
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem
          onClick={e => {
            handleClick(e);
          }}
          sx={cardsActionsMenuStyles.menuItem}>
          <ListItemIcon sx={cardsActionsMenuStyles.listItemIcon}>
            {openLinkIconAndText?.icon && (
              <openLinkIconAndText.icon sx={cardsActionsMenuStyles.svgIcon} />
            )}
          </ListItemIcon>
          <ListItemText sx={cardsActionsMenuStyles.listItemText}>
            {openLinkIconAndText?.title ?? 'Go to Search'}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteConfirmation(true);
          }}
          disabled={isDeleteLoading}
          sx={cardsActionsMenuStyles.menuItem}>
          <ListItemIcon sx={cardsActionsMenuStyles.listItemIcon}>
            {isDeleteLoading ? (
              <CircularProgress
                size={16}
                sx={{ ...cardsActionsMenuStyles.svgIcon, color: 'primary.700' }}
              />
            ) : (
              <TrashIcon sx={cardsActionsMenuStyles.svgIcon} />
            )}
          </ListItemIcon>
          <ListItemText sx={cardsActionsMenuStyles.listItemText}>Remove Activity</ListItemText>
        </MenuItem>
        <FavoriteAndProjectActions
          invokedBy={searchType as invokedByType}
          isButton={false}
          isFavorite={data?.isFavorite ?? false}
          inProjects={data?.inProjects ?? []}
          setFavoriteInHook={setFavorite}
          setInProjectInHook={setInProject}
          source=''
          sourceIndex={data?.source_index}
          id={data?.id}
        />
      </RoundedMenu>
      {openDeleteConfirmation && (
        <TitleDescriptionDialog
          dialogType='activity'
          heading='Confirm Delete'
          title=''
          description=''
          handleSubmit={() => {
            setOpenDeleteConfirmation(false);
            handleDeleteActivity();
          }}
          handleClose={() => setOpenDeleteConfirmation(false)}
          action='delete'
          titleMaxlength={0}
          descriptionMaxLength={0}
        />
      )}
    </Box>
  );
};
export default memo(ActivityListItem);
