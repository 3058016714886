import Skeleton from '@mui/lab/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { getGraphData } from '../../../api/modules/Reports';
import Graphs from '../../Core/components/Graphs';

import Store from '../../../store';
import Actions from '../../../store/actions';

import classes from '../styles/CarouselGraph.style';
import CORE_PAGE_GRAPHS from '../../Core/components/constant';

const GRAPH_TEMPLATES_IDS: any = {
  us: [
    'approval_tracks__radar__submission_type_application_type__us_fda',
    'approval_tracks__line__approval_year__us_fda',
    'patent_expiration__bar__quarter__us-fda--nda',
    'exclusivity_expiration__bar__quarter__us_fda--nda'
  ],
  eu: ['generics_products__pie_chart__yes_no__eu', 'biosimilars_products__pie_chart__yes_no__eu'],
  clinicalTrials: [
    'generics_products__pie_chart__yes_no__eu',
    'biosimilars_products__pie_chart__yes_no__eu'
  ],
  ca: ['patent_expiration__bar__quarter__ca'],
  hpra: ['products__pie_chart__hpra', 'approval_stats__bar_quarter__hpra'],
  hma: ['products__bar_quater__hma', 'approval_stats__bar_quater__hma'],
  'us-devices': ['approval_stats__bar__us-devices'],
  jp: ['approval_stats__bar__jp']
};

const StatGraphs: React.FC<{ source: string }> = ({ source }) => {
  const { state, dispatch }: any = useContext(Store);

  const [graphIndex, setGraphIndex] = useState(0);
  const [GraphsData, setGraphsData] = useState({});
  const [isAutoplay, setisAutoplay] = useState(true);
  const [GraphsParams, setGraphsParams] = useState<any>({});

  const handleNext = () => {
    setGraphIndex((graphIndex + 1) % GRAPH_TEMPLATES_IDS[source || 'us'].length);
  };
  const handlePrev = () => {
    setGraphIndex((graphIndex - 1) % GRAPH_TEMPLATES_IDS[source || 'us'].length);
  };

  const callLandingApi = useCallback(async (graphTemplateId: string) => {
    try {
      const response = await getGraphData(graphTemplateId, {});
      if (
        response &&
        response.data.Success !== '' &&
        Object.keys(response.data.Success.Data).length !== 0
      ) {
        setGraphsData(response.data.Success);
        const styles = CORE_PAGE_GRAPHS.find(report => report.id === graphTemplateId);
        setGraphsParams(styles || {});
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        await dispatch({ type: Actions.SET_MODULE, value: 'core' });

        callLandingApi(GRAPH_TEMPLATES_IDS[source || 'us'][graphIndex]);

        if (state.bot === true) {
          setisAutoplay(false);
        } else {
          setisAutoplay(true);
        }
      } catch (e) {
        console.error(e);
      }
    }
    getData();
  }, [dispatch, callLandingApi, graphIndex, source]);

  return (
    <div id='homeInsights'>
      <Box display='flex' height='8vh' justifyContent='space-between'>
        <Typography sx={classes.insightsText}>Insights</Typography>
      </Box>

      <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Carousel
          next={() => handleNext()}
          prev={() => handlePrev()}
          autoPlay={isAutoplay}
          indicators
          interval={20000}>
          {Object.keys(GraphsData).length !== 0 ? (
            <Graphs
              data={GraphsData}
              style={'graphStyle' in GraphsParams ? GraphsParams.graphStyle : {}}
            />
          ) : (
            <Box>
              <div>
                <Skeleton variant='rectangular' width='inherit' height='54.5vh' />
              </div>
            </Box>
          )}
        </Carousel>
      </Box>
    </div>
  );
};

export default React.memo(StatGraphs);
