import titleCase from '../../../utils/titleCase';

export const CSV_FORMAT_MAPPING: { [key: string]: string } = {
  time_frame: 'Time Frame',
  description: 'Description',
  measure: 'Measure',
  outcome_type: 'Outcome Type',
  designation: 'Designation',
  location: 'Location',
  name: 'Name',
  state_code: 'State Code',
  state_name: 'State Name',
  trade_name: 'Trade Name',
  address: 'Address',
  city: 'City',
  country: 'Country',
  email: 'Email',
  first_name: 'First Name',
  last_name: 'Last Name',
  org_name: 'Organization Name',
  phone_number: 'Phone Number',
  state: 'State',
  animal_specie_desc: 'Animal Species',
  challenge_agent: 'Challenge Agent',
  challenge_agent_desc: 'Challenge Agent Category',
  biomarker_category_desc: 'Biomarker Category',
  biomarker_type_desc: 'Biomarker Type',
  disease_or_condition_desc: 'Disease or Condition',
  measure_of_desc: 'Measure of',
  min_age: 'Minimum Age',
  product_type_desc: 'Product Type',
  therapeutic_area_desc: 'Therapeutic Area',
  outcome_of_interest: 'Outcome of Interest',
  age_group_desc: 'Age Group',
  coa_type_desc: 'COA Type',
  concept_of_interest: 'Concept of Interest',
  istand_type_desc: 'ISTAND Type',
  type: 'Type',
  timeframe: 'Timeframe',
  title: 'Title',
  approval_date: 'Approval Date',
  approval_year: 'Approval year',
  submission: 'Submission',
  indications_and_usage_text: 'Indications',
  term: 'Term',
  max_age: 'Maximum Age'
};

export const MAX_CSV_CELL_LENGTH = 32756;

export const sortObject = (data: any) => {
  const keys = Object.keys(data);
  keys.sort((a, b) => b.localeCompare(a));
  const sortedObj: any = {};
  keys.forEach(key => {
    sortedObj[key] = data[key];
  });

  return sortedObj;
};

// This function convet an object to string to display in the csv file
export const getFormatDataForCSV = (params: Array<any>, ignoreField = ['']) => {
  const result = params
    ?.map((obj: any) => {
      if (obj) {
        return Object.entries(sortObject(obj))
          .filter(([key]: string | any) => !ignoreField.includes(key))
          .map(([key, value]: string | any) => {
            if (typeof value === 'string') {
              return `${CSV_FORMAT_MAPPING[key as string]}: ${titleCase(value) || 'Unknown'}`;
            }
            return value;
          })
          .join('; ');
      }
      return '';
    })
    .join('\n');

  return (
    result.substring(0, Math.min(result.length, MAX_CSV_CELL_LENGTH)) +
    (result.length > MAX_CSV_CELL_LENGTH ? `...` : '')
  );
};

export default getFormatDataForCSV;
