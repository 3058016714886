const MAPPINGKEY: any = {
  state_code: 'State Code',
  state_name: 'State Name',
  trade_name: 'Trade Name',
  approval_date: 'Approval Date',
  submission_num: 'Submission Number',
  submission_type: 'Submission Type',
  submission: 'Submission',
  approval_year: 'Approval Year',
  min_age: 'Minimum Age',
  max_age: 'Maximum Age'
};

export const IGNORE_FIELDS = [
  'indications_and_usage_text',
  'submission',
  'approval_year',
  's3_url',
  's3_bucket',
  's3_path'
];
export default MAPPINGKEY;
