/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';
import {
  CANADA_COLUMNS,
  CT_ADVERSE_EVENTS,
  CT_COLUMNS,
  DEVICES_COLUMNS,
  EU_COLUMNS,
  EU_CT_ADVERSE_EVENTS,
  EU_CT_COLUMNS,
  HMA_COLUMNS,
  HPRA_COLUMNS,
  JP_COLUMNS,
  US_COLUMNS,
  AUSTRALIA_COLUMNS,
  MHRA_COLUMNS
} from './columnDefinitions';
import {
  CANADA_FILTERS,
  CT_FILTERS,
  DEVICES_FILTERS,
  EU_FILTERS,
  HMA_FILTERS,
  HPRA_FILTERS,
  JP_FILTERS,
  US_FILTERS,
  AUSTRALIA_FILTERS,
  MHRA_FILTERS
} from './filtersList';
import { CardMetadata } from '../types/resultsConst';

export const CARD_METADATA: any = {
  us: {
    title: 'active_ingredients',
    number: 'application_number',
    subtitle: 'trade_name',
    colorScheme: 'application_type',
    metadata1: 'sponsor_name',
    metadata2: 'formulation',
    metadata3: 'route',
    metadata4: 'approval_pathway',
    dateValue: 'approval_year'
  } as CardMetadata,
  eu: {
    title: 'active_ingredients',
    number: 'product_number',
    subtitle: 'trade_name',
    colorScheme: 'center',
    metadata1: 'sponsor_name',
    metadata2: 'formulation',
    metadata3: 'route',
    dateValue: 'approval_year'
  } as CardMetadata,
  hpra: {
    title: 'active_ingredients',
    number: 'product_number',
    subtitle: 'trade_name',
    colorScheme: 'source_index',
    metadata1: 'sponsor_name',
    metadata2: 'formulation',
    metadata3: 'route',
    metadata4: 'licence_status',
    dateValue: 'approval_year'
  } as CardMetadata,
  hma: {
    title: 'active_ingredients',
    number: 'product_number',
    subtitle: 'trade_name',
    metadata1: 'sponsor_name',
    metadata2: 'formulation',
    dateValue: 'approval_year'
  } as CardMetadata,
  'us-devices': {
    title: 'generic_name',
    number: 'application_number',
    subtitle: 'trade_name',
    colorScheme: 'approval_pathway',
    metadata1: 'sponsor_name',
    metadata2: 'product_code',
    metadata3: 'device_class',
    metadata4: 'approval_pathway',
    dateValue: 'approval_year'
  } as CardMetadata,
  ct: {
    title: 'interventions',
    number: 'nct_id',
    subtitle: 'official_title',
    colorScheme: 'overall_status_formated',
    metadata1: 'sponsors',
    metadata2: 'conditions',
    metadata3: 'phase',
    metadata4: 'arms_enrollment',
    dateValue: 'primary_completion'
  } as CardMetadata,
  ca: {
    title: 'active_ingredients',
    number: 'vin',
    subtitle: 'trade_name',
    colorScheme: 'application_type',
    metadata1: 'sponsor_name',
    metadata2: 'formulation',
    metadata3: 'route',
    metadata4: 'schedule',
    dateValue: 'approval_year'
  },
  ct_adverse_events: {
    number: 'nct_id',
    colorScheme: 'overall_status_formated'
  } as CardMetadata,
  jp: {
    title: 'active_ingredients',
    titleOriginal: 'active_ingredients_jp',
    number: 'yj_code',
    subtitle: 'trade_name',
    subtitleOriginal: 'trade_name_jp',
    colorScheme: 'application_type',
    metadata1: 'sponsor_name',
    metadata1Original: 'sponsor_name_jp',
    metadata2: 'formulation',
    metadata3: 'route',
    dateValue: 'sales_year'
  } as CardMetadata,
  au: {
    title: 'active_ingredients',
    number: 'vin',
    subtitle: 'product_name',
    colorScheme: 'application_type',
    metadata1: 'sponsor_name',
    metadata2: 'formulation',
    metadata3: 'route',
    dateValue: 'approval_year'
  } as CardMetadata,
  mhra: {
    title: 'active_ingredients',
    number: 'product_number',
    subtitle: 'trade_name',
    colorScheme: 'source_index',
    metadata1: 'sponsor_name',
    metadata2: 'formulation',
    metadata3: 'route',
    dateValue: 'approval_year'
  } as CardMetadata
};

export const COLOR_SCHEME_NUMBER: { [key: string]: string } = {
  nda: 'redNumber',
  anda: 'redNumber',
  bla: 'purpleNumber',
  eua: 'yellowNumber',
  ema: 'purpleNumber',
  ec: 'purpleNumber',
  'ema-who': 'yellowNumber',
  hpra: 'redNumber',
  '510(k)': 'redNumber',
  pma: 'purpleNumber',
  's-564': 'yellowNumber',
  recruiting: 'yellowNumber',
  completed: 'greenNumber',
  terminated: 'redNumber',
  other: 'grayNumber',
  denovo: 'orchidNumber',
  canada: 'redNumber',
  approved_for_marketing: 'orchidNumber',
  hma: 'purpleNumber',
  jp: 'purpleNumber',
  mhra: 'purpleNumber'
};

export const COLOR_SCHEME_TIMELINE: { [key: string]: string } = {
  recruiting: '#f39c12',
  completed: '#3AB09E',
  terminated: '#e74c3c',
  other: '#707070',
  approved_for_marketing: '#DA70D6'
};

export const COLOR_SCHEME_TEXT: { [key: string]: string } = {
  nda: 'redText',
  bla: 'purpleText',
  eua: 'yellowText',
  ema: 'purpleText',
  ec: 'purpleText',
  'ema-who': 'yellowText',
  hpra: 'redText',
  '510(k)': 'redText',
  pma: 'purpleText',
  's-564': 'yellowText',
  recruiting: 'yellowText',
  completed: 'greenText',
  terminated: 'redText',
  other: 'grayText',
  denovo: 'orchidText',
  canada: 'redText',
  approved_for_marketing: 'orchidText',
  hma: 'purpleText',
  jp: 'purpleText',
  mhra: 'purpleText'
};

export const DATAGRID_UNIQUE_FIELD: { [key: string]: string } = {
  us: 'application_number',
  eu: 'product_number',
  'us-devices': 'application_number',
  ct: 'nct_id',
  ca: 'vin',
  ct_adverse_events: 'unique_id',
  hpra: 'product_number',
  hma: 'product_number',
  jp: 'yj_code',
  eua: 'application_number',
  au: 'vin',
  mhra: 'vin'
};

export const DATAGRID_COLUMN_MAPPING: any = {
  us: US_COLUMNS as GridColDef[],
  eu: EU_COLUMNS as GridColDef[],
  'us-devices': DEVICES_COLUMNS as GridColDef[],
  usnlm: CT_COLUMNS as GridColDef[],
  euctr: EU_CT_COLUMNS as GridColDef[],
  ca: CANADA_COLUMNS as GridColDef[],
  ct_adverse_events: CT_ADVERSE_EVENTS as GridColDef[],
  eu_ct_adverse_event: EU_CT_ADVERSE_EVENTS,
  hpra: HPRA_COLUMNS,
  hma: HMA_COLUMNS,
  jp: JP_COLUMNS as GridColDef[],
  au: AUSTRALIA_COLUMNS as GridColDef[],
  mhra: MHRA_COLUMNS as GridColDef[]
};

export const YEAR_GROUPS: Array<string> = [
  '1930-1940',
  '1941-1950',
  '1951-1960',
  '1961-1970',
  '1971-1980',
  '1981-1990',
  '1991-2000',
  '2001-2010',
  '2011-2020',
  '2021-2030'
];

export const FILTER_CATEGORY_LIST: { [key: string]: Array<any> } = {
  us: US_FILTERS,
  eu: EU_FILTERS,
  'us-devices': DEVICES_FILTERS,
  ct: CT_FILTERS,
  ca: CANADA_FILTERS,
  hpra: HPRA_FILTERS,
  hma: HMA_FILTERS,
  jp: JP_FILTERS,
  au: AUSTRALIA_FILTERS,
  mhra: MHRA_FILTERS
};

export const AVATAR_INITIALS: { [key: string]: Array<any> } = {
  us: [
    { label: 'AA', field: 'accelerated_approval', hoverText: 'Accelerated Approval' },
    {
      label: 'BT',
      field: 'breakthrough_status',
      hoverText: 'Breakthrough Status'
    },
    { label: 'FT', field: 'fasttrack', hoverText: 'Fast Track' },
    {
      label: 'OR',
      field: 'orphan_status',
      hoverText: 'Orphan Status'
    }
  ]
};

// Function to create filter label section objects
function createFilterLabelSection(key: string, value: string) {
  return {
    id: 'filter_label_sections',
    value,
    key
  };
}

// Helper function to create section array
function createSectionArray(regionSections: { [key: string]: string }) {
  return Object.entries(regionSections).map(([key, value]) => createFilterLabelSection(key, value));
}

// Common elements for all regions
const COMMON_SECTIONS_US_CA = {
  indications_and_usage: 'Indications and Usage',
  contraindications: 'Contraindications',
  dosage_and_administration: 'Dosage and Administration',
  warnings_and_precautions: 'Warnings and Precautions',
  adverse_reactions: 'Adverse Reactions',
  drug_interactions: 'Drug Interactions',
  clinical_pharmacology: 'Clinical Pharmacology'
};

const COMMON_SECTIONS_EU_HPRA = {
  name_of_the_medicinal_product: 'Name of the Medicinal Product',
  qualitative_and_quantitative_composition: 'Qualitative and Quantitative Composition',
  pharmaceutical_form: 'Pharmaceutical Form',
  indications_and_usage: 'Therapeutic Indications',
  posology_and_method_of_administration: 'Posology and Method of Administration',
  contraindications: 'Contraindications',
  special_warnings_and_precautions_for_use: 'Special Warnings and Precautions for Use',
  interactions_with_other_medicinal_products_and_other_forms_of_interaction:
    'Interactions with Other Medicinal Products and Other Forms of Interaction',
  fertility_pregnancy_and_lactation: 'Fertility, Pregnancy and Lactation',
  effects_on_ability_to_drive_and_use_machines: 'Effects on Ability to Drive and Use Machines',
  undesirable_effects: 'Undesirable Effects',
  overdose: 'Overdose',
  pharmacodynamic_properties: 'Pharmacodynamic Properties',
  pharmacokinetic_properties: 'Pharmacokinetic Properties',
  preclinical_safety_data: 'Preclinical Safety Data',
  pharmaceutical_particulars: 'Pharmaceutical Particulars',
  marketing_authorisation_holder: 'Marketing Authorisation Holder',
  marketing_authorisation_number: 'Marketing Authorisation Number(s)',
  date_of_first_authorisation_renewal_of_the_authorisation:
    'Date of First Authorisation/Renewal of the Authorisation',
  date_of_revision_of_the_text: 'Date of Revision of the Text'
};

export const ALL_SECTIONS_FILTER_OPTIONS: { [key: string]: Array<any> } = {
  us: [
    ...createSectionArray({
      ...COMMON_SECTIONS_US_CA,
      dosage_forms_and_strengths: 'Dosage Forms and Strengths',
      use_in_specific_populations: 'Use in Specific Populations',
      drug_abuse_and_dependence: 'Drug Abuse and Dependence',
      overdosage: 'Overdose',
      non_clinical_toxicology: 'Non-clinical Toxicology',
      clinical_studies: 'Clinical Studies',
      how_supplied_storage_and_handling: 'How Supplied Storage and Handling',
      patient_counseling_information: 'Patient Counseling Information',
      description: 'Description',
      references: 'References'
    })
  ],
  ca: [
    ...createSectionArray({
      ...COMMON_SECTIONS_US_CA,
      'Serious Warnings and Precautions Box': 'Serious Warnings and Precautions Box',
      overdosage: 'Overdosage',
      'Dosage Forms Strengths Somposition and Packaging':
        'Dosage Forms Strengths Somposition and Packaging',
      'Storage Stability and Disposal': 'Storage Stability and Disposal',
      'Special Handling Instructions': 'Special Handling Instructions',
      'Pharmaceutical Information': 'Pharmaceutical Information',
      'Clinical Trials': 'Clinical Trials',
      Microbiology: 'Microbiology',
      'Nonclinical Toxicology': 'Nonclinical Toxicology',
      'Supporting Product Monographs': 'Supporting Product Monographs',
      'Detailed Pharmacology': 'Detailed Pharmacology',
      'Patient Medication Information': 'Patient Medication Information'
    })
  ],
  eu: [
    ...createSectionArray({
      ...COMMON_SECTIONS_EU_HPRA
    })
  ],
  ie: [
    ...createSectionArray({
      ...COMMON_SECTIONS_EU_HPRA
    })
  ],
  jp: [
    ...createSectionArray({
      warnings: 'Warnings',
      contraindications: 'Contraindications',
      composition_and_product_description: 'Composition and Product Description',
      indications: 'Indications',
      precautions_concerning_indications: 'Precautions Concerning Indications',
      dosage_and_administration: 'Dosage and Administration',
      precautions_concerning_dosage_and_administration:
        'Precautions Concerning Dosage and Administration',
      important_precautions: 'Important Precautions',
      precautions_concerning_patients_with_specific_backgrounds:
        'Precautions Concerning Patients With Specific Backgrounds',
      interactions: 'Interactions',
      adverse_reactions: 'Adverse Reactions',
      influence_on_laboratory_tests: 'Influence on Laboratory Tests',
      overdosage: 'Overdosage',
      precautions_concerning_use: 'Precautions Concerning Use',
      other_precautions: 'Other Precautions',
      pharmacokinetic_properties: 'Pharmacokinetic Properties',
      clinical_studies: 'Clinical Studies',
      pharmacology: 'Pharmacology',
      physicomechanical_properties: 'Physicomechanical Properties',
      precautions_for_handling: 'Precautions for Handling',
      approval_conditions: 'Approval Conditions',
      packaging: 'Packaging',
      references: 'References',
      reference_request_and_contact_information: 'Reference Request and Contact Information',
      precaution_concerning_health_insurance_benefits:
        'Precaution Concerning Health Insurance Benefits',
      marketing_authorization_holder_etc: 'Marketing Authorization Holder'
    })
  ],
  au: [
    ...createSectionArray({
      name_of_the_medicine: 'Name of the Medicine',
      qualitative_and_quantitative_composition: 'Qualitative and Quantitative Composition',
      pharmaceutical_form: 'Pharmaceutical Form',
      therapeutic_indications: 'Therapeutic Indications',
      dosage_and_method_of_adminstration: 'Dosage and Method of Administration',
      contraindications: 'Contraindications',
      special_warning_and_precautions_for_use: 'Special Warnings and Precautions for Use',
      interactions_other_medicines: 'Interactions with Other Medicines',
      fertility_pregnancy_lactation: 'Fertility, Pregnancy, and Lactation',
      effects_ability_drive_use_machines: 'Effects on Ability to Drive and Use Machines',
      adverse_effects: 'Adverse Effects',
      overdose: 'Overdose',
      pharmacodynamic_properties: 'Pharmacodynamic Properties',
      pharmacokinetic_properties: 'Pharmacokinetic Properties',
      preclinical_safety_data: 'Preclinical Safety Data',
      pharmaceutical_particulars: 'Pharmaceutical Particulars',
      medicine_schedule: 'Medicine Schedule',
      sponsor: 'Sponsor',
      date_first_approval: 'Date of First Approval',
      date_revision: 'Date of Revision'
    })
  ],
  mhra: [
    ...createSectionArray({
      ...COMMON_SECTIONS_EU_HPRA,
      dosimetry: 'Dosimetry',
      instructions_for_preparation_of_radiopharmaceuticals:
        'Instructions for Preparation of Radiopharmaceuticals'
    })
  ],
  hma: [
    ...createSectionArray({
      ...COMMON_SECTIONS_EU_HPRA,
      dosimetry: 'Dosimetry',
      instructions_for_preparation_of_radiopharmaceuticals:
        'Instructions for Preparation of Radiopharmaceuticals'
    })
  ]
};

export const SORTING_OPTIONS: any = {
  us: [
    {
      id: 'default',
      label: 'Active Ingredients: A to Z',
      fieldName: 'active_ingredients',
      sortType: 'asc'
    },
    {
      id: 'activeIngredientDesc',
      label: 'Active Ingredients: Z to A',
      fieldName: 'active_ingredients',
      sortType: 'desc'
    },
    {
      id: 'approvalYearDesc',
      label: 'Approval Year: New ',
      fieldName: 'approval_year',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Year: Oldest ',
      fieldName: 'approval_year',
      sortType: 'asc'
    }
  ],
  eu: [
    {
      id: 'default',
      label: 'Active Ingredients: A to Z',
      fieldName: 'active_ingredients',
      sortType: 'asc'
    },
    {
      id: 'activeIngredientDesc',
      label: 'Active Ingredients: Z to A',
      fieldName: 'active_ingredients',
      sortType: 'desc'
    },
    {
      id: 'approvalYearDesc',
      label: 'Approval Year: New ',
      fieldName: 'approval_year',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Year: Oldest ',
      fieldName: 'approval_year',
      sortType: 'asc'
    }
  ],
  'us-devices': [
    {
      id: 'default',
      label: 'Generic Name: A to Z',
      fieldName: 'generic_name',
      sortType: 'asc'
    },
    {
      id: 'genericNameDesc',
      label: 'Generic Name: Z to A',
      fieldName: 'generic_name',
      sortType: 'desc'
    },
    {
      id: 'approvalYearDesc',
      label: 'Approval Year: New ',
      fieldName: 'approval_year',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Year: Oldest ',
      fieldName: 'approval_year',
      sortType: 'asc'
    }
  ],
  ct: [
    {
      id: 'default',
      label: 'Interventions: A to Z',
      fieldName: 'interventions',
      sortType: 'asc'
    },
    {
      id: 'interventionsDesc',
      label: 'Interventions: Z to A',
      fieldName: 'interventions',
      sortType: 'desc'
    },
    {
      id: 'completionYearDesc',
      label: 'Completion Year: New ',
      fieldName: 'primary_completion',
      sortType: 'desc'
    },
    {
      id: 'completionYearAsc',
      label: 'Completion Year: Oldest ',
      fieldName: 'primary_completion',
      sortType: 'asc'
    }
  ],
  ca: [
    {
      id: 'default',
      label: 'Active Ingredients: A to Z',
      fieldName: 'active_ingredients',
      sortType: 'asc'
    },
    {
      id: 'activeIngredientDesc',
      label: 'Active Ingredients: Z to A',
      fieldName: 'active_ingredients',
      sortType: 'desc'
    },
    {
      id: 'approvalYearDesc',
      label: 'Approval Year: New ',
      fieldName: 'approval_year',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Year: Oldest ',
      fieldName: 'approval_year',
      sortType: 'asc'
    }
  ],
  hpra: [
    {
      id: 'default',
      label: 'Active Ingredients: A to Z',
      fieldName: 'active_ingredients',
      sortType: 'asc'
    },
    {
      id: 'activeIngredientDesc',
      label: 'Active Ingredients: Z to A',
      fieldName: 'active_ingredients',
      sortType: 'desc'
    },
    {
      id: 'approvalYearDesc',
      label: 'Approval Year: New ',
      fieldName: 'approval_year',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Year: Oldest ',
      fieldName: 'approval_year',
      sortType: 'asc'
    }
  ],
  hma: [
    {
      id: 'default',
      label: 'Active Ingredients: A to Z',
      fieldName: 'active_ingredients',
      sortType: 'asc'
    },
    {
      id: 'activeIngredientDesc',
      label: 'Active Ingredients: Z to A',
      fieldName: 'active_ingredients',
      sortType: 'desc'
    },
    {
      id: 'approvalYearDesc',
      label: 'Approval Year: New ',
      fieldName: 'approval_year',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Year: Oldest ',
      fieldName: 'approval_year',
      sortType: 'asc'
    }
  ],
  jp: [
    {
      id: 'default',
      label: 'Active Ingredients: A to Z',
      fieldName: 'active_ingredients',
      sortType: 'asc'
    },
    {
      id: 'activeIngredientDesc',
      label: 'Active Ingredients: Z to A',
      fieldName: 'active_ingredients',
      sortType: 'desc'
    },
    {
      id: 'latestSubmissionYearDesc',
      label: 'Latest Submission Year: New ',
      fieldName: 'latest_submission_year',
      sortType: 'desc'
    },
    {
      id: 'latestSubmissionYearAsc',
      label: 'Latest Submission Year: Oldest ',
      fieldName: 'latest_submission_year',
      sortType: 'asc'
    }
  ],
  mhra: [
    {
      id: 'default',
      label: 'Active Ingredients: A to Z',
      fieldName: 'active_ingredients',
      sortType: 'asc'
    },
    {
      id: 'activeIngredientDesc',
      label: 'Active Ingredients: Z to A',
      fieldName: 'active_ingredients',
      sortType: 'desc'
    },
    {
      id: 'approvalYearDesc',
      label: 'Approval Year: New ',
      fieldName: 'approval_year',
      sortType: 'desc'
    },
    {
      id: 'approvalYearAsc',
      label: 'Approval Year: Oldest ',
      fieldName: 'approval_year',
      sortType: 'asc'
    }
  ]
};

export const CTTIMELINE_CHANGES_GRAPH_SORTING_OPTIONS: any = [
  {
    id: 'default',
    label: 'Start Date: New ',
    fieldName: 'start_date',
    sortType: 'asc'
  },
  {
    id: 'startDateAsc',
    label: 'Start Date: Oldest ',
    fieldName: 'start_date',
    sortType: 'desc'
  },
  {
    id: 'firstSubmittedDateDesc',
    label: 'First Submitted Date: New ',
    fieldName: 'study_first_submitted_date',
    sortType: 'asc'
  },
  {
    id: 'firstSubmittedDateAsc',
    label: 'First Submitted Date: Oldest ',
    fieldName: 'study_first_submitted_date',
    sortType: 'desc'
  },
  {
    id: 'primaryCompletionDateDesc',
    label: 'Primary Completion Date: New ',
    fieldName: 'primary_completion_date',
    sortType: 'asc'
  },
  {
    id: 'primaryCompletionDateAsc',
    label: 'Primary Completion Date: Oldest ',
    fieldName: 'primary_completion_date',
    sortType: 'desc'
  }
];

export const APPROVAL_STATUS_TAGS: { [key: string]: any } = {
  us: [
    {
      label: 'Accelerated Approval',
      initial: 'AA',
      hoverText: 'accelerated_approval_text',
      fieldName: 'accelerated_approval',
      AA_status: 'accelerated_approval_status'
    },
    {
      label: 'Breakthrough Status',
      initial: 'BT',
      hoverText: 'breakthrough_status_text',
      fieldName: 'breakthrough_status'
    },
    { label: 'Fast Track', initial: 'FT', hoverText: 'fast_track_text', fieldName: 'fast_track' },
    {
      label: 'Orphan Status',
      initial: 'OR',
      hoverText: 'orphan_status_text',
      fieldName: 'orphan_status'
    },
    {
      label: 'Advisory Committee',
      initial: 'AC',
      hoverText: 'advisory_committee_date',
      fieldName: 'advisory_committee',
      showOnCard: false
    },
    {
      label: 'Biosimilar',
      initial: 'BS',
      hoverText: 'biosimilar_text',
      fieldName: 'biosimilar',
      showOnCard: false
    },
    {
      label: 'REMS',
      initial: 'RM',
      hoverText: 'rems_text',
      fieldName: 'rems_active_flag',
      showOnCard: false
    },
    {
      label: 'Boxed Warning',
      initial: 'BW',
      hoverText: 'application_boxed_warning_text',
      fieldName: 'application_boxed_warning_flag',
      showOnCard: false
    },
    {
      label: 'Companion Diagnostic Devices',
      initial: 'CD',
      hoverText: 'companion_diagnostic_devices_text',
      fieldName: 'companion_diagnostic_devices_status',
      showOnCard: false
    },
    {
      label: 'Review Standard',
      initial: 'S',
      hoverText: 'review_standard_text',
      fieldName: 'review_standard',
      showOnCard: false,
      showOnlyTrue: true
    },
    {
      label: 'Review Priority',
      initial: 'P',
      hoverText: 'review_priority_text',
      fieldName: 'review_priority',
      showOnlyTrue: true,
      showOnCard: false
    },
    {
      label: 'Review Other',
      initial: 'O',
      hoverText: 'review_other_text',
      fieldName: 'review_other',
      showOnlyTrue: true,
      showOnCard: false
    },
    {
      label: 'Combination Product',
      initial: 'CP',
      hoverText: 'combination_product_flag',
      fieldName: 'combination_product_flag',
      showOnCard: false
    }
  ],
  eu: [
    {
      label: 'Accelerated Assessment',
      initial: 'AA',
      hoverText: 'accelerated_assessment_text',
      fieldName: 'accelerated_assessment'
    },
    {
      label: 'Conditional Approval',
      initial: 'CA',
      hoverText: 'conditional_approval_text',
      fieldName: 'conditional_approval'
    },
    {
      label: 'Exceptional Circumstances',
      initial: 'EC',
      hoverText: 'exceptional_circumstances_text',
      fieldName: 'exceptional_circumstances'
    },
    {
      label: 'Orphan Medicine',
      initial: 'OR',
      hoverText: 'orphan_medicine_text',
      fieldName: 'orphan_medicine',
      orphanMedicineStatus: 'orphan_medicine_status'
    },
    {
      label: 'Advanced Therapy',
      initial: 'AT',
      hoverText: 'advanced_therapy_text',
      fieldName: 'advanced_therapy',
      showOnCard: false
    },
    {
      label: 'Biosimilar',
      initial: 'BS',
      hoverText: 'biosimilar_text',
      fieldName: 'biosimilar',
      showOnCard: false
    },
    {
      label: 'Combination Product',
      initial: 'CP',
      hoverText: 'combination_product_flag',
      fieldName: 'combination_product_flag',
      showOnCard: false
    }
    // disabled for now
    // {
    //   label: 'Scientific Advice',
    //   initial: 'SA',
    //   hoverText: 'scientific_advice_text',
    //   fieldName: 'scientific_advice',
    //   showOnCard: false
    // },
    // {
    //   label: 'New Active Substance',
    //   initial: 'AS',
    //   hoverText: 'new_active_substance_text',
    //   fieldName: 'new_active_substance',
    //   showOnCard: false
    // }
  ],
  'us-devices': [
    {
      label: 'Combination Product',
      initial: 'CO',
      hoverText: 'combination_product_text',
      fieldName: 'combination_product'
    },
    {
      label: 'Expedited Review',
      initial: 'ER',
      hoverText: 'expedited_review_flag_text',
      fieldName: 'expedited_review_flag'
    },
    {
      label: 'GMP Exemption',
      initial: 'GM',
      hoverText: 'gmp_exempt_flag_text',
      fieldName: 'gmp_exempt_flag'
    },
    {
      label: 'Implant Flag',
      initial: 'IM',
      hoverText: 'implant_flag_text',
      fieldName: 'implant_flag'
    },
    {
      label: 'Life Sustaining support',
      initial: 'LS',
      hoverText: 'life_sustain_support_flag_text',
      fieldName: 'life_sustain_support_flag'
    },
    {
      label: 'Third Party',
      initial: 'TP',
      hoverText: 'third_party_flag_text',
      fieldName: 'third_party_flag'
    },
    {
      label: 'Companion Diagnostic Devices',
      initial: 'CD',
      hoverText: 'companion_diagnostic_devices_text',
      fieldName: 'companion_diagnostic_devices_status',
      showOnCard: false
    }
  ],
  ct: [
    {
      label: 'Healthy Volunteers',
      initial: 'HV',
      hoverText: 'healthy_volunteers',
      fieldName: 'healthy_volunteers_flag'
    },
    {
      label: 'Expanded Access',
      initial: 'EA',
      hoverText: 'has_expanded_access',
      fieldName: 'has_expanded_access_flag'
    },
    {
      label: 'Subject Masked',
      initial: 'SU',
      hoverText: 'subject_masked',
      fieldName: 'subject_masked_flag'
    },
    {
      label: 'Investigator Masked',
      initial: 'IN',
      hoverText: 'investigator_masked',
      fieldName: 'investigator_masked_flag'
    }
  ],
  ct_masking: [
    {
      label: 'Subject Masked',
      initial: 'SU',
      hoverText: 'subject_masked',
      fieldName: 'subject_masked_flag'
    },
    {
      label: 'Investigator Masked',
      initial: 'IN',
      hoverText: 'investigator_masked',
      fieldName: 'investigator_masked_flag'
    },
    {
      label: 'Caregiver Masked',
      initial: 'CA',
      hoverText: 'caregiver_masked',
      fieldName: 'caregiver_masked_flag'
    },
    {
      label: 'Outcome Assessor Masked',
      initial: 'OA',
      hoverText: 'outcomes_assessor_masked',
      fieldName: 'outcomes_assessor_masked_flag'
    }
  ],
  ct_other: [
    {
      label: 'Healthy Volunteers',
      initial: 'HV',
      hoverText: 'healthy_volunteers',
      fieldName: 'healthy_volunteers_flag'
    },
    {
      label: 'Expanded Access',
      initial: 'EA',
      hoverText: 'has_expanded_access',
      fieldName: 'has_expanded_access_flag'
    }
  ],
  ca: [
    {
      label: 'NOC with Condition',
      initial: 'NC',
      hoverText: 'noc_w_condition_text',
      fieldName: 'noc_w_condition_flag'
    },
    {
      label: 'Priority Review',
      initial: 'PR',
      hoverText: 'priority_review_text',
      fieldName: 'priority_review_flag'
    }
  ],
  hpra: [
    {
      label: 'Supply Status',
      initial: 'SU',
      hoverText: 'supply_status',
      fieldName: 'supply_status'
    },
    {
      label: 'Advertising Status',
      initial: 'AS',
      hoverText: 'advertising_status',
      fieldName: 'advertising_status',
      AA_status: 'advertising_status'
    },
    {
      label: 'Legal Status',
      initial: 'LE',
      hoverText: 'legal_status',
      fieldName: 'legal_status'
    }
  ],
  hma: [],
  jp: [],
  au: [],
  mhra: [
    {
      label: 'Legal Status',
      initial: 'LE',
      hoverText: 'legal_status',
      fieldName: 'legal_status'
    },
    {
      label: 'Drug Type',
      initial: 'DT',
      hoverText: 'drug_type',
      fieldName: 'drug_type'
    }
  ]
};

export const APPROVAL_STATUS_TAGS_LOOKUP: { [key: string]: any } = {
  subject_masked_flag: 'ct_masking',
  has_expanded_access_flag: 'ct_other'
};

export const APPROVAL_STATUS_TAGS_LIST_VIEW: { [key: string]: any } = {
  us: [
    {
      label: 'Accelerated Approval',
      initial: 'AA',
      hoverText: 'accelerated_approval_text',
      fieldName: 'accelerated_approval',
      AA_status: 'accelerated_approval_status'
    },
    {
      label: 'Breakthrough Status',
      initial: 'BT',
      hoverText: 'breakthrough_status_text',
      fieldName: 'breakthrough_status'
    },
    { label: 'Fast Track', initial: 'FT', hoverText: 'fast_track_text', fieldName: 'fast_track' },
    {
      label: 'Orphan Status',
      initial: 'OR',
      hoverText: 'orphan_status_text',
      fieldName: 'orphan_status'
    },
    {
      label: 'Advisory Committee',
      initial: 'AC',
      hoverText: 'advisory_committee_date',
      fieldName: 'advisory_committee'
    },
    {
      label: 'Biosimilar',
      initial: 'BS',
      hoverText: 'biosimilar_text',
      fieldName: 'biosimilar'
    },
    {
      label: 'REMS',
      initial: 'RM',
      hoverText: 'rems_text',
      fieldName: 'rems_active_flag'
    },
    {
      label: 'Boxed Warning',
      initial: 'BW',
      hoverText: 'application_boxed_warning_text',
      fieldName: 'application_boxed_warning_flag'
    },
    {
      label: 'Companion Diagnostic Devices',
      initial: 'CD',
      hoverText: 'companion_diagnostic_devices_text',
      fieldName: 'companion_diagnostic_devices_status'
    },
    {
      label: 'Review Standard',
      initial: 'S',
      hoverText: 'review_standard_text',
      fieldName: 'review_standard',
      showOnlyTrue: true
    },
    {
      label: 'Review Priority',
      initial: 'P',
      hoverText: 'review_priority_text',
      fieldName: 'review_priority',
      showOnlyTrue: true
    },
    {
      label: 'Review Other',
      initial: 'O',
      hoverText: 'review_other_text',
      fieldName: 'review_other',
      showOnlyTrue: true
    }
  ],
  eu: [
    {
      label: 'Accelerated Assessment',
      initial: 'AA',
      hoverText: 'accelerated_assessment_text',
      fieldName: 'accelerated_assessment'
    },
    {
      label: 'Conditional Approval',
      initial: 'CA',
      hoverText: 'conditional_approval_text',
      fieldName: 'conditional_approval'
    },
    {
      label: 'Exceptional Circumstances',
      initial: 'EC',
      hoverText: 'exceptional_circumstances_text',
      fieldName: 'exceptional_circumstances'
    },
    {
      label: 'Orphan Medicine',
      initial: 'OR',
      hoverText: 'orphan_medicine_text',
      fieldName: 'orphan_medicine'
    },
    {
      label: 'Advanced Therapy',
      initial: 'AT',
      hoverText: 'advanced_therapy_text',
      fieldName: 'advanced_therapy'
    },
    {
      label: 'Biosimilar',
      initial: 'BS',
      hoverText: 'biosimilar_text',
      fieldName: 'biosimilar'
    }
  ],
  'us-devices': [
    {
      label: 'Combination Product',
      initial: 'CO',
      hoverText: 'combination_product_text',
      fieldName: 'combination_product'
    },
    {
      label: 'Expedited Review Flag',
      initial: 'ER',
      hoverText: 'expedited_review_flag_text',
      fieldName: 'expedited_review_flag'
    },
    {
      label: 'Companion Diagnostic Devices',
      initial: 'CD',
      hoverText: 'companion_diagnostic_devices_text',
      fieldName: 'companion_diagnostic_devices_status'
    }
  ],
  ct: [
    {
      label: 'Healthy Volunteers',
      initial: 'HV',
      hoverText: 'healthy_volunteers',
      fieldName: 'healthy_volunteers_flag'
    },
    {
      label: 'Expanded Access',
      initial: 'EA',
      hoverText: 'has_expanded_access',
      fieldName: 'has_expanded_access_flag'
    },
    {
      label: 'Subject Masked',
      initial: 'SU',
      hoverText: 'subject_masked',
      fieldName: 'subject_masked_flag'
    },
    {
      label: 'Investigator Masked',
      initial: 'IN',
      hoverText: 'investigator_masked',
      fieldName: 'investigator_masked_flag'
    }
  ],
  ct_masking: [
    {
      label: 'Subject Masked',
      initial: 'SU',
      hoverText: 'subject_masked',
      fieldName: 'subject_masked_flag'
    },
    {
      label: 'Investigator Masked',
      initial: 'IN',
      hoverText: 'investigator_masked',
      fieldName: 'investigator_masked_flag'
    },
    {
      label: 'Caregiver Masked',
      initial: 'CA',
      hoverText: 'caregiver_masked',
      fieldName: 'caregiver_masked_flag'
    },
    {
      label: 'Outcome Assessor Masked',
      initial: 'OA',
      hoverText: 'outcomes_assessor_masked',
      fieldName: 'outcomes_assessor_masked_flag'
    }
  ],
  ct_other: [
    {
      label: 'Healthy Volunteers',
      initial: 'HV',
      hoverText: 'healthy_volunteers',
      fieldName: 'healthy_volunteers_flag'
    },
    {
      label: 'Expanded Access',
      initial: 'EA',
      hoverText: 'has_expanded_access',
      fieldName: 'has_expanded_access_flag'
    }
  ],
  ca: [
    {
      label: 'NOC with Condition Flag',
      initial: 'NC',
      hoverText: 'noc_w_condition_text',
      fieldName: 'noc_w_condition_flag'
    },
    {
      label: 'Priority Review',
      initial: 'PR',
      hoverText: 'priority_review_text',
      fieldName: 'priority_review_flag'
    }
  ],
  hpra: [
    {
      label: 'Marketing Status',
      initial: 'MS',
      hoverText: 'marketing_text',
      fieldName: 'marketing_text'
    },
    {
      label: 'Supply Status',
      initial: 'SU',
      hoverText: 'supply_status',
      fieldName: 'supply_status'
    },
    {
      label: 'Advertising Status',
      initial: 'AS',
      hoverText: 'advertising_status',
      fieldName: 'advertising_status',
      AA_status: 'advertising_status'
    },
    {
      label: 'Legal Status',
      initial: 'LE',
      hoverText: 'legal_status',
      fieldName: 'legal_status'
    }
  ],
  jp: [],
  au: []
};

const noResultsFound: string =
  'No approved applications matched your search. Please check your spelling or try a new search.';
const internalServerError: string = 'Something went wrong. Please try again';
const noResultsFilters: string = 'Sorry, we couldn’t find any results.  Try removing some filters';

export const ERROR_MESSAGES: { [key: string | number]: string } = {
  404: noResultsFound,
  204: noResultsFound,
  filtersError: noResultsFilters,
  500: internalServerError
};

export const STATS_BAR_COUNTS: { [key: string]: any } = {
  us: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  },
  eu: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  },
  'us-devices': {
    second: { field: 'generic_name', label: 'Generic Name' },
    third: { field: 'product_code', label: 'Product Code' }
  }, // Will be updated in the next PR. Added as a placeholder
  ct: {
    second: { field: 'interventions', label: 'Unique Interventions' }
  },
  ca: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  },
  hpra: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  },
  hma: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  },
  jp: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  },
  au: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  },
  mhra: {
    second: { field: 'active_ingredients', label: 'Active Ingredients' },
    third: { field: 'formulation', label: 'Formulation' }
  }
};

export const CLINICAL_TRIALS_CATEGORIES: { [key: string]: any } = {
  us: ['active_ingredients', 'trade_name', 'indications_and_usage'],
  eu: ['active_ingredient', 'trade_name', 'indications_and_usage']
};

export const SHOW_REG_360_BTN: Array<string> = [
  'us',
  'eu',
  'ca',
  'ct',
  'us-devices',
  'hpra',
  'hma',
  'jp',
  'mhra'
];

export const DATE_FILTER_CATEGORIES: Array<string> = [
  'first_approval_year',
  'approval_year',
  'completion_date',
  'primary_completion_date',
  'study_first_submitted_date',
  'last_update_submitted_date',
  'approval_date',
  'approval_time',
  'sales_date',
  'sales_year',
  'document_created_date'
];

export const ELASTIC_SEARCH_ATTRIBUTES_LABELS: any = {
  us: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    formulations: 'Formulation',
    approval_date: 'First Approval Date',
    route: 'Route',
    approval_time: 'Approval Time (days)',
    sponsor_name: 'Sponsor Name',
    appl_num: 'Application Number',
    appl_type: 'Application Type',
    approval_pathway: 'Approval Pathway',
    accelerated_approval: 'Accelerated Approval',
    'approval-pathway': 'Approval Pathway',
    fast_track: 'Fast Track',
    rtor: 'RTOR',
    office: 'Office',
    application_type: 'Application type',
    designation_type: 'Application Type',
    category_bucket: 'Category',
    division: 'Division',
    trade_name: 'Trade Name',
    center: 'Center',
    category: 'Document Type',
    label_type: 'Label Type',
    sponsor: 'Sponsor Name',
    advisory_committee: 'Advisory Committee',
    accelerated_approval_flag: 'Accelerated Approval',
    bt_status: 'Breakthrough',
    fast_track_flag: 'Fast Track',
    orphan_status: 'Orphan Status',
    pediatric_use: 'Peds use',
    active_ingredient: 'Active Ingredient',
    application_number: 'Application Number',
    submission: 'Submission',
    label_section_search: 'All Label Sections',
    monoclonal_antibody: 'Monoclonal Antibody',
    rtor_flag: 'RTOR',
    'rems.rems_active_flag': 'REMS',
    'application_boxed_warning.application_boxed_warning_flag': 'Boxed Warning',
    first_approval_year: 'First Approval Year',
    monoclonol_antibody: 'Monoclonal Antibody',
    submission_class_code_description: 'Submission Class',
    filter_keywords: 'Keywords',
    'pediatric_use.pediatric_terms_matching': 'Peds Use',
    'advisory_committee.adcomm_flag': 'Advisory Committee',
    indications_and_usage: 'Indications',
    first_approval_date: 'First Approval Date',
    start_date: 'Start Date',
    filter_label_sections: 'Label Sections',
    end_date: 'End Date',
    mesh_tree_numbers: 'Synonyms',
    atc_code: 'Synonyms',
    atc_classification: 'ATC Classification',
    marketing_status: 'Marketing Status',
    search_terms: 'Keywords',
    companion_diagnostic_devices: 'Companion Diagnostic Devices',
    review_priority: 'Review Priority',
    review_standard: 'Review Standard',
    review_other: 'Review Other',
    document_created_date: 'RIA Added Date'
  },
  eu: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    product_number: 'Product Number',
    trade_name: 'Trade Name',
    accelerated_assessment: 'Accelerated Assessment',
    conditional_approval: 'Conditional Approval',
    exceptional_circumstances: 'Exceptional Circumstances',
    orphan_medicine: 'Orphan Medicine',
    monoclonal_antibody: 'Monoclonal Antibody',
    advanced_therapy: 'Advanced Therapy',
    filter_keywords: 'Keywords',
    'pediatric_use.pediatric_terms_matching': 'Peds Use',
    indications_and_usage: 'Indications',
    atc_classification: 'ATC Classification',
    approval_date: 'First Approval Date',
    start_date: 'Start Date',
    end_date: 'End Date',
    mesh_tree_numbers: 'Synonyms',
    atc_code: 'Synonyms',
    search_terms: 'Keywords',
    filter_label_sections: 'Label Sections',
    label_section_search: 'All Label Sections',
    // disabled for now
    // new_active_substance: 'New Active Substance',
    // scientific_advice: 'Scientific Advice',
    submission_pathway: 'Submission Pathway',
    document_created_date: 'RIA Added Date'
  },
  'us-devices': {
    generic_name: 'Generic Name',
    product_code: 'Product Code',
    device_class: 'Device Class',
    applicant: 'Sponsor Name',
    sponsor_name: 'Sponsor Name',
    application_number: 'Application Number',
    brand_name: 'Trade Name',
    trade_name: 'Trade Name',
    expedited_review_flag: 'Expedited Review Flag',
    combination_product: 'Combination Product',
    filter_keywords: 'Keywords',
    indications_and_usage: 'Definition/AOS',
    start_date: 'Start Date',
    end_date: 'End Date',
    approval_pathway: 'Approval Pathway',
    mesh_tree_numbers: 'Synonyms',
    approval_date: 'First Approval Year',
    search_terms: 'Keywords',
    companion_diagnostic_devices: 'Companion Diagnostic Devices',
    document_created_date: 'RIA Added Date'
  },
  ct: {
    overall_status: 'Overall Status',
    nct_id: 'NCT ID',
    study_type: 'Study Type',
    phase: 'Phase',
    gender: 'Gender',
    number_of_arms: 'Number of Arms',
    allocation: 'Allocation',
    masking: 'Masking',
    has_expanded_access: 'Expanded Access',
    healthy_volunteers: 'Healthy Volunteers',
    primary_completion_date: 'Primary Completion',
    completion_date: 'Study Completion',
    study_first_submitted_date: 'Study First Submitted',
    last_update_submitted_date: 'Study Last Updated',
    age_category: 'Age Category',
    filter_keywords: 'Keywords',
    sponsor_name: 'Sponsor name',
    agency_class: 'Agency Class',
    interventions: 'Interventions',
    active_ingredients: 'Interventions',
    primary_endpoint: 'Primary Endpoint',
    indications_and_usage: 'Conditions',
    start_date: 'Start Date',
    end_date: 'End Date',
    has_results: 'Has Results?',
    search_terms: 'Keywords',
    country: 'Country',
    primary_outcome: 'Primary Endpoint',
    secondary_outcome: 'Secondary Endpoint',
    document_created_date: 'RIA Added Date'
  },
  adcomm: {
    active_ingredient: 'Active Ingredient',
    formulation: 'Formulation',
    route: 'Route',
    sponsor: 'Sponsor Name',
    application_number: 'Application Number',
    joint_meeting_ids: 'Meeting Id',
    adcom_meeting_end_date: 'Meeting End Date',
    start_date: 'Start Date',
    end_date: 'End Date',
    search_terms: 'Keywords',
    document_created_date: 'RIA Added Date'
  },
  guidance: {
    field_docket_number: 'Field Docket Number',
    field_topics: 'Field Topics',
    open_comment: 'Open comment',
    field_issue_datetime: 'Issue year',
    field_issuing_office_taxonomy: 'FDA Organization',
    start_date: 'Start Date',
    end_date: 'End Date',
    search_terms: 'Keywords',
    document_created_date: 'RIA Added Date'
  },
  uk: {
    published: 'Published Datetime',
    reference_number: 'Reference Number',
    document_type: 'Document Type',
    document_category: 'Document Category',
    start_date: 'Start Date',
    end_date: 'End Date',
    search_terms: 'Keywords',
    document_created_date: 'RIA Added Date'
  },
  'ema-pips': {
    start_date: 'Start Date',
    end_date: 'End Date',
    decision_date: 'Decision Date',
    'decision-number': 'Decision Number',
    formulation: 'Formulation',
    route: 'Route',
    score_confidence: 'scoreConfidence',
    search_terms: 'Keywords',
    document_created_date: 'RIA Added Date'
  },
  ca: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    formulations: 'Formulation',
    approval_date: 'First Approval Date',
    route: 'Route',
    approval_time: 'Approval Time (days)',
    sponsor_name: 'Sponsor Name',
    vin: 'Application Number',
    appl_type: 'Application Type',
    trade_name: 'Trade Name',
    noc_w_condition_flag: 'NOC With Condition',
    priority_review_flag: 'Priority Review',
    clinical_information_flag: 'Clinical Information Docs',
    indications_and_usage: 'Indications',
    first_approval_date: 'First Approval Date',
    mesh_tree_numbers: 'Synonyms',
    atc_code: 'Synonyms',
    atc_classification: 'ATC Classification',
    approval_year: 'Approval Year',
    filter_keywords: 'Keywords',
    'vin-number': 'Application Number',
    'original-market-date': 'Original Market Date',
    _category: 'Category',
    'active-ingredient': 'Active Ingredients',
    'brand-name': 'Brnad Name',
    'monoclonal-antibody': 'Monoclonal AntiBody',
    search_terms: 'Keywords',
    filter_label_sections: 'Label Sections',
    label_section_search: 'All Label Sections',
    document_created_date: 'RIA Added Date'
  },
  chmp: {
    active_ingredient: 'Active Ingredient',
    brand_name: 'Brand Name',
    product_number: 'Product Number',
    sponsor: 'Sponsor Name',
    'chmp-meeting-id': 'Chmp Meeting ID',
    document_created_date: 'RIA Added Date'
  },
  hpra: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    approval_date: 'First Approval Date',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    product_number: 'Product Number',
    trade_name: 'Trade Name',
    active_ingredient: 'Active Ingredient',
    filter_keywords: 'Keywords',
    indications_and_usage: 'Indications',
    atc_code: 'Synonyms',
    atc_classification: 'ATC Classification',
    marketing_status: 'Marketing Status',
    search_terms: 'Keywords',
    source_index: 'Center',
    label_section_search: 'All Label Sections',
    filter_label_sections: 'Label Sections',
    mesh_tree_numbers: 'Synonyms',
    document_created_date: 'RIA Added Date'
  },
  hma: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    approval_date: 'First Approval Date',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    product_number: 'Product Number',
    trade_name: 'Trade Name',
    active_ingredient: 'Active Ingredient',
    filter_keywords: 'Keywords',
    indications_and_usage: 'Indications',
    atc_code: 'Synonyms',
    atc_classification: 'ATC Classification',
    marketing_status: 'Marketing Status',
    search_terms: 'Keywords',
    rm_state: 'Reference State',
    state_name: 'Concerned States',
    label_section_search: 'All Label Sections',
    filter_label_sections: 'Label Sections',
    mesh_tree_numbers: 'Synonyms',
    document_created_date: 'RIA Added Date'
  },
  jp: {
    active_ingredients: 'Active Ingredient',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name',
    sponsor_name: 'Sponsor Name',
    yj_code: 'Product Number',
    latest_submission_date: 'Latest Submission Date',
    latest_submission_year: 'Latest Submission Year',
    formulation: 'Formulation',
    route: 'Route',
    filter_keywords: 'Keywords',
    search_terms: 'Keywords',
    filter_label_sections: 'Label Sections',
    label_section_search: 'All Label Sections',
    mesh_tree_numbers: 'Synonyms',
    document_created_date: 'RIA Added Date'
  },
  euctr: {
    member_state: 'Member State',
    age_category: 'Age Category',
    brief_title: 'Brief Title',
    completion_date: 'Study Completion',
    conditions: 'Conditions',
    allocation: 'Allocation',
    category: 'Document Type',
    filter_keywords: 'Keywords',
    euct_id: 'EUCT ID',
    gender: 'Gender',
    enrollment: 'Enrollment',
    healthy_volunteers: 'Healthy Volunteers',
    interventions: 'Interventions',
    masking: 'Masking',
    phase: 'Phase',
    sponsors: 'Sponsor Name',
    doc_type: 'Document Type',
    region: 'Region',
    document_created_date: 'RIA Added Date'
  },
  au: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    approval_date: 'Approval Date',
    start_date: 'Approval Date',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    vin: 'Application Number',
    product_name: 'Trade Name',
    indications_and_usage: 'Indications',
    approval_year: 'Approval Year',
    filter_keywords: 'Keywords',
    search_terms: 'Keywords',
    filter_label_sections: 'Label Sections',
    label_section_search: 'All Label Sections',
    document_created_date: 'RIA Added Date'
  },
  mhra: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    approval_date: 'First Approval Date',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    product_number: 'Product Number',
    vin_number: 'Application Number',
    trade_name: 'Trade Name',
    active_ingredient: 'Active Ingredient',
    filter_keywords: 'Keywords',
    indications_and_usage: 'Indications',
    atc_code: 'Synonyms',
    atc_classification: 'ATC Classification',
    marketing_status: 'Marketing Status',
    search_terms: 'Keywords',
    source_index: 'Center',
    label_section_search: 'All Label Sections',
    filter_label_sections: 'Label Sections',
    mesh_tree_numbers: 'Synonyms',
    document_created_date: 'RIA Added Date'
  }
};

export const ariaSourceMapping: { [key: string]: string } = {
  us: 'us',
  eu: 'eu',
  ca: 'ca',
  'us-devices': 'us-devices',
  ct: 'ct',
  hpra: 'hpra',
  hma: 'hma',
  jp: 'jp',
  au: 'au',
  mhra: 'mhra'
};

export const ariaModuleMapping: { [key: string]: string } = {
  biologics: 'bla',
  '505b2': '505b2',
  core: 'core',
  guidance: 'guidance',
  adcomm: 'adcomm'
};

export const INGORE_METADATA_TITLE_CASING: Array<string> = [
  'ct',
  'ca',
  'us',
  'eu',
  'hpra',
  'hma',
  'jp',
  'mhra'
];

export const ENABLE_STACKED_LIST_SOURCE: Array<string> = [
  'us',
  'eu',
  'ca',
  'us-devices',
  'hma',
  'ct',
  'hpra',
  'jp',
  'au',
  'mhra'
];

export const CSV_UNIQUE_FIELD: { [key: string]: string } = {
  us: 'appl_num',
  eu: 'product_number',
  'us-devices': 'application_number',
  ct: 'nct_id',
  ca: 'vin',
  ct_adverse_events: 'unique_id',
  hpra: 'product_number',
  hma: 'product_number',
  jp: 'package_insert_number',
  au: 'vin',
  mhra: 'vin_number'
};

export const LABEL_COMPARISON_SOURCES: string[] = ['us', 'eu', 'ca', 'hpra', 'jp', 'mhra'];

export const IGNORE_STRING_MANIPULATION_FOR_FILTERS_LIST: string[] = ['approval_pathway'];

export const TOOLTIPTEXT: any = {
  us: 'All applications are approved by Center for Drug Evaluation and Research (CDER) unless specified',
  eu: 'The EMA data was last updated on 4 December 2023. Since the relaunch of the EMA website on 5 December 2023, we are facing technical difficulties for updating data on daily basis. We are working on resolving the issue.'
};
