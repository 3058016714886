import React, { useCallback, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { groupBy } from 'lodash';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import { FolderIcon } from '../../../assets/svgs/Icons';

import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import DocumentListing from '../../../components/Dialogs/DocumentListing';
import { getICHGuidanceDocumentsSignedURL } from '../../../api/pages/ICHGuidelines';

const GridCellDocuments: React.FC<any> = ({ documentsData, guidelineData }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopup = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const getPDFUrlFromS3Path = useCallback(async (pdfLink: any) => {
    const res = await getICHGuidanceDocumentsSignedURL({
      source: 'ich_guideline',
      s3_path: pdfLink
      // eslint-disable-next-line no-console
    }).catch(err => console.error(err));

    return res?.data?.body?.s3_url;
  }, []);

  const { documents, documentCount }: { documents: DocumentsGroup[]; documentCount: number } =
    useMemo(() => {
      const count = documentsData.length;
      const groupedDocuments = groupBy(documentsData, 'doc_category');
      const groups = Object.keys(groupedDocuments).map(key => {
        const group: DocumentsGroup = {
          id: key,
          categoryTitle: key,
          documents: [] as DocumentItem[]
        };
        const docs = groupedDocuments[key].map((doc: any) => {
          const { file_name: docTitle, s3_path: path, doc_category: type } = doc;

          return {
            title: docTitle,
            title_link: path,
            getPdfUrl: getPDFUrlFromS3Path,
            chatRiaMetadata: {
              _category: key ?? '',
              title: docTitle,
              step_date: guidelineData?.step_date,
              guideline_group: guidelineData?.guideline_group
            },
            metadata: [type ? `(${type})` : ''].filter((title: string) => !!title)
          };
        });

        group.documents = docs;

        return group;
      });

      return { documents: groups, documentCount: count };
    }, []);

  const mappedSourceName = getDocumentSourceName('ICH-Guideline');

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: documentsData?.length > 0 ? 'pointer' : 'default' }}>
        {documentsData && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => documentsData?.length > 0 && handleOpenPopup(e)}>
            <FolderIcon
              sx={{ fontSize: 12, color: documentsData?.length > 0 ? 'primary.600' : 'gray.600' }}
            />
            <Typography
              variant='subtitle2'
              sx={{
                ...styles.pdfCellText,
                color: documentsData?.length > 0 ? 'primary.600' : 'gray.600'
              }}>
              {documentsData?.length}
            </Typography>
          </Stack>
        )}
      </Box>
      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={`${guidelineData?.code} - ${guidelineData?.title}`}
          onClose={() => setOpen(false)}
          chatRIAMetadata={{}}
          source={mappedSourceName as string}
          metadata={[]}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
          allowComparison={documentCount > 1}
        />
      )}
    </Box>
  );
};

const DocumentHistoryPDF = (params: any) => {
  return (
    <GridCellDocuments
      documentsData={params?.value?.filter((each: any) => each?.is_ich)}
      guidelineData={params?.row}
    />
  );
};

export default DocumentHistoryPDF;
