import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
// third party
import { isEmpty, debounce, throttle, capitalize } from 'lodash';
// mui
import { Grid, Stack } from '@mui/material';

// styles
import styles from '../../EMAMeetings/styles/Meeting.styles';
import MeetingHeader from '../../EMAMeetings/components/common/MeetingHeader';
import MeetingNavigation from '../../EMAMeetings/components/common/MeetingNavigation';
// import MeetingDetailsCard from './MeetingDetails/MeetingDetailsCard';
import EmptyMeetingDetails from '../../EMAMeetings/components/EmptyMeetingDetails';
import PDFViewerModal from '../../EMAMeetings/components/common/PDFViewerModal';
import MeetingDetailsLoader from '../../EMAMeetings/components/common/MeetingDetailsLoader';
import MeetingDetails from './MeetingDetails';
import MeetingSectionIndex from '../../EMAMeetings/components/common/MeetingSectionIndex';
import { SECTIONS } from '../constants';
import { TopicProps } from '../types';
import { DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import DocumentListing from '../../../components/Dialogs/DocumentListing';
// import RightSectionTab from './RightSectionsTab/index';

const Main = ({
  allMeetingsByYear,
  selectedMeeting,
  handleSelectMeeting,
  meetingListLoading,
  meetingDetailsLoading,
  resources,
  meetingType,
  handleSelectMeetingType,
  containerHeight,
  isSearchPage
}: {
  allMeetingsByYear: any;
  selectedMeeting: any;
  handleSelectMeeting: any;
  meetingListLoading: boolean;
  meetingDetailsLoading: boolean;
  resources: any;
  meetingType: any;
  handleSelectMeetingType: any;
  containerHeight: string;
  isSearchPage: boolean;
}) => {
  const containerRef = useRef(null);
  const [activeSection, setActiveSection] = useState('');
  const [showSection, setShowSection] = useState(true);
  const [resourceDialog, setResourceDialog] = useState<boolean>(false);
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [documentCategory, setDocumentCategory] = useState<string>('');
  const [PDFData, setPDFData] = useState<string>('');
  const [filteredSections, setFilteredSections] = useState<Record<string, TopicProps[]>>(
    selectedMeeting?.sections
  );
  const scrollStatus = useRef<boolean>(false);

  useEffect(() => {
    if (selectedMeeting?.sections) {
      setFilteredSections(selectedMeeting.sections);
    }
  }, [selectedMeeting]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // throttle in order to reduce the stutter while scrolling
    const throttledSetActiveSection = throttle(sectionId => {
      setActiveSection(sectionId);
    }, 300);

    // handle intersections when scrolling manually
    const handleIntersectionEntries = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          throttledSetActiveSection(entry.target.id);
        }
      });
    };

    // handle intersection to avoid when user click on any section
    const handleEmptyIntersection = debounce(() => {
      scrollStatus.current = false;
    }, 200);

    const handleIntersection = (entries: any) => {
      if (scrollStatus.current) {
        handleEmptyIntersection();
      } else {
        handleIntersectionEntries(entries);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: container,
      threshold: 0.1
    });

    const sectionsId = Object.keys(filteredSections);

    if (sectionsId?.length > 0) {
      sectionsId.forEach((section: any) => {
        const element = document.getElementById(section);
        if (element) observer.observe(element);
      });
    } else {
      setActiveSection('');
    }

    // eslint-disable-next-line consistent-return
    return () => {
      sectionsId.forEach(section => {
        const element = document.getElementById(section);
        if (element) observer.unobserve(element);
      });
    };
  }, [meetingDetailsLoading, filteredSections]);

  const handleSectionClick = (id: string) => {
    const sectionId = id === '11.1 Others' ? 'Others' : id;
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setActiveSection(id);
      // ref is used in order for not triggering scroll intersection function
      scrollStatus.current = true;
    }
  };

  const getSelectedMeetingType = () => {
    const meeting = allMeetingsByYear[selectedMeeting?.meeting_stats?.meeting_year].find(
      (each: any) => each.date === selectedMeeting?.meeting_stats?.meeting_date
    );
    if (meeting?.category_bucket === 'minute') {
      return 'minutes';
    }
    return meeting?.category_bucket ?? 'minutes';
  };

  const handlePDFOpenClick = useCallback((pdfLink: string, name: string, category: string) => {
    setTitle(name);
    setDocumentCategory(category);
    setOpenPdf(true);
    setPDFData(pdfLink);
  }, []);

  const handleClosePDF = useCallback(() => {
    setOpenPdf(false);
    setPDFData('');
    setDocumentCategory('');
  }, []);

  const handleSectionChange = (data: { [key: string]: TopicProps[] }) => {
    setFilteredSections(data);
    setActiveSection('');
  };
  const documents: DocumentsGroup[] = useMemo(() => {
    const categoryMap: Record<string, string> = {
      agenda: 'Agenda',
      minute: 'Minutes'
    };

    const groups: DocumentsGroup[] = [];

    Object.keys(resources || {}).forEach(key => {
      // eslint-disable-next-line camelcase, no-shadow
      const docs = resources[key]?.map(({ title, s3_url }: any) => ({
        title,
        // eslint-disable-next-line camelcase
        title_link: s3_url,
        chatRiaMetadata: {
          'chmp-meeting-id': title,
          'meeting-year': selectedMeeting?.meeting_stats?.meeting_year,
          'category-buckets': categoryMap[key] || 'Others'
        }
      }));

      if (docs?.length) {
        groups.push({
          id: key,
          categoryTitle: categoryMap[key] || 'Others',
          documents: docs
        });
      }
    });

    return groups;
  }, [selectedMeeting?.meeting_stats?.meeting_id, resources]);

  const chatRIAMetadata = useMemo(() => {
    return {
      'original-file-name': title,
      'meeting-year': selectedMeeting?.meeting_stats?.meeting_id,
      'category-bucket': capitalize(documentCategory)
    };
  }, [selectedMeeting?.meeting_stats?.meeting_id, resources]);

  // DONT Remove below code , might be used later
  // const metadata = useMemo(() => {
  //   const defaults: Record<string, string> = {
  //     total_positive: 'Positives',
  //     total_negative: 'Negatives',
  //     total_withdrawal: 'Withdrawals'
  //   };

  //   return Object.entries(defaults).map(([key, label]) => {
  //     const value = selectedMeeting?.meeting_stats?.highlight_counts[key] || 0; // Default to 0 if the key is missing
  //     return `${label}: ${value}`;
  //   });
  // }, [selectedMeeting?.meeting_stats?.highlight_counts, resources]);

  const selectedMeetingCategoryBucket = useMemo(() => {
    const meeting = allMeetingsByYear[selectedMeeting?.meeting_stats?.meeting_year]?.find(
      (each: any) => each.date === selectedMeeting?.meeting_stats?.meeting_date
    );

    return meeting?.category_bucket;
  }, [allMeetingsByYear, selectedMeeting]);

  const isMeetingSectionsAvailable = Object.keys(filteredSections || {})?.length > 0;
  const meetingDetailsContainerSize = isMeetingSectionsAvailable ? 11 : 12;
  return (
    <Stack direction='column' px={isSearchPage ? 1.74 : 5}>
      <Grid container spacing={2}>
        {/* meeting navigation list */}
        <Grid item xs={2.4} md={2.4} lg={2.5}>
          {/* meeting list */}
          <MeetingNavigation
            meetingType={meetingType}
            handleSelectMeetingType={handleSelectMeetingType}
            allMeetingsByYear={allMeetingsByYear}
            selectedMeetingId={selectedMeeting?.meeting_stats?.meeting_id}
            meetingListLoading={meetingListLoading}
            handleSelectMeeting={handleSelectMeeting}
            committee='CHMP'
            containerHeight={containerHeight}
          />
        </Grid>
        <Grid item xs={9.6} md={9.6} lg={9.5}>
          {/* Meeting details */}
          <Stack
            spacing={2}
            sx={{
              ...styles.meetingListContainer,
              height: containerHeight
            }}>
            {!meetingDetailsLoading && !isEmpty(selectedMeeting) && (
              <>
                {/* Header */}
                <MeetingHeader
                  emaMeetingType='CHMP'
                  meetingType={getSelectedMeetingType()}
                  meetingDate={selectedMeeting?.meeting_stats?.meeting_date}
                  meetingYear={selectedMeeting?.meeting_stats?.meeting_year}
                  documentCount={selectedMeeting?.meeting_stats.documents.length}
                  onDocumentClick={() => setResourceDialog(true)}
                />

                {/* section */}
                <Grid
                  container
                  id='meetingSectionsContainer'
                  ref={containerRef}
                  sx={{
                    marginTop: '0px !important'
                  }}>
                  <Grid
                    item
                    sm={
                      showSection && isMeetingSectionsAvailable ? 8.8 : meetingDetailsContainerSize
                    }
                    md={showSection && isMeetingSectionsAvailable ? 9 : meetingDetailsContainerSize}
                    lg={
                      showSection && isMeetingSectionsAvailable ? 9.4 : meetingDetailsContainerSize
                    }
                    id='meetingSections'
                    sx={
                      isSearchPage
                        ? styles.sectionsScrollContainerSearchPage
                        : styles.sectionsScrollContainer
                    }>
                    {/* TODO: @Ashwath code here */}
                    {Object.keys(selectedMeeting?.sections).length > 0 ? (
                      <MeetingDetails
                        sections={selectedMeeting.sections}
                        meetingId={selectedMeeting?.meeting_stats?.start_date}
                        highlightedData={selectedMeeting?.highlightedData?.summary}
                        onSectionChange={handleSectionChange}
                        minutesCategory={selectedMeetingCategoryBucket}
                      />
                    ) : (
                      <EmptyMeetingDetails
                        resources={resources}
                        handlePDFOpenClick={handlePDFOpenClick}
                      />
                    )}
                  </Grid>
                  {Object.keys(filteredSections)?.length > 0 && (
                    <MeetingSectionIndex
                      activeSection={activeSection}
                      showSection={showSection}
                      setShowSection={setShowSection}
                      handleSectionClick={handleSectionClick}
                      selectedMeetingSections={filteredSections}
                      sections={SECTIONS}
                    />
                  )}
                </Grid>
              </>
            )}
            {resourceDialog && (
              <DocumentListing
                defaultDocuments={documents}
                defaultSelectedEntityId=''
                title={selectedMeeting?.meeting_stats?.meeting_date}
                onClose={() => setResourceDialog(false)}
                chatRIAMetadata={chatRIAMetadata}
                source='chmp'
                metadata={[]}
                infoComponent={null}
                disableChatRia={false}
                secondaryDrawerTitle=''
              />
            )}
            <PDFViewerModal
              source='chmp'
              openPdf={openPdf}
              setOpenPdf={setOpenPdf}
              setResourceDialog={setResourceDialog}
              meetingId={selectedMeeting?.meeting_stats?.meeting_id}
              handleClosePDF={handleClosePDF}
              title={title}
              documentCategory={documentCategory}
              PDFData={PDFData}
              setPDFData={setPDFData}
            />
            {meetingDetailsLoading && <MeetingDetailsLoader />}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(Main);
