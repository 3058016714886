import { DATAGRID_UNIQUE_FIELD } from '../../../ResultsPage/utils/constants';
import transformData from '../../utils/dataTransform';
import { PATENT_VALID_SOURCE } from './constant';
import { isEmpty } from '../../../../helpers/base';

const groupPatentByYear = (data: any, source: string, isReport: boolean = false) => {
  let appArray = transformData(data);
  appArray =
    source !== 'all'
      ? appArray.filter(
          (obj: { source: string }) =>
            obj?.source === source && PATENT_VALID_SOURCE.includes(source)
        )
      : appArray.filter((obj: { source: string }) => PATENT_VALID_SOURCE.includes(obj?.source));
  const applicationList = isReport ? appArray.slice(0, 50) : appArray;
  const result: any = {};
  const applList: Array<string> = [];
  applicationList.forEach((item: any) => {
    const key: string = DATAGRID_UNIQUE_FIELD[item?.source];
    if (item?.patentExclusivity && isEmpty(item?.patentExclusivity)) return;
    applList.push(item[key]);
    item?.patentExclusivity?.forEach((entry: any) => {
      const expireYear = entry?.expire_date?.split('-')[0];
      if (!expireYear) return;
      result[expireYear] = result[expireYear] || {};
      result[expireYear][item[key]] = result[expireYear][item[key]] || {
        application: item,
        patentExclusivity: []
      };
      result[expireYear][item[key]].patentExclusivity.push({
        ...entry
      });
    });
  });
  return {
    appArray: applicationList,
    result,
    applList
  };
};

export default groupPatentByYear;
