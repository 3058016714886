import React, { useContext, useState } from 'react';

import { format } from 'date-fns';
import { Typography, Box, Tooltip, Stack } from '@mui/material';
import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';
import DATE_FORMATS from './constants';
// eslint-disable-next-line import/no-named-as-default
import UTCtoLocalTime from '../../Header/utils/localTimeZoneDate';

import TIMELINE_INFO from '../constants';
import styles from './TimelineDate.styles';
import { getApplicationType } from '../../../pages/RegulatoryInformation/ApprovalHistory/utils';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import SubApprovalTimelineDrawer from '../SubApprovalTimeline/SubApprovalTimelineDrawer';

import { ReportIcon } from '../../../assets/svgs/Icons';

const formatFields: { [key: string]: string } = {
  ...DATE_FORMATS
};

const getFormattedDate = (column: any, dataItem: any) => {
  const date = dataItem[column.name];

  // eslint-disable-next-line no-restricted-globals
  if (date && date !== 'NA' && !isNaN(Date.parse(date))) {
    return format(UTCtoLocalTime(date), formatFields[column.name]);
  }

  return 'NA';
};

const getStyleForDate = (
  column: any,
  triggeredBy: string,
  dataItem: any,
  source: string,
  regulatoryState: any
) => {
  const applicationType = getApplicationType(source, regulatoryState?.applicationDetails);
  const timelineInfo = TIMELINE_INFO[applicationType][triggeredBy];

  const origCondition = dataItem[timelineInfo.dotField] === timelineInfo.dotFieldCondition;
  // Here we dont want green color background for certain dates like commission_decision_date
  const isDateWithoutBgColor = column?.name === 'commission_decision_date';

  const isDateWithTimelineIcon = column?.name === 'notification_issue_date';

  const baseStyle = {
    fontSize: isDateWithoutBgColor ? '13px' : '16px',
    // eslint-disable-next-line no-nested-ternary
    bgcolor: isDateWithoutBgColor ? 'none' : origCondition ? 'primary.700' : 'primary.400',
    color: isDateWithoutBgColor ? 'gray.darkStorm' : 'gray.0',
    fontWeight: 600,
    ...(applicationType === 'eu' &&
      isDateWithTimelineIcon && {
        padding: '0px 10px 0px 12px',
        bgcolor: 'primary.600'
      })
  };

  return origCondition
    ? { ...styles.TimelineDateDarkGreen, ...baseStyle }
    : { ...styles.TimelineDateSuccessGreen, ...baseStyle };
};

// Main component
const TimelineDate: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem,
  triggeredBy,
  source
}: CustomTimelinePropsInterface) => {
  const [showSubApprovalTimeline, setShowSubApprovalTimeline] = useState(false);

  // Early return if column is undefined
  if (!column) return <Box />;

  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const dateVal = getFormattedDate(column, dataItem);
  const stylesToApply = getStyleForDate(column, triggeredBy, dataItem, source, regulatoryState);

  // Conditional rendering for the date value
  const displayDate = source === 'hpra' && dateVal === '1900-01-01' ? 'Unknown' : dateVal;

  const isDateColumnWithIcon = column?.name === 'notification_issue_date';

  const showEmaApprovalTimelineIcon =
    source === 'eu' && dataItem?.timeline?.length > 0 && isDateColumnWithIcon;

  return (
    <>
      <Tooltip title={dateVal || ''}>
        <Box sx={stylesToApply}>
          <Stack
            direction='row'
            gap={0.5}
            sx={{
              justifyContent: 'center',
              ...(isDateColumnWithIcon && styles.dateColumnWithIconContainer)
            }}>
            <Typography align='center' variant='subtitle1' fontSize='inherit' fontWeight={600}>
              {displayDate}
            </Typography>
            {showEmaApprovalTimelineIcon && (
              <ReportIcon sx={styles.reportIcon} onClick={() => setShowSubApprovalTimeline(true)} />
            )}
          </Stack>
        </Box>
      </Tooltip>
      {showSubApprovalTimeline && (
        <SubApprovalTimelineDrawer
          defaultApprovalHistory={dataItem}
          allApprovalHistory={regulatoryState.approvalHistory}
          onClose={() => setShowSubApprovalTimeline(false)}
          applicationDetails={regulatoryState.applicationDetails}
        />
      )}
    </>
  );
};

export default React.memo(TimelineDate);
