import React, { useState, useMemo } from 'react';
import { Box, Typography, Tooltip, Stack, Divider } from '@mui/material';
import DocumentIcon from '../../../../assets/svgs/PDCO/DocumentIcon';
import TimelineDetailsSummary from './TimelineDetailsSummary';
import styles from './Timeline.styles';
import { shortDateFormat, getSectionDetails } from '../../utils';

interface TimelineCardProps {
  section: any;
  // eslint-disable-next-line no-unused-vars
  setDocumentPath: (documentPath: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentTitle: (documentTitle: string) => void;
  // eslint-disable-next-line no-unused-vars
  setOpenDocument: (openDocument: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentStartDate: (documentStartDate: string) => void;
  meetingId: string;
}

const TimelineCard = ({
  section,
  setDocumentPath,
  setDocumentTitle,
  setOpenDocument,
  setDocumentStartDate,
  meetingId
}: TimelineCardProps) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const handlePDFViwer = () => {
    setDocumentPath(section?.s3_url);
    setDocumentStartDate(section?.start_date);
    setDocumentTitle(section?.file_name);
    setOpenDocument(true);
  };

  const formattedSectionData = useMemo(() => getSectionDetails(section), [section]);

  return (
    <Box
      sx={{
        ...styles.timelineCard.container,
        borderColor: meetingId === section?.start_date ? 'primary.200' : 'gray.200'
      }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Box flexShrink={0} width={72} mr={1.5}>
          <Typography sx={styles.timelineCard.yearText}>
            {shortDateFormat(section?.start_date)}
          </Typography>
        </Box>
        <Box
          display='flex'
          flexGrow={1}
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-start'
          mr={2.5}
          sx={{ width: { md: 200, lg: '40%', xl: '68%' } }}>
          <Tooltip
            title={`${section?.section_number} ${section?.sub_section_name}`}
            placement='top'>
            <Typography sx={styles.timelineCard.sectionText}>
              {`${section?.section_number} ${section?.sub_section_name}`}
            </Typography>
          </Tooltip>
          <DocumentIcon
            onClick={handlePDFViwer}
            sx={{
              ...styles.timelineCard.documentIcon,
              cursor: section?.s3_url ? 'pointer' : null,
              color: 'secondary.700'
            }}
          />
        </Box>
        <Box flexShrink={0} sx={{ maxWidth: { md: 100, lg: '100%', xl: '32%' } }}>
          <Tooltip title={section?.product_number} placement='top'>
            <Typography sx={styles.timelineCard.productNumberText}>
              {section?.product_number}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
      <Divider sx={styles.timelineCard.divider} />
      <TimelineDetailsSummary
        summaryData={
          Array.isArray(section?.action_summary)
            ? {
                title: section?.action_type,
                actions: section?.action_summary
              }
            : null
        }
        productNumber={section?.product_number}
        sectionData={formattedSectionData}
        expandedItems={expandedItems}
        setExpandedItems={setExpandedItems}
      />
    </Box>
  );
};

export default React.memo(TimelineCard);
