import {
  DataBucketSourceMappingProp,
  DocumentComparisonData,
  DocumentDataProp,
  SignedUrlPayload,
  CustomGridColDef
} from './types';
import {
  getGuidanceDocumentsSignedURL,
  getEMAGuidanceDocumentsSignedURL,
  getCanadaGuidanceDocumentsSignedURL,
  getICHGuidanceDocumentsSignedURL
} from '../../api/pages/ICHGuidelines';
import { FILTER_TYPE } from '../../components/NewFilters/constants';
import renderTwoLineCellExpand from '../../components/Datagrid/DataGridTwoLineText';
import renderNextLineCellExpand from '../../components/Datagrid/DatagridNextLineText';
import renderHtmlTextCellExpand from '../../components/Datagrid/DatagridHtmlText';
import DocumentHistoryPDF from './components/DocumentHistoryPDF';
import getDateString from '../../utils/getDateString';

const ICH_GUIDELINES_FILTERS: Array<any> = [
  {
    id: 1,
    label: 'Category',
    value: 'category',
    elasticSearchType: 'category',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Guideline Code',
    value: 'code',
    elasticSearchType: 'code',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Implemented by ICH Member',
    value: 'party',
    elasticSearchType: 'party',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Guideline Group',
    value: 'guideline_group',
    elasticSearchType: 'guideline_group',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Guideline Status',
    value: 'status',
    elasticSearchType: 'status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Search ICH Guidelines',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Step Date',
    value: 'step_date',
    elasticSearchType: 'step_date',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  }
];

export interface Index {
  value: string;
  label: string;
}

export const GUIDELINES: Record<string, Index[]> = {
  'Quality (Q)': [
    {
      label: 'Q1: Stability',
      value: 'Q1A - Q1F Stability'
    },
    {
      label: 'Q2: Analytical Validation',
      value: 'Q2 Analytical Validation'
    },
    {
      label: 'Q3: Impurities',
      value: 'Q3A - Q3E Impurities'
    },
    {
      label: 'Q4: Pharmacopoeias',
      value: 'Q4A - Q4B Pharmacopoeias'
    },
    {
      label: 'Q5: Quality of Biotechnological Products',
      value: 'Q5A - Q5E Quality of Biotechnological Products'
    },
    {
      label: 'Q6: Specifications',
      value: 'Q6A- Q6B Specifications'
    },
    {
      label: 'Q7: Good Manufacturing Practice',
      value: 'Q7 Good Manufacturing Practice'
    },
    {
      label: 'Q8: Pharmaceutical Development',
      value: 'Q8 Pharmaceutical Development'
    },
    {
      label: 'Q9: Quality Risk Management',
      value: 'Q9 Quality Risk Management'
    },
    {
      label: 'Q10: Pharmaceutical Quality System',
      value: 'Q10 Pharmaceutical Quality System'
    },
    {
      label: 'Q11: Development and Manufacture of Drug Substances',
      value: 'Q11 Development and Manufacture of Drug Substances'
    },
    {
      label: 'Q12: Lifecycle Management',
      value: 'Q12 Lifecycle Management'
    },
    {
      label: 'Q13: Continuous Manufacturing of Drug Substances and Drug Products',
      value: 'Q13 Continuous Manufacturing of Drug Substances and Drug Products'
    },
    {
      label: 'Q14: Analytical Procedure Development',
      value: 'Q14 Analytical Procedure Development'
    }
  ],
  'Safety (S)': [
    {
      label: 'S1: Carcinogenicity Studies',
      value: 'S1A - S1C Carcinogenicity Studies'
    },
    {
      label: 'S2: Genotoxicity Studies',
      value: 'S2 Genotoxicity Studies'
    },
    {
      label: 'S3: Toxicokinetics and Pharmacokinetics',
      value: 'S3A - S3B Toxicokinetics and Pharmacokinetics'
    },
    {
      label: 'S4: Toxicity Testing',
      value: 'S4 Toxicity Testing'
    },
    {
      label: 'S5: Reproductive Toxicology',
      value: 'S5 Reproductive Toxicology'
    },
    {
      label: 'S6: Biotechnological Products',
      value: 'S6 Biotechnological Products'
    },
    {
      label: 'S7: Pharmacology Studies',
      value: 'S7A - S7B Pharmacology Studies'
    },
    {
      label: 'S8: Immunotoxicology Studies',
      value: 'S8 Immunotoxicology Studies'
    },
    {
      label: 'S9: Nonclinical Evaluation for Anticancer Pharmaceuticals',
      value: 'S9 Nonclinical Evaluation for Anticancer Pharmaceuticals'
    },
    {
      label: 'S10: Photosafety Evaluation',
      value: 'S10 Photosafety Evaluation'
    },
    {
      label: 'S11: Nonclinical Paediatric Safety',
      value: 'S11 Nonclinical Paediatric Safety'
    },
    {
      label: 'S12: Non-clinical Biodistribution Considerations for Gene Therapy Products',
      value: 'S12 Non-clinical Biodistribution Considerations for Gene Therapy Products'
    },
    {
      label: 'S13: Nonclinical safety studies for Oligonucleotide-based Therapeutics',
      value: 'S13 Nonclinical safety studies for Oligonucleotide-based Therapeutics'
    }
  ],

  'Efficacy (E)': [
    {
      label: 'E1: Clinical Safety for Drugs used in Long-Term Treatment',
      value: 'E1 Clinical Safety for Drugs used in Long-Term Treatment'
    },
    {
      label: 'E2: Pharmacovigilance',
      value: 'E2A - E2F Pharmacovigilance'
    },
    {
      label: 'E3: Clinical Study Reports',
      value: 'E3 Clinical Study Reports'
    },
    {
      label: 'E4: Dose-Response Studies',
      value: 'E4 Dose-Response Studies'
    },
    {
      label: 'E5: Ethnic Factors',
      value: 'E5 Ethnic Factors'
    },
    {
      label: 'E6: Good Clinical Practice',
      value: 'E6 Good Clinical Practice'
    },
    {
      label: 'E7: Clinical Trials in Geriatric Population',
      value: 'E7 Clinical Trials in Geriatric Population'
    },
    {
      label: 'E8: General Considerations for Clinical Trials',
      value: 'E8 General Considerations for Clinical Trials'
    },
    {
      label: 'E9: Statistical Principles for Clinical Trials',
      value: 'E9 Statistical Principles for Clinical Trials'
    },
    {
      label: 'E10: Choice of Control Group in Clinical Trials',
      value: 'E10 Choice of Control Group in Clinical Trials'
    },
    {
      label: 'E11: Clinical Trials in Pediatric Population',
      value: 'E11 - E11A Clinical Trials in Pediatric Population'
    },
    {
      label: 'E12: Clinical Evaluation by Therapeutic Category',
      value: 'E12 Clinical Evaluation by Therapeutic Category'
    },
    {
      label: 'E14: Clinical Evaluation of QT',
      value: 'E14 Clinical Evaluation of QT'
    },
    {
      label: 'E15: Definitions in Pharmacogenetics / Pharmacogenomics',
      value: 'E15 Definitions in Pharmacogenetics / Pharmacogenomics'
    },
    {
      label: 'E16: Qualification of Genomic Biomarkers',
      value: 'E16 Qualification of Genomic Biomarkers'
    },
    {
      label: 'E17: Multi-Regional Clinical Trials',
      value: 'E17 Multi-Regional Clinical Trials'
    },
    {
      label: 'E18: Genomic Sampling',
      value: 'E18 Genomic Sampling'
    },
    {
      label: 'E19: Safety Data Collection',
      value: 'E19 Safety Data Collection'
    },
    {
      label: 'E20: Adaptive Clinical Trials',
      value: 'E20 Adaptive Clinical Trials'
    },
    {
      label: 'E21: Inclusion of Pregnant and Breastfeeding Individuals in Clinical',
      value: 'E21 Inclusion of Pregnant and Breastfeeding Individuals in Clinical'
    },
    {
      label: 'E22: General Considerations for Patient Preference Studies',
      value: 'E22 General Considerations for Patient Preference Studies'
    }
  ],
  'Multidisciplinary (M)': [
    {
      label: 'M1: MedDRA Terminology',
      value: 'M1 MedDRA Terminology'
    },
    {
      label: 'M2: Electronic Standards',
      value: 'M2 Electronic Standards'
    },
    {
      label: 'M3: Nonclinical Safety Studies',
      value: 'M3 Nonclinical Safety Studies'
    },
    {
      label: 'M4: Organisation',
      value: 'M4: Organisation'
    },
    {
      label: 'M4E: Efficacy',
      value: 'M4E: Efficacy'
    },
    {
      label: 'M4Q: Quality',
      value: 'M4Q: Quality'
    },
    {
      label: 'M4S: Safety',
      value: 'M4S: Safety'
    },
    {
      label: 'M5: Data Elements and Standards for Drug Dictionaries',
      value: 'M5 Data Elements and Standards for Drug Dictionaries'
    },
    {
      label: 'M6: Gene Therapy',
      value: 'M6 Gene Therapy'
    },
    {
      label: 'M7: Mutagenic Impurities',
      value: 'M7 Mutagenic Impurities'
    },
    {
      label: 'M8: Electronic Common Technical Document (eCTD)',
      value: 'M8 Electronic Common Technical Document (eCTD)'
    },
    {
      label: 'M9: Biopharmaceutics Classification System-based Biowaivers',
      value: 'M9 Biopharmaceutics Classification System-based Biowaivers'
    },
    {
      label: 'M10: Bioanalytical Method Validation and Study Sample Analysis',
      value: 'M10 Bioanalytical Method Validation and Study Sample Analysis'
    },
    {
      label: 'M11: Clinical electronic Structured Harmonised Protocol (CeSHarP)',
      value: 'M11 Clinical electronic Structured Harmonised Protocol (CeSHarP)'
    },
    {
      label: 'M12: Drug Interaction Studies',
      value: 'M12 Drug Interaction Studies'
    },
    {
      label: 'M13: Bioequivalence for Immediate-Release Solid Oral Dosage Forms',
      value: 'M13 Bioequivalence for Immediate-Release Solid Oral Dosage Forms'
    },
    {
      label: 'M14: Use of real-world data for safety assessment of medicines',
      value: 'M14 Use of real-world data for safety assessment of medicines'
    },
    {
      label: 'M15: General Principles for Model-Informed Drug Development',
      value: 'M15 General Principles for Model-Informed Drug Development'
    }
  ]
};

export const STATUS_MAPPING: any = {
  'not yet implemented': 'Not yet',
  implemented: 'Implemented',
  implementated: 'Implemented',
  'not applicable': 'Not yet',
  'in the process of implementation': 'In-Process',
  default: 'Not yet'
};

export const STATUS_COLOR: any = {
  'Not yet': 'gray.200',
  Implemented: 'primary.0',
  'In-Process': '#FFF7EA'
};

export const STATUS_TEXT_COLOR: any = {
  'Not yet': 'gray.600',
  Implemented: 'primary.600',
  'In-Process': '#CC7A00'
};

export const CATEGORY_ORDER = [
  'Quality (Q)',
  'Safety (S)',
  'Efficacy (E)',
  'Multidisciplinary (M)'
];

export const METADATA_KEYS = [
  'status',
  'step_date',
  'wg_list.rapporteur',
  'wg_list.regulatory_chair'
];
export const METADATA_KEYS_MAPPING: Record<string, string> = {
  status: 'Status',
  step_date: 'Date of',
  'wg_list.rapporteur': 'Rapporteur',
  'wg_list.regulatory_chair': 'Regulatory Chair'
};

export const STEP_DETAILS_MAPPING: any = {
  'Step 1': 'Consensus Building - Technical Document',
  'Step 2': 'Adoption of Draft Guideline by Regulatory Members',
  'Step 3': 'Regulatory Consultation and Discussion',
  'Step 4': 'Adoption as an ICH Harmonized Guideline',
  'Step 5': 'Implementation'
};

export const GUDELINE_TITLE_MAPPING: any = {
  'Q1A - Q1F Stability': 'Q1: Stability',
  'Q2 Analytical Validation': 'Q2: Analytical Validation',
  'Q3A - Q3E Impurities': 'Q3: Impurities',
  'Q4A - Q4B Pharmacopoeias': 'Q4: Pharmacopoeias',
  'Q5A - Q5E Quality of Biotechnological Products': 'Q5: Quality of Biotechnological Products',
  'Q6A- Q6B Specifications': 'Q6: Specifications',
  'Q7 Good Manufacturing Practice': 'Q7: Good Manufacturing Practice',
  'Q8 Pharmaceutical Development': 'Q8: Pharmaceutical Development',
  'Q9 Quality Risk Management': 'Q9: Quality Risk Management',
  'Q10 Pharmaceutical Quality System': 'Q10: Pharmaceutical Quality System',
  'Q11 Development and Manufacture of Drug Substances':
    'Q11: Development and Manufacture of Drug Substances',
  'Q12 Lifecycle Management': 'Q12: Lifecycle Management',
  'Q13 Continuous Manufacturing of Drug Substances and Drug Products':
    'Q13: Continuous Manufacturing of Drug Substances and Drug Products',
  'Q14 Analytical Procedure Development': 'Q14: Analytical Procedure Development',
  'S1A - S1C Carcinogenicity Studies': 'S1: Carcinogenicity Studies',
  'S2 Genotoxicity Studies': 'S2: Genotoxicity Studies',
  'S3A - S3B Toxicokinetics and Pharmacokinetics': 'S3: Toxicokinetics and Pharmacokinetics',
  'S4 Toxicity Testing': 'S4: Toxicity Testing',
  'S5 Reproductive Toxicology': 'S5: Reproductive Toxicology',
  'S6 Biotechnological Products': 'S6: Biotechnological Products',
  'S7A - S7B Pharmacology Studies': 'S7: Pharmacology Studies',
  'S8 Immunotoxicology Studies': 'S8: Immunotoxicology Studies',
  'S9 Nonclinical Evaluation for Anticancer Pharmaceuticals':
    'S9: Nonclinical Evaluation for Anticancer Pharmaceuticals',
  'S10 Photosafety Evaluation': 'S10: Photosafety Evaluation',
  'S11 Nonclinical Paediatric Safety': 'S11: Nonclinical Paediatric Safety',
  'S12 Non-clinical Biodistribution Considerations for Gene Therapy Products':
    'S12: Non-clinical Biodistribution Considerations for Gene Therapy Products',
  'S13 Nonclinical safety studies for Oligonucleotide-based Therapeutics':
    'S13: Nonclinical safety studies for Oligonucleotide-based Therapeutics',
  'E1 Clinical Safety for Drugs used in Long-Term Treatment':
    'E1: Clinical Safety for Drugs used in Long-Term Treatment',
  'E2A - E2F Pharmacovigilance': 'E2: Pharmacovigilance',
  'E3 Clinical Study Reports': 'E3: Clinical Study Reports',
  'E4 Dose-Response Studies': 'E4: Dose-Response Studies',
  'E5 Ethnic Factors': 'E5: Ethnic Factors',
  'E6 Good Clinical Practice': 'E6: Good Clinical Practice',
  'E7 Clinical Trials in Geriatric Population': 'E7: Clinical Trials in Geriatric Population',
  'E8 General Considerations for Clinical Trials': 'E8: General Considerations for Clinical Trials',
  'E9 Statistical Principles for Clinical Trials': 'E9: Statistical Principles for Clinical Trials',
  'E10 Choice of Control Group in Clinical Trials':
    'E10: Choice of Control Group in Clinical Trials',
  'E11 - E11A Clinical Trials in Pediatric Population':
    'E11: Clinical Trials in Pediatric Population',
  'E12 Clinical Evaluation by Therapeutic Category':
    'E12: Clinical Evaluation by Therapeutic Category',
  'E14 Clinical Evaluation of QT': 'E14: Clinical Evaluation of QT',
  'E15 Definitions in Pharmacogenetics / Pharmacogenomics':
    'E15: Definitions in Pharmacogenetics / Pharmacogenomics',
  'E16 Qualification of Genomic Biomarkers': 'E16: Qualification of Genomic Biomarkers',
  'E17 Multi-Regional Clinical Trials': 'E17: Multi-Regional Clinical Trials',
  'E18 Genomic Sampling': 'E18: Genomic Sampling',
  'E19 Safety Data Collection': 'E19: Safety Data Collection',
  'E20 Adaptive Clinical Trials': 'E20: Adaptive Clinical Trials',
  'E21 Inclusion of Pregnant and Breastfeeding Individuals in Clinical':
    'E21: Inclusion of Pregnant and Breastfeeding Individuals in Clinical',
  'E22 General Considerations for Patient Preference Studies':
    'E22: General Considerations for Patient Preference Studies',
  'M1 MedDRA Terminology': 'M1: MedDRA Terminology',
  'M2 Electronic Standards': 'M2: Electronic Standards',
  'M3 Nonclinical Safety Studies': 'M3: Nonclinical Safety Studies',
  'M4: Organisation': 'M4: Organisation',
  'M4E: Efficacy': 'M4E: Efficacy',
  'M4Q: Quality': 'M4Q: Quality',
  'M4S: Safety': 'M4S: Safety',
  'M5 Data Elements and Standards for Drug Dictionaries':
    'M5: Data Elements and Standards for Drug Dictionaries',
  'M6 Gene Therapy': 'M6: Gene Therapy',
  'M7 Mutagenic Impurities': 'M7: Mutagenic Impurities',
  'M8 Electronic Common Technical Document (eCTD)':
    'M8: Electronic Common Technical Document (eCTD)',
  'M9 Biopharmaceutics Classification System-based Biowaivers':
    'M9: Biopharmaceutics Classification System-based Biowaivers',
  'M10 Bioanalytical Method Validation and Study Sample Analysis':
    'M10: Bioanalytical Method Validation and Study Sample Analysis',
  'M11 Clinical electronic Structured Harmonised Protocol (CeSHarP)':
    'M11: Clinical electronic Structured Harmonised Protocol (CeSHarP)',
  'M12 Drug Interaction Studies': 'M12: Drug Interaction Studies',
  'M13 Bioequivalence for Immediate-Release Solid Oral Dosage Forms':
    'M13: Bioequivalence for Immediate-Release Solid Oral Dosage Forms',
  'M14 Use of real-world data for safety assessment of medicines':
    'M14: Use of real-world data for safety assessment of medicines',
  'M15 General Principles for Model-Informed Drug Development':
    'M15: General Principles for Model-Informed Drug Development'
};
export const INITIAL_DOCUMENT_COMPARISON_DETAILS: DocumentComparisonData = {
  brandName: '',
  source: '',
  signedUrl: '',
  fileName: '',
  s3Path: ''
};

export const INITIAL_DOCUMENT_DATA: DocumentDataProp = {
  isDocumentOpen: false,
  documentTitle: '',
  documentPath: '',
  source: '',
  metadataSource: '',
  category: '',
  step_date: '',
  guideline_group: ''
};

export const DATA_BUCKET_SOURCE_MAPPING: DataBucketSourceMappingProp[] = [
  {
    source: 'us',
    metadataSource: 'Guidances',
    dataBucket: 'guidance',
    getSignedUrl: (payload: SignedUrlPayload) => getGuidanceDocumentsSignedURL(payload)
  },
  {
    source: 'eu',
    metadataSource: 'EMA Guidances',
    dataBucket: 'ema_guidance',
    getSignedUrl: (payload: SignedUrlPayload) => getEMAGuidanceDocumentsSignedURL(payload)
  },
  {
    source: 'ca',
    metadataSource: 'CA Guidances',
    dataBucket: 'ca_guidance',
    getSignedUrl: (payload: SignedUrlPayload) => getCanadaGuidanceDocumentsSignedURL(payload)
  },
  {
    source: 'ich-guideline',
    metadataSource: 'ICH-Guideline',
    dataBucket: 'ich_guideline',
    getSignedUrl: (payload: SignedUrlPayload) => getICHGuidanceDocumentsSignedURL(payload)
  }
];

export const ICH_GUIDELINE_SOURCE = 'ich-guideline';

export const ICH_GUIDELINE_COLUMN: CustomGridColDef[] = [
  {
    field: 'documents',
    headerName: 'Document(s)',
    width: 100,
    filterable: false,
    capitalCasing: false,
    headerClassName: 'table-header',
    disableExport: true,
    sortable: false,
    renderCell: DocumentHistoryPDF
  },
  {
    field: 'title',
    headerName: 'Guideline Title',
    flex: 1,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderTwoLineCellExpand,
    valueGetter: (params: any) => `${params?.row?.code} - ${params?.value}`
  },
  {
    field: 'guideline_group',
    headerName: 'Category',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderTwoLineCellExpand,
    valueGetter: (params: any) => GUDELINE_TITLE_MAPPING[params?.value]
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderNextLineCellExpand,
    valueGetter: (params: any) => {
      const data: string[] = [];
      if (params?.value) {
        data.push(
          `${params?.value} ${
            STEP_DETAILS_MAPPING[params?.value] ? `(${STEP_DETAILS_MAPPING[params?.value]})` : ''
          }`
        );
      }
      if (params?.row?.step_date_label && params?.row?.step_date) {
        data.push(
          `Date of ${params?.row?.step_date_label}: ${getDateString(params?.row?.step_date)}`
        );
      }
      return data?.join('\n');
    }
  },
  {
    field: 'wg_list',
    headerName: 'Other Details',
    width: 170,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderNextLineCellExpand,
    valueGetter: (params: any) => {
      const data: string[] = [];
      if (params?.value?.rapporteur) {
        data.push(`Rapporteur: ${params?.value?.rapporteur?.join(', ')}`);
      }
      if (params?.value?.regulatory_chair?.length > 0) {
        data.push(`Regulatory Chair: ${params?.value?.regulatory_chair?.join(', ')}`);
      }
      return data?.join('\n');
    }
  },
  {
    field: 'description',
    headerName: 'About',
    flex: 1,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderHtmlTextCellExpand
  }
];

const day = new Date();
const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
export const CSV_FILENAME = {
  prefix: 'ria',
  module: 'ich_guidances',
  postfix: date,
  additional: '',
  extension: ''
};

export default ICH_GUIDELINES_FILTERS;
