const styles = {
  drawer: {
    '& .MuiDrawer-paper': {
      width: 632,
      maxWidth: 632,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12
    }
  },
  headerContainer: {
    backgroundColor: 'primary.600',
    py: 1.5,
    px: 2.5,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  applicationName: {
    color: 'white.main',
    fontSize: 20,
    fontWeight: 700,
    fontFamily: 'Mulish'
  },
  icon: { fontSize: 16, color: 'white.main' },
  closeIconButton: {
    ml: 'auto',
    p: 0.75,
    mr: -0.75
  },
  metaDataContainer: {
    p: 2,
    bgcolor: 'gray.100',
    gap: 1.25
  },
  metaDataLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: 'gray.600',
    minWidth: 120,
    lineHeight: 1.215,
    fontFamily: 'Mulish'
  },
  metaDataValue: {
    fontSize: 14,
    fontWeight: 500,
    color: 'gray.800',
    lineHeight: 1.215,
    fontFamily: 'Mulish',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  metaDataValueTooltip: {
    maxHeight: 300,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.lightVariant3',
      borderRadius: '4px',
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  timelineText: {
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: 14,
    color: 'gray.900'
  },
  timelineContainer: {
    '& .MuiTimelineItem-root:before': {
      flex: 0,
      padding: 0
    },
    m: 0,
    py: 0,
    px: 0
  },
  timelineSeparator: { mt: 1.25, mr: 1 },
  circle: { m: 0, fontSize: 8, color: 'gray.400' },
  timelineConnector: {
    width: '1px',
    height: '100%',
    mb: -1.25,
    flexGrow: 0
  },
  timelineEventContainer: {
    gap: 1,
    p: 1,
    borderRadius: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'gray.400',
    backgroundColor: 'gray.50'
  },
  timelineEventWrapper: {
    gap: 1.5,
    alignItems: 'center',
    flexDirection: 'row'
  },
  timeIcon: {
    height: 12,
    width: 12,
    color: 'gray.500'
  },
  timeDuration: {
    fontSize: 14,
    color: 'gray.500',
    fontWeight: 600,
    fontFamily: 'Mulish'
  },
  timelineEventDate: {
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: 14,
    color: 'gray.900'
  },
  timelineEventDateType: {
    fontFamily: 'Mulish',
    fontWeight: 600,
    fontSize: 14,
    color: 'gray.900'
  },
  agencyTag: {
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    fontFamily: 'Mulish',
    '& span': {
      px: 0.5
    },
    cursor: 'default'
  },
  timelineButton: {
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'primary.700',
    color: 'primary.700',
    textTransform: 'none',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    px: 2,
    py: 0.75,
    height: 32,
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'primary.0'
    },
    '&:active': {
      backgroundColor: 'primary.50'
    },
    '&:disabled': {
      borderWidth: 0,
      backgroundColor: 'gray.200',
      color: 'gray.500'
    }
  },
  timelineButtonIcon: {
    height: 15,
    width: 15,
    mr: 1
  }
};

export default styles;
