import React from 'react';
import { Stack, Box, Typography, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import SelectButton from './MeetingTypeButton';
import MeetingsYearList from './MeetingsYearList';
import MeetingListLoader from './MeetingListLoader';
import styles from '../../styles/Meeting.styles';

const MeetingNavigation = ({
  meetingType,
  handleSelectMeetingType,
  allMeetingsByYear,
  selectedMeetingId,
  meetingListLoading,
  handleSelectMeeting,
  committee,
  containerHeight
}: any) => {
  return (
    <Stack
      sx={{
        ...styles.meetingListContainer,
        height: containerHeight ?? 'calc(100vh - 260px)'
      }}>
      {meetingListLoading ? (
        <MeetingListLoader />
      ) : (
        <>
          <Box sx={styles.meetingListHeaderContainer}>
            <Tooltip title='Meeting Type' placement='top'>
              <Typography sx={styles.meetingListHeaderText}>Meeting Type:</Typography>
            </Tooltip>
            <SelectButton
              meetingType={meetingType}
              handleSelectMeetingType={handleSelectMeetingType}
            />
          </Box>
          <MeetingsYearList
            handleSelectMeeting={handleSelectMeeting}
            allMeetingsByYear={allMeetingsByYear}
            selectedMeetingId={selectedMeetingId}
            committee={committee}
          />
        </>
      )}

      {!meetingListLoading && isEmpty(allMeetingsByYear) && (
        <Stack sx={{ height: 'calc(100vh - 290px)' }} alignItems='center' justifyContent='center'>
          <Typography variant='subtitle1' fontWeight='bold'>
            No Meetings available
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default React.memo(MeetingNavigation);
