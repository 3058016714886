const RESULT_VIEW_TYPES = {
  CT: 'ct',
  APPLICATION: 'application',
  DOCUMENT: 'document',
  ENTITY: 'entity_popup',
  CHMP: 'chmp'
};

export const CT_ROW_ID_MAPPING: any = {
  usnlm: 'nct_id',
  euctr: 'euct_id'
};

export const MODULE_TABS_SOURCE_LIST = ['chmp']; // add the source module name here if it requires module tab next to (applications and documents) tabs

export default RESULT_VIEW_TYPES;
