import React, { useContext } from 'react';

// muiv5
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { IconButton, Tooltip, Typography } from '@mui/material';
import VivproDatagrid from '../../../components/Datagrid';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
// context
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

// hoc
import withFetchingPatentExclusivityDetails from './withFetchingPatentExclusivityDetails';
import Timeline from './Timeline';
import { PatentExclusivity } from './constants';
import styles from '../../../components/Timeline/TimelineDocuments/TimelineDocuments.styles';
import Card from '../../SearchResults/components/Card';
import SOURCE_MAPPING, {
  PATENT_SOURCE_MAPPING
} from '../../SearchResults/components/PatentsExclusivityTimelineView/constant';
import { InfoIconSummary } from '../../../assets/svgs/Icons';

const PatentExclusivityDetails = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const getSource = (sourceType: string, applicationDetails: { [key: string]: any }) => {
    if (sourceType?.toLowerCase() === 'us') {
      if (applicationDetails?.application_type?.toLowerCase() === 'eua') {
        return 'us-devices';
      }
    }
    return sourceType;
  };
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      sx={{ width: '100%', height: '100%', position: 'relative' }}>
      {!regulatoryState?.loadingPatentExclusivity &&
      regulatoryState.patent_exclusivity?.length <= 0 ? (
        <EmptyPage message='Exclusivity are not available' />
      ) : (
        <>
          <Box position='absolute' top={10} zIndex={1}>
            <Typography ml={2}>
              Source
              <Tooltip
                title={
                  <Box>
                    {
                      SOURCE_MAPPING[
                        getSource(regulatoryState?.db, regulatoryState.applicationDetails)
                      ]
                    }
                    :{' '}
                    {
                      PATENT_SOURCE_MAPPING[
                        getSource(regulatoryState?.db, regulatoryState.applicationDetails)
                      ]
                    }
                  </Box>
                }>
                <IconButton sx={{ p: 0, ml: 0.5 }}>
                  <InfoIconSummary sx={{ fontSize: 12 }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Box>
          <Stack flexDirection='row' sx={{ height: '73vh', width: 'calc(100% - 24px)' }}>
            <VivproDatagrid
              rows={regulatoryState?.patentExclusivity}
              columnsMapping={PatentExclusivity}
              rowId='identifier'
              loading={regulatoryState?.loadingPatentExclusivity}
            />
            <Timeline
              isOpen={regulatoryState?.showTimeline}
              closeTimeline={() => {
                regulatoryDispatch({
                  type: 'SET_SHOW_TIMELINE',
                  value: false
                });
              }}
              timelineDetails={{
                timeline: regulatoryState?.patentExclusivity,
                applicationDetails: regulatoryState?.applicationDetails
              }}
              onClose={() =>
                regulatoryDispatch({
                  type: 'SET_SHOW_TIMELINE',
                  value: false
                })
              }
              infoComponent={
                <Box sx={styles.cardInfoComponent}>
                  <Card
                    data={regulatoryState.applicationDetails}
                    hideApplication={() => {}}
                    triggerChatRIA={() => {}}
                    setFavoriteInHook={() => {}}
                    setInProjectInHook={() => {}}
                    setSubscriptionInHook={() => {}}
                    hideCardOptions
                  />
                </Box>
              }
            />
          </Stack>
        </>
      )}
    </Box>
  );
};

export default withFetchingPatentExclusivityDetails(PatentExclusivityDetails);
