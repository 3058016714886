const styles = {
  container: {
    display: 'flex',
    width: 'inherit',
    maxHeight: 'inherit',
    height: 'inherit',
    overflow: 'auto',
    py: 2.5,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 3,
    backgroundColor: 'gray.0'
  },
  headingContainer: {
    display: 'flex',
    pr: 2,
    pb: 1.5,
    pl: 2.5,
    alignItems: 'center'
  },
  heading: {
    fontSize: 16,
    fontWeight: 700,
    color: 'gray.900'
  },
  infoContainer: {
    display: 'flex',
    pr: 5,
    pl: 2.5,
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%'
  },
  infoText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '21px',
    color: 'gray.900'
  },
  sectionContainer: {
    display: 'flex',
    py: 2,
    alignItems: 'flex-start',
    width: '100%'
  },
  sectionHeading: {
    width: '30%',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: 'gray.600'
  },
  divider: { mt: 2, width: '100%' }
};

export default styles;
