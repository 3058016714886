import React from 'react';
import { Popover } from '@mui/material';

const TitleInfoPopover = ({
  infoComponent,
  open,
  anchorEl,
  onClose
}: {
  infoComponent: React.ReactNode;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      sx={{
        zIndex: 1401,
        '& .MuiPaper-root': {
          backgroundColor: 'white.main',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'gray.400',
          borderRadius: 2,
          p: 2,
          pt: 0
        }
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      {infoComponent}
    </Popover>
  );
};

export default React.memo(TitleInfoPopover);
