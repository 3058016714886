const SOURCE_MAPPING: any = {
  us: 'US FDA - Drugs & Biologics',
  eu: 'EU EMA - Drugs & Biologics',
  ct: 'CT GOV - Clinical Trials',
  ca: 'CA HPFB - Drugs & Biologics',
  'us-devices': 'US FDA - Devices',
  hpra: 'IE HPRA - Drugs & Biologics',
  hma: 'EU HMA - Drugs & Biologics',
  jp: 'JP PMDA - Drugs & Biologics'
};
export const PATENT_SOURCE_MAPPING: any = {
  us: 'data is sourced from the US FDA orangebook database',
  ca: 'data is sourced from the health canada database'
};

export const PATENT_VALID_SOURCE = ['us', 'ca'];

export default SOURCE_MAPPING;
