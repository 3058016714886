type TimelineItem = {
  commission_decision_date?: string;
  notification_issue_date?: string;
  published_date?: string;
  close_date?: string;
  agency?: string;
  source?: string;
  delta?: number;
};

type Approval = {
  application_number?: string;
  scope?: string;
  summary?: string;
  timeline?: TimelineItem[];
};

export const normalizeTimeline = (timeline: TimelineItem[]): Record<string, unknown>[] => {
  const formatDate = (date: string | null): string | null =>
    date
      ? new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          timeZone: 'UTC'
        }).format(new Date(date))
      : null;

  return timeline.map((item, index) => {
    const date =
      item?.commission_decision_date ??
      item?.notification_issue_date ??
      item?.published_date ??
      null;

    const dateType = (() => {
      if (item?.commission_decision_date) return 'Commission Decision Issued';
      if (item?.notification_issue_date) return 'Opinion/Notification Issued';
      if (item?.published_date) return 'Published Date';
      return '';
    })();

    return {
      id: index + 1,
      date: formatDate(date),
      closeDate: formatDate(item?.close_date ?? null),
      closeDateType: item?.close_date ? 'EU Commission Close Date' : null,
      dateType,
      agency: item?.agency ?? 'ema',
      source: item?.source ?? null,
      duration: item?.delta ?? null
    };
  });
};

export const getSubmissionDetails = (approval: Approval) => {
  return {
    number: approval?.application_number ?? 'NA',
    metadata: [
      {
        label: 'Procedure No.:',
        value: approval?.application_number ?? 'NA'
      },
      {
        label: 'Scope:',
        value: approval?.scope ?? 'NA'
      },
      {
        label: 'Summary:',
        value: approval?.summary || 'NA'
      }
    ],
    timeline: normalizeTimeline(approval?.timeline ?? [])
  };
};
