const styles = {
  loadMoreButton: {
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 600,
    color: 'white.main',
    backgroundColor: 'primary.700',
    '&:hover': {
      backgroundColor: 'primary.main'
    },
    borderRadius: '16px',
    width: 200,
    my: 2,
    mb: 20
  },
  rightGrid: {
    position: 'fixed',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  keyInsightsButton: {
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 600,
    color: 'primary.700',
    borderRadius: '16px',
    mb: 10
  }
};

export default styles;
