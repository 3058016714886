import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import styles from './styles/productInformation.styles';

interface SectionProps {
  label: string;
  children: React.ReactNode;
  show: boolean;
}
const Section: React.FC<SectionProps> = ({ label, children, show }) => (
  <Stack direction='column' sx={styles.sectionContainer}>
    <Stack direction='row' width='100%' spacing={2} gap={4} alignItems='center'>
      <Typography variant='subtitle2' sx={styles.sectionHeading}>
        {label}:
      </Typography>
      {children}
    </Stack>
    {show && <Divider sx={styles.divider} />}
  </Stack>
);

const ProductInformation = ({ dataItem }: any) => {
  const sections = [
    {
      label: 'Name of Medicine',
      content: dataItem?.name_of_medicine || 'Not available'
    },
    { label: 'Active Substance', content: dataItem?.active_substance || 'Not available' },
    {
      label: 'Therapeutic Area (MeSH)',
      content: (
        <Stack
          direction='column'
          spacing={1}
          alignItems='flex-start'
          component='ul'
          style={{ paddingLeft: '20px', margin: 0 }}>
          {dataItem?.therapeutic_area?.map((item: string) => (
            <li
              key={item}
              style={{
                fontWeight: 400
              }}>
              <span style={{ display: 'inline-block' }}>{item}</span>
            </li>
          ))}
        </Stack>
      )
    },
    { label: 'ATC Code', content: dataItem?.atc_code || 'Not available' },
    {
      label: 'Pharmaco-therapeutic Group',
      content: dataItem?.human_pharmacotherapeutic_group || 'Not available'
    }
  ];
  return (
    <Stack spacing={2} sx={styles.container}>
      <Box sx={styles.headingContainer}>
        <Typography sx={styles.heading}>Product Information</Typography>
      </Box>
      <Box sx={styles.infoContainer}>
        {dataItem &&
          sections?.map(({ label, content }, index: number) => (
            <Section key={label} label={label} show={index !== (sections?.length || 0) - 1}>
              <Typography variant='subtitle2' sx={styles.infoText}>
                {content}
              </Typography>
            </Section>
          ))}
      </Box>
    </Stack>
  );
};

export default React.memo(ProductInformation);
