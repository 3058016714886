import getDateString from '../../utils/getDateString';
import {
  METADATA_KEYS,
  METADATA_KEYS_MAPPING,
  STEP_DETAILS_MAPPING,
  ICH_GUIDELINE_SOURCE,
  CATEGORY_ORDER
} from './contants';
import { ICHMetadataProps, GuidelineItem, DataType, GroupedData } from './types';

// Function to get or memoize a nested value by key path (supports dot notation)
const getNestedValue = (obj: any, key: string) => {
  const nestedValueCache = new Map();
  const cacheKey = JSON.stringify({ obj, key });
  if (!nestedValueCache.has(cacheKey)) {
    const value = key.split('.').reduce((acc, part) => acc?.[part], obj);
    nestedValueCache.set(cacheKey, value);
  }
  return nestedValueCache.get(cacheKey);
};

export const isEmptyObject = (data: any) => Object.values(data).every(value => value === '');

export const getCardMetadataList = (selectedData: any) => {
  const metadataList: ICHMetadataProps[] = [];

  METADATA_KEYS.forEach((key: string) => {
    const formatedValue = getNestedValue(selectedData, key);
    if (Array.isArray(formatedValue) ? formatedValue.length !== 0 : formatedValue) {
      const label = METADATA_KEYS_MAPPING[key];
      const mainText = Array.isArray(formatedValue) ? formatedValue.join(', ') : formatedValue;
      const metadata: { label: string; value: string; toolTipText: string }[] = [];
      let type = 'text';
      if (key === 'status') {
        metadata.push({
          label: '',
          value: `${formatedValue} (${STEP_DETAILS_MAPPING[formatedValue] || 'NA'})`,
          toolTipText: `${formatedValue} (${STEP_DETAILS_MAPPING[formatedValue] || 'NA'})`
        });

        type = 'piped-text';
      }
      if (key === 'step_date') {
        const statusData = metadataList.find((item: { id: string }) => item?.id === 'status');
        if (statusData?.metadata) {
          statusData?.metadata.push({
            label: `${label} ${selectedData?.step_date_label}`,
            value: getDateString(formatedValue),
            toolTipText: `${formatedValue} (${STEP_DETAILS_MAPPING[formatedValue] || 'NA'})`
          });
        }
      } else {
        metadataList.push({
          id: key,
          label,
          mainText,
          metadata,
          type,
          toolTipText: mainText
        });
      }
    }
  });

  return metadataList;
};

export const getComparisonMetadata = (documentList: Array<any>) => {
  if (documentList?.length !== 0) {
    const compareList: Array<any> = [];
    documentList.forEach(doc => {
      const docObject: any = {};
      docObject.title = `${doc?.code} - ${doc?.title}`;
      docObject.source = ICH_GUIDELINE_SOURCE;
      docObject.documentList = doc?.documents
        ?.sort((a: any, b: any) => (a?.doc_category || '').localeCompare(b?.doc_category || ''))
        .map((item: any, index: number) => {
          return {
            metadata: [
              {
                id: index + 1,
                label: 'Document Type',
                value: item?.doc_category
              }
            ].filter(meta => meta?.value),
            s3_path: item?.s3_path,
            title: item?.file_name,
            id: item?.s3_path
          };
        });
      compareList.push(docObject);
    });

    return compareList;
  }
  return [];
};

export const sortCompareGuidelineData = (data: DataType): GuidelineItem[] => {
  // Group the data by category and guideline group.
  const groupedData: GroupedData = {};

  Object.values(data).forEach(items => {
    items.forEach(item => {
      const category = item.group_by_category_name;
      const guidelineGroup = item.guideline_group;

      if (!groupedData[category]) {
        groupedData[category] = {};
      }
      if (!groupedData[category][guidelineGroup]) {
        groupedData[category][guidelineGroup] = [];
      }

      groupedData[category][guidelineGroup].push(item);
    });
  });

  // Sort the categories according to CATEGORY_ORDER.
  const sortedData: GroupedData = Object.fromEntries(
    Object.entries(groupedData).sort(([catA], [catB]) => {
      const indexA = CATEGORY_ORDER.indexOf(catA);
      const indexB = CATEGORY_ORDER.indexOf(catB);
      // Categories not found in the order are sorted to the end.
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    })
  ) as GroupedData;

  // Flatten the data while maintaining category order.
  const flatSortedData: GuidelineItem[] = CATEGORY_ORDER.reduce(
    (accumulator: GuidelineItem[], category) => {
      if (sortedData[category]) {
        // Sort the guideline group keys numerically based on any digits in the key.
        const sortedGroupKeys = Object.keys(sortedData[category]).sort((groupA, groupB) => {
          const numA = parseInt(groupA.match(/\d+/)?.[0] || '0', 10);
          const numB = parseInt(groupB.match(/\d+/)?.[0] || '0', 10);
          return numA - numB;
        });

        // Sort items within each group by their cleaned 'code' property.
        sortedGroupKeys.forEach(groupKey => {
          const sortedItems = sortedData[category][groupKey].sort((a, b) => {
            const cleanCodeA = a.code.replace(/[^a-zA-Z0-9]/g, '');
            const cleanCodeB = b.code.replace(/[^a-zA-Z0-9]/g, '');
            return cleanCodeA.localeCompare(cleanCodeB, undefined, { numeric: true });
          });
          accumulator.push(...sortedItems);
        });
      }
      return accumulator;
    },
    []
  );

  return flatSortedData;
};

export default getNestedValue;
