import React, { useState, useEffect } from 'react';
import { capitalize } from 'lodash';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';
import styles from './TimelineInformation.styles';

const TimelineInformation: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem
}: CustomTimelinePropsInterface) => {
  const [concatValue, setConcatValue] = useState<string>('');
  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [mouseOverContent, setMouseOverContent] = useState<boolean>(false);
  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (concatValue) {
      setAnchorEl(event.currentTarget);
      setShowPopper(true);
    }
  };

  const handleMouseLeave = () => {
    if (!mouseOverContent) {
      setAnchorEl(null);
      setShowPopper(false);
    }
  };

  if (column === undefined) {
    return <Box />;
  }

  const sortObject = (data: any) => {
    const keys = Object.keys(data);
    keys.sort((a, b) => b.localeCompare(a));
    const sortedObj: any = {};
    keys.forEach(key => {
      sortedObj[key] = data[key];
    });

    return sortedObj;
  };

  useEffect(() => {
    if (dataItem) {
      const names = dataItem[column.name]?.map((obj: any) => obj.name);
      const concatString = names?.join(', ') || '';
      setConcatValue(concatString);
    }
  }, [dataItem]);

  return (
    <div>
      <Box sx={styles.textAlignment} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Typography
          variant='body1'
          maxHeight={70}
          overflow='hidden'
          textOverflow='ellipsis'
          sx={{
            p: 2,
            color: 'gray.darkStorm',
            fontWeight: '600',
            textAlign: 'center'
          }}>
          {concatValue || '.....'}
        </Typography>
        {concatValue && (
          <Popper
            open={showPopper}
            placement='bottom'
            anchorEl={anchorEl}
            onMouseOver={() => setMouseOverContent(true)}
            onMouseLeave={() => {
              setMouseOverContent(false);
              setAnchorEl(null);
              setShowPopper(false);
            }}>
            <Paper
              elevation={1}
              sx={{
                maxHeight: 300,
                overflowY: 'auto',
                borderRadius: '8px',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                width: 300
              }}>
              {dataItem &&
                dataItem[column.name].map((item: any) => (
                  <Box
                    key={item}
                    sx={{
                      width: '100%',
                      textAlign: 'left',
                      backgroundColor: 'primary.backgroundDark',
                      marginBottom: '10px',
                      borderRadius: '16px',
                      padding: 2,
                      textTransform: 'capitalize'
                    }}>
                    {Object.keys(sortObject(item)).map((row: any) => (
                      <Typography key={row} style={{ fontSize: '14px' }}>
                        <>
                          <b>{`${capitalize(row)}: `} </b>
                          {item[row]}
                          <br />
                        </>
                      </Typography>
                    ))}
                  </Box>
                ))}
            </Paper>
          </Popper>
        )}
      </Box>
    </div>
  );
};

export default React.memo(TimelineInformation);
