import { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import SearchListItem from './SearchListItem';
import NoResultsView from '../NoResultsView';
import { SearchesProps } from '../../interface';

const Searches = ({ searches, handleFavoriteHook, handleProjectHook }: SearchesProps) => {
  const finalSearches = useMemo(() => {
    return [...(searches || [])].sort(
      (a: { date: string }, b: { date: string }) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }, [searches]);

  if (searches.length === 0) return <NoResultsView text='No Searches are saved' />;

  return (
    <Box mt='12px'>
      {finalSearches.map((search, index) => (
        <SearchListItem
          key={search.id}
          index={index + 1}
          data={search}
          title={search.term}
          searchType={search.type}
          source={search.source}
          handleFavoriteHook={handleFavoriteHook}
          handleProjectHook={handleProjectHook}
          isModule={search.isModule}
          moduleRedirectUrl={search.moduleRedirectUrl}
        />
      ))}
    </Box>
  );
};

export default memo(Searches);
