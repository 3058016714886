import palette from '../../../themev5/palette';
import notificationsListStyles from './NotificationsList.styles';

const details = {
  padding: '6px 0px',
  alignItems: 'flex-start',
  alignSelf: 'stretch'
};

const notificationsDetailStyles = {
  parentStack: {
    padding: '16px',
    // first child of the stack
    '& > :first-child': {
      pt: '0 !important'
    }
  },
  topSection: {
    p: '16px 0px !important',
    borderBottom: `1px solid ${palette.gray['200']} !important`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.015)'
    }
  },
  headerText: {
    '& > .MuiListItemText-primary': notificationsListStyles.title,
    '& > .MuiListItemText-secondary': notificationsListStyles.subTitle
  },
  headerTextWithHover: {
    '& > .MuiListItemText-primary': notificationsListStyles.title,
    '& > .MuiListItemText-secondary': notificationsListStyles.subTitle,
    '&:hover': {
      '& > .MuiListItemText-primary': {
        textDecoration: 'underline',
        textDecorationColor: palette.primary['500']
      },
      cursor: 'pointer'
    }
  },
  detailContainer: {
    p: '16px 16px 8px 16px'
  },
  detailHeader: {
    color: palette.gray['900'],
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    mb: '6px'
  },
  details,
  detailsWithHover: {
    ...details,
    '&:hover': {
      cursor: 'pointer',
      '& > .MuiTypography-root': {
        color: palette.primary['700'],
        textDecoration: 'underline'
      }
    }
  },
  changeTypeIcon: {
    mr: '8px',
    height: '22px',
    width: '16px',
    minWidth: '16px',
    maxWidth: '16px',
    '& > .MuiSvgIcon-root': {
      height: '12px',
      width: '12px',
      color: palette.gray['600']
    }
  },
  message: {
    color: palette.gray['900'],
    fontFeatureSettings: `'dlig' on`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%'
  },
  showMoreButton: {
    my: '10px',
    alignSelf: 'center',
    textTransform: 'none',
    borderRadius: '20px',
    border: '1px solid #BDBDBD',
    backgroundColor: 'white !important',
    color: 'gray.950',
    fontSize: '12px !important',
    padding: '3px 15px !important',
    fontWeight: 600,
    fontFamily: 'Mulish',
    cursor: 'pointer',
    '&:hover': {
      // theme color does not work here
      backgroundColor: '#F5F5F5FF !important',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
    }
  },
  verticalDivider: {
    height: 16,
    mx: 1,
    borderColor: 'gray.300'
  },
  countTag: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 16,
    height: 16,
    borderRadius: '50%',
    color: 'gray.700',
    fontWeight: 700,
    backgroundColor: 'gray.200',
    px: 0.25,
    py: 0.25
  },
  countTagKey: {
    color: 'gray.700',
    fontSize: 13,
    fontWeight: 400
  }
};
export default notificationsDetailStyles;
