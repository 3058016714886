const GUIDES: any = {
  home: { title: 'Home Page Guide', tags: ['HomePage'] },
  documents: { title: 'Documents Page Guide', tags: ['Documents'] },
  applications: { title: 'Applications Page Guide', tags: ['Applications', 'Notifications'] },
  cdp: { title: 'CDP Page Guide', tags: ['CDPModule'] },
  collaborate: { title: 'Collaborate Page Guide', tags: ['Collaborate'] },
  serviceRequests: { title: 'Service Request Page Guide', tags: ['ServiceRequest'] },
  helpCenter: { title: 'Help Page Guide', tags: ['HelpPage'] },
  reg360: { title: 'Reg360 Page Guide', tags: ['Reg360'] },
  notifications: { title: 'Notifications Page Guide', tags: ['Notifications'] },
  PDCO: { title: 'EMA PDCO Page Guide', tags: ['PDCO'] }
};

export default GUIDES;
