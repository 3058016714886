/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Button, Typography, Stack } from '@mui/material';

import Stats from '../../../SearchResults/components/Stats';
import styles from '../../styles/Header.styles';
import { Stat } from '../../../SearchResults/types';
import { ReportIcon, SubHeaderPipeGreyIcon } from '../../../../assets/svgs/Icons';

import { MODULE_TABS_SOURCE_LIST } from '../../../SearchResults/components/constants';
import FavoriteAndProjectActions from '../../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';

type SubHeaderProps = {
  title: any;
  stats: Stat[];
  handleVisualizeOpen: () => void;
  moduleName: string;
  isFilterApplied?: boolean;
  inFavourites?: boolean;
  inProjects?: any[];
  setInFavourites?: any;
  setInProjects?: any;
  internalSearchId?: string;
};

const Header = ({
  title,
  stats,
  handleVisualizeOpen,
  moduleName,
  isFilterApplied = false,
  inFavourites = false,
  inProjects = [],
  setInFavourites = null,
  setInProjects = null,
  internalSearchId = ''
}: SubHeaderProps) => {
  return (
    <Box display='flex' bgcolor='white.main' px={5}>
      <p>
        <SubHeaderPipeGreyIcon sx={styles.subHeaderPipeGreyIcon} />
      </p>
      <Box sx={styles.statsContainer}>
        <Typography sx={styles.title}>{title}</Typography>
        <Box sx={styles.contentContainer}>
          <Stats stats={stats} />
          <Stack direction='row' spacing={3}>
            {isFilterApplied && (
              <Stack direction='row' alignItems='center'>
                {MODULE_TABS_SOURCE_LIST.includes(moduleName) && (
                  <FavoriteAndProjectActions
                    invokedBy='chmp'
                    source=''
                    id={internalSearchId}
                    isButton
                    isFavorite={inFavourites ?? false}
                    inProjects={inProjects ?? []}
                    setFavoriteInHook={({ isFavorite }: { isFavorite: boolean }) => {
                      setInFavourites(isFavorite);
                    }}
                    setInProjectInHook={({ project }: { project: any }) => {
                      let projects: any = [];
                      if (project?.inProject) {
                        projects = inProjects.filter(
                          (p: any) =>
                            (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
                        );
                      } else {
                        projects = [
                          ...(inProjects ?? []),
                          {
                            id: project?.project_id ?? project?.id,
                            name: project?.name
                          }
                        ];
                      }
                      setInProjects(projects);
                    }}
                  />
                )}
              </Stack>
            )}
            <Button
              onClick={handleVisualizeOpen}
              id='visualize-button'
              startIcon={<ReportIcon sx={styles.primaryButtonIcon} />}
              sx={styles.primaryButton}>
              Visualize & Report
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Header);
