import { useEffect, useState } from 'react';
import { Box, Typography, Tooltip, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { InfoIconSummary } from '../../assets/svgs/Icons';
import { filterSidebarStyles, styles } from './styles';
import { SearchKeywordProps } from './types';

const SearchKeyword = ({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  searchLabel = 'Search Keywords',
  searchValue = 'filter_keywords',
  isBottomFilterView = false,
  isMultiSearch = false
}: SearchKeywordProps) => {
  const [searchText, setSearchText] = useState('');
  const [searchedTexts, setSearchedTexts] = useState<string[]>([]);
  const [showAllSearchedTexts, setShowAllSearchedTexts] = useState(false);
  const [formattedSearchedTexts, setFormattedSearchedTexts] = useState<string[]>([]);

  useEffect(() => {
    setSearchedTexts(selectedCategoryOptions[searchValue] || []);
    setFormattedSearchedTexts(selectedCategoryOptions[searchValue]?.slice(0, 4) || []);
  }, [selectedCategoryOptions]);

  const handleSearch = (ev: any) => {
    ev.preventDefault();
    if (searchText && !searchedTexts.includes(searchText)) {
      setSearchedTexts([...searchedTexts, searchText]);
      setFormattedSearchedTexts([...searchedTexts, searchText].slice(0, 4));
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [searchValue]:
          selectedCategoryOptions && selectedCategoryOptions[searchValue]
            ? [...selectedCategoryOptions[searchValue], searchText]
            : [searchText]
      });
    }
    setSearchText('');
    setShowAllSearchedTexts(false);
  };

  const handleRemoveSearchText = (value: any) => {
    const remainingSearchedTexts = searchedTexts.filter(text => text !== value);
    setSearchedTexts(remainingSearchedTexts);
    setFormattedSearchedTexts(remainingSearchedTexts.slice(0, 4));
    if (remainingSearchedTexts.length === 0) {
      const { [searchValue]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions(others);
    } else {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [searchValue]: remainingSearchedTexts
      });
    }
  };

  return (
    <>
      <Box display='flex' alignItems='center'>
        <Typography
          fontSize={14}
          fontWeight={isBottomFilterView || isMultiSearch ? 600 : 400}
          color={isBottomFilterView && !isMultiSearch ? 'primary.700' : 'gray.900'}>
          {searchLabel}
        </Typography>
        {searchValue === 'filter_keywords' && (
          <Tooltip
            placement='top'
            title='Use keywords to narrow results across all or specific categories below.'>
            <InfoIconSummary sx={filterSidebarStyles.infoIcon} />
          </Tooltip>
        )}
      </Box>
      <form onSubmit={handleSearch}>
        <TextField
          placeholder='Search Keywords'
          value={searchText}
          onChange={(ev: any) => setSearchText(ev.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon onClick={handleSearch} sx={styles.icon} />
              </InputAdornment>
            ),
            sx: {
              borderRadius: 2,
              fontSize: 14,
              width: isMultiSearch ? 212 : 242,
              height: isMultiSearch ? 36 : 44
            }
          }}
          sx={filterSidebarStyles.search}
        />
        <Box
          display='flex'
          gap={1}
          flexWrap='wrap'
          mt={0.6}
          sx={{ ...styles.scrollContainer, maxHeight: 98, overflowY: 'scroll' }}>
          {formattedSearchedTexts.map((searchedText: any) => (
            <Box sx={filterSidebarStyles.searchedText} key={searchedText} display='flex'>
              <Typography fontSize={12} fontWeight={700} color='primary.600' sx={{ mr: 0.5 }}>
                {searchedText}
              </Typography>
              <CloseIcon
                onClick={() => handleRemoveSearchText(searchedText)}
                sx={{ cursor: 'pointer', fontSize: 12, color: 'primary.avatar' }}
              />
            </Box>
          ))}
          {searchedTexts?.length > 4 && (
            <Box
              width={showAllSearchedTexts ? 68 : '1.6rem'}
              height={showAllSearchedTexts ? 22 : '1.6rem'}
              sx={{
                ...filterSidebarStyles.hideShowSearchedText,
                borderRadius: showAllSearchedTexts ? 3 : '50%'
              }}
              onClick={() => {
                if (!showAllSearchedTexts) {
                  setFormattedSearchedTexts(searchedTexts);
                } else {
                  setFormattedSearchedTexts(searchedTexts.slice(0, 4));
                }
                setShowAllSearchedTexts(!showAllSearchedTexts);
              }}>
              {showAllSearchedTexts ? 'Show Less' : `+${searchedTexts.length - 4}`}
            </Box>
          )}
        </Box>
      </form>
    </>
  );
};

export default SearchKeyword;
