import React, { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// component
import DocumentListing from '../../../components/Dialogs/DocumentListing';

// utils
import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import getDateString from '../../../utils/getDateString';
// api
import { getCanadaConsultationDocDetails } from '../../../api/pages/CanadaConsultation';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { FolderIcon } from '../../../assets/svgs/Icons';

const GridCellDocuments: React.FC<any> = ({ data: rowData }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<Array<any>>([]);

  const getData = async () => {
    const res = await getCanadaConsultationDocDetails(rowData.consultation_id);
    setData(res?.data?.body ?? []);
  };

  const handleOpenPopup = async (e: any) => {
    e.preventDefault();
    await getData();
    setOpen(true);
  };

  const documents: DocumentsGroup[] = useMemo(() => {
    if (!data) return [];

    // Helper function to convert category keys to title case
    const toTitleCase = (str: string) => {
      return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    };

    // Process each key in the data object
    return Object.keys(data).map((key: any) => {
      const docs: DocumentItem[] = data[key]?.map((item: any) => ({
        title: item.doc_title?.split('.pdf')[0]?.replaceAll('_', ' ')?.replaceAll('-', ' '),
        title_link: item.s3_url
      }));

      const group: DocumentsGroup = {
        id: key,
        categoryTitle: toTitleCase(key),
        documents: docs
      };

      return group;
    });
  }, [data]);

  const chatRIAMetadata = useMemo(() => {
    return {
      title: rowData?.title ?? '',
      issued_date: rowData?.start_date ?? '',
      end_date: rowData?.end_date ?? '',
      document_type: rowData?.document_type ?? ''
    };
  }, [data]);

  const metadata = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    return [rowData?.start_date, rowData?.title].filter(
      (title: string) => !!title && title !== 'NA'
    );
  }, [data]);

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12, color: 'primary.600' }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {rowData?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>

      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={rowData?.start_date ? getDateString(rowData?.start_date) : rowData?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source='canada-consultation'
          metadata={metadata}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
          allowComparison={rowData?.document_count > 1}
        />
      )}
    </Box>
  );
};

const DocumentHistory = ({ row }: any) => {
  return <GridCellDocuments data={row} />;
};

export default DocumentHistory;
