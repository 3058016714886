import React, { memo, useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
// component
import BasicTimeline from './TimelineDetails';
// styles
import styles from '../../PDCO/styles/Timeline.styles';
import {
  AngleDownIcon,
  CloseCrossIcon,
  CustomCheckIcon,
  InfoIconSummary
} from '../../../assets/svgs/Icons';
import TitleInfoPopover from '../../../components/Dialogs/DocumentListing/components/TitleInfoPopover';

const Timeline = ({ timelineDetails, closeTimeline, isOpen, infoComponent, onClose }: any) => {
  const [infoElement, setInfoElement] = useState(null);

  const [option, setOption] = useState('all');
  const optionList = [
    { value: 'all', label: 'All' },
    { value: 'patent', label: 'Patent' },
    { value: 'exclusivity', label: 'Exclusivity' }
  ];

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      transitionDuration={500}
      hideBackdrop={false}
      onClose={async () => {
        closeTimeline(false);
      }}
      sx={{
        ...styles.drawer,
        '& >.MuiDrawer-paper': {
          width: {
            md: '40%'
          },
          overflow: 'hidden',
          boxShadow: '-0.1em 0.1em 5em #33b1874a',
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12
        }
      }}>
      <Box bgcolor='primary.600' py={1.5} px={2.5}>
        <Stack direction='row' alignItems='center'>
          <Tooltip title={timelineDetails?.applicationDetails?.title}>
            <Typography sx={{ ...styles.drawerTitle, maxWidth: !infoElement ? '90%' : '45%' }}>
              {timelineDetails?.applicationDetails?.title}
            </Typography>
          </Tooltip>

          {infoComponent && (
            <IconButton
              sx={{ p: 0.75, ml: 1 }}
              onClick={(event: any) => setInfoElement(event.currentTarget)}>
              <InfoIconSummary sx={styles.drawerHeaderIcon} />
            </IconButton>
          )}
          <IconButton sx={styles.closeIconButton} onClick={onClose}>
            <CloseCrossIcon sx={styles.drawerHeaderIcon} />
          </IconButton>
        </Stack>
        <TitleInfoPopover
          open={!!infoElement}
          anchorEl={infoElement}
          infoComponent={infoComponent}
          onClose={() => setInfoElement(null)}
        />
      </Box>
      <Box px={2.5} py={2}>
        <Stack direction='row' alignItems='center'>
          <Select
            value={option}
            sx={styles.categorySelection}
            onChange={(event: any) => setOption(event.target.value)}
            MenuProps={{ PaperProps: { sx: styles.categorySelectionPopover } }}
            IconComponent={AngleDownIcon}>
            {optionList.map((doc: any) => (
              <MenuItem
                value={doc.value}
                key={doc.value}
                sx={{
                  px: 3,
                  '& .Mui-selected': {
                    bgcolor: 'primary.0'
                  }
                }}>
                <ListItemText
                  sx={{
                    m: 0,
                    span:
                      option === doc.value
                        ? {
                            fontSize: 13,
                            fontWeight: 700,
                            color: 'gray.800',
                            letterSpacing: 0.2
                          }
                        : {
                            fontSize: 13,
                            fontWeight: 400,
                            color: 'gray.800',
                            letterSpacing: 0.2
                          }
                  }}>
                  {doc.label}
                </ListItemText>
                <ListItemIcon>
                  {option === doc.value && (
                    <CustomCheckIcon sx={styles.secondaryDrawerListItemSelectedCheckbox} />
                  )}
                </ListItemIcon>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Box>
      <Divider sx={{ my: '12px', borderColor: 'gray.200' }} />
      <Box my={2}>
        <Typography
          color='gray.900'
          fontSize={14}
          fontWeight={700}
          lineHeight='140%'
          sx={{ pl: 2.2 }}>
          {`Timeline of “${timelineDetails?.applicationDetails?.title}” Patent & Exclusivity`}
        </Typography>
      </Box>
      <BasicTimeline timelineTrack={timelineDetails?.timeline ?? []} option={option} />
    </Drawer>
  );
};

export default memo(Timeline);
