const styles = {
  pdfViewerContainer: {
    position: 'fixed',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  divider: {
    borderColor: 'gray.200'
  },
  loadingSkeleton: {
    bgcolor: 'gray.200',
    height: 30
  },
  insightsContainer: {
    mt: 0.5,
    px: 4,
    py: 3,
    borderRadius: 4,
    backgroundColor: 'gray.50',
    border: '1px solid',
    borderColor: 'gray.300'
  },
  insightContent: {
    '& h1, & h2': {
      fontSize: 16,
      fontWeight: 600,
      color: 'black.main'
    },
    '& h3, & h4, & h5, & h6': {
      fontSize: 15,
      fontWeight: 600,
      color: 'black.main'
    },
    '& strong': {
      color: 'gray.800'
    }
  },
  numberText: { color: 'gray.900', fontWeight: 700, fontSize: 13 }
};

export default styles;
