import React, { useMemo, useCallback, useState, useRef } from 'react';
import {
  Stack,
  Box,
  TextField,
  InputAdornment,
  Typography,
  Divider,
  Button,
  Tooltip,
  Checkbox,
  debounce
} from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import MagnifyingGlassIcon from '../../../assets/svgs/Icons/MagnifyingGlassIcon';
import VivproDatagrid from '../../../components/Datagrid';
import { ICH_GUIDELINE_COLUMN, CSV_FILENAME, ICH_GUIDELINE_SOURCE } from '../contants';
import ComparsionSelectionDialog from '../../../components/Comparison/CustomComparison/ComparsionSelectionDialog';
import { getComparisonMetadata } from '../utils';
import { getFileName } from '../../../helpers/getFileName';

const DataGrid = ({ data, isLoading, fetchGuidelineDetails, appliedFilters }: any) => {
  const [documentsToCompare, setDocumentsToCompare] = useState<any>([]);
  const [openComparisonPopup, setOpenComparisonPopup] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const handleComparisonCheckbox = useCallback(
    async (id: string) => {
      // eslint-disable-next-line no-underscore-dangle
      if (documentsToCompare.find((doc: any) => doc._id === id)) {
        // eslint-disable-next-line no-underscore-dangle
        setDocumentsToCompare(documentsToCompare?.filter((doc: any) => doc._id !== id));
      } else {
        // eslint-disable-next-line no-underscore-dangle
        const selectedDocuments = data?.find((doc: any) => doc._id === id);
        selectedDocuments.documents = selectedDocuments?.documents?.filter(
          (each: any) => each?.is_ich
        );
        // eslint-disable-next-line no-underscore-dangle
        setDocumentsToCompare([...documentsToCompare, selectedDocuments]);
      }
    },
    [data, documentsToCompare]
  );

  const renderAbleTableColumns = useMemo(() => {
    const renderAbleColumns = [...ICH_GUIDELINE_COLUMN];
    renderAbleColumns.unshift({
      field: '_id',
      headerName: 'Select',
      width: 60,
      headerClassName: 'table-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      disableExport: true,
      sortable: false,
      renderCell: (params: any) => {
        const row = params?.row;
        const value = params?.value;
        return (
          <Tooltip
            title={
              documentsToCompare.length === 2 &&
              // eslint-disable-next-line no-underscore-dangle
              documentsToCompare.find((doc: any) => doc._id === value)
                ? 'Selection limit reached'
                : ''
            }>
            <Box key={value}>
              <Checkbox
                disabled={
                  row?.documents?.length === 0 ||
                  (documentsToCompare.length === 2 &&
                    // eslint-disable-next-line no-underscore-dangle
                    !documentsToCompare.find((doc: any) => doc._id === value))
                }
                // eslint-disable-next-line no-underscore-dangle
                checked={!!documentsToCompare.find((doc: any) => doc._id === value)}
                onClick={() => handleComparisonCheckbox(value)}
              />
            </Box>
          </Tooltip>
        );
      }
    });

    return renderAbleColumns;
  }, [documentsToCompare, data]);

  const getCompareList = useMemo(() => {
    return getComparisonMetadata(documentsToCompare);
  }, [documentsToCompare]);

  const updateSearch = useCallback(
    (query: string) => {
      // Cancel the previous request if it exists
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }
      // Create a new cancel token source
      cancelTokenSourceRef.current = axios.CancelToken.source();
      const allFilters = {
        ...appliedFilters,
        ...(query?.length > 0 ? { filter_keywords: [query] } : {})
      };

      if (query?.length < 1) {
        delete allFilters.filter_keywords;
      }
      fetchGuidelineDetails(allFilters, false, cancelTokenSourceRef.current.token);
    },
    [appliedFilters]
  );

  const debouncedChangeHandler = useMemo(() => debounce(updateSearch, 200), [updateSearch]);

  const handleInputTextChange = (event: any) => {
    setSearchQuery(event.target.value);
    debouncedChangeHandler(event.target.value);
  };

  return (
    <>
      <Stack
        sx={{
          height: 'calc(100vh - 74px)',
          pb: 0,
          mt: 0,
          '& .MuiDataGrid-root': {
            width: '100%',
            '& .MuiDataGrid-columnHeaderTitle': {
              textTransform: 'none'
            }
          },
          '& .MuiDataGrid-virtualScroller': {
            overflowX: 'hidden'
          }
        }}>
        <Box mt={2}>
          <Stack
            direction='row'
            sx={{
              justifyContent: 'space-between',
              px: '28px',
              height: '30px'
            }}>
            <TextField
              sx={{
                '& .MuiInputBase-root': { borderRadius: '32px', height: '30px', width: '300px' }
              }}
              placeholder='Find Guidelines'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <MagnifyingGlassIcon sx={{ height: '14px', width: '14px' }} />
                  </InputAdornment>
                )
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: 75
              }}
              onChange={handleInputTextChange}
              value={searchQuery}
            />
            <Stack direction='row' spacing={2} alignItems='center'>
              <Box>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: documentsToCompare?.length > 0 ? 'gray.800' : 'gray.500'
                  }}>
                  {documentsToCompare.length} selected
                </Typography>
              </Box>
              <Divider orientation='vertical' sx={{ height: 16 }} />
              <Box>
                <Typography
                  onClick={() => {
                    setDocumentsToCompare([]);
                  }}
                  sx={{
                    color: documentsToCompare?.length > 0 ? 'primary.500' : 'gray.500',
                    fontSize: '14px',
                    fontWeight: 600,
                    cursor: documentsToCompare?.length > 0 ? 'pointer' : 'default'
                  }}>
                  Reset
                </Typography>
              </Box>
              <Divider orientation='vertical' sx={{ height: 16 }} />
              <Button
                sx={{
                  height: '30px',
                  padding: '15px 24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '16px',
                  backgroundColor: 'primary.650',
                  color: 'white.main',
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: 700,
                  '&:hover': {
                    backgroundColor: 'white.main',
                    border: 1,
                    borderColor: 'primary.main',
                    borderStyle: 'solid',
                    color: 'primary.600'
                  },
                  '&:disabled': {
                    backgroundColor: 'gray.550',
                    color: 'gray.background'
                  }
                }}
                onClick={() => setOpenComparisonPopup(true)}
                disabled={documentsToCompare.length !== 2}>
                Compare
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ my: '16px', mx: '28px' }} />
        </Box>
        <Stack
          direction='row'
          sx={{
            justifyContent: 'space-between',
            px: '28px'
          }}>
          <Box
            sx={{
              borderRadius: '6px',
              p: '2px 8px',
              backgroundColor: 'gray.100',
              height: '21px'
            }}>
            <Typography sx={{ color: 'gray.700', fontFamily: 'Mulish', fontSize: '12px' }}>
              Showing <strong>{data?.length ?? 0}</strong> of{' '}
              <strong>{data?.length ?? 0} Guidelines</strong>
            </Typography>
          </Box>
        </Stack>

        <VivproDatagrid
          rows={data ?? []}
          columnsMapping={renderAbleTableColumns}
          rowId='id'
          csvFileName={getFileName(CSV_FILENAME)}
          noRowMessage='Loading...'
          loading={isLoading}
          rowCount={data?.length ?? 0}
          isServerSideExport={false}
          isFilterModeServer={false}
          allowFilterPanelWithLogicOperators
        />
      </Stack>
      {documentsToCompare?.length === 2 && (
        <ComparsionSelectionDialog
          open={openComparisonPopup}
          source={ICH_GUIDELINE_SOURCE}
          title='Compare Guidelines'
          comparsionType='guidance'
          onClose={() => setOpenComparisonPopup(false)}
          compareList={getCompareList}
          showBackButton
          onBack={() => setOpenComparisonPopup(false)}
        />
      )}
    </>
  );
};

export default React.memo(DataGrid);
