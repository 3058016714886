import { memo, useMemo } from 'react';
import { groupBy } from 'lodash';

import { Box, Stack, Typography } from '@mui/material';
import { getLabelForDate } from '../../utils';
import ActivityListItem from './ActivityListItem';
import landingPageStyles from '../../styles/LandingPage.styles';

const Activities = ({ activityData }: any) => {
  const groupResultsByDate = (results: any[]) => {
    const groupedByDate = groupBy(results, (result: any) => {
      const dateInUserTimezone = new Date(result.date);
      return dateInUserTimezone.toISOString().split('T')[0]; // Group by UTC date
    });

    return Object.entries(groupedByDate).map(([dateWithoutTime, dateResults]) => {
      const sortedResults = dateResults.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      const resultWithLatestDate = sortedResults[0]; // Safely pick the latest

      return {
        ...resultWithLatestDate,
        date: new Date(resultWithLatestDate.date),
        dateWithoutTime,
        quantity: dateResults.length,
        activityIds: dateResults.map((result: any) => result.activity_id),
        activityTime: sortedResults.map((result: any) => new Date(result.date))
      };
    });
  };

  const finalData = useMemo(() => {
    // Group data by ID first
    const dataGroupedById = groupBy(activityData, 'id');

    // Group by date within each ID group
    const dataGroupedByIdAndDate = Object.entries(dataGroupedById).map(([, results]) =>
      groupResultsByDate(results)
    );

    // Flatten and regroup by dateWithoutTime
    const groupedByDate = Object.entries(groupBy(dataGroupedByIdAndDate.flat(), 'dateWithoutTime'));

    // Sort the final grouped data by dateDescending
    return groupedByDate.sort(
      ([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime()
    );
  }, [activityData]);

  return (
    <Box>
      {finalData?.map(([date, results]) => (
        <Box key={date} mb='12px'>
          <Stack justifyContent='center' width='100%'>
            <Box sx={landingPageStyles.dateChip}>
              <Typography>{getLabelForDate(date)}</Typography>
            </Box>
          </Stack>
          {results.map(result => (
            <ActivityListItem
              key={result.activity_id}
              data={result}
              date={result.date}
              quantity={result.quantity ?? 0}
              title={result.term}
              searchType={result.type}
              source={result.source}
              isModule={result.isModule}
              moduleRedirectUrl={result.moduleRedirectUrl}
              showDivider
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default memo(Activities);
