import { Box, Typography } from '@mui/material';

const CAProducts = ({ data }: any) => {
  return (
    <Box>
      <Typography>
        <b>DIN number:</b> {data.din}
      </Typography>
      <Typography>
        <b>Strength:</b> {data?.active_ingredients?.[0]?.strength}{' '}
        {data?.active_ingredients?.[0]?.strength_unit} {data?.active_ingredients?.[0]?.dosage_unit}
      </Typography>
      <Typography>
        <b>Schedule:</b> {data?.schedule}
      </Typography>
      <Typography>
        <b>ATC Code:</b> {data?.atc_code}
      </Typography>
      <Typography>
        <b>Current Status:</b> {data?.current_status}
      </Typography>
    </Box>
  );
};
export default CAProducts;
