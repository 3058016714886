const styles: any = {
  heading: {
    color: 'black.light',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: '700'
  },
  divider: {
    marginX: '8px',
    borderColor: 'gray.300',
    height: '24px',
    borderRightWidth: '1px'
  },
  changeButton: {
    cursor: 'pointer',
    color: 'secondary.700',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: '700'
  },
  backArrow: {
    color: 'gray.700',
    width: '24px',
    height: '24px'
  },
  reg360: { width: '40px', height: '28px', color: 'gray.700' },
  separator: { mr: 2 },
  cdpButton: {
    height: 32,
    py: 0.75,
    px: 2,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    backgroundColor: 'primary.600',
    color: 'white.main',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'primary.700'
    }
  },
  reportIcon: {
    height: 15,
    width: 15,
    mr: 1
  }
};

export default styles;
