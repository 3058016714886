import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { memo, useCallback, useContext, useMemo, useState, SyntheticEvent } from 'react';
import { groupBy } from 'lodash';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

import DocumentListing from '../../../components/Dialogs/DocumentListing';
import Card from '../../SearchResults/components/Card';

import styles from './styles/faqDetails.styles';
import CaretDownIcon from '../../../assets/svgs/Icons/CaretDown';
import DocumentIcon from '../../../assets/svgs/PDCO/DocumentIcon';

const FaqDetails = ({ dataItem }: any) => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const [expanded, setExpanded] = useState<string | false>('');
  const [isDocumentsDrawerOpen, setIsDocumentsDrawerOpen] = useState<boolean>(false);

  const handleChange = useCallback(
    (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    []
  );

  const documents = useMemo(() => dataItem?.overview?.files ?? [], [dataItem]);

  const documentsList = useMemo(() => {
    const allGroups: any = [];
    const documentGroups = groupBy(documents, 'category');

    Object.keys(documentGroups).forEach((groupName: string) => {
      const docs = documentGroups[groupName].map((item: any) => ({
        title: item?.name,
        title_link: item?.link,
        category: groupName
      }));
      const group = {
        id: groupName.toLowerCase(),
        categoryTitle: groupName,
        documents: docs
      };
      allGroups.push(group);
    });

    return allGroups;
  }, [documents]);

  const chatRiaMetadata = useMemo(
    () => ({
      active_ingredient: `${regulatoryState?.applicationDetails?.active_ingredients} (${regulatoryState?.applicationDetails?.title})`,
      application_number: regulatoryState?.applicationDetails?.application_number
    }),
    [regulatoryState]
  );

  return (
    <Stack spacing={2} sx={styles.container}>
      <Stack direction='row' sx={styles.headingContainer}>
        <Typography sx={styles.heading}>Details</Typography>
        {documents.length > 0 && (
          <>
            <Stack
              direction='row'
              sx={styles.documentsContainer}
              onClick={() => setIsDocumentsDrawerOpen(true)}>
              <DocumentIcon sx={styles.documentsIcon} />
              <Typography sx={styles.documentsText}>View Documents({documents.length})</Typography>
            </Stack>

            {isDocumentsDrawerOpen && (
              <DocumentListing
                disableChatRia={false}
                title={regulatoryState?.applicationDetails?.title}
                chatRIAMetadata={chatRiaMetadata}
                source={regulatoryState?.db}
                secondaryDrawerTitle=''
                defaultSelectedEntityId=''
                onClose={() => setIsDocumentsDrawerOpen(false)}
                defaultDocuments={documentsList}
                allowComparison={documents.length > 1}
                infoComponent={
                  <Box width={360}>
                    <Card
                      data={regulatoryState.applicationDetails}
                      hideApplication={() => {}}
                      triggerChatRIA={() => {}}
                      setFavoriteInHook={() => {}}
                      setInProjectInHook={() => {}}
                      setSubscriptionInHook={() => {}}
                      hideCardOptions
                    />
                  </Box>
                }
              />
            )}
          </>
        )}
      </Stack>
      {dataItem?.overview?.summary && (
        <Box sx={styles.infoContainer}>
          <Typography sx={styles.subHeading}>{dataItem?.overview?.summary}</Typography>
        </Box>
      )}
      {dataItem?.overview?.faq ? (
        <Box sx={styles.infoContainer}>
          <Box sx={styles.divider}>
            {dataItem?.overview?.faq?.map((item: any, index: number) => (
              <Accordion
                key={item?.question}
                elevation={0}
                sx={{
                  ...styles.accordion,
                  borderTop: index !== 0 && expanded === item?.question ? '1px solid #ddd' : 'none'
                }}
                expanded={expanded === item?.question}
                onChange={handleChange(item?.question)}>
                <AccordionSummary
                  aria-controls='panel1d-content'
                  id='panel1d-header'
                  sx={styles.accordionStyle}>
                  <Typography sx={styles.accordionTitle}>{item?.question}</Typography>
                  <IconButton sx={styles.iconButton}>
                    <CaretDownIcon
                      sx={{
                        ...styles.caretDownIcon,
                        rotate: expanded === item?.question ? '180deg' : '0deg'
                      }}
                    />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={styles.subHeading}>{item?.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={styles.noContentContainer}>
          <Typography sx={styles.noContentheading}>No Details Available</Typography>
        </Box>
      )}
    </Stack>
  );
};

export default memo(FaqDetails);
