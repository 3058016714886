/* eslint-disable no-dupe-keys */
const styles: { [key: string]: any } = {
  container: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      bgcolor: '#f2f2f2'
    }
  },
  moreText: {
    color: 'primary.avatar',
    fontSize: '13px',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'inline',
    textAlign: 'justify'
  },
  text: {
    fontSize: '13px',
    color: 'gray.darkStorm',
    fontWeight: '600',
    padding: '12px',
    maxWidth: '100%',
    textAlign: 'center',
    display: '-webkit-box !important',
    WebkitLineClamp: '3  !important',
    WebkitBoxOrient: 'vertical  !important',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  },
  textAlignment: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    bgcolor: 'white.main'
  }
};

export default styles;
