import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { parseISO } from 'date-fns';
import { DateRangeProps } from './types';
import { FILTER_TYPE } from './constants';
import { dateYearRangeStyles } from './styles';
import { formatDate, isObject } from './utils';

const DateYearRange = ({
  rangeType,
  selectedCategory,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  shouldRefreshDate,
  setShouldRefreshDate
}: DateRangeProps) => {
  const [date, setDate] = useState<{ startDate: null | Date; endDate: null | Date }>({
    startDate: null,
    endDate: null
  });
  const [fromDateBoundary, setFromDateBoundary] = useState<{
    minDate: null | Date;
    maxDate: null | Date;
  }>({
    minDate: null,
    maxDate: null
  });
  const [toDateBoundary, setToDateBoundary] = useState<{
    minDate: null | Date;
    maxDate: null | Date;
  }>({
    minDate: null,
    maxDate: null
  });
  const selectedCategoryValueKey = selectedCategory?.elasticSearchType || selectedCategory?.value;

  const updateDateOnRefresh = () => {
    let startDate = null;
    let endDate = null;
    // if date option filter has already been applied, then add start date and end date to local state
    if (selectedCategoryOptions[selectedCategoryValueKey]) {
      // handle year range
      if (rangeType === FILTER_TYPE.YEAR_RANGE) {
        // slicing the dates to get the year only as in some cases although rangeType is year,
        //  we send full year i.e. yyyy-mm-dd to backend but when we need to display it in frontend we display year only
        startDate = new Date(
          selectedCategoryOptions[selectedCategoryValueKey][0].toString().slice(0, 4),
          0,
          1
        );
        endDate = new Date(
          selectedCategoryOptions[selectedCategoryValueKey][1].toString().slice(0, 4),
          12,
          0
        );
      }
      // handle date range
      else {
        startDate = parseISO(selectedCategoryOptions[selectedCategoryValueKey][0].toString());
        endDate = parseISO(selectedCategoryOptions[selectedCategoryValueKey][1].toString());
      }
    }
    setDate({
      startDate,
      endDate
    });

    // set boundary for min and max date displayed
    let selectedCategoryStartDate;
    let selectedCategoryEndDate;

    // if we have min and max date available from backend
    if (selectedCategory?.options?.length) {
      selectedCategoryStartDate = isObject(selectedCategory.options[0])
        ? selectedCategory.options[0].label
        : selectedCategory.options[0];

      selectedCategoryEndDate = isObject(
        selectedCategory.options[selectedCategory.options.length - 1]
      )
        ? selectedCategory.options[selectedCategory.options.length - 1].label
        : selectedCategory.options[selectedCategory.options.length - 1];
    }
    // default min and max date if backend does not send these data
    else {
      selectedCategoryStartDate = '1900';
      selectedCategoryEndDate = '2100';
    }

    // format the date on the basis of date being passed is only year or full date
    const formattedSelectedCategoryStartDate =
      String(selectedCategoryStartDate)?.trim().length === 4
        ? new Date(selectedCategoryStartDate, 0, 1)
        : new Date(new Date(selectedCategoryStartDate).getFullYear(), 0, 1);
    const formattedSelectedCategoryEndDate =
      String(selectedCategoryEndDate)?.trim().length === 4
        ? new Date(selectedCategoryEndDate, 12, 0)
        : new Date(new Date(selectedCategoryEndDate).getFullYear(), 12, 0);

    const formattedFromMinDate = formattedSelectedCategoryStartDate;
    const formattedToMaxDate = formattedSelectedCategoryEndDate;
    let formattedToMinDate;
    let formattedFromMaxDate;

    // if date filter is already applied then set min and max date from the applied fitler
    if (selectedCategoryOptions[selectedCategoryValueKey]) {
      formattedFromMaxDate = new Date(
        selectedCategoryOptions[selectedCategoryValueKey][1].toString()
      );
      formattedToMinDate = new Date(
        selectedCategoryOptions[selectedCategoryValueKey][0].toString()
      );
    } else {
      formattedFromMaxDate = formattedSelectedCategoryEndDate;
      formattedToMinDate = formattedSelectedCategoryStartDate;
    }

    setFromDateBoundary({
      minDate: formattedFromMinDate,
      maxDate: formattedFromMaxDate
    });

    setToDateBoundary({
      minDate: formattedToMinDate,
      maxDate: formattedToMaxDate
    });
  };

  useEffect(() => {
    // whenever we are clearing the date from bottom filters, that should reset the selected filters
    if (shouldRefreshDate) {
      updateDateOnRefresh();
      setShouldRefreshDate(false);
    }
  }, [shouldRefreshDate]);

  useEffect(() => {
    // if both startDate and endDate is not available i.e. when opening the date filter option for first time or refreshing the page
    if (!date.startDate && !date.endDate) {
      updateDateOnRefresh();
    }
  }, [selectedCategoryOptions]);

  useEffect(() => {
    if (date.startDate && date.endDate) {
      const formattedStartDate = formatDate(date.startDate);
      const formattedEndDate = formatDate(date.endDate);
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [selectedCategoryValueKey]: [
          rangeType === FILTER_TYPE.DATE_RANGE ? formattedStartDate : date.startDate.getFullYear(),
          rangeType === FILTER_TYPE.DATE_RANGE ? formattedEndDate : date.endDate.getFullYear()
        ]
      });
    } else if (!date.startDate && !date.endDate) {
      const { [selectedCategoryValueKey]: _, ...others } = selectedCategoryOptions;
      setSelectedCategoryOptions({ ...others });
    }
  }, [date?.startDate, date?.endDate]);

  const handleChange = (newDate: any, dateType: any) => {
    // handle date range type
    if (rangeType === FILTER_TYPE.DATE_RANGE) {
      if (dateType === 'startDate') {
        const currentDate = date?.endDate || fromDateBoundary.maxDate;

        setToDateBoundary({ ...toDateBoundary, minDate: newDate || toDateBoundary.minDate });
        setFromDateBoundary({ ...fromDateBoundary, maxDate: currentDate });
        setDate({ startDate: newDate, endDate: newDate ? currentDate : date.endDate });
      } else if (dateType === 'endDate') {
        const minDate = date?.startDate || toDateBoundary.minDate;

        setToDateBoundary({ ...toDateBoundary, minDate: toDateBoundary.minDate });
        setFromDateBoundary({ ...fromDateBoundary, maxDate: newDate || fromDateBoundary.maxDate });
        setDate({
          startDate: newDate ? minDate : date.startDate,
          endDate: newDate
        });
      }
    }

    // handle year range type
    else if (rangeType === FILTER_TYPE.YEAR_RANGE) {
      if (dateType === 'startDate') {
        const currentDate = date?.endDate || fromDateBoundary.maxDate;

        setToDateBoundary({
          ...toDateBoundary,
          minDate: newDate || toDateBoundary.minDate
        });
        setFromDateBoundary({ ...fromDateBoundary, maxDate: currentDate });
        setDate({ startDate: newDate, endDate: newDate ? currentDate : date.endDate });
      } else if (dateType === 'endDate') {
        const minDate = date?.startDate || toDateBoundary.minDate;

        setToDateBoundary({ ...toDateBoundary, minDate: toDateBoundary.minDate });
        setFromDateBoundary({
          ...fromDateBoundary,
          maxDate: newDate || fromDateBoundary.maxDate
        });
        setDate({
          startDate: newDate ? minDate : date.startDate,
          endDate: newDate
        });
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={dateYearRangeStyles.container}>
        <DatePicker
          minDate={fromDateBoundary.minDate}
          maxDate={fromDateBoundary.maxDate}
          views={rangeType === FILTER_TYPE.DATE_RANGE ? ['year', 'month', 'day'] : ['year']}
          onChange={newDate => handleChange(newDate, 'startDate')}
          value={date.startDate}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder='From'
              variant='outlined'
              id='start-date'
              size='small'
              inputProps={{ ...params.inputProps, placeholder: 'From' }}
              sx={{ background: 'white' }}
              error={false}
            />
          )}
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            OpenPickerIcon: () => <KeyboardArrowDownIcon sx={{ fontSize: 16 }} />
          }}
        />
        <DatePicker
          minDate={toDateBoundary.minDate}
          maxDate={toDateBoundary.maxDate}
          views={rangeType === FILTER_TYPE.DATE_RANGE ? ['year', 'month', 'day'] : ['year']}
          onChange={newDate => handleChange(newDate, 'endDate')}
          value={date.endDate}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder='To'
              variant='outlined'
              id='end-date'
              size='small'
              inputProps={{ ...params.inputProps, placeholder: 'To' }}
              error={false}
              sx={{ background: 'white' }}
            />
          )}
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            OpenPickerIcon: () => <KeyboardArrowDownIcon sx={{ fontSize: 16 }} />
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateYearRange;
