import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Drawer } from '@mui/material';
import useBusinessIntelligenceAdverseEventsData from '../../../components/ClinicalTrials/TimelineCT/hooks/useBusinessIntelligenceAdverseEventsData';
import styles from '../styles/Buttons.styles';
import Visualize from '../components/Visualize';
import { ReportIcon } from '../../../assets/svgs/Icons';
import useTimelineData from '../../../components/ClinicalTrials/TimelineCT/hooks/useTimelineData';
import ResultsStore from '../../../store/SearchResults';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import { handleCTGraphFetch } from '../../ResultsPage/hoc/withFetchingSearchResults';
import RESULT_VIEW_TYPES from '../components/constants';
import getSearchPathParams from '../../../components/Header/utils/getSearchPathParams';
import HomePage from '../../EMAMeetings/components/common/HomePage';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';

const VisualizeAndReportButton = ({
  disabled,
  applications,
  isModuleTab
}: {
  disabled: boolean;
  applications: any[];
  isModuleTab?: boolean;
}) => {
  const { resultsState } = useContext(ResultsStore);
  const location = useLocation();
  const [openVisulize, setOpenVisulize] = useState<boolean>(false);
  const { getTimelineData } = useTimelineData();
  const handleClose = () => {
    setOpenVisulize(false);
  };

  const { fetchCTBusinessSearchResultsFromId, fetchCTAdverseEventsResultsFromId } =
    useBusinessIntelligenceAdverseEventsData();
  // this is used to track if searchId is changed and re-call the api accordingly
  const isSearchIdChangedRef = useRef(true);

  useEffect(() => {
    isSearchIdChangedRef.current = true;
  }, [resultsState?.searchIds?.ctSearchId]);

  const getSearchId = useMemo(() => {
    if (resultsState?.viewType === 'chmp') {
      return resultsState?.searchIds?.chmpSearchId;
    }
    return '';
  }, [resultsState?.viewType, resultsState?.searchIds?.chmpSearchId]);

  useEffect(() => {
    if (resultsState.viewType !== RESULT_VIEW_TYPES.CT) return;

    if (
      openVisulize &&
      isSearchIdChangedRef.current &&
      'ct' in (resultsState?.decryptedSource ?? {})
    ) {
      const { searchId }: any = getSearchPathParams(location.pathname);
      const {
        search_term: searchTerm,
        entity_text: entityText,
        entity_category: ctEntityCategory,
        search_type: ctSearchType
      }: any = decodeBase64ToObject(searchId ?? '');
      fetchCTAdverseEventsResultsFromId(resultsState?.searchIds?.ctSearchId);
      fetchCTBusinessSearchResultsFromId(resultsState?.searchIds?.ctSearchId);
      const { cnfOfSelectedQuery, dateRange, useSynonyms } = handleCTGraphFetch(
        ctSearchType,
        resultsState.decryptedPayload,
        resultsState.filters,
        ctEntityCategory || 'indications_and_usage',
        entityText || searchTerm
      );
      getTimelineData(cnfOfSelectedQuery, '', dateRange, false, useSynonyms);
      isSearchIdChangedRef.current = false;
    }

    if (
      openVisulize &&
      isSearchIdChangedRef.current &&
      resultsState?.decryptedSource?.eu?.includes('euctr')
    ) {
      const { searchId }: any = getSearchPathParams(location.pathname);
      const {
        search_term: searchTerm,
        entity_text: entityText,
        entity_category: ctEntityCategory,
        search_type: ctSearchType
      }: any = decodeBase64ToObject(searchId ?? '');
      const { cnfOfSelectedQuery, dateRange, useSynonyms } = handleCTGraphFetch(
        ctSearchType,
        resultsState.decryptedPayload,
        resultsState.filters,
        ctEntityCategory || 'indications_and_usage',
        entityText || searchTerm
      );
      getTimelineData(cnfOfSelectedQuery, '', dateRange, false, useSynonyms, 'euctr');
    }
  }, [openVisulize, resultsState.viewType, location.pathname, resultsState?.decryptedSource]);

  return (
    <>
      <Button
        onClick={() => {
          setOpenVisulize(true);
        }}
        disabled={disabled || resultsState.isContentLoading}
        id='visualize-button'
        startIcon={
          <ReportIcon
            sx={
              disabled || resultsState.isContentLoading
                ? styles.primaryButtonIconLoading
                : styles.primaryButtonIcon
            }
          />
        }
        sx={styles.primaryButton}>
        Visualize & Report
      </Button>
      {isModuleTab ? (
        <BottomDrawer
          isDrawerOpen={openVisulize}
          closeDrawer={handleClose}
          hideReportIcon
          hideDivider>
          <HomePage
            stats={applications}
            loading={resultsState?.isContentLoading}
            source={resultsState?.viewType}
            searchId={getSearchId}
          />
        </BottomDrawer>
      ) : (
        <Drawer anchor='bottom' open={openVisulize} sx={styles.visualizeDrawer}>
          <Visualize handleClose={handleClose} applications={applications} />
        </Drawer>
      )}
    </>
  );
};

VisualizeAndReportButton.defaultProps = {
  isModuleTab: false
};

export default memo(VisualizeAndReportButton);
