import { EXPLORE_FEATURES } from '../Home/const';
import {
  NestedSubNotification,
  ParentNotification,
  SubNotificationType
} from './components/NotificationInterface';

export const SOURCE_TO_DISPLAY_NAME_MAPPING = {
  us: { display: 'US FDA - Drugs & Biologics', description: 'US FDA - Drugs & Biologics' },
  eu: { display: 'EU EMA - Drugs & Biologics', description: 'EU EMA - Drugs & Biologics' },
  ca: { display: 'Canada - Drugs & Biologics', description: 'Canada - Drugs & Biologics' },
  ct: { display: 'Clinical Trials - USA', description: 'Clinical Trials - USA' },
  'us-devices': { display: 'US FDA - Devices', description: 'US FDA - Devices' },
  hpra: {
    display: 'EU - NCA & MR - Ireland',
    description: 'Ireland - Drugs & Biologics'
  },
  adcomm: {
    display: 'US FDA - Adcomm',
    description: 'US FDA - Advisory Committee',
    url: '/adcomm?committee=ALL&meetingId=PCNSDAC_2024-06-10&tab=summary',
    icon: EXPLORE_FEATURES.advisoryCommittee.Icon
  },
  chmp: {
    display: 'EU EMA - CHMP',
    description: 'EU EMA - Committee for Medicinal Products for Human Use',
    url: '/chmp',
    icon: EXPLORE_FEATURES.ema_meetings.Icon
  },
  guidance: {
    display: 'US FDA - Guideline',
    description: 'US FDA - Guideline',
    url: '/guidanceDocuments',
    icon: EXPLORE_FEATURES.guidance.Icon
  },
  'ema-guidance': {
    display: 'EU EMA - Guideline',
    description: 'EU EMA - Guideline',
    url: '/ema_guidance',
    icon: EXPLORE_FEATURES.guidance.Icon
  },
  'ca-guidance': {
    display: 'Canada - Guideline',
    description: 'Canada - Guideline',
    url: '/canada-guidance',
    icon: EXPLORE_FEATURES.guidance.Icon
  },
  'ca-consultations': {
    display: 'Canada - Consultation',
    description: 'Canada - Consultation',
    url: '/consultation/canada',
    icon: EXPLORE_FEATURES.consultation.Icon
  },
  'fda-letters': {
    display: 'US FDA - Compliance Letter',
    description: 'US FDA - Compliance Letter',
    url: '/letters',
    icon: EXPLORE_FEATURES['fda-letters'].Icon
  },
  'fda-written-request': {
    display: 'US FDA - Written Requests',
    description: 'US FDA - Written Requests',
    url: '/pediatrics',
    icon: EXPLORE_FEATURES.pediatrics.Icon
  },
  hma: { display: 'EU - NCA & MR - HMA', description: 'HMA - Drugs & Biologics' },
  jp: { display: 'Japan - PMDA', description: 'Japan PMDA - Drugs & Biologics' },
  mhra: { display: 'UK - MHRA', description: 'UK MHRA - Drugs & Biologics' },
  sec: { display: 'Financial - SEC Edgar', description: 'Financial - SEC Edgar Filings' },
  'sec-edgar': { display: 'Financial - SEC Edgar', description: 'Financial - SEC Edgar Filings' }
};

// Used for dashboard of sources. Empty sources will be filled when we have the dashboards.
export const SOURCE_LEVEL_REDIRECT_URL = {
  us: '',
  eu: '',
  ca: '',
  ct: '',
  hma: '',
  jp: '',
  'us-devices': '',
  hpra: '',
  adcomm: '/adcomm',
  chmp: '/chmp',
  guidance: '/guidanceDocuments',
  'ema-guidance': '/ema_guidance',
  'fda-letters': 'letters',
  'ca-guidance': '/canada-guidance',
  'ca-consultations': '/consultation/canada'
};

export const SOURCE_WITH_REG_360 = [
  'us',
  'eu',
  'ca',
  'ct',
  'us-devices',
  'hpra',
  'hma',
  'jp',
  'mhra'
];

export const CHANGE_TYPE_FONT_COLOR_MAPPING = {
  add: 'primary.main',
  update: 'orange.main',
  delete: 'red.main'
};

export const ADVANCED_SEARCH_CHANNEL_NAMES = ['advanced_search', 'advanced_search_filters'];

export const APPLICATION_SEARCH_CHANNEL_NAMES = ['quick_search', ...ADVANCED_SEARCH_CHANNEL_NAMES];

export const CHANNELS = [
  'application',
  ...APPLICATION_SEARCH_CHANNEL_NAMES,
  'document_search',
  'data_source'
] as const;

export const NOTIFICATION_CHANGE_TYPE = ['add', 'update', 'delete'] as const;

export const INITIAL_SELECTED_NOTIFICATION: ParentNotification = {
  change_type: NOTIFICATION_CHANGE_TYPE[0],
  channel: {
    displayName: '',
    id: 0,
    name: CHANNELS[5]
  },
  clicked: false,
  created_at: '',
  curation_id: '',
  entity_id: '',
  entity_id_name: '',
  entity_id_type: '',
  entity_module_changed: '',
  entity_module_changed_id: '',
  entity_module_changed_type: '',
  s3_path: '',
  s3_bucket: '',
  file_name: '',
  heading: '',
  id: [0],
  message: '',
  seen: false,
  source: 'us',
  application_data: {
    application_type: '',
    center: '',
    source_index: null
  },
  count_info: {},
  notifications: {
    Documents: [] as NestedSubNotification[]
  } as SubNotificationType,
  timestamp: '',
  updates_count: 0,
  searchData: {
    date: '',
    dateFilter: {},
    filters: {},
    id: '',
    searchData: {},
    source: {},
    type: '',
    use_synonyms: false
  },
  date: ''
};

export const channelTypeTitles: Record<(typeof CHANNELS)[number], string> = {
  application: 'Applications',
  search: 'Search',
  quick_search: 'Quick Search',
  advanced_search: 'Advanced Search',
  document_search: 'Document Search',
  source: 'Sources'
};

export const redirectTabs: Record<(typeof CHANNELS)[number], string> = {
  application: 'application',
  search: 'searches',
  quick_search: 'searches',
  advanced_search: 'searches',
  document_search: 'document-searches',
  source: 'source'
};
