const styles = {
  wrapper: {
    ml: 1,
    mb: 3,
    borderRadius: 1,
    border: '1px solid',
    backgroundColor: 'gray.50',
    p: 1
  },
  yearText: {
    color: 'gray.900',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: ' 120%',
    whiteSpace: 'nowrap'
  },
  productText: {
    overflow: 'hidden',
    color: 'gray.650',
    textOverflow: 'ellipsis',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
    textTransform: 'capitalize'
  },
  secondaryText: {
    color: 'gray.800',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%'
  },
  sectionText: {
    marginRight: 0.75,
    overflow: 'hidden',
    color: 'gray.900',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '120%',
    whiteSpace: 'nowrap',
    maxWidth: 200
  },
  secondarySectionText: {
    color: 'gray.800',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%'
  },
  documentIcon: { fontSize: 14, mr: 0.75 },
  pdcoNumberText: {
    overflow: 'hidden',
    color: 'gray.650',
    textOverflow: 'ellipsis',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '120%',
    whiteSpace: 'nowrap'
  },
  accordion: {
    display: 'flex',
    flexDirection: 'column-reverse',
    boxShadow: 'none',
    background: 'none',
    border: 'none',
    '&::before': {
      display: 'none'
    },
    '& .Mui-expanded': {
      marginTop: '0px !important'
    }
  },
  hideIcon: {
    width: '16px',
    height: '12px',
    marginRight: '3px'
  },
  accordionSummary: {
    width: 'fit-content',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: 0,
    mx: 'auto',
    pt: 0.5,
    pl: 2,
    minHeight: 'unset',
    '&.Mui-expanded': {
      mx: 'auto',

      minHeight: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0
      }
    }
  },
  hideText: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '120%'
  },
  accordionDetails: {
    boxShadow: 'none',
    border: 'none',
    padding: 0,
    '& .MuiAccordionDetails-root': {
      padding: 0,
      marginTop: '12px'
    }
  },
  accordionDetails2: {
    boxShadow: 'none',
    border: 'none',
    px: '8px',
    py: 0,
    '& .MuiAccordionDetails-root': {
      padding: 0
    }
  },
  ulStyle: { margin: 0, paddingLeft: 3 },
  liStyle: { marginBottom: '8px' },
  accordionSummaryText: {
    color: 'gray.800',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%'
  },
  daysContainer: {
    borderRadius: 1,
    backgroundColor: 'gray.200',
    px: 0.5,
    height: 19,
    mr: 0.75
  },
  nameTag: {
    display: 'flex',
    padding: '0px 4px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '4px',
    color: 'white.main',
    textAlign: 'center',
    fontFamily: 'Mulish',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '13px',
    textTransform: 'uppercase',
    '&#patent': {
      backgroundColor: 'primary.300'
    },
    '&#exclusivity': {
      backgroundColor: 'secondary.500'
    },
    '&#type': {
      backgroundColor: 'gray.600'
    }
  },
  closeIconButton: { ml: 'auto', p: 0.75, mr: -0.75 },
  drawerHeaderIcon: { fontSize: 16, color: 'white.main' },
  secondaryDrawer: {
    position: 'fixed',
    top: 0,
    transform: 'translate(-100%, 0)',
    height: '100%',
    width: 300,
    bgcolor: 'white.main',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderRight: '1px solid gray',
    borderColor: 'primary.100',
    overflow: 'hidden'
  },
  secondaryDrawerListItemText: {
    color: 'gray.800',
    fontSize: 14,
    letterSpacing: 0.2,
    width: 'calc(100% - 28px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap'
  },
  secondaryDrawerListItemSelectedText: {
    color: 'gray.900',
    fontSize: 14,
    letterSpacing: 0.2,
    width: 'calc(100% - 28px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontWeight: 700
  },
  secondaryDrawerListItemSelectedCheckbox: { ml: 'auto', fontSize: 16, color: 'gray.800' },
  secondaryDrawerListItem: { px: 3, py: 0.75, cursor: 'pointer' },
  secondaryDrawerListItemSelected: { px: 3, py: 0.75, bgcolor: 'primary.0' },
  cardInfoComponent: {
    width: 360
  },
  drawerTitle: {
    maxWidth: '45%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white.main',
    fontSize: 20,
    fontWeight: 700
  }
};

export default styles;
