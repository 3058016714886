import { memo, useState, MouseEvent } from 'react';

import { Drawer, Stack, Tooltip, Typography, IconButton, Button, Box } from '@mui/material';

import SubApprovalTimelineDetails from './SubApprovalTimelineDetails';

import { CloseCrossIcon, InfoIconSummary, HideIcon, ViewIcon } from '../../../assets/svgs/Icons';

import subApprovalTimelineStyles from './SubApprovalTimeline.styles';
import { getSubmissionDetails } from './utils';
import TitleInfoPopover from '../../Dialogs/DocumentListing/components/TitleInfoPopover';
import Card from '../../../pages/SearchResults/components/Card';

const SubApprovalTimelineDrawer = ({
  onClose,
  defaultApprovalHistory,
  allApprovalHistory,
  applicationDetails
}: {
  onClose: () => void;
  defaultApprovalHistory: {
    [key: string]: any;
  };
  allApprovalHistory: any[];
  applicationDetails: any;
}) => {
  const [showAllTimeline, setShowAllTimeline] = useState(false);
  const [infoElement, setInfoElement] = useState<HTMLButtonElement | null>(null);

  const currentSubmissionTimeline = getSubmissionDetails(defaultApprovalHistory);

  const allSubmissionTimeline = allApprovalHistory
    .filter((approval: any) => {
      return (
        approval?.application_number !== defaultApprovalHistory?.application_number &&
        approval?.timeline?.length
      );
    })
    ?.map((approval: any, index) => {
      return {
        id: index + 1,
        ...getSubmissionDetails(approval)
      };
    });

  return (
    <Drawer
      open
      anchor='right'
      transitionDuration={500}
      hideBackdrop={false}
      onClose={onClose}
      sx={subApprovalTimelineStyles.drawer}>
      <Stack direction='row' sx={subApprovalTimelineStyles.headerContainer}>
        <Stack direction='row'>
          <Tooltip title={defaultApprovalHistory?.product_name ?? ''}>
            <Typography sx={subApprovalTimelineStyles.applicationName}>
              {applicationDetails?.title ?? ''}
            </Typography>
          </Tooltip>
          <IconButton
            onClick={(event: MouseEvent<HTMLButtonElement>) => setInfoElement(event.currentTarget)}>
            <InfoIconSummary sx={subApprovalTimelineStyles.icon} />
          </IconButton>
        </Stack>
        <IconButton onClick={onClose} sx={subApprovalTimelineStyles.closeIconButton}>
          <CloseCrossIcon onClick={onClose} sx={subApprovalTimelineStyles.icon} />
        </IconButton>
        <TitleInfoPopover
          open={!!infoElement}
          anchorEl={infoElement}
          infoComponent={
            <Stack width={360}>
              <Card
                data={applicationDetails}
                hideApplication={() => {}}
                triggerChatRIA={() => {}}
                setFavoriteInHook={() => {}}
                setInProjectInHook={() => {}}
                setSubscriptionInHook={() => {}}
                hideCardOptions
              />
            </Stack>
          }
          onClose={() => setInfoElement(null)}
        />
      </Stack>

      <Box overflow='auto'>
        <SubApprovalTimelineDetails submissionTimeline={currentSubmissionTimeline} />

        {allSubmissionTimeline?.length > 0 && (
          <Stack alignItems='center' mt={4} mb={6}>
            <Button
              disableRipple
              sx={subApprovalTimelineStyles.timelineButton}
              onClick={() => setShowAllTimeline(!showAllTimeline)}>
              {showAllTimeline ? (
                <HideIcon sx={subApprovalTimelineStyles.timelineButtonIcon} />
              ) : (
                <ViewIcon sx={subApprovalTimelineStyles.timelineButtonIcon} />
              )}
              {showAllTimeline ? 'Hide' : 'View'} all Timelines
            </Button>
          </Stack>
        )}

        {showAllTimeline && allSubmissionTimeline?.length > 0 && (
          <Stack gap={7}>
            {allSubmissionTimeline?.map((submission: any) => (
              <SubApprovalTimelineDetails key={submission.id} submissionTimeline={submission} />
            ))}
            <Stack alignItems='center' mb={6}>
              <Button
                disableRipple
                sx={subApprovalTimelineStyles.timelineButton}
                onClick={() => setShowAllTimeline(false)}>
                <HideIcon sx={subApprovalTimelineStyles.timelineButtonIcon} />
                Hide all Timelines
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

export default memo(SubApprovalTimelineDrawer);
